// TOREFACTOR: This is mounting and unmounting a LOT.
import { Collapse, DropdownToggle, NavbarToggler, UncontrolledDropdown } from 'reactstrap';
import { matchPath, NavLink, Route } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { DSPill, DSPillType } from '@demandstar/components/pill';
import { Margin, Padding } from '@demandstar/components/styles';

import Configurations from '../../settings';
import HeaderDropDown from '../common/headerdropdown';
import LogoDS from '../../assets/images/logo_ds';

import { addDsEvent, clearStorageFn, hasPermission, openHelpPage } from '../../utils/helpers';
import { bonfireBannerLinks } from '../../utils/constants/bid';
import { showBanners } from 'src/shared/constants';
import {
  appstatetokenName,
  idleTimeOut,
  MemberType,
  relogintokenName,
  tokenCookieName,
  TourId,
  userPermissionKeys,
} from '../../utils/constants';
import { getRefreshToken, getUserAccounts, setSharedDetails } from '../../store/actions';
import { navItems, opsNavItems } from './header.constants';

import { Breadcrumbs } from '../common/breadcrumb';
import { Feature } from '../common/feature-flag';
import { getCookie } from '../../utils/cookie';
import { useDSSelector } from '../../store/reducers';
import { useIdleMonitor } from '../../utils/helperHooks';
import { useRouter } from 'src/shared/hooks';
import { useSetMemberInfo } from '../../shared/hooks/useMemberInfo';

const BetaPill = styled.div`
  display: inline-flex;
  align-content: flex-start;

  > div {
    height: 1.25rem;
    margin: 0 0 0 ${Margin.Small};
    padding: 0 ${Padding.Small};

    > span {
      font-size: 0.889rem !important;
      margin: 0 ${Margin.Small};
      position: inherit !important;
    }
  }
`;

export const Header = () => {
  const dispatch = useDispatch();

  // TODO: replace Redux state with Recoil from `useAuth` hook
  const auth = useDSSelector(state => state.auth);
  // TODO: replace Redux acctInfo in recoil
  const userAccounts = useDSSelector(state => state.accountinfo?.userAccounts) || [];
  const tourStart = useDSSelector(state => state.tours?.tourStart);
  const tourId = useDSSelector(state => state.tours?.tourId);
  const currentDSTheme = useDSSelector(state => state.shared?.currentDSTheme) || 'light_DS';
  const { history, routerParams } = useRouter();
  const {
    location: { pathname },
  } = history;

  const { fln: fullName, mt: memberType, opi = '', tk = '', un = '', us, mi } = auth || {};
  const buyer = memberType === MemberType.AgencyBuyer;
  const userpath = buyer ? '/buyers' : '/suppliers';
  const memberinfo = useDSSelector(state => state.memberinfo);
  const { prms } = memberinfo || {};
  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  const filteredNavItems = hasEditMemberPermission
    ? navItems
    : navItems.filter(nav => nav.name !== 'Contracts');

  useEffect(() => {
    if (sessionStorage.getItem(relogintokenName) && auth && tk && un) {
      sessionStorage.removeItem(relogintokenName);
      dispatch(getRefreshToken());
    }
  }, [dispatch, tk, un, auth]);

  // TODO: move getAccts request to a Recoil selector.
  useEffect(() => {
    if (opi && mi) dispatch(getUserAccounts(mi));
  }, [dispatch, mi, opi]);

  // Set the selectedMemberId and selectedMemberType once a valid auth is found.
  useSetMemberInfo(auth);

  useEffect(() => {
    const CheckLocalStorage = setTimeout(() => {
      const lStorage = localStorage.getItem(appstatetokenName) || '';
      if (!lStorage) {
        clearStorageFn(true, true);
      }
    }, 10000);
    return () => clearTimeout(CheckLocalStorage);
  }, []);

  useEffect(() => {
    addDsEvent(window, 'storage', (event: any) => {
      if (event.key === appstatetokenName && !event.newValue) {
        clearStorageFn(true, true);
      }
    });
  }, []);

  useEffect(() => {
    if (auth && (!memberType || opi)) {
      const CheckOpsLocalStorage = setInterval(() => {
        const tokenValue = getCookie(tokenCookieName);
        if (!tokenValue) {
          const regEx = /^\/(buyers|suppliers)\/(bids)\/[0-9]+/;
          const regExLimited = /^\/(limited)\/(bids)\/[0-9]+/;
          if (regEx.test(pathname) || regExLimited.test(pathname)) {
            const limitedBidId = parseInt(routerParams.bidId ?? '') || '';
            clearStorageFn(false, false);
            window.location.href = `/app/limited/bids/${limitedBidId}/details`;
          } else clearStorageFn(true, true);
        }
      }, 1000);
      return () => clearInterval(CheckOpsLocalStorage);
    }
  }, [auth, memberType, opi, pathname, routerParams]);

  useEffect(() => {
    dispatch(setSharedDetails({ currentDSTheme: 'light_DS' }));
  }, [dispatch]);

  useEffect(() => {
    if (currentDSTheme) {
      document.body.className = '';
      document.body.classList.add(currentDSTheme);
    }
  }, [currentDSTheme]);

  const ApplyClassForSubFn = useCallback(() => {
    const currentpathname = window.location.pathname || '';
    const maintag = document.getElementById('root');
    const path = currentpathname.split('/');
    if (maintag) {
      if (path.includes('bid') || path.includes('bids')) {
        maintag.className = 'bidThem';
      } else if (path.includes('quotes') || path.includes('quote')) {
        maintag.className = 'quotesThem';
      } else {
        maintag.className = 'defaultThem';
      }
    }
  }, []);

  useEffect(() => {
    ApplyClassForSubFn();
  }, [ApplyClassForSubFn]);

  const GoHome = (e: { preventDefault: () => unknown }) => {
    e && e.preventDefault();
    if (auth) {
      const RedirectURL = `${userpath}/dashboard`;
      history.push(RedirectURL);
    } else {
      window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useIdleMonitor(idleTimeOut, () => window.location.reload());

  const currentAccount = userAccounts.find((item: { accountId: number }) => item.accountId === us);

  const tourPosition: { position?: 'relative' } =
    tourStart && tourId === TourId.bidSearch ? { position: 'relative' } : {};

  return (
    <>
      {auth && opi ? (
        <>
          {currentAccount && !currentAccount.active ? (
            <div className='opsAlertWrapper bg-yellow200 text-grayD200'>
              Pinned by OPS - This account is inactive.
            </div>
          ) : (
            <div className='opsAlertWrapper bg-red200' data-testid='layout.header.ops.heading'>
              Pinned by OPS
            </div>
          )}
        </>
      ) : null}
      <div className={'headerBreadcrumbWrapper ' + (auth && opi ? 'ops' : '')} style={tourPosition}>
        <header>
          <nav className='navbar navbar-expand-lg navbar-light'>
            <span className='navbar-brand staticLink' onClick={GoHome}>
              <LogoDS />
            </span>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <ul className='navbar-nav mt-2 mt-lg-0 float-right'>
                {auth && auth.mt
                  ? filteredNavItems
                      .filter(
                        ({ memberTypeFilter }) => !memberTypeFilter || memberTypeFilter(auth.mt),
                      )
                      .map(nav => {
                        const routeMatch = `${userpath}${nav.routeMatch}`;
                        const href = `${userpath}${nav.href}`;
                        const link = (
                          <li className='nav-item' key={nav.name}>
                            <NavLink
                              activeClassName='active'
                              className={`nav-link ${
                                matchPath(pathname, {
                                  path: routeMatch,
                                  exact: true,
                                }) && 'active'
                              }`}
                              to={href}
                            >
                              {nav.name}
                            </NavLink>
                          </li>
                        );
                        return nav.featureFlag ? (
                          <Feature flag={nav.featureFlag} key={nav.name}>
                            {link}
                          </Feature>
                        ) : (
                          link
                        );
                      })
                  : opsNavItems.map(nav => (
                      <li className='nav-item' key={nav.name}>
                        <a
                          className={`nav-link ${
                            matchPath(pathname, {
                              path: `${userpath}${nav.routeMatch}`,
                              exact: true,
                            }) && 'active'
                          }`}
                          href={nav.href}
                        >
                          {nav.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </Collapse>
            <span
              className='helpLink staticLink cursorPointer'
              title='Help'
              onClick={() => openHelpPage(memberType)}
            >
              <i className='mdi mdi-help-circle-outline mdi-24px mr-4' />
            </span>
            <UncontrolledDropdown size='sm' className='dropDownWrapper'>
              <DropdownToggle
                title={fullName}
                className='btn btn-link dropdown-toggle'
                data-testid='account-dropdown'
              >
                {fullName}
              </DropdownToggle>
              <HeaderDropDown />
            </UncontrolledDropdown>
          </nav>
          {showBanners === true ? (
            <Route path='/suppliers/dashboard'>
              <div
                className='pb-1 bg-purple text-white text-center '
                onClick={() => window.open(bonfireBannerLinks, '_blank')}
              >
                {' '}
                {/* add new link here  */}
                <b>
                  {' '}
                  Now you can connect with over 1,800 new state & local governments through the
                  DemandStar Network <br /> Click here to learn more{' '}
                </b>
              </div>
            </Route>
          ) : null}
        </header>
        <Breadcrumbs />
      </div>
    </>
  );
};
