import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { DSTextInput, LibraryStyleWrapper } from '@demandstar/components/inputs/text-input';
import { DSAlert } from '@demandstar/components/alert';
import { DSButton } from '@demandstar/components/button';
import { DSCheckbox } from '@demandstar/components/inputs';
import { DSLink } from '@demandstar/components/link';
import { DSSelect } from '@demandstar/components/inputs/';
import { Status } from '@demandstar/components/constants/common-enum';

import {
  CommodityState,
  useCommodities,
  useRefreshCommodities,
} from 'src/shared/hooks/useCommodity';
import { Sort, SortOption } from './CommodityCode.d';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { recoilRegistrationDataState } from 'src/store/recoil/registrationState';
import { registrationComponent } from 'src/utils/constants';
import { RegistrationData } from 'src/types/supplierregistration';
import { scrollToTop } from 'src/utils/helpers';
import { useDebounce } from '../../../utils/helperHooks';
import { useRegistration } from 'src/shared/hooks/useRegistration';

interface Commodity {
  commodityCategory: string;
  commodityCode: string;
  commodityDescription: string;
  commodityGroup: string;
  commodityId: number;
  formattedCode: string;
  fullCode: string;
  isSelected: boolean;
}

export const CommodityCode = () => {
  const sortAsc: SortOption = { label: 'A - Z', value: 'asc' };
  const sortDesc: SortOption = { label: 'Z - A', value: 'desc' };
  const sortOptions: SortOption[] = [sortAsc, sortDesc];

  const registrationData = useRecoilValue<RegistrationData>(recoilRegistrationDataState);
  const [inputValue, setInputValue] = useState('');
  const [commMap, setCommMap] = useState<Map<string, Commodity>>(new Map());
  const [commoditiesFiltered, setCommoditiesFiltered] = useState<Commodity[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isSelectedNone, setIsSelectedNone] = useState(false);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const [confirmationPopup, toggleConfirmationPopup] = useState(false);
  const [sortOption, setSortOption] = useState<Sort>('asc');
  const { commodities } = useCommodities();
  const { saveIncompleteRegistration } = useRegistration();

  useRefreshCommodities();

  const inputValueDebounced = useDebounce(inputValue, 500);

  const findCommodities = (comms: Commodity[], text: string) => {
    if (!text) {
      return comms.filter((comm: Commodity) => {
        return comm.isSelected;
      });
    }

    const upperCase = text.toUpperCase();
    return comms.filter((comm: Commodity) => {
      return comm.isSelected || comm.commodityDescription.indexOf(upperCase) > -1;
    });
  };

  const findCommoditiesCategories = (
    commMap: Map<string, Commodity>,
    comms: Commodity[],
  ): Commodity[] => {
    const categoryCodes = new Set<string>([]);

    comms.forEach((comm: Commodity) => {
      if (comm.commodityCategory === '000') {
        return;
      }

      categoryCodes.add(`${comm.commodityGroup}-${comm.commodityCategory}-00`);
    });

    return Array.from(categoryCodes).reduce((comms: Commodity[], code: string) => {
      if (commMap.has(code)) {
        const comm = commMap.get(code);
        if (comm) {
          comms.push(comm);
        }
      }
      return comms;
    }, []);
  };

  const sortCommodities = (commodities: Commodity[], order: string) => {
    commodities.sort((a, b) => {
      if (a.isSelected === b.isSelected) {
        return (
          (a.commodityDescription > b.commodityDescription ? 1 : -1) * (order === 'asc' ? 1 : -1)
        );
      } else {
        return a.isSelected > b.isSelected ? -1 : 1;
      }
    });

    return [...commodities];
  };

  const select = (commodity: Commodity) => {
    const tempCommodities = [...commoditiesFiltered];
    const tempCommodity = tempCommodities.find(
      (temp: Commodity) => temp.formattedCode === commodity.formattedCode,
    );
    if (tempCommodity) {
      tempCommodity.isSelected = !commodity.isSelected;
    }
    setCommoditiesFiltered(tempCommodities);
  };

  const selectAll = () => {
    if (!isSelectedAll) {
      const tempList = [...commoditiesFiltered];
      tempList.forEach(commodity => (commodity.isSelected = true));
      setCommoditiesFiltered(tempList);
      setIsSelectedNone(false);
    }

    setIsSelectedAll(!isSelectedAll);
  };

  const selectNone = () => {
    if (!isSelectedNone) {
      const tempList = [...commoditiesFiltered];
      tempList.forEach(commodity => (commodity.isSelected = false));
      setCommoditiesFiltered(tempList);
      setIsSelectedAll(false);
    }

    setIsSelectedNone(!isSelectedNone);
  };

  const hasFreeAgency = () => {
    return !!registrationData.freeAgency;
  };

  const moveToNextPage = async (commodityIds: number[]) => {
    const regData = { ...registrationData, commodityIds };
    await saveIncompleteRegistration(registrationComponent.ChooseSubscription, regData);
  };

  const goBack = async () => {
    await saveIncompleteRegistration(registrationComponent.ChooseAgency, registrationData);
  };

  const confirmSkip = () => {
    moveToNextPage([]);

    toggleConfirmationPopup(false);
  };

  const confirmContinue = () => {
    let commodityIds: number[] = [];

    if (hasFreeAgency()) {
      commodityIds = commoditiesFiltered
        .filter((comm: Commodity) => comm.isSelected)
        .map((comm: Commodity) => comm.commodityId);

      if (!commodityIds.length) {
        toggleConfirmationPopup(true);
        return;
      }
    }

    moveToNextPage(commodityIds);
  };

  useEffect(() => {
    if (commodities) {
      const commMap: Map<string, Commodity> = new Map();
      const selectedCommodites = new Set(registrationData.commodityIds);

      commodities.forEach((comm: CommodityState) => {
        const formattedCode = `${comm.commodityGroup}-${comm.commodityCategory}-${comm.commodityCode}`;

        commMap.set(formattedCode, {
          commodityCategory: comm.commodityCategory,
          commodityCode: comm.commodityCode,
          commodityDescription: comm.commodityDescription?.toUpperCase(),
          commodityGroup: comm.commodityGroup,
          commodityId: comm.commodityId,
          formattedCode: formattedCode,
          fullCode: comm.fullCode,
          isSelected: selectedCommodites.has(comm.commodityId),
        });
      });

      setCommMap(commMap);
    }
  }, [commodities, registrationData.commodityIds]);

  useEffect(() => {
    if (commodities) {
      const foundCommodities = findCommodities(Array.from(commMap.values()), inputValueDebounced);
      const categories = findCommoditiesCategories(commMap, foundCommodities);

      setCommoditiesFiltered(sortCommodities(categories, sortOption));
      setIsSelectedAll(false);
      setIsSelectedNone(false);
      setIsEmptyResult(!!inputValueDebounced && (!categories || categories.length === 0));
    } else {
      setCommoditiesFiltered([]);
    }
  }, [inputValueDebounced, commMap, commodities, sortOption]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='colWrapper'>
              <div className='row'>
                <div className='col col-8'>
                  <h2 className='arrowWrapper'>2 of 4: Choose Commodity Codes</h2>
                </div>
              </div>
              <div className='row'>
                <div className='col col-8'>
                  <h2>What are commodity codes?</h2>
                  <p className='reg-intro no-top-padding'>
                    DemandStar commodity codes are how we categorize goods and services for
                    government procurement. When you choose commodity codes, DemandStar uses them to
                    match what your company provides to what governments in your subscription need,
                    helping you find the best bids and quotes to bid on.
                  </p>
                </div>
              </div>
              {!hasFreeAgency() && (
                <div className='row'>
                  <div className='col col-8'>
                    <DSAlert
                      header={"You haven't selected a free agency."}
                      link={
                        <DSLink onClick={goBack}>
                          Please go back and select and agency for the best experience.
                        </DSLink>
                      }
                      type={Status.Warning}
                    >
                      Without an agency selected, we can&apos;t match you up with commodity codes.
                    </DSAlert>
                  </div>
                </div>
              )}
              {hasFreeAgency() && (
                <>
                  <div className='row'>
                    <div className='col col-8'>
                      <h2>Tell us what your business does</h2>
                      <p className='reg-intro no-top-padding'>
                        Use a few keywords to tell us what your business does, and we&apos;ll match
                        you up with some commodity codes. You can add more codes or change these in
                        your DemandStar profile later.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col col-6'>
                      <LibraryStyleWrapper>
                        <DSTextInput
                          name='search'
                          label='Search Commodity Codes'
                          message='For example, "construction"'
                          value={inputValue}
                          onChange={setInputValue}
                        />
                      </LibraryStyleWrapper>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col col-3'>
                      <DSSelect
                        label='Sort results alphabetically'
                        isSearchable={false}
                        onSelect={setSortOption}
                        options={sortOptions}
                        labelField='label'
                        valueField='value'
                        name='sortResults'
                        value={sortOption}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col col-10'>
                      <div data-testid='filter-results'>
                        {commoditiesFiltered.map(commodity => (
                          <DSCheckbox
                            key={commodity.commodityId}
                            name='checkbox'
                            label={commodity.commodityDescription}
                            onClick={() => select(commodity)}
                            checked={commodity.isSelected}
                          />
                        ))}
                        {!!commoditiesFiltered && commoditiesFiltered.length > 0 && (
                          <DSCheckbox
                            name='checkbox'
                            label='Select All'
                            onClick={selectAll}
                            checked={isSelectedAll}
                          />
                        )}
                        {!!commoditiesFiltered && commoditiesFiltered.length > 0 && (
                          <DSCheckbox
                            name='checkbox'
                            label='Select None'
                            onClick={selectNone}
                            checked={isSelectedNone}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {isEmptyResult && (
                    <div className='row'>
                      <div className='col col-8'>
                        <DSAlert header='Your search returned no results.' type={Status.Warning}>
                          {
                            'Don\'t worry! We\'re working to improve our search results, but in the meantime, try other words or spellings. For example, if you searched "architecture" try "architectural services".'
                          }
                        </DSAlert>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className='row pt-3 mt-3'>
                <div className='col col-8'>
                  <DSButton type='secondary' onClick={goBack}>
                    Go Back
                  </DSButton>
                  <span className='bttn-continue'>
                    {hasFreeAgency() && (
                      <span className='mr-3'>
                        <DSLink onClick={() => toggleConfirmationPopup(true)}>
                          Skip Commodity Codes
                        </DSLink>
                      </span>
                    )}
                    <DSButton type='primary' onClick={confirmContinue}>
                      Continue
                    </DSButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        size='lg'
        title='Are you sure?'
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        <p>
          To get notifications about bids that may be a good fit for your business, you must choose
          commodity codes. Adding codes is free!
        </p>
        <div className='pt-3 mt-3'>
          <DSButton type='secondary' onClick={confirmSkip}>
            Skip adding commodity codes
          </DSButton>
          <span className='bttn-continue'>
            <DSButton type='primary' onClick={() => toggleConfirmationPopup(false)}>
              Add commodity codes
            </DSButton>
          </span>
        </div>
      </ModalPopUp>
    </>
  );
};
