import { matchPath, useHistory } from 'react-router';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { useMemo } from 'react';

import { BreadcrumbItem } from '@demandstar/components/breadcrumbs';

import { buyerRoutes, opsRoutes, supplierRoutes } from 'src/containers/component-routes';
import { MemberType, Pages } from 'src/utils/constants';

import { useAuth } from 'src/shared/hooks/useAuth';

import { breadcrumbsState } from 'src/store/recoil/navigationState';

/** TOREFACTOR: Implement intelligent breadcrumbs that pull from appropriate state hooks (e.g. useBid) */
/** Enum of pages for which "recordLabel" should be used in place of page name
 *  e.g. "DSSAMPLEBIDID-1101011" (bidId) should show in place of "Bids Details" */
enum RecordDetailPage {
  Bid = 'bids details',
  Quote = 'quote details',
  Contract = 'contract details',
}

const recordDetailsPages: string[] = Object.values(RecordDetailPage);

function isRecordDetails(page?: string) {
  return page && recordDetailsPages.includes(page.toLowerCase());
}

export function useBreadcrumbs() {
  const [breadcrumbInfo, setBreadcrumbInfo] = useRecoilState(breadcrumbsState);
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const { component, recordId, recordLabel, page, activePageLabel } = breadcrumbInfo;
  const {
    auth: { memberType, opsId },
  } = useAuth();

  /** There is a little-used way in which an OPS user can log in to the application w/o "pinning in"
   *  to a member account. This requires slightly different Breadcrumb behavior.
   *  An example of this is the "Agency Creation" wizard. */
  const isUnpinnedOps = useMemo(() => {
    return !memberType && opsId > 0;
  }, [memberType, opsId]);

  const routeOptions = useMemo(() => {
    const memberOptions = memberType === MemberType.AgencyBuyer ? buyerRoutes : supplierRoutes;

    if (opsId > 0) {
      return memberOptions.concat(opsRoutes);
    }

    return memberOptions;
  }, [memberType, opsId]);

  const currentRoute = useMemo(() => {
    return page
      ? routeOptions.find(route => route.page === page)
      : routeOptions.find(route =>
          matchPath(pathname, {
            path: route.path,
            exact: true,
          }),
        );
  }, [page, pathname, routeOptions]);

  const resetBreadcrumbInfo = useRecoilCallback(
    ({ set }) =>
      () => {
        set(breadcrumbsState, { activePageLabel: '' });
      },
    [],
  );

  const breadcrumbs: BreadcrumbItem[] = useMemo(() => {
    if (!currentRoute?.breadcrumb || (!recordLabel && !activePageLabel && !currentRoute?.page))
      return [];

    const breadcrumb = currentRoute.breadcrumb;
    /** TOREFACTOR: this should work for :quoteId as well */
    const recordIdRegex = /(:bidId)|(:contractId)/g;
    const breadcrumbKeys = Object.keys(currentRoute.breadcrumb);
    const homePath =
      memberType === MemberType.AgencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
    return (
      breadcrumbKeys
        /** Home label is hidden for OPS user not pinned into an account */
        .filter(label => !isUnpinnedOps || label !== 'Home')
        .map(pageName => {
          const pathStr = breadcrumb[pageName];
          const path =
            pageName === 'Home'
              ? homePath
              : (recordId &&
                  pathStr.match(recordIdRegex) &&
                  pathStr.replace(recordIdRegex, recordId.toString())) ||
                pathStr;
          return {
            display: recordLabel && isRecordDetails(pageName) ? recordLabel : pageName,
            handleClick: () => {
              history.push(path);
            },
          } as BreadcrumbItem;
        })
        .concat([
          {
            display:
              /** If the current page is 'Bids Details', for example, this breadcrumb displays the Bid ID
               * which is set on the `recordLabel` attr of breadcrumbs state */
              (recordLabel && isRecordDetails(activePageLabel) ? recordLabel : activePageLabel) ||
              currentRoute.page,
          },
        ])
    );
  }, [currentRoute, recordLabel, activePageLabel, memberType, isUnpinnedOps, recordId, history]);

  return {
    breadcrumbs,
    breadcrumbInfo,
    component,
    currentRoute,
    resetBreadcrumbInfo,
    setBreadcrumbInfo,
  };
}
