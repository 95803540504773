import React from 'react';
import { Table } from 'reactstrap';

import {
  bidItemParamType,
  ebidItemParamType,
  purchaseInfoParamType,
} from '../../../../../types/biddetail';
import { authTypes } from '../../../../../types/auth';
import BidWriter from './bidwriterview';
import { displayDateTime } from '../../../../../utils/helpers';
import { partOfExtNetworkText } from '../../../../../utils/constants';
import { MemberType } from '../../../../../utils/constants';

const tdWidth = {
  width: '240px',
};

interface BidDetailProps {
  auth: authTypes;
  data: bidItemParamType;
  purchaseInfo: purchaseInfoParamType;
  eBids?: ebidItemParamType;
  bidWriterInfo: Record<string, unknown>;
}

const BidDetail = (props: BidDetailProps) => {
  const { auth } = props;
  const { mt: memberType } = auth || {};
  const bonfireAgencyUrl = `https://${props.data.bonfireAgencyURL}/portal?tab=login`;
  return (
    <>
      <Table borderless className='tableData'>
        <tbody>
          <tr>
            <th style={tdWidth}>Agency Name</th>
            <td>
              {props.data.agencyName}
              <br />
              <div className='text-purple200'>
                {props.data.isBonfireBid === true ? <>( {partOfExtNetworkText} )</> : null}
              </div>
            </td>
          </tr>
          {!props.data.isBonfireBid ? (
            <tr>
              <th style={tdWidth}>Bid Writer</th>
              <td>
                <BidWriter {...props} bidWriter={props.data.bidWriter} />
              </td>
            </tr>
          ) : props.data.contactInfoVisibility === 1 ? (
            <>
              <tr>
                <th style={tdWidth}>Bid Writer</th>
                <td>{props.data.contactName}</td>
              </tr>
              <tr>
                <th style={tdWidth}>Contact Email</th>
                <td>{props.data.contactInfo}</td>
              </tr>
            </>
          ) : null}

          <tr>
            <th>Bid ID</th>
            <td>{props.data.bidIdentifier}</td>
          </tr>
          <tr>
            <th>Bid Type</th>
            <td>{props.data.bidTypeDescription}</td>
          </tr>
          {props.data.broadcastDate ? (
            <tr>
              <th>Broadcast Date</th>
              <td className='text-red200'>
                {displayDateTime(props.data.broadcastDate, props.data.tzfn)}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Fiscal Year</th>
            <td>{props.data.fiscalYear}</td>
          </tr>
          {props.data.dueDate ? (
            <tr>
              <th>Due</th>
              <td className='text-red200'>
                {displayDateTime(props.data.dueDate, props.data.tzfn)}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Bid Status Text</th>
            <td>{props.data.bidStatusText}</td>
          </tr>
          {memberType !== MemberType.AgencyBuyer ? (
            <>
              {props.purchaseInfo && props.purchaseInfo.showButton ? (
                props.purchaseInfo.isPurchased ? (
                  <tr>
                    <th>Bid Package Purchased on</th>
                    <td className='text-red200'>
                      {displayDateTime(props.purchaseInfo.orderDate, props.data.tzfn)}
                    </td>
                  </tr>
                ) : null
              ) : null}
              {props.eBids && props.data.eBidding === 'ViewEdit' ? (
                <tr>
                  <th>eBid Submitted on</th>
                  <td className='text-red200'>
                    {displayDateTime(props.eBids.responseDateTime, props.data.tzfn)}
                  </td>
                </tr>
              ) : null}
            </>
          ) : null}
          <tr>
            <th>
              Additional Registration
              <br />
              <div className='text-danger'>(NEW)</div>
            </th>
            <td>
              {props.data.isBonfireBid === false ? (
                <>NONE</>
              ) : (
                <span
                  className='staticLink'
                  onClick={() => window.open(bonfireAgencyUrl, '_blank')}
                >
                  {bonfireAgencyUrl}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default BidDetail;
