import React, { memo } from 'react';
import { defaultFilter } from '../../../store/reducers/bids';
import { initialquotesFilters } from '../../../store/reducers/quotes';
import { BidExternalStatusType, MemberType, quoteExternalStatus } from '../../../utils/constants';
import { encodeFilters, formatNumber } from '../../../utils/helpers';
import Cardchart from '../../common/dashboard/cardchart';
import CardCount from '../../common/dashboard/cardcount';
import { sharedTypes } from '../../../types/shared';
import { authTypes } from '../../../types/auth';
import { getBidsTypes } from '../../../types/bids';
import { Colors } from '../../../shared/styles';

interface propsTypes {
  shared: sharedTypes;
  auth: authTypes;
  history: any;
  setBidDetails: any;
  setQuotesDetails: any;
  getBids: getBidsTypes;
  getQuotesList: any;
}

function Rightside(props: propsTypes) {
  const { shared, setBidDetails, setQuotesDetails, auth, getBids, getQuotesList, history } = props;
  const { dashboardData, dashboardnetworkData } = shared;
  const { bids = [], quotes = [] } = dashboardData;
  const { mt: memberType } = auth;

  const [bidsOpenCount = 0] =
    bids
      .filter(item => item.categoryType === BidExternalStatusType.Active)
      .map(item => Number(item.count)) || [];
  const [bidsAwardedCount = 0] =
    bids
      .filter(item => item.categoryType === BidExternalStatusType.Awarded)
      .map(item => item.count) || [];
  const [quotesOpenCount = 0] =
    quotes
      .filter(item => item.categoryType === quoteExternalStatus.open)
      .map(item => Number(item.count)) || [];
  const [quotesAwardedCount = 0] =
    quotes
      .filter(item => item.categoryType === quoteExternalStatus.awarded)
      .map(item => item.count) || [];

  const bidschartData =
    bids
      .filter(item => item.categoryType === BidExternalStatusType.Published)
      .map(item => ({ x: item.monthName, y: item.count })) || [];
  let bidschartDataCount: any =
    bids
      .filter(item => item.categoryType === BidExternalStatusType.Published)
      .map(item => Number(item.count)) || [];
  bidschartDataCount =
    (bidschartDataCount.length > 0 && bidschartDataCount.reduce((a: number, b: number) => a + b)) ||
    0;

  const quotschartData =
    quotes
      .filter(item => item.categoryType === quoteExternalStatus.published)
      .map(item => ({ x: item.monthName, y: item.count })) || [];
  let quoteschartDataCount: any =
    quotes
      .filter(item => item.categoryType === quoteExternalStatus.published)
      .map(item => Number(item.count)) || [];
  quoteschartDataCount =
    (quoteschartDataCount.length > 0 &&
      quoteschartDataCount.reduce((a: number, b: number) => a + b)) ||
    0;

  const {
    suppliers = 0,
    nearBySuppliers = 0,
    governments = 0,
    solicitations = 0,
  } = dashboardnetworkData;

  const gotoPage = async (status: string, Type: string) => {
    const pageType = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
    if (Type === 'bids') {
      const bidStatus = status;
      let additionalFilter = { showBids: 'Notified', bidsNotified: true } as any;
      if (memberType === MemberType.AgencyBuyer) {
        additionalFilter = { showBids: 'Mine', myBids: true };
      }
      const searchFilters = { ...defaultFilter, ...additionalFilter, bidStatus };
      await setBidDetails({
        filters: searchFilters,
        initialRequest: false,
        selectedBidIds: '',
        loadedBids: [],
        results: [],
        bidscurrentPage: 1,
      });
      await getBids();
      history.push(`/${pageType}/bids?${encodeFilters(searchFilters)}`);
    } else {
      let quoteStatuses: any = '';
      switch (status) {
        case quoteExternalStatus.open:
          quoteStatuses = { label: 'Open', value: quoteExternalStatus.open };
          break;
        case quoteExternalStatus.awarded:
          quoteStatuses = { label: 'Awarded', value: quoteExternalStatus.awarded };
          break;
        case quoteExternalStatus.cancelled:
          quoteStatuses = { label: 'Cancelled', value: quoteExternalStatus.cancelled };
          break;
        case quoteExternalStatus.closed:
          quoteStatuses = { label: 'Closed', value: quoteExternalStatus.closed };
          break;
        case quoteExternalStatus.incomplete:
          quoteStatuses = { label: 'Incomplete', value: quoteExternalStatus.incomplete };
          break;
        default:
          quoteStatuses = '';
          break;
      }
      await setQuotesDetails({
        quotesFilters: { ...initialquotesFilters, quoteStatuses },
        quotesinitial: false,
        quotesreload: false,
        quotecurrentPage: 1,
      });
      await getQuotesList({ types: 'search' });
      history.push(`/${pageType}/quotes`);
    }
  };

  return (
    <>
      <div className='row mb-4'>
        <div className='col-12 col-lg-6 mb-3 mb-lg-0 pr-lg-1'>
          <div className='chartWrapper clearfix  w-100'>
            <Cardchart
              title='Bids Published'
              data={bidschartData}
              count={formatNumber(bidschartDataCount)}
              strokecolor='#FFB84A'
              label='Bids'
            />
          </div>
          <div className='row'>
            <div className='col-12 col-sm-6 pr-lg-1 mb-3 mb-sm-0'>
              <CardCount
                title='Open Bids'
                subtitle=''
                count={formatNumber(bidsOpenCount)}
                classNames='bid'
                gotoPage={() => gotoPage(BidExternalStatusType.Active, 'bids')}
                count0Text='No Open Bids'
              />
            </div>
            <div className='col-12 col-sm-6'>
              <CardCount
                title='Awarded Bids'
                subtitle='(Last 12 Months)'
                count={formatNumber(bidsAwardedCount)}
                classNames='bid'
                gotoPage={() => gotoPage(BidExternalStatusType.Awarded, 'bids')}
                count0Text='No Awarded Bids'
              />
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='chartWrapper clearfix w-100 '>
            <Cardchart
              title='Quotes Published'
              data={quotschartData}
              count={formatNumber(quoteschartDataCount)}
              strokecolor={Colors.blueGreenMedium}
              label='Quotes'
            />
          </div>
          <div className='row'>
            <div className='col-12 col-sm-6 pr-lg-1 mb-3 mb-sm-0'>
              <CardCount
                title='Open Quotes'
                subtitle=''
                count={formatNumber(quotesOpenCount)}
                classNames='quote'
                gotoPage={() => gotoPage(quoteExternalStatus.open, 'quotes')}
                count0Text='No Open Quotes'
              />
            </div>
            <div className='col-12 col-sm-6'>
              <CardCount
                title='Awarded Quotes'
                subtitle='(Last 12 Months)'
                count={formatNumber(quotesAwardedCount)}
                classNames='quote'
                gotoPage={() => gotoPage(quoteExternalStatus.awarded, 'quotes')}
                count0Text='No Awarded Quotes'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h3>DemandStar Network</h3>
          <div className='row'>
            <div className='col-12 col-sm-6 col-lg-4 mb-3 mb-sm-0'>
              <CardCount
                title='New Suppliers Past 30 Days'
                subtitle=''
                subcounttitle='In your county'
                count={formatNumber(suppliers)}
                subcount={formatNumber(nearBySuppliers)}
                count0Text='No New Suppliers Past 30 Days'
              />
            </div>
            <div className='col-12 col-sm-6 col-lg-4 px-lg-1'>
              <CardCount
                title='New Governments This Year'
                subtitle=''
                count={formatNumber(governments)}
                count0Text='No New Governments This Year'
              />
            </div>
            <div className='col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0'>
              <CardCount
                title='Total Solicitations Published by DemandStar'
                subtitle=''
                count={formatNumber(solicitations)}
                count0Text='No Total Solicitations Published by DemandStar'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Rightside);
