import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DSButton, DSPhoneField, DSTextField } from '@demandstar/components/index';
import { FlexContainer, H2 } from '@demandstar/components/styles';

import LogoDSLoader from 'src/assets/images/loader';

import { AddressInfo, AddressLookup } from '../../common/formcomponents/AddressLookup';
import { cartTotalState, selectedProductsState } from '../../../store/recoil/productState';
import { isValidRequiredTrimmingSpace } from 'src/utils/helpers';
import { recoilRegistrationDataState } from '../../../store/recoil/registrationState';
import { registrationComponent } from '../../../utils/constants';
import { RegistrationData } from '../../../types/supplierregistration';
import { RegistrationErrorModal } from '../review-order/RegistrationErrorModal';
import { scrollToTop } from 'src/utils/helpers';
import { SubscriptionProducts } from '../../../types/products';
import { useCheckout } from '../review-order/useCheckout';
import { useRegistration } from 'src/shared/hooks/useRegistration';
import { UserType } from 'src/types/accountinfo';

export interface CompleteProfileFormInputs {
  addressInfo: AddressInfo;
  contactPhone: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  website: string;
}

/**
 * @description a form used in registration to collect contact information
 * @returns JSX.Element
 * @example <CompleteProfile setRegistrationData={(payload) => { dispatch(setRegistrationData(payload)); }} />
 */
export const CompleteProfile = () => {
  const [recoilRegistrationData, setRecoilRegistrationData] = useRecoilState<RegistrationData>(
    recoilRegistrationDataState,
  );
  const { checkout } = useCheckout();
  const { saveIncompleteRegistration, setCurrentComponent } = useRegistration();
  const cartTotal = useRecoilValue<number>(cartTotalState);
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);

  const [showModal, setShowModal] = useState(false);
  const [checkingOut, setCheckingOut] = useState(false);
  const methods = useForm<CompleteProfileFormInputs>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      contactPhone: recoilRegistrationData?.memberContactPhone?.phoneNumber || '',
      firstName: recoilRegistrationData?.account?.firstName || '',
      lastName: recoilRegistrationData?.account?.lastName || '',
      phoneNumber: recoilRegistrationData?.memberPhones?.[0]?.phoneNumber || '',
      website: recoilRegistrationData?.member?.website || '',
    },
  });
  const { formState, getValues, handleSubmit, setValue, trigger } = methods;
  const [memberTypeStatus, setMemberTypeStatus] = useState({
    type: UserType.PaidSupplier,
    status: 'SW',
  });

  const continueButtonText = cartTotal === 0 ? 'Finish registration' : 'Go to Checkout';

  const trimInputValue = (value: string) => value.trim();

  /**
   * @description updates the Recoil state with form values
   * @returns void
   * @example updateRecoilState()
   */
  const updateRecoilState = useCallback(() => {
    const regData: RegistrationData = {
      ...recoilRegistrationData,
      account: {
        ...recoilRegistrationData.account,
        userName: recoilRegistrationData?.emailAddress,
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        initials: `${getValues('firstName')[0]}${getValues('lastName')[0]}`,
      },
      member: {
        ...recoilRegistrationData.member,
        memberType: memberTypeStatus.type,
        memberStatus: memberTypeStatus.status,
        memberId: '',
        productIds:
          typeof selectedProducts.agency?.productId === 'number'
            ? [selectedProducts.agency.productId]
            : [],
        scraperDomain: '',
        shortName: recoilRegistrationData?.companyName,
        website: getValues('website'),
      },
      memberAddress: {
        ...recoilRegistrationData.memberAddress,
        address1: getValues('addressInfo.address1'),
        address2: getValues('addressInfo.address2'),
        addressType: 'MA',
        city: getValues('addressInfo.city'),
        county: getValues('addressInfo.county'),
        country: getValues('addressInfo.country'),
        countryId: getValues('addressInfo.countryId'),
        countyId: getValues('addressInfo.countyId'),
        countyName: getValues('addressInfo.countyName'),
        postalCode: getValues('addressInfo.postalCode'),
        stateId: getValues('addressInfo.stateId'),
        stateName: getValues('addressInfo.stateName'),
        state: getValues('addressInfo.state'),
      },
      memberContact: {
        email: recoilRegistrationData.emailAddress,
        mainContact: true,
      },
      memberContactPhone: { phoneType: 'MN', phoneNumber: getValues('contactPhone') },
      memberPhones: [{ phoneType: 'MN', phoneNumber: getValues('phoneNumber') }],
    };
    saveIncompleteRegistration(registrationComponent.CompleteProfile, regData);
    setRecoilRegistrationData(regData);
  }, [
    getValues,
    memberTypeStatus.status,
    memberTypeStatus.type,
    recoilRegistrationData,
    saveIncompleteRegistration,
    selectedProducts.agency?.productId,
    setRecoilRegistrationData,
  ]);

  /**
   * @description updates registration state and either moves to the next page or checks out if no products are selected
   * @returns void
   * @example submitProfile()
   */
  const submitProfile = useCallback(async () => {
    updateRecoilState();

    if (cartTotal > 0) {
      setCurrentComponent(registrationComponent.ReviewOrder);
      return;
    }

    setCheckingOut(true);
    try {
      const result = await checkout({});
      if (result?.status) {
        setCurrentComponent(registrationComponent.AccountConfirmation);
      } else {
        setShowModal(true);
      }
    } catch (error: any) {
      setShowModal(true);
    }
    setCheckingOut(false);
  }, [cartTotal, checkout, setCurrentComponent, updateRecoilState]);

  const moveBack = () => {
    updateRecoilState();
    setCurrentComponent(registrationComponent.ChooseSubscription);
  };

  /**
   * @description submits the form and attempts to move forward
   * @returns void
   * @example submitProfile()
   */
  const moveForward = async () => {
    await trigger();

    // I shouldn't have to do this formState.errors.length check
    // According to RHF docs, formState.isValid should be false if errors
    // are present. However, placing a debug between the trigger and this if
    // revealed that formState.isValid would be true while errors are present

    if (Object.keys(formState.errors).length === 0 && formState.isValid) {
      await submitProfile();
    } else {
      scrollToTop();
    }
  };

  useEffect(() => {
    if (cartTotal === 0 && selectedProducts.agency?.productId === 0)
      setMemberTypeStatus({ type: UserType.BasicSupplier, status: 'AC' });

    scrollToTop();
  }, [cartTotal, selectedProducts.agency]);

  /*
   * This sets the initial formState equal to recoilRegistrationData
   */
  useEffect(() => {
    if (recoilRegistrationData.account) {
      const account = recoilRegistrationData.account;
      Object.keys(account).forEach(key => {
        const typedKey = key as keyof typeof account;
        if (account[typedKey] !== undefined) {
          setValue(`${typedKey}`, account[typedKey]);
        }
      });
    }

    if (recoilRegistrationData.memberAddress) {
      const memberAddress = recoilRegistrationData.memberAddress;
      Object.keys(memberAddress).forEach(key => {
        const typedKey = key as keyof typeof memberAddress;
        const stringKey = String(typedKey);
        if (memberAddress[typedKey] !== undefined) {
          setValue(`addressInfo.${stringKey}`, memberAddress[typedKey]);
        }
      });
    }

    if (recoilRegistrationData?.member?.website) {
      setValue('website', recoilRegistrationData.member.website);
    }

    if (recoilRegistrationData?.memberContactPhone?.phoneNumber) {
      // TODO: `phoneNumber` doesn't appear to be saved anywhere, so it is not populated
      setValue('contactPhone', recoilRegistrationData.memberContactPhone.phoneNumber);
    }
  }, [recoilRegistrationData, setValue]);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col col-8'>
                  <h2 className='arrowWrapper'>4 of 4: Complete your profile</h2>
                  <p className='reg-intro'>
                    <span className='bold'>{"We've saved your selections. "}</span>
                    {
                      "Now that you've set up your subscriptions on DemandStar, tell us a little bit more about yourself."
                    }
                  </p>

                  <FormProvider {...methods}>
                    <form data-testid='complete-profile' onSubmit={handleSubmit(submitProfile)}>
                      <fieldset>
                        <H2>Your contact information</H2>

                        <DSTextField
                          label='First Name'
                          id='firstName'
                          name='firstName'
                          rules={{
                            setValueAs: trimInputValue,
                            validate: isValidRequiredTrimmingSpace,
                            maxLength: 50,
                          }}
                        />

                        <DSTextField
                          label='Last Name'
                          id='lastName'
                          name='lastName'
                          rules={{
                            setValueAs: trimInputValue,
                            validate: isValidRequiredTrimmingSpace,
                            maxLength: 50,
                          }}
                        />

                        <DSPhoneField label='Phone Number' name='contactPhone' />
                      </fieldset>
                      <fieldset>
                        <H2>
                          {recoilRegistrationData?.companyName
                            ? `${recoilRegistrationData.companyName} `
                            : 'Your Company '}
                          Information
                        </H2>
                        <DSPhoneField label='Company Phone Number' name='phoneNumber' />
                        <DSTextField
                          optional
                          label='Website'
                          dataTestId='website'
                          name='website'
                          rules={{
                            pattern: {
                              value:
                                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                              message: 'Website is not formatted properly',
                            },
                            setValueAs: trimInputValue,
                            maxLength: 50,
                          }}
                        />

                        <AddressLookup />
                      </fieldset>
                    </form>
                  </FormProvider>

                  <FlexContainer justifyContent='space-between'>
                    <DSButton type='secondary' onClick={moveBack}>
                      Go Back
                    </DSButton>
                    <DSButton onClick={moveForward}>{continueButtonText}</DSButton>
                  </FlexContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {checkingOut && (
        <div className='overlay'>
          <div className='progressbox'>
            <div>
              <LogoDSLoader alt='Processing Payment' />
            </div>
          </div>
        </div>
      )}

      <RegistrationErrorModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
