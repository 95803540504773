import jwt from 'jsonwebtoken';

import { AuthKey, TokenKey } from './constants';
import { Authorization, MemberType } from 'src/types';
import { AuthState, AuthUserType } from 'src/store/reducers/auth';

import { Assert } from './helpers';
import { ProductType } from 'src/types/products';

type BooleanString = 'True' | 'False';

export type AuthToken = {
  /** Account Created Date */
  [AuthKey.AccountCreatedDate]: string;
  /** Account Id */
  [AuthKey.AccountId]: string;
  /** Account Locked */
  [AuthKey.AccountLocked]: BooleanString;
  /** Account Locked Minutes */
  [AuthKey.AccountLockedMinutes]: string;
  /** Account Permissions */
  [AuthKey.AccountPermissions]: string;
  /** Disabled Notifications */
  [AuthKey.DisabledNotifications]: string;
  /** Email */
  [AuthKey.Email]: string;
  /** First Name */
  [AuthKey.FirstName]: string;
  /** Free Member Id*/
  [AuthKey.FreeMemberId]: string;
  /** Full Name */
  [AuthKey.FullName]?: string;
  /** Has Error */
  [AuthKey.HasError]?: BooleanString;
  /** Last Name */
  [AuthKey.LastName]: string;
  /** Last Login */
  [AuthKey.LastLogin]: string;
  /** Main Contact */
  [AuthKey.MainContact]: BooleanString;
  /** Member Blacklisted */
  [AuthKey.MemberBlackListed]: BooleanString;
  /** Member Created Date */
  [AuthKey.MemberCreatedDate]: string;
  /** Member Id */
  [AuthKey.MemberId]: string;
  /** Membership Levels */
  [AuthKey.MembershipLevels]: string;
  /** Member Status  */
  [AuthKey.MemberStatus]: string; //TODO: Turn this into an enum.
  /** Member Type */
  [AuthKey.MemberType]: MemberType;
  /** Migrated */
  [AuthKey.Migrated]: BooleanString;
  /** Ops Id */
  [AuthKey.OpsId]?: number;
  /** Primary Member Id */
  [AuthKey.PrimaryMemberId]: string;
  /** Product Type */
  [AuthKey.ProductType]: ProductType;
  /** Theme Token Name */
  [AuthKey.Theme]: string; // TODO: Turn this into an enum.
  /** Username */
  [AuthKey.Username]: string;
  /** Auth User Type */
  [AuthKey.AuthUserType]: AuthUserType;
  /** (Expiration Time) Claim */
  [TokenKey.ExpirationTime]: number;
  /** (Issued At) Claim */
  [TokenKey.IssuedAtClaim]: number;
  /** (Issuer) Claim */
  [TokenKey.IssuerClaim]: string;
  /** (Not Before) Claim */
  [TokenKey.NotBeforeClaim]: number;
};

export function getTokenData(token: string): Authorization {
  const tokenData = jwt.decode(token) as AuthToken;
  Assert(tokenData, 'We should not get a null value when decoding tokenData', 'token.ts');
  const fullName =
    tokenData[AuthKey.FullName] || tokenData[AuthKey.FirstName] + ' ' + tokenData[AuthKey.LastName];

  const {
    // acd = '',
    eml,
    fn,
    her,
    lkd,
    lm,
    ln,
    mbl,
    mc,
    mcd = '',
    mgrtd,
    mi,
    ml,
    ms,
    mt,
    opi = 0,
    pmid,
    prms,
    tm = '',
    un,
    us,
    ut,
  } = tokenData;

  const authData: Authorization = {
    accountId: Number(us),
    accountLocked: Boolean(lkd === 'True'),
    accountLockedMinutes: Number(lm),
    accountPermissions: prms,
    email: eml,
    firstName: fn,
    fullName,
    hasError: Boolean(her === 'True'),
    lastName: ln,
    mainContact: Boolean(mc === 'True'),
    memberBlackListed: Boolean(mbl === 'True'),
    memberCreatedDate: mcd,
    memberId: Number(mi),
    membershipLevels: ml,
    memberStatus: ms,
    memberType: mt,
    migrated: Boolean(mgrtd === 'True'),
    opsId: Number(opi),
    primaryMemberId: Number(pmid),
    themetokenName: tm,
    token,
    userName: un,
    userType: ut,
    validatedResult: { status: true, firstName: fn, lastName: ln },
  };
  authData.legacyAuth = getLegacyTokenData(authData);
  return authData;
}

/**
 * Convert resolved, readable token params back to expected legacy abbreviations for backwards compatibility.
 * @param {Authorization} auth
 * @returns {authTypes object}
 * @todo - merge `authTypes` and `AuthTypes` types
 */
function getLegacyTokenData(auth: Authorization): AuthState {
  return {
    [AuthKey.AccountCreatedDate]: '',
    [AuthKey.AccountId]: auth.accountId,
    [AuthKey.AccountLocked]: auth.accountLocked,
    [AuthKey.AccountLockedMinutes]: auth.accountLockedMinutes,
    [AuthKey.AccountPermissions]: auth.accountPermissions,
    [AuthKey.AuthUserType]: auth.userType,
    [AuthKey.Email]: auth.email,
    [AuthKey.FirstName]: auth.firstName,
    [AuthKey.FullName]: auth.fullName,
    [AuthKey.HasError]: !!auth.hasError,
    [AuthKey.LastName]: auth.lastName,
    [AuthKey.MainContact]: auth.mainContact,
    [AuthKey.MemberBlackListed]: auth.memberBlackListed,
    [AuthKey.MemberCreatedDate]: auth.memberCreatedDate,
    [AuthKey.MemberId]: auth.memberId,
    [AuthKey.MembershipLevels]: auth.membershipLevels,
    [AuthKey.MemberStatus]: auth.memberStatus,
    [AuthKey.MemberType]: auth.memberType,
    [AuthKey.Migrated]: auth.migrated,
    [AuthKey.OpsId]: auth.opsId,
    [AuthKey.PrimaryMemberId]: auth.primaryMemberId,
    [AuthKey.ProductType]: '',
    [AuthKey.Theme]: auth.themetokenName,
    [AuthKey.Token]: auth.token,
    [AuthKey.Username]: auth.userName,
  };
}
