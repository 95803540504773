import { Cell, Column } from 'react-table';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { DSCheckbox, DSPaginate, DSTable } from '@demandstar/components/index';

import {
  bidssummaryParamType,
  similarPlanholderType,
  supplierItemFields,
} from '../../../../../types/biddetail';
import { commonLabels } from 'src/shared/constants';
import { loadingMsg } from '../../../../../utils/texts';
import NoResult from '../../../../customcontrols/noresult';
import styled from 'styled-components';

interface PropsTypes {
  setBidSummaryDetails?: any;
  sendBidSelectedSupplier?: any;
  loadBidsSimilarSuppliers?: any;
  similarSuppliers?: supplierItemFields[];
  bidId: number;
  bidssummary?: bidssummaryParamType;
  pagfor: string;
}

export const SupplierNameWrapper = styled.span`
  float: left;
  width: 270px;
`;
export const ContactPersonWrapper = styled.span`
  float: left;
  width: 140px;
`;
export const SendBidWrapper = styled.div`
  float: left;
  width: 120px;
`;
export const SendBidText = styled.span`
  float: left;
  margin-top: 5px;
  margin-right: 5px;
`;
export const SendBidCheckboxWrapper = styled.span`
  float: right;
  margin-bottom: 10px;
  margin-top: 10px;
`;
export const SendBidSelectItemCheckboxWrapper = styled.span`
  float: left;
  margin-top: 7px;
`;
function Similarsuppliers(props: PropsTypes) {
  const {
    setBidSummaryDetails,
    sendBidSelectedSupplier,
    loadBidsSimilarSuppliers,
    bidId = '',
    bidssummary,
  } = props;
  const { currentsupplier, similarSuppliers = [], spLoader = false } = bidssummary || {};

  const SelectSuppliers = useCallback(
    (index: number) => {
      const newsimilar = similarSuppliers.find((item, indexs) => indexs === index);
      if (newsimilar) {
        newsimilar.selected = newsimilar.selected ? false : true;
      }
      setBidSummaryDetails({ similarSuppliers });
    },
    [similarSuppliers, setBidSummaryDetails],
  );

  const enablesendbtn = similarSuppliers.find(item => item.selected);
  const selectedSuppliersCount = similarSuppliers.filter(item => item.selected).length;

  const SendBidSelectedSuppliers = () => {
    if (enablesendbtn && currentsupplier) {
      sendBidSelectedSupplier({ similarSuppliers, bidId, supplierId: currentsupplier.mi });
    }
  };

  const selectDeselectAllSuppliers = useCallback(() => {
    const value = selectedSuppliersCount === similarSuppliers.length ? false : true;
    const newSimilar = similarSuppliers.map(item => {
      return { ...item, selected: value };
    });
    setBidSummaryDetails({ similarSuppliers: newSimilar });
  }, [similarSuppliers, setBidSummaryDetails, selectedSuppliersCount]);

  useEffect(() => {
    if (currentsupplier && currentsupplier.mi && bidId) {
      loadBidsSimilarSuppliers({ bidId, supplierId: currentsupplier && currentsupplier.mi });
    }
  }, [bidId, currentsupplier, loadBidsSimilarSuppliers]);

  const similarSuppliersColumn = useMemo((): Column<similarPlanholderType>[] => {
    return [
      {
        Header: 'Supplier',
        accessor: 'supplierName',
        Cell: (cellData: Cell<similarPlanholderType>) => {
          const rowIndex = Number(cellData.row.id);
          return (
            <SupplierNameWrapper>
              {similarSuppliers[rowIndex] ? `${similarSuppliers[rowIndex].supplierName}` : ''}
            </SupplierNameWrapper>
          );
        },
      },
      {
        Header: 'Contact Person',
        accessor: 'fn',
        Cell: (cellData: Cell<similarPlanholderType>) => {
          const rowIndex = Number(cellData.row.id);
          return (
            <ContactPersonWrapper>
              {similarSuppliers[rowIndex]
                ? `${similarSuppliers[rowIndex].fn} ${similarSuppliers[rowIndex].ln}`
                : ''}
            </ContactPersonWrapper>
          );
        },
      },
      {
        Header: () => {
          return (
            <SendBidWrapper>
              <SendBidText>Send Bid </SendBidText>
              <SendBidCheckboxWrapper>
                <DSCheckbox
                  name='checkbox'
                  label={''}
                  onClick={() => selectDeselectAllSuppliers()}
                  checked={selectedSuppliersCount === similarSuppliers.length ? true : false}
                />
              </SendBidCheckboxWrapper>
            </SendBidWrapper>
          );
        },
        accessor: 'mi',
        disableSortBy: true,
        Cell: (cellData: Cell<similarPlanholderType>) => {
          const rowIndex = Number(cellData.row.id);
          return (
            <>
              <SendBidSelectItemCheckboxWrapper>
                <DSCheckbox
                  key={similarSuppliers[rowIndex] && similarSuppliers[rowIndex].mi}
                  name='checkbox'
                  label={''}
                  onClick={() => SelectSuppliers(rowIndex)}
                  checked={
                    similarSuppliers[rowIndex] && similarSuppliers[rowIndex].selected ? true : false
                  }
                />
              </SendBidSelectItemCheckboxWrapper>
            </>
          );
        },
      },
    ];
  }, [SelectSuppliers, similarSuppliers, selectDeselectAllSuppliers, selectedSuppliersCount]);

  return (
    <>
      <h4>Similar Planholders</h4>
      {similarSuppliers.length > 0 ? (
        <>
          <header className='blockControls clearfix'>
            <ul className='list-unstyled'>
              <li
                className={enablesendbtn ? '' : 'disabled-link'}
                onClick={SendBidSelectedSuppliers}
              >
                <i className='mdi mdi-content-save-outline' /> Send Bid to Selected Suppliers
              </li>
            </ul>
          </header>
          <DSPaginate data={similarSuppliers} showLabel={false} disableDeepLink>
            {data => (
              <DSTable
                columns={similarSuppliersColumn}
                data={data}
                emptyMessage={commonLabels.notFound}
                sort={'supplierName'}
              />
            )}
          </DSPaginate>
        </>
      ) : (
        <NoResult message={spLoader ? loadingMsg : 'No Similar Planholders  Available'} />
      )}
    </>
  );
}

export default memo(Similarsuppliers);
