import { Suspense, useCallback, useEffect, useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
} from 'recoil';
import { useHistory } from 'react-router';

import { FlexContainer, H3 } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';

import LogoDSLoader from 'src/assets/images/loader';

import { allProductsState, selectedProductsState } from '../../../store/recoil/productState';
import { Cart, useCart } from '../cart';
import { CheckoutErrorType, useCheckout } from '../review-order/useCheckout';
import { CompleteOrderModal, ComplimentaryOrder } from '../ops-actions';
import { NationalProductId, ProductType, SubscriptionProducts } from '../../../types/products';
import {
  prepopulatedProductsState,
  prorationMismatchState,
} from 'src/store/recoil/subscriptionState';
import { ProductSelectionWrapper, UpsellSectionWrapper } from './styles';
import { useAccountInfo, useRefreshAccountInfo } from '../../../shared/hooks/useAccountInfo';
import { useProfileInfo, useRefreshProfileInfo } from '../../../shared/hooks/useProfileInfo';
import { AgencyByProductModal } from '../agency-by-product/AgencyByProductModal';
import { memberInfoSelector } from 'src/store/recoil/memberState';
import { Page } from '../styles';
import { ProductsPaths } from 'src/utils/texts/supplier/subscription';
import { SelectState } from '../select-products/SelectState';
import { UpsellList } from '../upsell/UpsellList';
import { useAuth } from '../../../shared/hooks/useAuth';
import { UserType } from 'src/types/accountinfo';
import { useSetBreadcrumb } from '../../../shared/hooks';

/**
 * @description page for displaying upgrades product selection
 * @returns JSX.Element
 *
 * @example <UpgradeSubscription />
 */
export const UpgradeSubscription = () => {
  useRefreshAccountInfo();
  useRefreshProfileInfo();

  const {
    accountInfo: { daysToRenew, products: subscribedProducts, userType },
  } = useAccountInfo();
  const {
    profileInfo: { card, result },
  } = useProfileInfo();
  const {
    auth: { opsId = 0, memberId: authMemberId },
  } = useAuth();
  const { checkout } = useCheckout();

  const memberInfo = useRecoilValueLoadable(memberInfoSelector);
  const allProducts = useRecoilValueLoadable(allProductsState);
  const history = useHistory();
  const prepopulatedProducts = useRecoilValue(prepopulatedProductsState);
  const resetSelectedProducts = useResetRecoilState(selectedProductsState);
  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);
  const { discountedCartTotal, setCartRenewalOn, showCart } = useCart();
  const [urlProductsLoaded, setUrlProductsLoaded] = useState(false);
  const [checkingOut, setCheckingOut] = useState(false);
  const prorationMismatch = useRecoilValue(prorationMismatchState);

  const isOpsUser = opsId > 0;
  const memberInfoId = memberInfo.valueMaybe()?.mi || 0;
  /**
   * For some reason, it seems possible that auth.memberId is 0 upon reaching this page
   * this checks against a memberInfo API call
   */
  const memberId = authMemberId || memberInfoId;
  const hasProfile = result === 'SUCCESS';
  const canRenew = userType === UserType.PaidSupplier && daysToRenew > 0 && daysToRenew < 90;

  let prepopulatedState = prepopulatedProducts.stateName;
  /* istanbul ignore else */
  if (selectedProducts.state) {
    prepopulatedState = selectedProducts.state[selectedProducts.state.length - 1]
      ? selectedProducts.state[selectedProducts.state.length - 1].productName
      : prepopulatedProducts.stateName;
  }

  const goBack = useCallback(() => {
    resetSelectedProducts();

    history.goBack();
  }, [history, resetSelectedProducts]);

  const confirmAndPay = useCallback(
    async (opsConfirmed = false) => {
      setCheckingOut(true);
      if (card?.cardLastFour && hasProfile) {
        // attempt to charge card
        try {
          const result = await checkout({ memberId, opsConfirmed });

          if (result?.status) history.push(ProductsPaths.CurrentSubscription);
          else if (result?.errorType !== CheckoutErrorType.Proration)
            history.push(ProductsPaths.Checkout);
        } catch {
          history.push(ProductsPaths.Checkout);
        }
      } else {
        history.push(ProductsPaths.Checkout);
      }
      setCheckingOut(false);
    },
    [card?.cardLastFour, checkout, hasProfile, history, memberId],
  );

  // Get & set products from URL Search
  const products: string | null = new URLSearchParams(history?.location?.search).get('pid');

  useEffect(() => {
    if (
      !urlProductsLoaded &&
      products?.length &&
      allProducts.state === 'hasValue' &&
      allProducts.contents.length &&
      !(subscribedProducts?.filter(s => s.productType === ProductType.National)?.length ?? false)
    ) {
      const productIds = products.split(',').map(id => Number(id));

      const urlProducts = allProducts.contents.filter(p => productIds.includes(p.productId));
      if (urlProducts?.length) {
        const newCounties = urlProducts.filter(
          p =>
            p.productType === ProductType.County &&
            !subscribedProducts?.filter(
              sp => sp.productId === p.productId || sp.productId === p.parentId,
            )?.length,
        );
        const newStates = urlProducts.filter(
          p =>
            p.productType === ProductType.State &&
            !subscribedProducts?.filter(sp => sp.productId === p.productId)?.length,
        );
        const newNational = urlProducts.filter(p => p.productType === ProductType.National);

        setSelectedProducts({
          ...selectedProducts,
          county: [...(selectedProducts?.county ?? []), ...newCounties],
          state: [...(selectedProducts?.state ?? []), ...newStates],
          national: newNational?.length ? NationalProductId.UnitedStates : 0,
        });

        setUrlProductsLoaded(true);
      }
    }
  }, [
    allProducts.contents,
    allProducts.state,
    products,
    selectedProducts,
    setSelectedProducts,
    subscribedProducts,
    urlProductsLoaded,
  ]);

  useSetBreadcrumb({
    page: 'Upgrade and Renew',
    title: 'Upgrade and Renew',
    altname: 'Upgrade and Renew',
    name: 'Upgrade and Renew',
  });

  return (
    <Page>
      <ProductSelectionWrapper>
        <H3 className='arrowWrapper'>Upgrade and Renew Subscription</H3>
        <p>
          Select the regions where you are interested in doing business to to get notifications from
          those governments and grow your network of potential clients.
        </p>
        <SelectState prePopulatedState={prepopulatedState} />
        {showCart ? (
          <>
            <Suspense fallback={<></>}>
              <Cart />
            </Suspense>

            <CompleteOrderModal completeOrder={() => confirmAndPay(true)} />

            <FlexContainer justifyContent='space-between'>
              <DSButton type={'secondary'} onClick={goBack} inactive={checkingOut}>
                Go Back
              </DSButton>
              {isOpsUser ? <ComplimentaryOrder /> : null}
              {discountedCartTotal > 0 && result !== 'PENDING' && memberId > 0 && (
                <DSButton
                  type={'primary'}
                  onClick={() => confirmAndPay(false)}
                  inactive={checkingOut || prorationMismatch !== undefined}
                >
                  {checkingOut ? 'Processing...' : 'Confirm and Pay'}
                </DSButton>
              )}
            </FlexContainer>
          </>
        ) : (
          <FlexContainer justifyContent='space-between'>
            <DSButton type={'secondary'} onClick={goBack}>
              Go Back
            </DSButton>
            {canRenew && result !== 'PENDING' && memberId > 0 && (
              <DSButton onClick={setCartRenewalOn}>Renew</DSButton>
            )}
          </FlexContainer>
        )}
      </ProductSelectionWrapper>
      <UpsellSectionWrapper>
        <UpsellList />
      </UpsellSectionWrapper>
      <AgencyByProductModal />
      {checkingOut && (
        <div className='overlay'>
          <div className='progressbox'>
            <div>
              <LogoDSLoader alt='Processing Payment' />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};
