import React, { HTMLProps } from 'react';
import styled from 'styled-components';

import {
  BorderColor,
  ButtonColor,
  ButtonHex,
  ButtonHighlight,
  ButtonHighlightHex,
  TextHex,
} from '../styles/colors';
import {
  BorderRadius,
  BorderThickness,
  FlexContainer,
  FontFamily,
  FontWeight,
  InputFontSize,
  Padding,
  TextColor,
} from '../styles';
import { DSButtonAppearance } from '.';
import { Inactive } from '../types';

export type ButtonStyleProps = Pick<
  HTMLProps<HTMLButtonElement>,
  'onClick' | 'name' | 'id' | 'title' | 'type' | 'onKeyDown'
> &
  Partial<CSSStyleDeclaration> &
  Inactive & {
    appearance?: DSButtonAppearance;
    contrast?: boolean;
  };

/**
 * Functions passed to styled-component string template literals
 * "...will receive the styled component's props as the first and only argument."
 * (https://styled-components.com/docs/api#taggedtemplateliteral)
 * */

type BGColorHex = TextHex | ButtonHex;
type BGColorHoverHex = TextHex | ButtonHighlightHex;

function getBgColor({
  appearance,
  inactive,
  contrast,
}: Pick<ButtonStyleProps, 'appearance' | 'contrast' | 'inactive'>): BGColorHex {
  if (inactive) {
    return ButtonColor.Inactive;
  }
  if (contrast) {
    return appearance === 'secondary' ? TextColor.Action : ButtonColor.Secondary;
  }
  switch (appearance) {
    case 'primary':
      return ButtonColor.Primary;
    case 'secondary':
      return ButtonColor.Secondary;
    case 'tertiary':
      return ButtonColor.Tertiary;
    case 'danger':
    case 'ops':
      return ButtonColor.Danger;
    default:
      return ButtonColor.Primary;
  }
}

function getBgColorHover({
  inactive,
  appearance,
  contrast,
}: ButtonStyleProps): BGColorHoverHex | undefined {
  if (inactive) {
    return ButtonHighlight.Inactive;
  }
  if (contrast) {
    return getHoverBorderTextColor({ inactive, appearance, contrast: false });
  }
  switch (appearance) {
    case 'primary':
      return ButtonHighlight.Primary;
    case 'secondary':
      return ButtonHighlight.Secondary;
    case 'tertiary':
      return ButtonHighlight.Tertiary;
    case 'danger':
    case 'ops':
      return ButtonHighlight.Danger;
    default:
      return ButtonHighlight.Primary;
  }
}

function getBorderColor({
  inactive,
  appearance,
  contrast,
}: Pick<ButtonStyleProps, 'appearance' | 'contrast' | 'inactive'>) {
  if (inactive) {
    return BorderColor.Inactive;
  }
  if (contrast) {
    if (appearance === 'secondary') {
      return BorderColor.None;
    }

    return getBgColor({ inactive, appearance, contrast: false });
  }
  switch (appearance) {
    case 'secondary':
      return BorderColor.Action;
    default:
      return BorderColor.None;
  }
}

/** For secondary button, this changes the color and border.
 * Does not impact any other button type.
 */
function getHoverBorderTextColor({ inactive, appearance, contrast }: ButtonStyleProps) {
  if (inactive) {
    return;
  }
  if (contrast) {
    return getBgColorHover({ inactive, appearance, contrast: false });
  }
  if (appearance !== 'secondary') {
    return;
  }
  return TextColor.ActionHover;
}

function getTextColor({
  inactive,
  appearance,
  contrast,
}: Pick<ButtonStyleProps, 'appearance' | 'contrast' | 'inactive'>) {
  if (inactive) {
    return TextColor.Inactive;
  }

  if (contrast) {
    return getBgColor({ inactive, appearance, contrast: false });
  }

  switch (appearance) {
    case 'secondary':
      return TextColor.Action;
    default:
      return TextColor.Contrast;
  }
}

function getCursor({ inactive }: ButtonStyleProps) {
  return inactive ? 'not-allowed' : 'pointer';
}

/**
 * Styled button base component.
 * This is intended to be overridden via styled components for other implementations
 *   using optional props directly available in `ButtonBaseProps`, or
 *   using ```const MyButton = styled(BaseButton)`...`;
 * @param props: BaseButtonProps
 */
export const BaseButton: React.FunctionComponent<ButtonStyleProps> = styled.button.attrs<ButtonStyleProps>(
  ({ appearance, contrast }: ButtonStyleProps) => ({
    appearance, // Pass appearance from styled wrappers
    contrast,
  }),
)`
  background-color: ${getBgColor};
  color: ${getTextColor};
  cursor: ${getCursor};
  font-family: ${FontFamily.Header};
  font-size: ${InputFontSize.Placeholder};
  font-weight: ${FontWeight.Bold};
  padding: ${Padding.Button};
  border: solid ${BorderThickness.Base};
  border-color: ${getBorderColor};
  border-radius: ${BorderRadius.Base};
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: ${getBgColorHover};
    color: ${getHoverBorderTextColor};
    border-color: ${getHoverBorderTextColor};
  }
  &:active {
    background-color: ${getBgColorHover};
  }
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
`;

/** Button Group Styling */
/**
 * @description Flex wrapper that sets the widths of each button equally, and fills the container
 */
export const ButtonsFlexContainer = styled(FlexContainer)`
  button,
  a {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }

  a > button {
    width: 100%;
  }
`;
