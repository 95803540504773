import styled from 'styled-components';

import {
  FlexContainer,
  FontFamily,
  FontWeight,
  Margin,
  Padding,
  ParagraphFontSize,
} from '@demandstar/components/styles';

export const AgencySelectionList = styled.div`
  padding-top: ${Padding.Base};
  padding-bottom: ${Padding.Extra};

  > label {
    font-family: ${FontFamily.Base};
    font-size: ${ParagraphFontSize.Large};
    font-weight: ${FontWeight.Base};
    margin-top: ${Margin.Extra};

    &:last-child {
      margin-bottom: ${Margin.Extra};
    }
  }
`;

export const SpacedButtonWrapper = styled(FlexContainer)`
  padding-top: ${Padding.Extra};
`;
