import history from '../../../utils/history';
import React from 'react';

import { authTypes, MembershipLevel, Permission } from '../../../types/auth';
import { anyParamType } from '../../../types/paymentdetails';

interface PropsTypes {
  auth: authTypes;
  resetQuoteSummaryDetails: anyParamType;
  resetCommodityCodes: anyParamType;
  setAddBidDetails: anyParamType;
  resetAddQuotesDetails: anyParamType;
  //trackAmplitudeUserActions?: anyParamType;
}

function Quotescontrol(props: PropsTypes) {
  const {
    auth,
    /* trackAmplitudeUserActions, */ resetQuoteSummaryDetails,
    resetCommodityCodes,
    setAddBidDetails,
    resetAddQuotesDetails,
  } = props;
  const { ml = '', prms = '', mc = false } = auth;
  const mlcheck = ml.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = prms.replace(/ /g, '').split(',') as Permission[];

  const AddQuote = () => {
    resetQuoteSummaryDetails();
    setAddBidDetails({ ResetState: true, broadcastListId: '' });
    resetCommodityCodes();
    resetAddQuotesDetails();
    //trackAmplitudeUserActions({title:'Add quote - button', desc:'add quote button clicked from quote search'});
    history.push('/buyers/quotes/creation');
  };

  return (
    <>
      {mc ||
      (mlcheck.includes(MembershipLevel.QuotePoster) &&
        prmscheck.includes(Permission.CreateQuotes)) ? (
        <li title='Add Quote'>
          <span className='staticLink addBid' onClick={AddQuote}>
            <i className='mdi mdi-plus mr-1' /> Add Quote
          </span>
        </li>
      ) : null}
    </>
  );
}

export default Quotescontrol;
