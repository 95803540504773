import React, { memo, useEffect } from 'react';
import CheckBox from '../../../../customcontrols/checkbox';
import { Collapse } from 'reactstrap';
import { anyParamType } from '../../../../../types/paymentdetails';
import { commoditycodesTypes } from '../../../../../types/broadcastlist';
import { useDSSelector } from 'src/store/reducers';
import { hasPermission, toastFn } from 'src/utils/helpers';
import { userPermissionKeys } from 'src/utils/constants';
import history from 'src/utils/history';

interface PropsTypes {
  setCommodityCodes?: anyParamType;
  SetSelectedCommodity: anyParamType;
  setLocalCommodity?: anyParamType;
  commoditycodes?: commoditycodesTypes;
  pagefor?: string;
}

function Commoditylist(props: PropsTypes) {
  const {
    setCommodityCodes,
    SetSelectedCommodity,
    setLocalCommodity,
    commoditycodes,
    pagefor = '',
  } = props;
  const {
    commoditieslist = [],
    commodityGroup = '',
    commodityCategory = '',
  } = commoditycodes || {};

  const commodityGroups =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(
        item => item.commodityCategory === '000' && item.commodityCode === '00',
      )) ||
    [];

  const commodityCategories =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(
        item =>
          item.commodityGroup === commodityGroup &&
          item.commodityCategory !== '000' &&
          item.commodityCode === '00',
      )) ||
    [];

  const commodityCodes =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist.filter(
        item =>
          item.commodityCategory === commodityCategory &&
          item.commodityGroup === commodityGroup &&
          item.commodityCode !== '00',
      )) ||
    [];

  const selectedFormatCodes =
    (commoditieslist &&
      commoditieslist.length > 0 &&
      commoditieslist
        .filter(item => item.isSelected)
        .map(item => item.formattedCode && item.formattedCode.slice(0, 3))) ||
    [];
  const memberinfo = useDSSelector(state => state.memberinfo);
  const auth = useDSSelector(state => state.auth);
  const { prms } = memberinfo;
  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact;
  useEffect(() => {
    if (hasEditMemberPermission === false) {
      history.push(`/suppliers/dashboard`);
    }
  }, [hasEditMemberPermission]);

  return (
    <>
      <h4>Commodity Codes List</h4>
      <div className='innerColIndent'>
        {commodityGroups &&
          commodityGroups.map((group, groupindex) => (
            <div className='accordin-band' key={groupindex}>
              <div
                className='accordin-title'
                onClick={() => {
                  if (commodityGroup === group.commodityGroup) {
                    setCommodityCodes({ commodityGroup: '' });
                  } else {
                    setCommodityCodes({ commodityGroup: group.commodityGroup });
                  }
                }}
              >
                <p>
                  {selectedFormatCodes.includes(
                    group.formattedCode && group.formattedCode.slice(0, 3).toString(),
                  ) ? (
                    <strong>
                      {group.commodityDescription} (Contains {group.contains} Categories){' '}
                      {group.formattedCode}
                    </strong>
                  ) : (
                    <>
                      {group.commodityDescription} (Contains {group.contains} Categories){' '}
                      {group.formattedCode}
                    </>
                  )}
                </p>
                <i
                  className={
                    'mdi ' +
                    (commodityGroup === group.commodityGroup
                      ? 'mdi-chevron-down-circle-outline'
                      : 'mdi-chevron-right-circle-outline')
                  }
                />
              </div>
              {group.commodityGroup === commodityGroup ? (
                <>
                  {commodityCategories && commodityGroup && (
                    <>
                      {commodityCategories.map((category, categoryindex) => {
                        return (
                          <Collapse
                            key={categoryindex}
                            isOpen={group.commodityGroup === commodityGroup}
                          >
                            <div className='quotes-details'>
                              <div className='accordin-band'>
                                <div className='accordin-title'>
                                  <p>
                                    <i>
                                      <CheckBox
                                        handleChecked={(name: string, value: any) => {
                                          if (
                                            (pagefor === 'addbid' ||
                                              pagefor === 'addquotes' ||
                                              pagefor === 'accountBroadcastList') &&
                                            value
                                          ) {
                                            setLocalCommodity({
                                              value: value,
                                              category: category,
                                              name: 'commodityCategory',
                                              confirmmodal: true,
                                            });
                                          } else {
                                            SetSelectedCommodity(
                                              value,
                                              category,
                                              'commodityCategory',
                                            );
                                          }
                                        }}
                                        checked={category.isSelected}
                                        indeterminate={category.isPartial && !category.isSelected}
                                      />
                                    </i>
                                    <span
                                      onClick={() => {
                                        if (commodityCategory === category.commodityCategory) {
                                          setCommodityCodes({ commodityCategory: '' });
                                        } else {
                                          setCommodityCodes({
                                            commodityCategory: category.commodityCategory,
                                          });
                                        }
                                      }}
                                    >
                                      {category.isSelected || category.isPartial ? (
                                        <strong>
                                          {category.commodityDescription} (Contains{' '}
                                          {category.contains} Codes)
                                          {category.formattedCode}
                                        </strong>
                                      ) : (
                                        <>
                                          {category.commodityDescription} (Contains{' '}
                                          {category.contains} Codes)
                                          {category.formattedCode}
                                        </>
                                      )}
                                    </span>
                                  </p>
                                  <i
                                    className={
                                      'mdi ' +
                                      (commodityCategory === category.commodityCategory
                                        ? 'mdi-chevron-down-circle-outline'
                                        : 'mdi-chevron-right-circle-outline')
                                    }
                                    onClick={() => {
                                      if (commodityCategory === category.commodityCategory) {
                                        setCommodityCodes({ commodityCategory: '' });
                                      } else {
                                        setCommodityCodes({
                                          commodityCategory: category.commodityCategory,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {category.commodityCategory === commodityCategory ? (
                                  <>
                                    {commodityCodes && commodityCategory && (
                                      <>
                                        {commodityCodes.map((code, codeindex) => (
                                          <Collapse
                                            key={codeindex}
                                            isOpen={
                                              commodityCategory === category.commodityCategory
                                            }
                                          >
                                            <div className='quotes-details'>
                                              <h6>
                                                <i>
                                                  <CheckBox
                                                    handleChecked={(name: string, value: any) =>
                                                      SetSelectedCommodity(
                                                        value,
                                                        code,
                                                        'commodityCodes',
                                                      )
                                                    }
                                                    checked={code.isSelected}
                                                  />
                                                </i>
                                                {code.isSelected ? (
                                                  <strong>
                                                    {code.commodityDescription} [
                                                    {code.formattedCode}]
                                                  </strong>
                                                ) : (
                                                  <>
                                                    {code.commodityDescription} [
                                                    {code.formattedCode}]
                                                  </>
                                                )}
                                              </h6>
                                            </div>
                                          </Collapse>
                                        ))}
                                      </>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </Collapse>
                        );
                      })}
                    </>
                  )}
                </>
              ) : null}
            </div>
          ))}
      </div>
    </>
  );
}

export default memo(Commoditylist);
