import { connect, ConnectedProps } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { DSTextField } from '@demandstar/components/fields';

import * as actionCreators from '../../../store/actions';
import Configurations from '../../../settings';
import LogoDS from '../../../assets/images/logo_ds';

import { dismisstoastAllFn, isValidRequiredTrimmingSpace, toastFn } from '../../../utils/helpers';
import { MemberType, Pages, Paths } from '../../../utils/constants';
import { ErrorPanel } from '../loading';
import { Permission } from '../../../types/auth';
import { useAuth } from 'src/shared/hooks/useAuth';
import { useRouter } from 'src/shared/hooks';

function Login({ login }: PropsFromRedux) {
  const methods = useForm({
    defaultValues: {
      userName: '',
      password: '',
    },
  });
  const { getValues, handleSubmit } = methods;

  const { auth, authError, handleLogin, resetAuthError } = useAuth();
  const { memberType = '', accountPermissions } = auth;
  const { history } = useRouter();

  useEffect(() => {
    const isExpired = sessionStorage.getItem('isloggingout');
    if (isExpired === '1') {
      toastFn('error', 'Session expired', 'Session expired');
    }
    sessionStorage.removeItem('isloggingout');
  }, []);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  useEffect(() => {
    if (memberType) {
      const path =
        memberType === MemberType.AgencyBuyer ? Pages.buyerDashboard : Pages.supplierDashboard;
      history.replace(path);
    } else if (auth && auth.accountId > 0 && accountPermissions.includes(Permission.Ops)) {
      history.replace(Paths.ops.agencyRegistration);
    }
  }, [accountPermissions, auth, history, memberType]);

  useEffect(() => {
    document.body.className = '';
    document.body.classList.add('light_DS');
  }, []);

  const handleLoginSubmit = async () => {
    resetAuthError();
    const userName = getValues('userName');
    const password = getValues('password');
    if (userName && password) {
      handleLogin({ userName, password });
      login({ userName, password });
    }
  };

  const GoHome = () => {
    window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
  };

  return (
    <form onSubmit={handleSubmit(handleLoginSubmit)}>
      <div className='container loginWrapper'>
        <div
          className='row d-flex justify-content-center align-items-center'
          style={{ height: '100vh' }}
        >
          <div className='col-10 col-md-5'>
            <span onClick={GoHome}>
              <LogoDS />
            </span>
            <FormProvider {...methods}>
              <DSTextField
                label='Username'
                name='userName'
                rules={{ validate: isValidRequiredTrimmingSpace }}
              />
              <DSTextField label='Password' name='password' type='password' />
            </FormProvider>
            {authError && <ErrorPanel message={authError} />}
            <Link className='needHelp' to={'/accountrecovery'}>
              Need help logging in?
            </Link>
            <br></br>
            <span>
              If you are a new government agency,{' '}
              <a href='https://network.demandstar.com/schedule-a-demo/'>sign up here</a>.
            </span>
            <div className='container action-buttons mt-3'>
              <div className='row'>
                <div className='col'>
                  <Link className='bttn bttn-secondary m-auto d-block' to={'/registration'}>
                    Sign Up
                  </Link>
                </div>
                <div className='col'>
                  <button className='bttn bttn-primary m-auto d-block' name='login' type='submit'>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

const connector = connect(
  state => state,
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Login));
