import { createPortal } from 'react-dom';

import { DSProgressIndicator } from '@demandstar/components/progress-indicator';
import { useDSSelector } from '../../store/reducers';

const loaderElement = document.getElementById('loaderOverlay') as HTMLElement;

export function Loader() {
  const isLoading = useDSSelector(state => state.loader.isLoading);
  const isSuspenseLoading = useDSSelector(state => state.shared.isSuspenseLoading);
  return createPortal(
    isLoading || isSuspenseLoading ? (
      <div className='overlay'>
        <div className='progressbox'>
          <div>
            <DSProgressIndicator />
          </div>
        </div>
      </div>
    ) : null,
    loaderElement,
  );
}
