import Api from './api';
import { Paths } from '../../utils/constants';
import { constants } from '../../utils/settings';
import { AddBidDocTypeOption, BidDocument } from '../../types/addbid';
import { stringify } from 'querystring';

//----- API calls-------//

export function getAddBidBidTypes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.BidTypes, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getAddBidBidWriters(data = {}) {
  return Api.postRequestWithAuthentication(Paths.BidWriters, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getAddBidResponseItems(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidResponseItems, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidPublications(data = {}) {
  return Api.postRequestWithAuthentication(Paths.publications, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidMemberPublications(data = {}) {
  return Api.postRequestWithAuthentication(Paths.memberPublications, data, {
    baseURL: constants.api.url,
  });
}

export function getBidMemberLegal(data = {}) {
  return Api.postRequestWithAuthentication(Paths.memberLegal, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitAddBidUpdateBidPublications(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateBidPublication, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.AddBidInfo, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidUpdateLegal(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateLegal, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidUpdateMemo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateMemo, data, {
    baseURL: constants.api.url,
  });
}

export function UpdateEbidInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.UpdateEbidInfo, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidAddList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addList, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidBroadcastMembers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.broadcastMembers, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidDeleteList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.deleteList, data, {
    baseURL: constants.api.url,
  });
}

export function getBidStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getBidStatus, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidChangeDue(data = {}) {
  return Api.postRequestWithAuthentication(Paths.changedue, data, {
    baseURL: constants.api.url,
  });
}

export function getPreBuiltList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getList, data, {
    baseURL: constants.api.url,
  });
}

export function getPreBuiltListCount(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getListCount, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidUsePrebuiltList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addToBid, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidSearchVendors(data = {}) {
  return Api.postRequestWithAuthentication(Paths.searchVendors, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidSupplierCount(data = {}) {
  return Api.postRequestWithAuthentication(Paths.supplierCount, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidIsFilterStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.isFilterStatus, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidActivePrograms(data = {}) {
  return Api.postRequestWithAuthentication(Paths.activePrograms, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getAddBidDocTypes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.doctypes, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidIsConstruction(data = {}) {
  return Api.postRequestWithAuthentication(Paths.isConstruction, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidConversionTypes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.conversionTypes, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidUpdatePlan(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updatePlan, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidDocumentDelete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.documentdelete, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidComplete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.complete, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidAddSupplemental(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addsupplemental, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getAddBidsupplementalsuppliers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.supplementalsuppliers, data, {
    baseURL: constants.api.url,
  });
}

export function updateBidDocumentTitle(data = {}) {
  return Api.postRequestWithAuthentication(Paths.documentupdate, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidDocumentUpload(data: BidDocument, config = { uploadPath: '' }) {
  /*
    TODO:
    This is an area where I had to rely on any.
    This was previously typed to accept any object, but it is only ever sent a BidDocument.
    Now that it's typed, we're getting errors. But we are successfully uploading our docs.
    It might be worth parsing numbers and booleans to string. Seems like a safe change.
    https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
  */

  const formData = new FormData();
  formData.append('bidId', data && (data.bidId as any)); // this is a number. formData.append wants a string or Blob.
  formData.append('documentTitle', data && data.doctitle);
  formData.append('documentType', data && (data.doctype as AddBidDocTypeOption).value);
  formData.append('isPdfConversion', data && (data.docconversion as any)); // this is a boolean. formData.append wants a string or Blob.
  formData.append('docFormatType', data && data.docext);
  formData.append('uploadFile', data && (data.docfile as string));

  return Api.postRequestWithAuthentication(config.uploadPath, formData, config);
}

export function getAddBidBroadcastListId(data = {}) {
  return Api.postRequestWithAuthentication(Paths.broadcastListId, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidBroadcastFilters(data = {}) {
  return Api.postRequestWithAuthentication(Paths.broadcastFilters, data, {
    baseURL: constants.api.url,
  });
}

export function getAddBidIsPermissions(data = {}) {
  return Api.postRequestWithAuthentication(Paths.permissions, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidDocumentApprove(data = {}) {
  return Api.postRequestWithAuthentication(Paths.documentapprove, data, {
    baseURL: constants.api.url,
  });
}

export function submitAddBidMoveNext(data = {}) {
  return Api.postRequestWithAuthentication(Paths.movenext, data, {
    baseURL: constants.api.url,
  });
}

export function AddBidRemoveSupplimentalSuppliers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.removesupplemental, data, {
    baseURL: constants.api.url,
  });
}

export function canComplete(data = {}) {
  return Api.postRequestWithAuthentication(Paths.canComplete, data, {
    baseURL: constants.api.url,
  });
}

export function getAwardee(data: { bidId: number }) {
  return Api.postRequestWithAuthentication(Paths.awards, data, {
    baseURL: constants.api.url,
  });
}

export function addAwards(data = {}) {
  return Api.postRequestWithAuthentication(Paths.addAwardees, data, {
    baseURL: constants.api.url,
  });
}

export function removeBroadcastSupplier(data = {}) {
  return Api.postRequestWithAuthentication(Paths.removeSupplier, data, {
    baseURL: constants.api.url,
  });
}

export function builkUploadDocument(data: any = {}) {
  const formData = new FormData();
  formData.append('BidId', data && data.BidId);
  formData.append('SupplierFile', data && data.SupplierFile);
  return Api.postRequestWithAuthentication(Paths.supplimentalsupplierupload, formData, {
    baseURL: constants.api.url,
  });
}

export function updateSheet(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.updateSheet, data, {
    baseURL: constants.api.url,
  });
}

export function getsupplimentalDocUploadStatus(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.supplimentalSupplierStatus, data, {
    baseURL: constants.api.url,
  });
}

export function saveUploadedSupplimentalSuppliers(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.supplimentalSupplierSave, data, {
    baseURL: constants.api.url,
  });
}

export function docProcessingStatus(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.docprocessingstatus, data, {
    baseURL: constants.api.url,
  });
}

export function addBulkSupplimentalSuppliers(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.addBulkSupplimentalSuppliers, data, {
    baseURL: constants.api.userUrl,
  });
}

export function reBroadcastBid(data: any = {}) {
  return Api.postRequestWithAuthentication(Paths.reBroadcastBid, data, {
    baseURL: constants.api.url,
  });
}
export function clearDashboardCache(data = {}) {
  return Api.postRequestWithAuthentication(Paths.clearDashboardCache, data, {
    baseURL: constants.api.url,
  });
}

//----- API calls-------//
