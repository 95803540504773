import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DSButton } from '@demandstar/components/button';
import { DSRadio } from '@demandstar/components/inputs';
import { DSLink } from '@demandstar/components/link';
import { FlexContainer } from '@demandstar/components/styles';
import { Field, Formik } from 'formik';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import { accountinfoType } from '../../../types/paymentdetails';
import {
  agencyRegistrationParamType,
  generalInformationType,
} from '../../../types/agencyregistration';
import { DeprecatedFormikRadio } from '../../common/controls/inputs/DeprecatedFormikRadio';
import { existingAgencyBuyerType } from 'src/types/agencyregistration';
import { generalInformationValidate } from './validations';
import {
  getMatchedAgencies,
  setAgencyRegistrationDetails,
  updateAndGetExistingBuyer,
} from '../../../store/actions';
import { listParamType } from '../../../types/payments';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import renderDateField from '../../common/controls/inputs/datepicker';
import renderSelect from '../../common/controls/inputs/selectbox';
import { RegistrationWizardButtons } from './RegistrationWizardButtons';
import { renderDeprecatedTextField } from '../../common/controls/inputs/text';
import { setWizardStatus } from '../../../utils/helpers';
import { sharedParamType } from '../../../types/commoditycodes';
import { usaDateFormat, usaZipCodeRegex } from '../../../utils/constants';
import { useDSSelector } from '../../../store/reducers';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { WizardPage } from '../../../types/wizard';

interface GeneralInformationProps {
  change?: any;
  handleSubmit?: any;
  pristine?: Record<string, unknown> | undefined;
  getStateList?: any;
  getCountiesList?: any;
  populateMetrodetails?: any;
  getplanholderTypes?: any;
  createBuyer?: any;
  shared?: sharedParamType;
  agencyregistration?: agencyRegistrationParamType;
  accountinfo?: accountinfoType;
  stateslist?: listParamType[];
  currentwizard?: WizardPage;
  match?: any;
  addagencyreduxForm?: any;
  initialValues: any;
  setFormInitialValues: any;
}

const GeneralInformation = (props: GeneralInformationProps) => {
  const dispatch = useDispatch();

  const {
    getStateList,
    getCountiesList,
    populateMetrodetails,
    getplanholderTypes,
    shared,
    accountinfo,
    stateslist,
    currentwizard,
    initialValues,
    setFormInitialValues,
  } = props;

  const agencyRegistrationWizard = useDSSelector(
    state => state.agencyregistration.agencyRegistrationWizard,
  );
  const wizardcurrentpage = useDSSelector(state => state.agencyregistration.wizardcurrentpage);
  const matchedAgencies = useDSSelector(state => state.agencyregistration.matchedAgencies || []);

  const {
    countrieslist = [],
    countieslist = [],
    timeZoneList = [],
    sharedFormInitialValues = {},
  } = shared || {};
  const { planholderTypes = [] } = accountinfo || {};
  const [metroList, setMetroList] = useState([]);
  const [agencyExistsPopup, setAgencyExistsPopup] = useState({
    showPopup: false,
    showSkipAndAddNewAgencyButton: false,
  });
  const [selectedAgencyMember, setAgencyExistsSelectedMember] =
    useState<existingAgencyBuyerType | null>(null);

  useSetBreadcrumb({
    component: '',
    page: 'Agency Creation',
    title: `Agency Creation - ${currentwizard && currentwizard.name}`,
    altname: `Agency Creation - ${currentwizard && currentwizard.name}`,
    name: 'Agency Creation',
    id: 'AgCR',
    menuactive: 'agency',
  });

  useEffect(() => {
    getplanholderTypes();
  }, [getplanholderTypes]);

  useEffect(() => {
    getStateList();
  }, [getStateList]);

  useEffect(() => {
    if (matchedAgencies?.length > 0) {
      const showSkipAndAddNewAgency = matchedAgencies.some(
        x => x.usernameExists || x.agencyNameExists,
      );
      setAgencyExistsPopup({
        showPopup: true,
        showSkipAndAddNewAgencyButton: !showSkipAndAddNewAgency,
      });
    }
  }, [matchedAgencies]);

  const populateMetro = (name: string, value: any, formData: any) => {
    if (value.length >= 5 && usaZipCodeRegex.test(value)) {
      populateMetrodetails({ zip: value, formData, formPage: 'agencyregistration' });
      // setZipcodeChange(true)
    }
  };

  const onSubmitFn = (data: generalInformationType) => {
    if (data) {
      if (wizardcurrentpage === 1) {
        dispatch(
          getMatchedAgencies({
            agencyRegistrationDetails: {
              generalInfoFormInitialValues: data,
              agencyRegistrationWizard: setWizardStatus(
                agencyRegistrationWizard,
                wizardcurrentpage ? wizardcurrentpage + 1 : 0,
              ),
              wizardcurrentpage: wizardcurrentpage ? wizardcurrentpage + 1 : 0,
            },
            agencyExistsPayload: {
              userName: data.loginusername,
              email: data.contactEmail,
              agencyname: data.agencyname,
            },
          }),
        );
      }
      setFormInitialValues(data);
    }
  };
  const handleUpdateAndGetBuyerInfo = () => {
    if (matchedAgencies && matchedAgencies.length > 0) {
      dispatch(updateAndGetExistingBuyer({ buyerId: selectedAgencyMember?.memberId }));
    }
  };
  const onAgencySelect = (member: existingAgencyBuyerType) => {
    setAgencyExistsSelectedMember(member);
  };
  const closeModal = () => {
    setAgencyExistsSelectedMember(null);
    setAgencyExistsPopup({ showPopup: false, showSkipAndAddNewAgencyButton: false });
  };
  const skipAndAddNewAgency = (data: generalInformationType) => {
    dispatch(
      setAgencyRegistrationDetails({
        generalInfoFormInitialValues: data,
        agencyRegistrationWizard: setWizardStatus(
          agencyRegistrationWizard,
          wizardcurrentpage ? wizardcurrentpage + 1 : 0,
        ),
        wizardcurrentpage: wizardcurrentpage ? wizardcurrentpage + 1 : 0,
        matchedAgencies: [],
      }),
    );
  };
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={generalInformationValidate}
            onSubmit={onSubmitFn}
          >
            {formikProps => {
              const { handleSubmit, setFieldValue } = formikProps;

              const { memberStatus } = formikProps.values;

              return (
                <form onSubmit={handleSubmit}>
                  <h2 className='lineHR'>General Information</h2>
                  <h5>Create Your Username</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='Login Username (Email)'
                        type='text'
                        classNames='class'
                        placeholder='Enter Login Email'
                        name='loginusername'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={80}
                      />
                    </div>
                  </div>
                  <h5>Main Contact Information </h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='First Name'
                        name='firstname'
                        type='text'
                        classNames='class'
                        placeholder='Enter First Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={25}
                      />
                      <Field
                        label='Last Name'
                        name='lastname'
                        type='text'
                        classNames='class'
                        placeholder='Enter Last Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={25}
                      />
                      <Field
                        label='Phone'
                        name='contactPhone'
                        type='text'
                        classNames='class'
                        placeholder='Enter Phone'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />
                      <Field
                        label='E-mail'
                        name='contactEmail'
                        type='text'
                        classNames='class'
                        placeholder='Enter E-mail'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <h5>Company Information</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        label='Agency Name'
                        name='agencyname'
                        type='text'
                        classNames='class'
                        placeholder='Enter Agency Name'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={100}
                      />

                      <Field
                        name='timeZone'
                        label='Agency Timezone'
                        type='select'
                        component={renderSelect}
                        options={timeZoneList}
                        onChange={setFieldValue}
                        parentClass=''
                      />

                      <Field
                        name='country'
                        label='Country'
                        type='select'
                        component={renderSelect}
                        options={countrieslist}
                        parentClass='w-50'
                        onChange={(name: string, value: any) => {
                          if (value) {
                            getStateList(value.value);
                            getCountiesList('');
                          }
                          setFieldValue('country', value);
                          setMetroList([]);
                          setFieldValue('state', '');
                          setFieldValue('county', '');
                          setFieldValue('metro', '');
                        }}
                      />

                      <Field
                        label='Address 1'
                        name='address1'
                        type='text'
                        classNames='class'
                        component={renderDeprecatedTextField}
                        placeholder='Enter Address1'
                        onChange={setFieldValue}
                        maxLength={50}
                      />

                      <Field
                        label='Address 2'
                        name='address2'
                        type='text'
                        classNames='class'
                        component={renderDeprecatedTextField}
                        placeholder='Enter Address2'
                        optional={true}
                        onChange={setFieldValue}
                        maxLength={50}
                      />
                      <Field
                        label='Zip/Postal Code'
                        name='zipcode'
                        type='text'
                        classNames='class'
                        placeholder='Enter Zip/Postal Code'
                        component={renderDeprecatedTextField}
                        onChange={(name: any, value: any) => {
                          setFieldValue('zipcode', value);
                          populateMetro(name, value, { ...formikProps.values, zipcode: value });
                        }}
                        parentClass='w-48'
                        maxLength={30}
                      />
                      <div className='d-block clear-both'>
                        <Field
                          label='City'
                          name='city'
                          type='text'
                          classNames='class'
                          placeholder='Enter City'
                          parentClass='w-48 float-left'
                          component={renderDeprecatedTextField}
                          onChange={setFieldValue}
                          maxLength={50}
                        />

                        <Field
                          name='state'
                          label='State/Province'
                          type='select'
                          component={renderSelect}
                          options={stateslist}
                          parentClass='w-48 float-right'
                          onChange={(name: string, value: any) => {
                            setFieldValue('state', value);
                            getCountiesList(value ? value.value : '');
                            setFieldValue('county', '');
                            //setFieldValue('metro', '');
                            //setMetroList([]);
                          }}
                        />
                      </div>
                      <div className='d-block clear-both'>
                        <Field
                          name='county'
                          label='County'
                          type='select'
                          component={renderSelect}
                          options={countieslist}
                          parentClass='w-48 float-left'
                          optional={true}
                          onChange={setFieldValue}
                        />

                        <Field
                          name='metro'
                          label='Metro Area'
                          type='select'
                          component={renderSelect}
                          options={metroList}
                          parentClass='w-48 float-right'
                          disabled={'true'}
                          onChange={setFieldValue}
                        />
                      </div>

                      <Field
                        label='Agency Phone'
                        name='buyerPhone'
                        type='text'
                        classNames='class'
                        placeholder='Enter Agency Phone'
                        parentClass='clear-both'
                        component={renderDeprecatedTextField}
                        onChange={setFieldValue}
                        maxLength={17}
                      />

                      <Field
                        label='Website'
                        name='website'
                        type='text'
                        classNames='class'
                        placeholder='Enter Website'
                        component={renderDeprecatedTextField}
                        optional={true}
                        onChange={setFieldValue}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <h5>Operations Functions</h5>
                  <div className='row'>
                    <div className='col-md-9 col-lg-10 col-xl-8'>
                      <Field
                        name='memberStatus'
                        title='Active'
                        component={DeprecatedFormikRadio}
                        type='radio'
                        value='AC'
                        handleSelect={(name: string, value: boolean) => {
                          setFieldValue('memberStatus', value);
                        }}
                        defaultChecked={memberStatus === 'AC' ? true : false}
                      />

                      <Field
                        name='memberStatus'
                        title='Preliminary Agency'
                        component={DeprecatedFormikRadio}
                        type='radio'
                        ID='addSupplierRadio1'
                        value='PR'
                        handleSelect={(name: string, value: boolean) => {
                          setFieldValue('memberStatus', value);
                        }}
                        defaultChecked={memberStatus === 'PR' ? true : false}
                      />
                      <Field
                        label='Implementation Date'
                        classNames='class'
                        name='initialDate'
                        component={renderDateField}
                        parentClass='clear-both mt-5'
                        optional={`(e.g. "${moment().format(usaDateFormat)}")`}
                        onChange={setFieldValue}
                        minDate=''
                        value={moment().toDate()}
                      />

                      <Field
                        name='planholderDisplayType'
                        label='Planholder Display'
                        type='select'
                        component={renderSelect}
                        options={planholderTypes}
                        parentClass=''
                        onChange={setFieldValue}
                      />
                    </div>
                  </div>
                  <RegistrationWizardButtons {...props} />
                  <ModalPopUp
                    title={'Agency Exists'}
                    size='xxl'
                    isOpen={agencyExistsPopup.showPopup}
                    closeModal={closeModal}
                  >
                    {' '}
                    <div className='mb-4'>
                      {!selectedAgencyMember ? (
                        'The information entered matches an agency in the DemandStar database. Do you want to continue to use the existing one?'
                      ) : selectedAgencyMember?.memberStatus === 'AC' ? (
                        <div>
                          The selected agency is already an active DemandStar customer. If you need
                          help logging in,
                          <DSLink to={'/accountrecovery'}>click here</DSLink>. Otherwise contact the
                          primary user of the account - {selectedAgencyMember.userName} -{' '}
                          {selectedAgencyMember.email}
                        </div>
                      ) : (
                        <span>
                          The selected agency was a DemandStar customer previously. They will be
                          changed to an active DemandStar account. Please check the details of the
                          account after activation.
                        </span>
                      )}
                    </div>
                    <Table className='tableHData' responsive size='xl'>
                      <tr>
                        <th>Member ID</th>
                        <th>User Name</th>
                        <th>Agency Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>City</th>
                        <th>Postal Code</th>
                        <th>Select</th>
                      </tr>
                      {matchedAgencies?.length > 0 &&
                        matchedAgencies.map((agency, index) => (
                          <tr key={index}>
                            <td>{agency.memberId}</td>
                            <td className={agency.usernameExists ? 'textRed' : ''}>
                              {agency.userName}
                            </td>
                            <td className={agency.agencyNameExists ? 'textRed' : ''}>
                              {agency.agencyName}
                            </td>
                            <td>{agency.phone}</td>
                            <td className={agency.emailExists ? 'textRed' : ''}>{agency.email}</td>
                            <td>{agency.city}</td>
                            <td>{agency.postalCode}</td>

                            <td>
                              <DSRadio
                                name='selectAgency'
                                checked={selectedAgencyMember?.memberId === agency.memberId}
                                onSelect={() => onAgencySelect(agency)}
                                label={''}
                              />
                            </td>
                          </tr>
                        ))}
                    </Table>
                    <div className='pagingWrapper '>
                      <FlexContainer justifyContent={'space-between'}>
                        <DSButton type='secondary' onClick={closeModal}>
                          Cancel
                        </DSButton>
                        {agencyExistsPopup.showSkipAndAddNewAgencyButton && (
                          <DSButton
                            type='secondary'
                            onClick={() => skipAndAddNewAgency(formikProps.values)}
                          >
                            Skip & Add new agency
                          </DSButton>
                        )}
                        <DSButton
                          type='primary'
                          onClick={handleUpdateAndGetBuyerInfo}
                          inactive={
                            !selectedAgencyMember || selectedAgencyMember.memberStatus === 'AC'
                          }
                        >
                          Choose selected agency
                        </DSButton>
                      </FlexContainer>
                    </div>
                  </ModalPopUp>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default memo(GeneralInformation);
