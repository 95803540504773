import { memo, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import PaymentsControl from '../../../common/breadcrumb/supplier/paymentsControl';

import { Buttons, NoResult } from '../../../customcontrols';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  width: '180px',
};

interface PropsTypes {
  accountinfo?: {
    geoCountries?: Array<{
      value?: number;
      title?: string;
    }>;
    profileInfo?: {
      card?: {
        cardholderName: string;
        cardLastFour: number;
        expirationMonth: number;
        expirationYear: number;
      };
    };
    paymentvals?: {
      isEditBilling: boolean;
      activeTab: string;
    };
    reloadBillingInfo?: boolean;
    card_internalLoader?: boolean;
    billing_address_internalLoader?: boolean;
    accountinfo?: {
      memberInfo: Array<{
        addressType: string;
      }>;
    };
  };
  handleEditClick: any;
  getAccountInfo?: any;
  getProfileInfo?: any;
  setAccountInfoDetails?: any;
  submitRemoveCard?: any;
  setSharedDetails?: any;
  auth?: {
    opi: number;
  };
  handleEditPayment: any;
}

type billingType = {
  addressType?: string;
  countryId?: number;
  address1?: string;
  address2?: string;
  city?: string;
  stateName?: string;
  postalCode?: string;
};

const BillingInfo = (props: PropsTypes) => {
  const {
    accountinfo,
    handleEditClick,
    getAccountInfo,
    getProfileInfo,
    setAccountInfoDetails,
    submitRemoveCard,
    setSharedDetails,
    auth,
  } = props;
  const {
    geoCountries = [],
    profileInfo,
    paymentvals,
    reloadBillingInfo,
    card_internalLoader,
    billing_address_internalLoader,
    accountinfo: accInfo,
  } = accountinfo || {};
  const { memberInfo = [] } = accInfo || {};
  const { card /*, result */ } = profileInfo || {};
  const { opi = 0 } = auth || {};
  // const hasCard = profileInfo.card
  const { cardholderName, cardLastFour, expirationMonth, expirationYear } = card || {};
  const { isEditBilling, activeTab } = paymentvals || {};

  useSetBreadcrumb({
    component: (
      <PaymentsControl
        handleEditClick={handleEditClick}
        isEditBilling={isEditBilling}
        activeTab={activeTab}
      />
    ),
    page: 'Payments',
    title: 'Payments',
    altname: 'Payments',
    name: 'Payments',
  });

  useEffect(() => {
    if (reloadBillingInfo) {
      getProfileInfo({ isDefault: false });
      getAccountInfo();
      setAccountInfoDetails({ reloadBillingInfo: false });
    }
  }, [setSharedDetails, getAccountInfo, getProfileInfo, reloadBillingInfo, setAccountInfoDetails]);

  let billing = memberInfo.find(item => item.addressType === 'BA') as billingType;
  const mainAddress = memberInfo.find(item => item.addressType === 'MA');
  if (!billing) {
    billing = { ...mainAddress };
    billing.addressType = 'BA';
  }
  const country = geoCountries.find((v, i) => v.value === billing.countryId) || {};

  const RemoveCardOPI = () => {
    submitRemoveCard();
  };

  const [removecardmodal, setRemovecardmodal] = useState(false);

  const DeleteConfirmFn = () => {
    setRemovecardmodal(!removecardmodal);
  };

  return (
    <div className='row'>
      <div className='col-7'>
        <h3 className='arrowWrapper'>Billing Info</h3>
        <h4>Pay with card</h4>
        {cardLastFour ? (
          <>
            <div className='innerColIndent'>
              <Table borderless className='tableData'>
                {cardholderName && (
                  <tr>
                    <th style={tdWidth}>Cardholder Name</th>
                    <td>{cardholderName}</td>
                  </tr>
                )}
                {cardLastFour && (
                  <tr>
                    <th>Card Number</th>
                    <td>************ {cardLastFour}</td>
                  </tr>
                )}
                {expirationMonth && expirationYear && (
                  <tr>
                    <th>Expiration Date</th>
                    <td>
                      {expirationMonth}/{expirationYear}
                    </td>
                  </tr>
                )}
              </Table>
              {auth && opi ? (
                <Buttons
                  onClick={DeleteConfirmFn}
                  title='Remove Card'
                  text='Remove Card'
                  classNames='bttn-secondary small float-right'
                />
              ) : null}
            </div>
            <ModalPopUp
              title='Delete Confirmation'
              size='md'
              isOpen={removecardmodal}
              closeModal={DeleteConfirmFn}
            >
              <p>Are you sure you want to delete?</p>
              <Buttons
                classNames='bttn-secondary mt-2'
                text='Cancel'
                title='Cancel'
                type='button'
                onClick={DeleteConfirmFn}
              />
              <Buttons
                classNames='bttn-primary  mt-2 float-right'
                text='Ok'
                title='Ok'
                type='button'
                onClick={RemoveCardOPI}
              />
            </ModalPopUp>
          </>
        ) : (
          <NoResult message={card_internalLoader ? loadingMsg : 'No Card Available'} />
        )}
        <h4 className='clear-both'>Billing Address</h4>
        {billing && billing.address1 ? (
          <>
            <div className='innerColIndent'>
              <Table borderless className='tableData'>
                {billing.address1 && (
                  <tr>
                    <th style={tdWidth}>Address 1</th>
                    <td>{billing.address1}</td>
                  </tr>
                )}
                {billing.address2 && (
                  <tr>
                    <th>Address 2</th>
                    <td>{billing.address2}</td>
                  </tr>
                )}
                {billing.city && (
                  <tr>
                    <th>City</th>
                    <td>{billing.city}</td>
                  </tr>
                )}
                {/* {billing.county && (
								<tr>
									<th>County</th>
									<td>{billing.county}</td>
								</tr>
							)} */}
                {billing.stateName && (
                  <tr>
                    <th>State</th>

                    <td>{billing.stateName}</td>
                  </tr>
                )}
                {billing.postalCode && (
                  <tr>
                    <th>Postal Code</th>
                    <td>{billing.postalCode}</td>
                  </tr>
                )}
                {country.title && (
                  <tr>
                    <th>Country</th>
                    <td>{country.title}</td>
                  </tr>
                )}
              </Table>
            </div>
          </>
        ) : (
          <NoResult
            message={billing_address_internalLoader ? loadingMsg : 'No Billing Address Available'}
          />
        )}
        <Buttons
          classNames='bttn-primary mt-2 float-right'
          text='Edit'
          type='button'
          onClick={handleEditClick}
        />
      </div>
    </div>
  );
};

export default memo(BillingInfo);
