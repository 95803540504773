import React, { memo, useEffect } from 'react';
import { Table } from 'reactstrap';
import { deprecatedGetDate, formatPrice } from '../../../../utils/helpers';
import { chargeType, MemberType } from '../../../../utils/constants';
import { accountinfoType, billingParamType, paymentsType } from '../../../../types/paymentdetails';
import history from '../../../../utils/history';
import LogoDS from 'src/assets/images/logo_ds';

const tdWidth = {
  width: '200px',
};

interface PropsTypes {
  handleCancelClick: any;
  paymentInfo: paymentsType;
  auth?: {
    fln: string;
    eml: string;
    mt: string;
  };
  accountinfo?: accountinfoType;
  memberinfo?: {
    mn: string;
  };
  setAccountInfoDetails?: any;
  getOrderInfo?: any;
  history?: any;
  initialval: any;
}

const Receipt = (props: PropsTypes) => {
  const {
    auth,
    accountinfo,
    memberinfo,
    handleCancelClick,
    paymentInfo,
    setAccountInfoDetails,
    getOrderInfo,
    history,
    initialval,
  } = props;
  const { fln: fullName, eml: email, mt: memberType = '' } = auth || {};
  const { accountinfo: accountInfo, geoCountries = [], orderinfo } = accountinfo || {};
  const { memberInfo = [] } = accountInfo || {};
  const { trackingNumber = '', chargeType: cType = '' } = paymentInfo || {};
  const {
    bidName = '',
    bidId = '',
    fullName: orderinfofullName = '',
    email: orderinfoemail = '',
  } = orderinfo || {};
  const { mn: memberName } = memberinfo || {};

  let billing = memberInfo.find(item => item.addressType === 'BA') as billingParamType;
  const mainAddress = memberInfo.find(item => item.addressType === 'MA');
  if (!billing) {
    billing = { ...mainAddress };
    billing.addressType = 'BA';
  }

  useEffect(() => {
    if (trackingNumber && cType === chargeType.bidPurchase) {
      getOrderInfo({ orderId: trackingNumber });
    }
  }, [cType, getOrderInfo, trackingNumber]);

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ orderinfo: '' });
    };
  }, [setAccountInfoDetails]);

  const GoToPage = (bidId?: number) => {
    history.push(`/suppliers/bids/${bidId}/details`);
    setAccountInfoDetails({ paymentvals: { ...initialval } });
  };

  const refundNotes = paymentInfo.notes ? (
    <tr>
      <th>Payment Notes</th>
      <td>{paymentInfo.notes}</td>
    </tr>
  ) : null;

  const bidDetails =
    paymentInfo.chargeType === 'BR' && paymentInfo.bidId ? (
      <>
        <tr>
          <th>Bid Name</th>
          <td>
            <span
              title={paymentInfo.bidName}
              className='staticLink'
              onClick={() => GoToPage(paymentInfo.bidId)}
            >
              {paymentInfo.bidName}
            </span>
          </td>
        </tr>
        <tr>
          <th>Agency</th>
          <td>{paymentInfo.agencyName}</td>
        </tr>
      </>
    ) : null;

  const country = geoCountries.find(item => item.value === billing.countryId);
  return (
    <div className='container'>
      <div className='receipt-logo'>
        <LogoDS />
      </div>
      <div className='row'>
        <div className='col-8'>
          <h3 className='reciept-print'>Receipt</h3>
          {cType === chargeType.bidPurchase && bidName && (
            <>
              <h4>Bid Information</h4>
              <div className='innerColIndent'>
                <Table borderless className='tableData'>
                  <tr>
                    <th style={tdWidth}>Bid Name</th>
                    <td>
                      <strong
                        className='staticLink'
                        onClick={() => {
                          if (bidId)
                            history.push(
                              `/${
                                memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                              }/bids/${bidId}/details`,
                            );
                        }}
                      >
                        {bidName} <i className='mdi mdi-launch ml-2' />
                      </strong>
                    </td>
                  </tr>
                </Table>
              </div>
            </>
          )}
          <h4>Transaction Information</h4>
          <div className='innerColIndent'>
            <Table borderless className='tableData'>
              <tr>
                <th style={tdWidth}>Merchant</th>
                <td>{memberName}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{paymentInfo.paidAmount && `$${formatPrice(paymentInfo.paidAmount, 2)}`}</td>
              </tr>
              <tr>
                <th>Transaction Date</th>
                <td>{deprecatedGetDate(paymentInfo.date)}</td>
              </tr>
              <tr>
                <th>Purchase Order Number</th>
                <td>{paymentInfo.trackingNumber}</td>
              </tr>
              {refundNotes}
              {bidDetails}
            </Table>
          </div>

          <h4>Payment Information</h4>
          <div className='innerColIndent'>
            <Table borderless className='tableData'>
              <tr>
                <th style={tdWidth}>Payment Type:</th>
                <td>{paymentInfo.paymentType}</td>
              </tr>
              {(paymentInfo.typeCode === 'CC' || paymentInfo.typeCode === 'RC') &&
                paymentInfo.creditCardName && (
                  <>
                    <tr>
                      <th>Card Type:</th>
                      <td>{paymentInfo.creditCardName}</td>
                    </tr>
                    {paymentInfo.creditCardHolderName && (
                      <tr>
                        <th>Cardholder Name:</th>
                        <td>{paymentInfo.creditCardHolderName}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Credit Card Ends With:</th>
                      <td>{paymentInfo.ccNumberLastFourDigits}</td>
                    </tr>
                  </>
                )}
            </Table>
          </div>

          <h4>Customer Information</h4>
          <div className='innerColIndent'>
            <Table borderless className='tableData'>
              <tr>
                <th style={tdWidth}>Name:</th>
                <td>{orderinfofullName ? orderinfofullName : fullName}</td>
              </tr>
              <tr>
                <th>Email:</th>
                <td>{orderinfoemail ? orderinfoemail : email}</td>
              </tr>
              {/* <tr>
								<th>Phone:</th>
								<td>{}</td>
							</tr> */}
              <tr>
                <th>Billing Address:</th>
                <td>
                  {billing.address1}
                  {billing.address1 && billing.address2 ? ',' : ''} {billing.address2} <br />
                  {billing.city}
                  {billing.city && billing.countyName ? ',' : ''} {billing.countyName} <br />
                  {billing.stateName}
                  {billing.stateName && country && country.title ? ',' : ''}{' '}
                  {country && country.title}
                  {country && country.title && billing.postalCode ? ',' : ''} {billing.postalCode}
                </td>
              </tr>
            </Table>
          </div>

          <span onClick={handleCancelClick} className='reciept-screen bttn-secondary float-left'>
            Back
          </span>
          {paymentInfo.printbtn ? null : (
            <span
              onClick={() => window.print()}
              //target='_blank'
              className='reciept-screen bttn bttn-secondary float-right staticLink'
            >
              Print Receipt
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Receipt);
