import React, { useEffect } from 'react';
import { MemberType } from '../../../utils/constants';
import { noresultFound } from '../../../utils/texts';
import { NoResult } from '../../customcontrols/index';
import DashboardSummaryItem from './dashboardsummaryitem';
import { bidstatParamType } from '../../../types/commoditycodes';

interface PropsTypes {
  shared?: any;
  getDashboardBidsList?: any;
  getDashboardQuotesList?: any;
  auth?: any;
  history?: any;
  getBids?: any;
  setBidDetails?: any;
  getQuotesList?: any;
  setQuotesDetails?: any;
}

function DashboardLeftPanel(props: PropsTypes) {
  const {
    shared,
    getDashboardBidsList,
    getDashboardQuotesList,
    auth,
    history,
    getBids,
    setBidDetails,
    getQuotesList,
    setQuotesDetails,
  } = props;
  const { statistics, dashboardBids = [], dashboardQuotes = [] } = shared || {};
  const { bids = [], quotes = [] } = statistics || {};
  const { mt: memberType } = auth || {};

  useEffect(() => {
    getDashboardBidsList();
  }, [getDashboardBidsList]);

  useEffect(() => {
    getDashboardQuotesList();
  }, [getDashboardQuotesList]);

  return (
    <>
      {bids.length > 0 ? (
        <>
          <h3 className='lineHR' data-testid='dashboard.mybids.head'>
            My Bids
          </h3>
          {bids.map(
            (item: bidstatParamType, index: number) =>
              (MemberType.AgencyBuyer === memberType ||
                !['MB', 'UP', 'OP'].includes(item.categoryType)) && ( // should these be BidCategory enums?
                <DashboardSummaryItem
                  key={index}
                  dashboardBids={dashboardBids}
                  pagefor='bids'
                  item={item}
                  memberType={memberType}
                  history={history}
                  getBids={getBids}
                  setBidDetails={setBidDetails}
                />
              ),
          )}
        </>
      ) : (
        <NoResult message={noresultFound} />
      )}
      {memberType !== MemberType.BasicSupplier ? (
        quotes.length > 0 ? (
          <>
            <h3 className='lineHR mt-4' data-testid='dashboard.myquotes.head'>
              My Quotes
            </h3>
            {quotes.map(
              (item: any, index: any) =>
                (MemberType.AgencyBuyer === memberType ||
                  !['MQ', 'IN'].includes(item.categoryType)) && (
                  <DashboardSummaryItem
                    key={index}
                    dashboardQuotes={dashboardQuotes}
                    pagefor='quotes'
                    item={item}
                    memberType={memberType}
                    history={history}
                    getQuotesList={getQuotesList}
                    setQuotesDetails={setQuotesDetails}
                  />
                ),
            )}
          </>
        ) : (
          <NoResult message={noresultFound} />
        )
      ) : null}
    </>
  );
}

export default DashboardLeftPanel;
