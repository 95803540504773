import { FormProvider, useForm } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { DSCheckField, DSSelectField, DSTextField } from '@demandstar/components/fields';
import { DataTestId } from '@demandstar/components/types';
import { DSButton } from '@demandstar/components/button';
import { DSFileField } from '@demandstar/components/fields/file-field';
import { FileUploadText } from '@demandstar/components/inputs/file/constants';
import { FlexContainer } from '@demandstar/components/styles';
import { getFileExtension } from '@demandstar/components/utils';
import { useFileInput } from '@demandstar/components/inputs/file/useFileInput';

import { AcceptedFileFormatsModal } from '../AcceptedFileFormatsModal';
import { documentLabels } from '../../../shared/constants';
import { documentUploadFormChangedState } from '../../../store/recoil/documentState';
import { DocumentUploadFormValues } from '../../../types';
import { FileUploadLabels } from '../../../shared/constants/bids';
import { RequiredDocType } from 'src/shared/hooks/award-bid/useRequiredDocumentTypes';
import { tryCatchLog } from 'src/utils/errors';
import { UploadFormWrapper } from './DocumentUploadForm.styles';
import { useAcceptedFileFormats } from 'src/store/recoil/accepted-files-formats/useAcceptedFileFormats';
import { useSelectedMemberType } from '../../../shared/hooks/useMemberInfo';

interface DocumentUploadFormProps extends DataTestId {
  docReferenceId?: number;
  docTypeLabel?: string;
  docTypes?: RequiredDocType[];
  handleFormSubmit: (formValues: DocumentUploadFormValues) => Promise<void>;
  presetDocumentType?: string; // Optional for explicit doc type, e.g. required doc response uploads
  submitButtonLabel?: string;
  uploadProgress: number;
}

/**
 * FIXME: Add Documentation. What is the scope of this form?
 * Is this for all documents we're uploading?
 * All Award Documents?
 * Currently this is used in two places, both of which are used for bids that are being awarded or have been awarded.
 */
export function DocumentUploadForm({
  dataTestId,
  docReferenceId,
  docTypeLabel,
  docTypes,
  handleFormSubmit,
  presetDocumentType,
  uploadProgress = 0,
}: DocumentUploadFormProps) {
  // Form hook functions
  const methods = useForm<DocumentUploadFormValues>({
    mode: 'onTouched',
  });

  const { reset, handleSubmit, watch } = methods;

  const setDocumentUploadFormStarted = useSetRecoilState(documentUploadFormChangedState);

  const { selectedMemberIsAgency } = useSelectedMemberType();

  /// Local state
  const { validateFileSize } = useFileInput();
  const { isConvertibleFormat, validateFileFormats } = useAcceptedFileFormats();
  const [fileFormatModalOpen, setFileFormatModalOpen] = useState(false);

  const uploading = useMemo(() => {
    return uploadProgress > 0 && uploadProgress < 100;
  }, [uploadProgress]);

  /// Functions

  /**
   * Call supplied callback with form values.
   * @param {DocumentUploadFormValues} values
   * @description Registered `react-hook-form` values are passed back to the callback.
   * The current implementation of `react-select` doesn't register correctly,
   * so the `documentType` value is passed explicitly.
   * @todo Remove `react-hook-form`  and process manually or upgrade / replace `react-select`.
   */
  async function onUploadFormSubmit(values: DocumentUploadFormValues) {
    const submitValues = { ...values };
    submitValues.documentTypeValue = values.documentType || presetDocumentType || '';
    submitValues.docReferenceId = docReferenceId;

    setDocumentUploadFormStarted(true);
    tryCatchLog(async () => {
      await handleFormSubmit(submitValues);
      reset({
        files: [],
        documentTitle: '',
        convertToPDF: false,
        documentType: '',
      });
    }, 'DocumentUploadForm -> onUploadFormSubmit');
  }

  const filesToUpload = useMemo(() => watch('files'), [watch]);

  const allowConvertToPDF = useMemo(() => {
    const file = filesToUpload ? filesToUpload[0] : undefined;
    if (file && file.name) {
      const extension = getFileExtension(file?.name);
      return isConvertibleFormat(extension) && selectedMemberIsAgency();
    }
    return false;
  }, [filesToUpload, isConvertibleFormat, selectedMemberIsAgency]);

  const typeLabel = docTypeLabel || FileUploadLabels.DocumentType;

  function toggleFileFormatModalOpen() {
    setFileFormatModalOpen(!fileFormatModalOpen);
  }

  return (
    <FormProvider {...methods}>
      <UploadFormWrapper onSubmit={handleSubmit(onUploadFormSubmit)}>
        <DSFileField
          dataTestId={dataTestId}
          label={'File'}
          message={FileUploadText.ChooseFileDescription}
          name={'files'}
          errorClick={{
            documentTypeSupported: toggleFileFormatModalOpen,
          }}
          rules={{
            validate: {
              documentSizeSupported: validateFileSize,
              documentTypeSupported: validateFileFormats,
            },
          }}
          uploadProgress={uploadProgress}
        ></DSFileField>
        <DSTextField
          dataTestId={'documentTitle'}
          name={'documentTitle'}
          label={FileUploadLabels.DocumentTitle}
          message={FileUploadLabels.FileNameMessage}
          optional
        />
        {!presetDocumentType && docTypes && (
          <DSSelectField
            dataTestId='documentType'
            name='documentType'
            label={typeLabel}
            options={docTypes}
            valueField='title'
            labelField='title'
          />
        )}
        {allowConvertToPDF && (
          <DSCheckField label={documentLabels.convertToPdf} name='convertToPDF' />
        )}
        <FlexContainer justifyContent={'end'}>
          <DSButton type='submit' inactive={uploading}>
            {FileUploadText.SaveAndAddAnother}
          </DSButton>
        </FlexContainer>
      </UploadFormWrapper>
      <AcceptedFileFormatsModal
        isOpen={fileFormatModalOpen}
        closeModal={toggleFileFormatModalOpen}
      />
    </FormProvider>
  );
}
