import jwt from 'jsonwebtoken';

import { Assert } from './testing';
import { AuthToken } from '../token';

export const isExpiredToken = (token: string) => {
  const tokenData = jwt.decode(token) as AuthToken;
  Assert(tokenData, 'We should not get a null value when decoding tokenData', 'helpers/auth.ts');
  if (tokenData && tokenData.exp && Date.now() > tokenData.exp * 1000) {
    return true;
  }
  return false;
};
