import { GetAuthTokenRequest, GetAuthTokenResponse } from 'src/types';

import { axiosBareRequest } from 'src/store/services/api';
import { constants } from 'src/utils/settings';
import { Paths } from 'src/utils/constants';

const defaultTokenResponse: GetAuthTokenResponse = { url: '', token: '' };

/**
 * Authorization token request
 * @param {GetAuthTokenRequest} requestPayload
 * @returns {Promise<GetAuthTokenResponse>} tokenresponse
 */
export async function getAuthToken(
  requestPayload: GetAuthTokenRequest,
): Promise<GetAuthTokenResponse> {
  const { password: Password, userName: UserName } = requestPayload;
  try {
    const tokenResponse = await axiosBareRequest<GetAuthTokenResponse>({
      baseURL: constants.api.authUrl,
      data: {
        Expiration: 24,
        IsAnonymous: false,
        Password,
        UserName,
      },
      method: 'POST',
      url: Paths.auth.authorization,
    });
    return tokenResponse;
  } catch (error) {
    console.error('error', error);
    return defaultTokenResponse;
  }
}
