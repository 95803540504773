import React, { memo, useEffect } from 'react';
import { Table } from 'reactstrap';
import { MemberType } from '../../../../../utils/constants';
import { bidItemParamType, bidssummaryParamType } from '../../../../../types/biddetail';
import { addbidParamType } from '../../../../../types/addbid';
import { authTypes } from '../../../../../types/auth';
import { sharedTypes } from '../../../../../types/shared';

const tdWidth = {
  width: '240px',
};

interface PropsTypes {
  getAddBidResponseItems?: any;
  getResponseDisplays?: any;
  bidssummary?: bidssummaryParamType;
  addbid?: addbidParamType;
  shared?: sharedTypes;
  auth?: authTypes;
}

const EbidInfo = (props: PropsTypes) => {
  const { getAddBidResponseItems, getResponseDisplays, bidssummary, addbid, shared, auth } = props;
  const { results = {} } = bidssummary || {};

  const { bidresponseitems = [] } = addbid || {};
  const { responseDisplays = [] } = shared || {};

  const { bidID = '', tse = '', bidAmtOptional = false } = results as bidItemParamType;

  const { mt: memberType } = auth || {};

  useEffect(() => {
    if (bidID) getAddBidResponseItems({ bidId: bidID });
  }, [bidID, getAddBidResponseItems]);

  useEffect(() => {
    if (bidID) getResponseDisplays({ bidId: bidID });
  }, [bidID, getResponseDisplays]);

  const filteredResult = responseDisplays.filter(item => item.value === tse) || [];
  const filteredResponseDocs = bidresponseitems.filter(item => item.BidResponseItemId) || [];

  return (
    <Table borderless className='tableData'>
      <tbody>
        {memberType === MemberType.AgencyBuyer && (
          <tr>
            <th style={tdWidth}>Require Suppliers To Enter Bid Amount</th>
            <td>{bidAmtOptional === true ? 'No' : 'Yes'}</td>
          </tr>
        )}
        {memberType === MemberType.AgencyBuyer && filteredResult.length > 0 ? (
          <tr>
            <th style={tdWidth}>View Supplier Info on eBid Responses Tab</th>
            <td>{filteredResult[0].label}</td>
          </tr>
        ) : null}
        {filteredResponseDocs.length > 0 ? (
          <>
            <tr>
              <th style={tdWidth}>Required Documents</th>
              <td>
                {filteredResponseDocs.map((item, index) => (
                  <span key={index}>
                    <span>
                      {index + 1}. {item.Description}
                    </span>
                    <br />
                  </span>
                ))}
              </td>
            </tr>
          </>
        ) : null}
      </tbody>
    </Table>
  );
};

export default memo(EbidInfo);
