import { BarDatum, ResponsiveBar } from '@nivo/bar';

import { DataColor, Flex, FlexContainer, H2, H3 } from '@demandstar/components/styles';
import { DSButtonGroup } from '@demandstar/components/button/DSButtonGroup';
import { DSLink } from '@demandstar/components/link';
import { DSLinkButton } from '@demandstar/components/button';
import { DSPageLayout } from '@demandstar/components/page-layout';
import { formatDollar } from '@demandstar/components/utils';

import * as texts from './CMDashboard.texts';
import {
  cmButtonTexts,
  cmExplanatoryTexts,
  contractManagementLabels,
} from '../../ContractManagement.texts';

import { BidSearchResult, useAwardedBidSearch } from '../add-contract/useAwardedBidSearch';
import { DashboardContent, Ul } from './CMDashboard.styles';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';

import { ButtonsFlexContainer } from '../../ContractManagement.styles';
import { ComingSoon } from './ComingSoon';
import { ContractAddedAlert } from './ContractAddedAlert';
import { ContractLimitAlert } from '../components/ContractLimitAlert';
import { hasPermission, hasValue } from 'src/utils/helpers';
import { paths } from '../../ContractManagement.paths';
import { Reminders } from '../reminders/Reminders';
import { useContractSearch } from '../../search';
import { useMemberAwards } from './useMemberAwards';
import { useReminders } from '../reminders/hook/useReminders';
import { useDSSelector } from 'src/store/reducers';
import { userPermissionKeys } from 'src/utils/constants';
import { useEffect } from 'react';
import history from 'src/utils/history';

export const CMDashboard = () => {
  const { bidsAwarded, memberAwards, suppliersAwarded } = useMemberAwards();
  const { reminders } = useReminders();
  const { contractLimitExceeded } = useContractSearch();

  // TODO create a way to limit the number of awards returned in a search;
  const { awardedBidsWithoutContracts } = useAwardedBidSearch();
  const auth = useDSSelector(state => state.auth);
  const memberinfo = useDSSelector(state => state.memberinfo);
  const { prms } = memberinfo;
  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  useEffect(() => {
    if (hasEditMemberPermission === false) {
      history.push(`/buyers/dashboard`);
    }
  }, [hasEditMemberPermission]);

  const awardedBid = ({ bidId, bidName }: BidSearchResult) => {
    return (
      <DSLink title={bidName} to={paths.navigation.addContractFromBid(bidId)}>
        {bidName}
      </DSLink>
    );
  };

  const AwardedBidsSection = () =>
    contractLimitExceeded ? (
      <DSLinkButton to={paths.navigation.awardedBids}>{texts.importButton}</DSLinkButton>
    ) : (
      <>
        <H3>{texts.importHeader}</H3>
        <p>{texts.importInfo}</p>
        <Ul>
          {awardedBidsWithoutContracts?.slice(0, 10).map(bid => {
            return <li key={bid.bidId}>{awardedBid(bid)}</li>;
          })}
        </Ul>
        <DSLinkButton to={paths.navigation.awardedBids}>{texts.importButton}</DSLinkButton>
      </>
    );

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      <DSPageLayout>
        <FlexContainer>
          <H2>{contractManagementLabels.title}</H2>
        </FlexContainer>
        <FlexContainer>
          <p>{cmExplanatoryTexts.Dashboard}</p>
        </FlexContainer>
        <FlexContainer>
          <ContractAddedAlert />
        </FlexContainer>
        <FlexContainer>
          <ContractLimitAlert />
        </FlexContainer>
        <FlexContainer>
          <H3>{contractManagementLabels.manage}</H3>
        </FlexContainer>
        <DSButtonGroup>
          <ButtonsFlexContainer>
            <DSLinkButton
              type={'tertiary'}
              icon={'mdi-eye-outline'}
              to={paths.navigation.reminders()}
            >
              {cmButtonTexts.yourReminders}
            </DSLinkButton>
            <DSLinkButton type={'tertiary'} icon={'mdi-sync'} to={paths.navigation.searchContracts}>
              {cmButtonTexts.managedContracts}
            </DSLinkButton>
            <DSLinkButton
              type={'tertiary'}
              icon={'mdi-plus'}
              inactive={contractLimitExceeded}
              to={paths.navigation.addContractInterstitial(false)}
            >
              {cmButtonTexts.addContracts}
            </DSLinkButton>
            <DSLinkButton
              type={'tertiary'}
              icon={'mdi-newspaper'}
              to={paths.navigation.documents()}
            >
              {cmButtonTexts.recentDocuments}
            </DSLinkButton>
          </ButtonsFlexContainer>
        </DSButtonGroup>
        <FlexContainer>
          <Flex grow={2}>
            {reminders && <Reminders preview compact canHide={false} />}
            <AwardedBidsSection />
          </Flex>
          <Flex grow={5}>
            <FlexContainer justifyContent='space-between' wrap='wrap'>
              {hasValue(memberAwards) && memberAwards.contents && (
                <DashboardContent>
                  <h5>{texts.mainGraphHeader}</h5>
                  <div>
                    <ResponsiveBar
                      axisBottom={{ tickRotation: 45 }}
                      axisLeft={{ format: value => formatDollar(value, '', false), tickValues: 5 }}
                      colors={`${DataColor.Primary}`}
                      data={memberAwards.contents as BarDatum[]}
                      indexBy='month'
                      keys={['amount']}
                      labelSkipHeight={16}
                      labelSkipWidth={16}
                      labelTextColor='inherit:darker(1.4)'
                      margin={{ top: 10, right: 110, bottom: 60, left: 80 }}
                      padding={0.2}
                      valueFormat={value => formatDollar(value, '', false)}
                    />
                  </div>
                </DashboardContent>
              )}
              {hasValue(suppliersAwarded) && suppliersAwarded.contents && (
                <DashboardContent grow={1} basis={'45%'}>
                  <h6>{texts.primaryCalloutHeader}</h6>
                  <p>
                    <span>{suppliersAwarded.contents}</span>
                  </p>
                </DashboardContent>
              )}
              {hasValue(bidsAwarded) && bidsAwarded.contents && (
                <DashboardContent grow={1} basis='45%'>
                  <h6>{texts.secondaryCalloutHeader}</h6>
                  <p>
                    <span>{bidsAwarded.contents}</span>
                  </p>
                </DashboardContent>
              )}
              <ComingSoon />
            </FlexContainer>
          </Flex>
        </FlexContainer>
      </DSPageLayout>
    </Feature>
  );
};
