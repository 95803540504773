import React, { memo, useState } from 'react';
import { Collapse } from 'reactstrap';
import { defaultFilter } from '../../../store/reducers/bids';
import { initialquotesFilters } from '../../../store/reducers/quotes';
import {
  bidExternalStatusNames,
  MemberType,
  quoteExternalStatusNames,
} from '../../../utils/constants';
import { noresultFound } from '../../../utils/texts';
import { /* formatPrice, */ encodeFilters, getKeyofObject } from '../../../utils/helpers';
import DashboardSummaryList from './dashboardsummarylist';
//import { NoResult } from '../../customcontrols/index'
import { bidstatParamType } from '../../../types/commoditycodes';
import { commonLabels } from '../../../shared/constants';

interface PropsTypes {
  item?: bidstatParamType;
  dashboardBids?: bidstatParamType[];
  dashboardQuotes?: bidstatParamType[];
  pagefor?: string;
  memberType?: string;
  history?: any;
  setBidDetails?: any;
  getBids?: any;
  setQuotesDetails?: any;
  getQuotesList?: any;
}

const DashboardSummaryItem = (props: PropsTypes) => {
  const {
    item,
    dashboardBids = [],
    dashboardQuotes = [],
    pagefor,
    memberType,
    history,
    setBidDetails,
    getBids,
    setQuotesDetails,
    getQuotesList,
  } = props;
  const [openType, setOpenType] = useState('');
  const [bidsList, setBidList] = useState<bidstatParamType[]>([]);
  const { categoryType = '', count = 0, title = '' } = item || {};

  const openBidType = (type: string) => {
    if (openType === type) {
      setOpenType('');
      setBidList([]);
    } else {
      setOpenType(type);
      let newBidList = [];
      if (pagefor === 'bids') newBidList = dashboardBids.filter(item => item.categoryType === type);
      else newBidList = dashboardQuotes.filter(item => item.categoryType === type);

      setBidList(newBidList);
    }
  };

  const GoToPage = async () => {
    const pageType = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
    let urlParam = '';
    if (pagefor === 'bids') {
      if (categoryType === 'MB') {
        await setBidDetails({ filters: defaultFilter, bidscurrentPage: 1 });
        await getBids({ initialRequest: true });
      } else {
        const bidStatus = {
          label: getKeyofObject(bidExternalStatusNames, categoryType),
          value: categoryType,
        };
        let additionalFilter = { showBids: 'Notified', bidsNotified: true } as any;
        if (memberType === MemberType.AgencyBuyer) {
          additionalFilter = { showBids: 'Mine', myBids: true };
        }
        const searchFilters = { ...defaultFilter, bidStatus, ...additionalFilter };
        await setBidDetails({
          filters: searchFilters,
          initialRequest: false,
          selectedBidIds: '',
          loadedBids: [],
          results: [],
          bidscurrentPage: 1,
        });
        await getBids();
        urlParam = `?${encodeFilters(searchFilters)}`;
      }
      await history.push(`/${pageType}/bids${urlParam}`);
    } else {
      if (categoryType === 'MQ') {
        await setQuotesDetails({ quotesFilters: initialquotesFilters, quotecurrentPage: 1 });
        await getQuotesList({ types: 'initial' });
        await history.push(`/${pageType}/quotes`);
      } else {
        let quoteStatuses = '' as any;
        quoteStatuses = {
          label: getKeyofObject(quoteExternalStatusNames, categoryType),
          value: categoryType,
        };
        await setQuotesDetails({
          quotesFilters: { ...initialquotesFilters, quoteStatuses },
          quotesinitial: false,
          quotesreload: false,
          quotecurrentPage: 1,
        });
        await getQuotesList({ types: 'search' });
        await history.push(`/${pageType}/quotes`);
      }
    }
  };

  return (
    <>
      <div className='accordin-title clearfix' data-testid='dashboard.leftpanel.list'>
        <h6
          color='primary'
          onContextMenu={e => {
            e.preventDefault();
            if (count) GoToPage();
          }}
          onClick={() => {
            openBidType(categoryType);
          }}
        >
          <i
            className={
              'mr-1 pt-2 mdi ' +
              (openType === categoryType
                ? 'mdi-chevron-down-circle-outline'
                : 'mdi-chevron-right-circle-outline')
            }
          />
          {title} <span>({count})</span>
        </h6>
      </div>
      <Collapse
        key='open'
        isOpen={openType === categoryType}
        data-testid='dashboard.leftpanel.collapse'
      >
        {count ? (
          <>
            <DashboardSummaryList memberType={memberType} pagefor={pagefor} dataList={bidsList} />
            <div
              data-testid='view.more.bids'
              className='float-right staticLink mt-2'
              onClick={GoToPage}
            >
              {commonLabels.viewMore} <i className='mdi mdi-launch' />
            </div>
            <br />
          </>
        ) : (
          <>
            <div className='pl-4 py-3 noResultWrapper' data-testid='dashboard.leftpanel.nodata'>
              <i className='mdi mdi-information-outline mr-2' />
              {noresultFound}
            </div>
          </>
        )}
      </Collapse>
    </>
  );
};

export default memo(DashboardSummaryItem);
