import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import { MemberType, userPermissionKeys } from '../../../../utils/constants';
import { canShowPrograms, canShowSelfDeclarations, hasPermission } from '../../../../utils/helpers';
import navHistory from '../../../../utils/history';
import MemberInfo from './memberinfo';
import Programs from './program';
import SelfDeclaration from './selfdeclaration';
import UserAccount from './useraccount';
import AgencyPageSettings from './agencypagesettings';
import BroadcastSupplierList from '../../../buyer/research/broadcastlist';
import { broadcastlistTypes, generatenewTypes } from '../../../../types/broadcastlist';
import { sharedTypes } from '../../../../types/shared';
import { memberinfoParamType } from '../../../../types/bids';
import { authTypes } from '../../../../types/auth';
import { nargsfnTypes } from '../../../../types/functions';
import Preferences from './preferences';

let navLinks = [
  {
    name: 'Member Information',
    id: '1',
    active: 'memberinfo',
  },
  {
    name: 'User Accounts',
    id: '2',
    active: 'useraccount',
  },
  {
    name: 'Certifications',
    id: '3',
    active: 'certifications',
  },
  {
    name: 'Self Declarations',
    id: '4',
    active: 'selfdeclaration',
  },
  {
    name: 'Broadcast List',
    id: '5',
    active: 'broadcastlist',
  },
];

interface propsTypes extends PropsFromRedux {
  memberinfo: memberinfoParamType;
  auth: authTypes;
  GoBackToPage: any;
  SetSelectedCommodity: any;
  getBroadcastListSearchVendors: any;
  timeZone: any;
  history: any;
  broadcastList: broadcastlistTypes;
  shared: sharedTypes;
  getUserAccounts: any;
  match: any;
  setSharedDetails: nargsfnTypes;
}

type combinedTypes = propsTypes & generatenewTypes & broadcastlistTypes;

function AccountInfoTabContent(props: combinedTypes) {
  const [isEdit, setIsEdit] = useState(false);
  const {
    shared,
    history,
    getUserAccounts,
    getPermissions,
    memberInfo,
    memberinfo,
    getAcoountinfoMemberdetails,
    auth,
    getSelfDeclarations,
    accountinfo,
    setAccountInfoDetails,
    setSharedDetails,
    match,
  } = props;
  const { ...otherprops } = props;
  const { selfdeclaredattributes } = shared;
  const { reloadABillingInfo } = accountinfo;

  const { mi: memberId, mt: memberType, prms } = memberinfo;
  const selfDeclarations = selfdeclaredattributes || {};
  const { prms: authPerms, mc: isPrimaryContact /* , opi = 101 */ } = auth;
  const accountPermissions = prms ? prms : authPerms;
  // const opi = 101

  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact || false;

  const Tab = match.params.Tab;

  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'memberinfo');

  useEffect(() => {
    if (reloadABillingInfo) {
      setSharedDetails({ internalLoader: true });
      memberInfo({ isCurrentMember: true });
      setAccountInfoDetails({ reloadABillingInfo: false });
    }
  }, [setSharedDetails, memberInfo, memberId, reloadABillingInfo, setAccountInfoDetails]);

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ reloadABillingInfo: true });
    };
  }, [setAccountInfoDetails]);

  useEffect(() => {
    setSharedDetails({ internalLoader: true });
    getAcoountinfoMemberdetails();
  }, [setSharedDetails, getAcoountinfoMemberdetails]);

  useEffect(() => {
    if (memberId) {
      setSharedDetails({ internalLoader: true });
      getUserAccounts(memberId);
    }
  }, [setSharedDetails, getUserAccounts, memberId]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  useEffect(() => {
    setSharedDetails({ internalLoader: true });
  }, [setSharedDetails]);

  const selfDeclarationinitialValues = {} as any;
  selfDeclarations &&
    selfDeclarations.length &&
    selfDeclarations.forEach((v: any, i: number) => {
      const name = v.attributeName ? v.attributeName.replace(/ /g, '') : '';
      if (name) {
        selfDeclarationinitialValues[v.attributeType] = v.selected;
      }
    });

  const programsInitials = { attribMatch: 'onlySD', certificationStatus: 'Any' };

  const GoBackToPage = useCallback(() => {
    history.push(
      `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/accountinfo/${activeTab}`,
    );
  }, [activeTab, history, memberType]);

  const handleSelfDeclarationsSubmit = (values: any) => {
    const { saveSelfDeclarations } = props;

    //const { memberId } = props.memberInfo
    const attributeTypes = [];
    for (const key in values) {
      if (values[key]) {
        attributeTypes.push(key);
      }
    }
    saveSelfDeclarations({
      attributeTypes: attributeTypes.join(','),
      memberId: memberId,
    });
  };

  const EditMemberInfo = () => {
    setIsEdit(true);
  };

  const displayPrograms = canShowPrograms(memberType);
  const displaySelfDeclarations = canShowSelfDeclarations(memberType);

  if (memberType && memberType !== MemberType.SubscribingSupplier) {
    navLinks = navLinks.filter(link => !['3', '4'].includes(link.id));
  }

  //let FilteredNavLinks = [...navLinks]
  /* if (memberType && memberType !== MemberType.AgencyBuyer) {
		if (!displayPrograms) {
			navLinks = navLinks.filter(link => link.id !== '3')
		}

		if (!displaySelfDeclarations) {
			navLinks = navLinks.filter(link => link.id !== '4')
		}
	} else {
		navLinks = navLinks.filter(link => !['3', '4'].includes(link.id))
	} */

  //const showBroadcastSupplier = memberType === MemberType.AgencyBuyer ? 'buyers' : null
  if (memberType && MemberType.AgencyBuyer !== memberType) {
    navLinks = navLinks.filter(item => item.id !== '5');
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      navHistory.push(
        `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/accountinfo/${tab}`,
      );
      setIsEdit(false);
      // setActiveTab(tab)
    }
  };

  useEffect(() => {
    setActiveTab(Tab);
  }, [Tab]);

  const memberInfoGoBackToPage = useCallback(() => {
    setIsEdit(false);
  }, [setIsEdit]);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Nav tabs>
              {navLinks.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === item.active,
                    })}
                    onClick={() => {
                      toggle(item.active);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
              {memberType === MemberType.AgencyBuyer ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'pagesettings',
                    })}
                    onClick={() => {
                      toggle('pagesettings');
                    }}
                  >
                    Agency Page Settings
                  </NavLink>
                </NavItem>
              ) : null}
              {memberType === MemberType.SubscribingSupplier ? (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 'preferences',
                    })}
                    onClick={() => {
                      toggle('preferences');
                    }}
                  >
                    Preferences
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='memberinfo'>
                {activeTab === 'memberinfo' && (
                  <MemberInfo
                    {...props}
                    isEdit={isEdit}
                    hasEditMemberPermission={hasEditMemberPermission}
                    GoBackToPage={memberInfoGoBackToPage}
                    EditMemberInfo={EditMemberInfo}
                  />
                )}
              </TabPane>
              <TabPane tabId='useraccount'>
                {activeTab === 'useraccount' && (
                  <UserAccount {...props} isEdit={isEdit} GoBackToPage={GoBackToPage} />
                )}
              </TabPane>
              {displayPrograms && (
                <TabPane tabId='certifications'>
                  {activeTab === 'certifications' && (
                    <Programs {...otherprops} initialValues={programsInitials} />
                  )}
                </TabPane>
              )}
              {displaySelfDeclarations && (
                <TabPane tabId='selfdeclaration'>
                  {activeTab === 'selfdeclaration' && (
                    <SelfDeclaration
                      {...otherprops}
                      memberId={memberId}
                      onSubmit={handleSelfDeclarationsSubmit}
                      initialValues={selfDeclarationinitialValues}
                      GoBackToPage={GoBackToPage}
                    />
                  )}
                </TabPane>
              )}
              {memberType === MemberType.AgencyBuyer && (
                <TabPane tabId='pagesettings'>
                  {activeTab === 'pagesettings' && (
                    <AgencyPageSettings activeTab={activeTab} {...otherprops} />
                  )}
                </TabPane>
              )}
              {MemberType.AgencyBuyer === memberType && (
                <TabPane tabId='broadcastlist'>
                  {activeTab === 'broadcastlist' && <BroadcastSupplierList {...props} />}
                </TabPane>
              )}
              {MemberType.SubscribingSupplier === memberType && (
                <TabPane tabId='preferences'>
                  {activeTab === 'preferences' && <Preferences {...props} />}
                </TabPane>
              )}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    addUpdateUserAccountResult: state.addUpdateUserAccountResult,
    accountActivationResult: state.accountActivationResult,
    shared: state.shared,
    memberinfo: state.memberinfo,
    auth: state.auth,
    accountinfo: state.accountinfo,
    commoditycodes: state.commoditycodes,
    broadcastList: state.broadcastList,
    addbid: state.addbid,
    agencyregistration: state.agencyregistration,
    limited: state.limited,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(AccountInfoTabContent));
