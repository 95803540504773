import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Table } from 'reactstrap';

import DateRangePickerComponent from '../../../customcontrols/daterangepicker';
import NoResult from '../../../customcontrols/noresult';

import { activitiesTypes, memberinfoTypes } from '../../../../types';
import {
  bidReportsActivityPaginationsOptions,
  reportbidStatus,
  reportotherbidStatus,
  usaDateFormat,
} from '../../../../utils/constants';
import { Buttons, Paging, ScrollButton, SelectBox } from '../../../customcontrols';

import { authTypes } from '../../../../types/auth';
import { bidreports_quotesInitialValue } from '../../../../store/reducers/activities';
import { deprecatedConvertotherTimezonetoUTC } from '../../../../utils/helpers';
import { loadingMsg } from '../../../../utils/texts';
import { reactselectTypes } from '../../../../types/shared';
import { useSetBreadcrumb } from 'src/shared/hooks';

const style = {
  tdWidth: { width: '140px' },
  tdWidth1: { minWidth: '160px' },
  tdWidth2: { minWidth: '220px' },
};

interface PropTypes {
  auth: authTypes;
  activities: activitiesTypes;
  memberinfo: memberinfoTypes;
  getBidActivityReport: any;
  exportBidActivityReport: any;
  setActivitiesDetails: any;
  memberInfo: any;
}

const BidActivityReport = (props: PropTypes) => {
  const { getBidActivityReport, auth, activities, memberinfo, setActivitiesDetails } = props;
  const {
    activities_bidreports,
    bidreports_status,
    bidreports_startDate,
    bidreports_endDate,
    bidreport_internalLoader,
    activities_bidreports_search,
    activities_bidreports_search_result,
    bidReportsActivityPaginations,
  } = activities || {};
  const {
    bidActivity = [],
    upcomingBids = 0,
    activeBids = 0,
    underEvaluationBids = 0,
    awardedBids = 0,
    totalBids = 0,
  } = activities_bidreports || {};
  const {
    bidActivity: ResultbidActivity = [],
    upcomingBids: ResultupcomingBids = 0,
    activeBids: ResultactiveBids = 0,
    underEvaluationBids: ResultunderEvaluationBids = 0,
    awardedBids: ResultawardedBids = 0,
    totalBids: ResulttotalBids = 0,
  } = activities_bidreports_search_result || {};
  const { mi } = auth || {};

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastLists = currentPage * bidReportsActivityPaginations?.value;
  const indexOfFirstLists = indexOfLastLists - bidReportsActivityPaginations?.value;
  const { tzfn } = memberinfo || {};

  useSetBreadcrumb({
    page: 'Activities',
    altname: 'Reports',
    title: 'Reports',
    name: 'Reports',
    menuactive: 'activities',
  });

  useEffect(() => {
    return () => {
      setActivitiesDetails({
        activities_bidreports_search: false,
        bidreports_startDate: moment().subtract(1, 'months'),
        bidreports_endDate: moment(),
        bidreports_status: reportbidStatus[0],
        activities_bidreports_search_result: bidreports_quotesInitialValue,
      });
    };
  }, [setActivitiesDetails]);

  const ApiCallFn = (
    startDate: string,
    endDate: string,
    statusList: string,
    search: boolean,
    type: string,
  ) => {
    let final_value: any = {
      bidreport_internalLoader: true,
      activities_bidreports_search: search,
      activities_bidreports: bidreports_quotesInitialValue,
      activities_bidreports_search_result: bidreports_quotesInitialValue,
    };
    if (type === 'clear') {
      final_value = {
        ...final_value,
        bidreports_startDate: moment().subtract(1, 'months'),
        bidreports_endDate: moment(),
        bidreports_status: reportbidStatus[0],
      };
    }
    setActivitiesDetails(final_value);
    if (startDate) startDate = deprecatedConvertotherTimezonetoUTC(startDate, tzfn, usaDateFormat);
    if (endDate) endDate = deprecatedConvertotherTimezonetoUTC(endDate, tzfn, usaDateFormat);

    getBidActivityReport({ memberId: mi, startDate, endDate, statusList, search: search });
    setCurrentPage(1);
  };

  const onChangeSelectBox = (name: string, value: reactselectTypes) => {
    setActivitiesDetails({ [name]: value });
    if (value?.value === 'bidReportsActivityPaginations') {
      setCurrentPage(1);
    }
  };

  const onInputChange = (name: string, value: React.SetStateAction<moment.Moment>) => {
    if (name === 'startDate') {
      setActivitiesDetails({ bidreports_startDate: value });
    } else {
      setActivitiesDetails({ bidreports_endDate: value });
    }
  };

  const onSearchFn = () => {
    const apistartDate = bidreports_startDate
      ? moment(bidreports_startDate).format(usaDateFormat)
      : '';
    const apiendDate = bidreports_endDate ? moment(bidreports_endDate).format(usaDateFormat) : '';
    const statusList =
      bidreports_status?.value === 'all' ? reportotherbidStatus : bidreports_status?.value;
    ApiCallFn(apistartDate, apiendDate, statusList, true, '');
  };

  const onClearFn = () => {
    const apistartDate = moment().subtract(1, 'months').format(usaDateFormat);
    const apiendDate = moment().format(usaDateFormat);
    ApiCallFn(apistartDate, apiendDate, reportotherbidStatus, false, 'clear');
  };

  const reports_list = activities_bidreports_search ? ResultbidActivity : bidActivity;

  const finalupcomingBids = activities_bidreports_search ? ResultupcomingBids : upcomingBids;
  const finalactiveBids = activities_bidreports_search ? ResultactiveBids : activeBids;
  const finalunderEvaluationBids = activities_bidreports_search
    ? ResultunderEvaluationBids
    : underEvaluationBids;
  const finalawardedBids = activities_bidreports_search ? ResultawardedBids : awardedBids;
  const finaltotalBids = activities_bidreports_search ? ResulttotalBids : totalBids;

  const nodata_text = activities_bidreports_search
    ? 'No Search Result Available'
    : 'No Data Available';

  const nodata_component = (
    <tr>
      <td colSpan={12}>
        <NoResult message={bidreport_internalLoader ? loadingMsg : nodata_text} />
      </td>
    </tr>
  );

  return (
    <>
      {/* <div className='row'>
        <div className='col-lg-12' data-testid='activities.bids.reports.head'>
          <h2 className='arrowWrapper'>Bid Reports ({reports_list.length})</h2>

        </div>
      </div> */}
      <div className='row mb-3'>
        <div className='col-lg-12'>
          <div
            className='clearfix d-flex align-items-center py-3 activitiesFilter'
            data-testid='activities.bids.reports.inputs'
          >
            <SelectBox
              reactselect={true}
              label='Status'
              name='bidreports_status'
              options={reportbidStatus}
              handleSelect={onChangeSelectBox}
              value={bidreports_status}
            />
            <DateRangePickerComponent
              label='Date Range'
              startDatename='startDate'
              endDatename='endDate'
              handleChange={onInputChange}
              isOutsideRange={true}
              startDatevalue={bidreports_startDate}
              endDatevalue={bidreports_endDate}
              showClearDate={true}
              parentClass='clearfix mr-3 mb-0'
            />
            <Buttons
              text='Search'
              title='Search'
              classNames='bttn-primary'
              onClick={onSearchFn}
              disable={!bidreports_startDate || !bidreports_endDate}
            />
            <Buttons
              classNames='bttn-secondary'
              text='Reset'
              title='Reset'
              type='button'
              onClick={onClearFn}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 countsWrapper'>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.upcoming'>
          <h2>{finalupcomingBids}</h2>
          <p>Upcoming</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.active'>
          <h2>{finalactiveBids}</h2>
          <p>Active</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.under'>
          <h2>{finalunderEvaluationBids}</h2>
          <p>Under Evaluation</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.award'>
          <h2>{finalawardedBids}</h2>
          <p>Awarded</p>
        </div>
        <div className='col text-center' data-testid='activities.bids.reports.table.head.total'>
          <h2>{finaltotalBids}</h2>
          <p>Total Bids Broadcast in this Period</p>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='d-block clearfix mb-4' />
          <div className='bidReportScrollContainer'>
            <ScrollButton direction='left' elementId='bidReportTable'></ScrollButton>
            <div className='table-responsive' id='bidReportTable'>
              <Table className='tableHData' data-testid='activities.bids.reports.maintable.div'>
                <thead>
                  <tr data-testid='activities.bids.reports.maintable.head'>
                    <th style={style.tdWidth}>BidID</th>
                    <th style={style.tdWidth1}>Bid Identifier</th>
                    <th style={style.tdWidth2}>Bid Name</th>
                    <th style={style.tdWidth1}>Buyer Account Initials</th>
                    <th style={style.tdWidth1}>Broadcast Date</th>
                    <th style={style.tdWidth1}>Due Date</th>
                    <th style={style.tdWidth}>Status</th>
                    <th style={style.tdWidth2}>Number of Suppliers Broadcast To</th>
                    <th style={style.tdWidth2}>Supplier with Certifications</th>
                    <th style={style.tdWidth}>Number of Planholders</th>
                    <th style={style.tdWidth2}>Number of Submitted Responses</th>
                    <th style={style.tdWidth}>Estimated Budget</th>
                    <th style={style.tdWidth}>Award</th>
                    <th style={style.tdWidth}>Awardee</th>
                  </tr>
                </thead>
                <tbody data-testid='activities.bids.reports.main.table.body.list'>
                  {reports_list && reports_list.length > 0 ? (
                    <>
                      {reports_list
                        .map((item: any, index: number) => (
                          <tr key={index}>
                            <td>{item.bidId}</td>
                            <td>{item.bidName}</td>
                            <td>{item.bidIdentifier}</td>
                            <td>{item.agency}</td>
                            <td>
                              {item.broadCastDate
                                ? moment(item.broadCastDate).format(usaDateFormat)
                                : ''}
                            </td>
                            <td>
                              {item.dueDate ? moment(item.dueDate).format(usaDateFormat) : ''}
                            </td>
                            <td>{item.status}</td>
                            <td>{item.numberofBroadcasts}</td>
                            <td>{item.noOfSuppliersPossessedCertificate}</td>
                            <td>{item.numberofPlanholders}</td>
                            <td>{item.numberofResponses}</td>
                            <td>{item.estimatedBudget ? item.estimatedBudget : ''}</td>
                            <td>{item.awards}</td>
                            <td>{item.awardee}</td>
                          </tr>
                        ))
                        .slice(indexOfFirstLists, indexOfLastLists)}
                    </>
                  ) : (
                    nodata_component
                  )}
                </tbody>
              </Table>
            </div>
            <ScrollButton direction='right' elementId='bidReportTable'></ScrollButton>
          </div>
          <Paging
            totalRecords={reports_list.length}
            currentPage={currentPage}
            onPagingClick={(value: number) => {
              if (value !== currentPage) setCurrentPage(Number(value));
            }}
            pageLimit={bidReportsActivityPaginations?.value}
          />
          <div className='row mt-2'>
            <div className='col'>
              <div className='d-flex justify-content-end align-items-center'>
                Display{' '}
                <SelectBox
                  reactselect={true}
                  label='Rows'
                  name='bidReportsActivityPaginations'
                  options={bidReportsActivityPaginationsOptions}
                  handleSelect={onChangeSelectBox}
                  value={bidReportsActivityPaginations}
                  parentClass='clearfix small mx-2'
                  disabled={reports_list.length === 0 ? 'true' : 'false'}
                />
                Results
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(BidActivityReport);
