import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useHistory } from 'react-router';

import { DSCheckbox, DSRadio, DSSelect, DSTextInput } from '@demandstar/components/inputs';
import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { DSPaginate } from '@demandstar/components/paginate';

import { AgencySelectionList, SpacedButtonWrapper } from './styles';
import { allProductsState, selectedProductsState } from '../../../store/recoil/productState';
import { normalizeFreeAgencies, searchAgencies, selectFreeAgency } from './helpers';
import { ProductApiResponse, ProductType, SubscriptionProducts } from '../../../types/products';
import { AgencySelectionProps } from './types';
import { agencySelectionText } from './texts';
import { commonLabels } from 'src/shared/constants';
import { compareObjectsByKey } from '../../../utils';
import { MultipleAgencyConfirmationModal } from './MultipleAgencyConfirmationModal';
import { NoResult } from '../../customcontrols/index';
import { ProductsPaths } from 'src/utils/texts/supplier/subscription';
import { scrollToTop } from '../../../utils/helpers';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useAmplitude } from '../../amplitude';
import { useSubscription } from '../../../shared/hooks/useSubscription';
import { useDispatch } from 'react-redux';
import { getRefreshToken } from 'src/store/actions';

/**
 * @description Component that allows for the filtering and selecting a free agency product
 * @returns A filterable, selectable list of agencies with optional buttons for navigating
 * @example
 * <AgencySelection
      products={[{
        productId: 1,
        productName: 'Sample Free Agency',
        productType: ProductType.FreeAgency,
      }]}
      pageFor='registration'
      movetoNextPage={movetoNextPage}
  />
* @example
  <AgencySelection
      products={products}
      toggleAgencySelection={() => toggleAgencySelection}
      isOpsUser={true}
  />
 */
export const AgencySelection = ({
  moveToNextPage,
  pageFor = 'subscription',
  products = [],
  toggleAgencySelection,
  isOpsUser = false,
}: AgencySelectionProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { updateSupplierFreeAgency } = useSubscription();
  const { refreshAccountInfo } = useAccountInfo();
  const { logEvent } = useAmplitude();
  const allProducts = useRecoilValueLoadable(allProductsState);

  const [selectedProducts, setSelectedProducts] =
    useRecoilState<SubscriptionProducts>(selectedProductsState);

  // Filters
  const [autoSuggestText, setAutoSuggestText] = useState<string>('');
  const [selectedState, setSelectedState] = useState<number>(0);
  const [selectedCounty, setSelectedCounty] = useState<number>(0);

  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [newAgenciesExist, setNewAgenciesExist] = useState(false);

  /**
   * @description converts products:(Product | number)[] prop into ProductApiResponse[]
   * @returns ProductApiResponse[]
   */
  const normalizedProducts = useMemo(() => {
    return normalizeFreeAgencies(allProducts, products, pageFor === 'registration');
  }, [allProducts, pageFor, products]);

  const [selectedFreeAgencies, setSelectedFreeAgencies] = useState(normalizedProducts);
  const [freeAgencyName, setSelectedFreeAgencyName] = useState('');
  const [selectedAgencyInfo, setSelectedAgencyInfo] = useState({
    agencyName: '',
    agencyState: '',
    agencyCounty: '',
  });

  const agencyStates = useMemo(() => {
    const states = allProducts.valueMaybe() || [];

    return states
      .filter(item => item.productType === ProductType.State)
      .sort(compareObjectsByKey('productName'));
  }, [allProducts]);

  /**
   * @description sets filtered free agency products state based on set filters
   * @param filters SelectAgencyFilters filters to apply
   * @returns void
   *
   * @example getFilteredAgencies({
   *  state: { value: 12345 },
   *  county: { value: 12345 },
   *  autoSuggestText: '',
   * })
   */
  const filteredFreeAgencies = useMemo(() => {
    return searchAgencies(
      allProducts.valueMaybe() || [],
      selectedState,
      selectedCounty,
      autoSuggestText,
    );
  }, [allProducts, autoSuggestText, selectedCounty, selectedState]);

  const agencyCounties = useMemo(() => {
    if (selectedState) {
      const counties = allProducts.valueMaybe() || [];
      return counties
        .filter(product => product.parentId === selectedState)
        .sort(compareObjectsByKey('productName'));
    }
    return [];
  }, [allProducts, selectedState]);

  /** resets filter state */
  const resetFilter = useCallback(() => {
    setAutoSuggestText('');
    setSelectedState(0);
    setSelectedCounty(0);
  }, []);

  /** resets filter state and clears selections */
  const resetFilterAndClearSelections = useCallback(() => {
    resetFilter();
    setSelectedFreeAgencies([]);
    if (allProducts.state === 'hasValue') {
      setSelectedProducts({ ...selectedProducts, agency: undefined });
    }
  }, [allProducts.state, resetFilter, selectedProducts, setSelectedProducts]);

  /**
   * @description handles a click on a checkbox or radio button to add a free agency to selection
   * @param agencyId (number) the product id of the agency to select
   * @returns void
   *
   * @example selectAgency(1)
   */
  const selectAgency = useCallback(
    (agencyId: number) => {
      const result = selectFreeAgency(
        allProducts,
        selectedFreeAgencies,
        normalizedProducts,
        agencyId,
        isOpsUser,
      );

      if (!result) return;

      const { agency, agencyInfo, newAgenciesExist, selectedAgencies } = result;

      setNewAgenciesExist(newAgenciesExist);
      setSelectedFreeAgencies(selectedAgencies);
      setSelectedFreeAgencyName(agency?.productName);
      setSelectedAgencyInfo(agencyInfo);

      setSelectedProducts({ ...selectedProducts, agency });
    },
    [
      allProducts,
      isOpsUser,
      normalizedProducts,
      selectedFreeAgencies,
      selectedProducts,
      setSelectedProducts,
    ],
  );

  const isOptionSelected = useCallback(
    (freeAgency: ProductApiResponse) => {
      return selectedFreeAgencies.find(fa => freeAgency.productId === fa.productId) ? true : false;
    },
    [selectedFreeAgencies],
  );

  /**
   * @description hits endpoint to update supplier free agency, resets filters, and redirects to another page
   *    only used when pageFor === 'subscription'
   * @returns void
   *
   * @example updateFreeAgencies()
   */
  const updateFreeAgencies = useCallback(async () => {
    await updateSupplierFreeAgency(selectedFreeAgencies, pageFor === 'registration');
    dispatch(getRefreshToken());
    if (isOpsUser) await refreshAccountInfo();

    resetFilter();

    if (toggleAgencySelection) toggleAgencySelection();

    const redirectPath =
      pageFor === 'subscription' ? ProductsPaths.CurrentSubscription : '/suppliers/dashboard';

    history.push(redirectPath);
  }, [
    history,
    isOpsUser,
    pageFor,
    refreshAccountInfo,
    resetFilter,
    selectedFreeAgencies,
    toggleAgencySelection,
    updateSupplierFreeAgency,
    dispatch,
  ]);

  /**
   * @description checks if more or less than one agency is selected & displays a warning modal if so
   * @returns void
   *
   * @example checkAgencyCount()
   */
  const checkAgencyCount = useCallback(async () => {
    if (selectedFreeAgencies.length === 1) {
      await updateFreeAgencies();
    } else {
      setConfirmationPopup(true);
    }
  }, [selectedFreeAgencies, updateFreeAgencies]);

  /**
   * @description logs an event pagination change
   * @param page ({selected: number}) a pagination parameter to handle when page is changed
   * @returns void
   *
   * @example handlePageChange({selected: 3})
   */
  const handlePageChange = useCallback(
    (page: { selected: number }) => {
      if (page) {
        logEvent('registration (supplier) - click agency pagination', {
          Description:
            "User clicks any of the numbers or < >'s in the pagination component to see more agencies",
          Page: page.selected + 1, // Increases index from 0 to 1
        });
      }
    },
    [logEvent],
  );

  const renderFreeAgencies = useCallback(
    (data: ProductApiResponse[]) =>
      data.map((freeAgency: ProductApiResponse) => {
        const isSelected = isOptionSelected(freeAgency);
        return !isOpsUser ? (
          <DSRadio
            data-testid={`agency-selection-select-item-${freeAgency.productId}`}
            checked={isSelected}
            onSelect={() => selectAgency(freeAgency.productId)}
            key={freeAgency.productId}
            name={`freeagency-${freeAgency.productName}`}
            label={freeAgency.productName}
          />
        ) : (
          <DSCheckbox
            checked={isSelected}
            onClick={() => selectAgency(freeAgency.productId)}
            key={freeAgency.productId}
            name={`freeagency-${freeAgency.productName}`}
            label={freeAgency.productName}
          />
        );
      }),
    [isOpsUser, isOptionSelected, selectAgency],
  );

  useEffect(() => {
    if (normalizedProducts.length > 0 && !freeAgencyName) {
      const selectedProduct = normalizedProducts[0];
      setSelectedFreeAgencyName(selectedProduct.productName);
      setSelectedFreeAgencies(normalizedProducts);
    }
  }, [freeAgencyName, normalizedProducts]);

  /** This prevents the case where the address state changes after a county is selected.
   * We avoid stale data by resetting selected county if it does not exist in `agencyCounties`.
   */
  useEffect(() => {
    if (!selectedCounty) return;
    const fullCounty = agencyCounties.find(county => {
      return county.productId === selectedCounty;
    });
    if (!fullCounty) {
      setSelectedCounty(0);
    }
  }, [agencyCounties, selectedCounty]);

  useEffect(() => {
    scrollToTop();
  }, []);

  function toggleConfirmationPopup() {
    setConfirmationPopup(!confirmationPopup);
  }

  return (
    <div data-testid='agency-selection'>
      {pageFor === 'registration' ? (
        <p className='reg-intro no-bottom-margin'>{agencySelectionText.registrationHeader}</p>
      ) : (
        <p className='reg-intro no-bottom-margin'>{agencySelectionText.standardHeader}</p>
      )}
      {selectedFreeAgencies.length > 0 && !isOpsUser && (
        <p className='reg-intro no-top-padding'>
          {agencySelectionText.selectedAgencyLabel} <strong>{freeAgencyName}</strong>
        </p>
      )}

      <DSTextInput
        dataTestId='agency-selection-name-filter'
        onChange={setAutoSuggestText}
        label='Search by Agency Name'
        name='autoSuggestText'
        value={autoSuggestText}
      />
      <DSSelect
        dataTestId='agency-selection-state-filter'
        onSelect={setSelectedState}
        label='State'
        name='state'
        options={agencyStates}
        labelField='productName'
        valueField='productId'
        value={selectedState}
      />
      <DSSelect
        dataTestId='agency-selection-county-filter'
        onSelect={setSelectedCounty}
        label='County'
        name='county'
        options={agencyCounties}
        labelField='productName'
        valueField='productId'
        value={selectedCounty}
      />

      <div className='clear-both text-right w-100 mb-4'>
        <DSLink dataTestId='agency-selection-reset-filter' onClick={resetFilterAndClearSelections}>
          Reset Search
        </DSLink>
      </div>

      <AgencySelectionList>
        <DSPaginate
          data={filteredFreeAgencies}
          data-testid='agency-selection-paginate'
          onPageChange={handlePageChange}
          disableDeepLink
        >
          {renderFreeAgencies}
        </DSPaginate>
        {!!(autoSuggestText || selectedState || selectedCounty) &&
          filteredFreeAgencies.length === 0 && <NoResult message='No Agencies Available' />}
      </AgencySelectionList>
      {pageFor !== 'registration' ? (
        <SpacedButtonWrapper justifyContent='space-between'>
          <DSButton type='secondary' onClick={toggleAgencySelection}>
            {commonLabels.cancel}
          </DSButton>

          <DSButton
            type='primary'
            inactive={!newAgenciesExist || selectedFreeAgencies.length === 0}
            onClick={checkAgencyCount}
          >
            {commonLabels.save}
          </DSButton>
        </SpacedButtonWrapper>
      ) : (
        <SpacedButtonWrapper justifyContent='end'>
          <DSLink
            onClick={() => {
              setSelectedProducts({ ...selectedProducts, agency: undefined });
              setSelectedFreeAgencies([]);
              resetFilter();
              if (moveToNextPage) moveToNextPage();
            }}
          >
            Skip Agency Selection
          </DSLink>
          <DSButton
            type='primary'
            onClick={() => {
              if (moveToNextPage)
                moveToNextPage(
                  selectedFreeAgencies.find(() => true),
                  selectedAgencyInfo,
                );
            }}
            inactive={selectedFreeAgencies.length === 0}
          >
            Next
          </DSButton>
        </SpacedButtonWrapper>
      )}
      <MultipleAgencyConfirmationModal
        isOpen={confirmationPopup}
        onCancel={toggleConfirmationPopup}
        onConfirm={() => {
          toggleConfirmationPopup();
          updateFreeAgencies();
        }}
      />
    </div>
  );
};
