import { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../../store/actions';
import { DemandStarSupportEmail, TollFreeNumber } from 'src/utils/texts/common';
import { AcceptedFileFormatsModal } from '../../common/AcceptedFileFormatsModal';
import { authTypes } from '../../../types/auth';
import { MemberType } from '../../../utils/constants';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface propsTypes {
  history: any;
  auth: authTypes;
  getaccountinforequiredDocs: any;
}

function UserManual(props: propsTypes) {
  const { history, auth, getaccountinforequiredDocs } = props;
  const { mt: memberType } = auth;

  const [fileTypeModalVisible, setFileTypeModalVisible] = useState(false);

  useSetBreadcrumb({
    component: '',
    page: 'usermanual',
    altname: 'Help',
    title: 'Help',
    name: 'Help',
    menuactive: 'help',
  });

  useEffect(() => {
    getaccountinforequiredDocs();
  }, [getaccountinforequiredDocs]);

  const GoToPage = (page: string) => {
    const user = memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers';
    history.push(`/${user}/help/${page}`);
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col helpWrapper'>
            <div className='colWrapper'>
              {memberType === MemberType.AgencyBuyer ? (
                <>
                  <div className='d-none'>
                    <h3 className='arrowWrapper'>Agency Resources</h3>
                    <h4 className='lineHR'>Welcome to the new DemandStar </h4>
                    <div className='innerColIndent'>
                      <p>
                        We are excited to have your government entity as part of the DemandStar
                        network. As part of our ongoing commitment to our procurement partners, we
                        have put together this resource to help you navigate the system and to
                        provide you with an ongoing reference should you need it.
                      </p>
                      <p>
                        If you have feature requests or need help with anything, do not hesitate to
                        contact us. We are available Monday through Friday from 6 a.m. to 6 p.m.
                        Pacific Time. We can be reached at {TollFreeNumber}
                        or via email at {DemandStarSupportEmail}.
                      </p>
                    </div>
                    <h4 className='lineHR'>Navigating DemandStar </h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Setting Up Your Account'
                            onClick={() => GoToPage('setupaccount')}
                            className='staticLink'
                          >
                            Setting Up Your Account
                          </span>
                        </li>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('agencyadduser')}
                            className='staticLink'
                          >
                            Adding Users
                          </span>
                        </li>
                        <li>
                          <span
                            title='Setting-up your Certifications'
                            onClick={() => GoToPage('setupcertification')}
                            className='staticLink'
                          >
                            Setting-up your Certifications
                          </span>
                        </li>
                        <li>
                          <span
                            title='Setting up your Publications'
                            onClick={() => GoToPage('setuppublication')}
                            className='staticLink'
                          >
                            Setting up your Publications
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Creating and Broadcasting a Solicitation </h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('createbroadcastbid')}
                            className='staticLink'
                          >
                            Add a Bid
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Details Page </h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('detailspage')}
                            className='staticLink'
                          >
                            detailspage
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Reminders </h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('reminders')}
                            className='staticLink'
                          >
                            Reminders
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Tabulation Form</h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Tabulation Form'
                            onClick={() => GoToPage('tabulationform')}
                            className='staticLink'
                          >
                            tabulationform
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Making an Award</h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('makingaward')}
                            className='staticLink'
                          >
                            Add a Bid
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>How to Log Out </h4>
                    <span
                      title='How to Log Out'
                      onClick={() => GoToPage('logout')}
                      className='staticLink'
                    >
                      How to Log Out
                    </span>
                    <h4 className='lineHR'>Creating and Broadcasting a Quote</h4>
                    <span
                      title='Creating and Broadcasting a Quote'
                      onClick={() => GoToPage('createbroadcastquote')}
                      className='staticLink'
                    >
                      Quote
                    </span>
                    <h4 className='lineHR'>Broadcasting a Quote</h4>
                    <div className='innerColIndent'>
                      <ul className='list-unstyled'>
                        <li>
                          <span
                            title='Adding Users'
                            onClick={() => GoToPage('broadcastingquote')}
                            className='staticLink'
                          >
                            Viewing Quote Responses
                          </span>
                        </li>
                      </ul>
                    </div>
                    <h4 className='lineHR'>Getting Help</h4>
                    <span
                      title='Tabulation Form'
                      onClick={() => GoToPage('agencygettinghelp')}
                      className='staticLink'
                    >
                      Help
                    </span>
                  </div>
                </>
              ) : null}
              <h3 className='arrowWrapper'>Supplier Resources</h3>
              <h4 data-testid='usermanual.mainpage.welcome'>
                Welcome to DemandStar {new Date().getFullYear()}
              </h4>
              <div className='innerColIndent'>
                <p>
                  {`Welcome to DemandStar! We are pleased to have you as a valued supplier on the DemandStar network. You
									may notice our new look and feel, but don't worry - this is still the same DemandStar you’ve come to
									love. It's the same workflow but with more bells and whistles, and a user interface designed to
									facilitate better communication on bids and deliver better (and more) opportunities directly into your
									hands.`}
                </p>
              </div>
              <h4 className='lineHR' data-testid='usermanual.mainpage.account'>
                Account Management
              </h4>
              <div className='innerColIndent'>
                <p>
                  Very little has changed with your DemandStar account. Your account settings will
                  now appear under your name in the upper right-hand corner once you log-in. Here
                  are some specific instructions on common issues:
                </p>
                <ul className='list-unstyled'>
                  <li>
                    <span
                      title='Adding Users'
                      onClick={() => GoToPage('adduser')}
                      className='staticLink'
                    >
                      Adding Users
                    </span>
                  </li>
                  <li>
                    <span
                      title='Upgrading your Subscription'
                      onClick={() => GoToPage('upgradesubscription')}
                      className='staticLink'
                    >
                      Upgrading your Subscription
                    </span>
                  </li>
                  <li>
                    <span
                      title='Managing Certifications'
                      onClick={() => GoToPage('mapingcertificate')}
                      className='staticLink'
                    >
                      Managing Certifications{' '}
                    </span>
                  </li>
                  <li>
                    <span
                      title='Self Declarations'
                      onClick={() => GoToPage('selfdeclarations')}
                      className='staticLink'
                    >
                      Self Declarations{' '}
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className='lineHR' data-testid='usermanual.mainpage.using'>
                Using DemandStar
              </h4>
              <div className='innerColIndent'>
                <ul className='list-unstyled'>
                  <li>
                    <span
                      title='Adding Commodity Codes to your DemandStar Account'
                      onClick={() => GoToPage('addcommoditycode')}
                      className='staticLink'
                    >
                      Adding Commodity Codes to your DemandStar Account{' '}
                    </span>
                  </li>
                  <li>
                    <span
                      title='Searching for Bids'
                      onClick={() => GoToPage('searchbid')}
                      className='staticLink'
                    >
                      Searching for Bids{' '}
                    </span>
                  </li>
                  <li>
                    <span
                      title='Tips and Tricks'
                      onClick={() => GoToPage('tipstricks')}
                      className='staticLink'
                    >
                      Tips and Tricks{' '}
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className='lineHR' data-testid='usermanual.mainpage.ebid'>
                eBidding
              </h4>
              <div className='innerColIndent'>
                <ul className='list-unstyled'>
                  <li>
                    <span
                      title='Responding to an Electronic Bid'
                      onClick={() => GoToPage('respondingebid')}
                      className='staticLink'
                    >
                      Responding to an Electronic Bid on the DemandStar network.{' '}
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className='lineHR' data-testid='usermanual.mainpage.ebid.filetypes'>
                Supported File Types
              </h4>
              <div className='innerColIndent'>
                <ul className='list-unstyled'>
                  <li>
                    <span
                      title='Responding to an Electronic Bid'
                      onClick={() => setFileTypeModalVisible(true)}
                      className='staticLink'
                    >
                      DemandStar Supported File Format.{' '}
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className='lineHR' data-testid='usermanual.mainpage.getting'>
                Getting Help
              </h4>
              <div className='innerColIndent'>
                <ul className='list-unstyled'>
                  <li>
                    <span
                      title='Still need help? Contact DemandStar'
                      onClick={() => GoToPage('gettinghelp')}
                      className='staticLink'
                    >
                      Still need help? Contact DemandStar{' '}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AcceptedFileFormatsModal
        isOpen={fileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      />
    </>
  );
}

export default connect(
  (state: any) => ({ auth: state.auth, accountinfo: state.accountinfo }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(UserManual));
