import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Prompt } from 'react-router-dom';

import * as actionCreators from '../../../../store/actions';
import BidTypes from './bidtypes';
import EBidDocuments from './ebiddocuments';
import LegalAd from '../templates/legalad';
import PublicationsAccount from './publications';

import {
  AddBid,
  AgencyDocs,
  BidType,
  Broadcastlist,
  CommodityCodes,
  NaviLink,
  Shared,
} from '../../../../types/publications';

import { authTypes } from '../../../../types/auth';
import { Buttons } from '../../../customcontrols';
import { leavingAlertMessage } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { nargsfnTypes } from '../../../../types/functions';

interface accountInfo {
  agencydocs: AgencyDocs;
  ebiddocumentPopupdata: any;
  ebiddocumentPopup: boolean;
  requiredDocuments_internalLoader: boolean;
  ebiddocumentPopupDelete: boolean;
  ebiddocumentreorder: boolean;
  halfWayCancel: boolean;
  halfWayCancelAllow: boolean;
  bidtypesType: string;
  bidtypesDesc: string;
  bidtypesdeletemodal: boolean;
  agencybidtypes: BidType[];
  bidtypesnmodal: boolean;
  publicationmodal: boolean;
  agencypublications: any;
  publicationName: string;
  editpublication: any;
  publicationdeletemodal: boolean;
  autoApprovalStatus: boolean;
  suppDocDownloadStatus: boolean;
  addTemplateModal: boolean;
  selectedTemplateItem: any;
  savedLegalData: any;
  updateType: number;
  memberinfo: {
    qintro: string;
    qins: string;
    qclose: string;
    mi: string;
  };
}

interface propsTypes extends PropsFromRedux {
  addbid: AddBid;
  accountinfo: accountInfo;
  setAccountInfoDetails: any;
  match: any;
  getaccountinforequiredDocs: any;
  submitaccountinforequiredDocsUpdate: any;
  setSharedDetails: nargsfnTypes;
  handleSubmit: any;
  submitting: boolean;
  getaccountinfobidtypes: any;
  submitaccountinfobidtypesremove: any;
  submitaccountinfobidtypesadd: any;
  pristine: boolean;
  valid: boolean;
  auth: authTypes;
  submitaccountinfopublicationsremove: any;
  submitaccountinfopublicationsadd: any;
  toggleDocAutoApproval: any;
  getDocAutoApprovalStatus: any;
  toggleSuppDocDownload: any;
  getSuppDocDownloadStatus: any;
  getaccountinfopublications: any;
  broadcastList: Broadcastlist;
  shared: Shared;
  commoditycodes: CommodityCodes;
  history: any;
}

function PublicationsIndex(props: propsTypes) {
  const { ...otherprops } = props;
  const { accountinfo, setAccountInfoDetails, history } = props;
  const { halfWayCancel, halfWayCancelAllow } = accountinfo;
  const Tab: string = props.match.params.Tab;

  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'publications');
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState('' as any);
  const [isSameWindow, setIsSameWindow] = useState(false);
  const [clickedTab, setclickedTab] = useState('' as any);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      history.push(`/buyers/account/configuration/${tab}`);
      setActiveTab(tab);
      setIsSameWindow(false);
    }
  };

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ halfWayCancel: false, halfWayCancelAllow: false });
    };
  }, [setAccountInfoDetails]);

  const navLinks: NaviLink[] = [
    {
      name: 'Bid Types',
      id: 'bidtypes',
      component: <BidTypes {...props} />,
    },
    {
      name: 'eBidding Documents',
      id: 'ebiddinginfo',
      component: <EBidDocuments {...otherprops} />,
    },
    {
      name: 'Publications',
      id: 'publications',
      component: <PublicationsAccount {...props} />,
    },
    {
      name: 'Legal Ads',
      id: 'legalads',
      component: <LegalAd {...props} />,
    },
  ];

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ halfWayCancel: false });
    };
  }, [setAccountInfoDetails]);
  const handleBlockedNavigation = (nextLocation: any) => {
    if (halfWayCancelAllow || ConfirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (isSameWindow) {
      setConfirmModalVisible(false);
      toggle(clickedTab);
    } else {
      if (nextLocation) {
        history.push(nextLocation.pathname);
      }
    }
  };
  return (
    <>
      <Prompt when={halfWayCancel} message={handleBlockedNavigation} />
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Nav tabs>
              {navLinks.map((item: NaviLink, index: number) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === item.id })}
                    onClick={() => {
                      setIsSameWindow(true);
                      setclickedTab(item.id);
                      if (halfWayCancel && !halfWayCancelAllow) {
                        setConfirmModalVisible(true);
                      } else {
                        toggle(item.id);
                      }
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {navLinks.map((item: NaviLink, index: number) => (
                <TabPane key={index} tabId={item.id}>
                  {activeTab === item.id && item.component}
                </TabPane>
              ))}
            </TabContent>
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <>
          <p>{leavingAlertMessage}</p>
          <Buttons
            classNames='bttn-secondary mt-2'
            text='Stay on this page'
            title='Cancel'
            type='button'
            onClick={() => {
              setConfirmModalVisible(false);
            }}
          />
          <Buttons
            classNames='bttn-primary  mt-2 float-right'
            text='Leave without saving'
            title='OK'
            type='button'
            onClick={discardConfirmationPopup}
          />
        </>
      </ModalPopUp>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    addbid: state.addbid,
    accountinfo: state.accountinfo,
    auth: state.auth,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(PublicationsIndex));
