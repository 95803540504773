import { memo, useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import Publicationcontrol from './publicationcontrol';
import SortingIcon from '../../../common/sortingIcon';

import { AccountInfo, Auth, Shared } from '../../../../types/publications';
import { Buttons, DeprecatedInput, NoResult, Paging } from '../../../customcontrols';
import { pageSize, sortDirections } from '../../../../utils/constants';

import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { nargsfnTypes } from '../../../../types/functions';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  width: '30px',
};

interface propsTypes {
  auth: Auth;
  accountinfo: AccountInfo;
  submitaccountinfopublicationsremove: any;
  submitaccountinfopublicationsadd: any;
  setAccountInfoDetails: any;
  setSharedDetails: nargsfnTypes;
  shared: Shared;
  toggleDocAutoApproval: any;
  getDocAutoApprovalStatus: any;
  toggleSuppDocDownload: any;
  getSuppDocDownloadStatus: any;
  getaccountinfopublications: any;
}

function PublicationsAccount(props: propsTypes) {
  const {
    getaccountinfopublications,
    accountinfo,
    submitaccountinfopublicationsremove,
    submitaccountinfopublicationsadd,
    setAccountInfoDetails,
    setSharedDetails,
    shared,
    // toggleDocAutoApproval,
    getDocAutoApprovalStatus,
    // toggleSuppDocDownload,
    getSuppDocDownloadStatus,
    auth,
  } = props;
  const { mc: isMainContact = false, opi = 0 } = auth || {};
  const {
    agencypublications = [],
    publicationmodal,
    publicationName,
    editpublication,
    publicationdeletemodal,
    // autoApprovalStatus,
    // suppDocDownloadStatus = false,
  } = accountinfo || {};
  const { internalLoader } = shared || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [deletedetails, setDeletedetails] = useState('');

  useEffect(() => {
    setSharedDetails({ internalLoader: true });
    getDocAutoApprovalStatus();
    getaccountinfopublications();
    if (opi > 0) {
      getSuppDocDownloadStatus();
    }
  }, [
    setSharedDetails,
    getaccountinfopublications,
    getDocAutoApprovalStatus,
    auth,
    getSuppDocDownloadStatus,
    opi,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [agencypublications]);

  const AddPublicationFn = useCallback(() => {
    setAccountInfoDetails({
      publicationmodal: !publicationmodal,
      publicationName: '',
      editpublication: '',
    });
  }, [publicationmodal, setAccountInfoDetails]);

  useSetBreadcrumb({
    component: (
      <Publicationcontrol
        pagefor='publications'
        accountinfo={accountinfo}
        AddFn={AddPublicationFn}
      />
    ),
    page: 'Agency Bid Configuration',
    altname: 'Publications',
    title: 'Publications',
    name: 'Publications',
    menuactive: '',
  });

  const EditPublicationFn = (item: any) => {
    setAccountInfoDetails({
      publicationmodal: false,
      publicationName: item && item.publication ? item.publication : '',
      editpublication: item,
    });
  };

  // Logic for displaying pagination
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  //Logic for sort
  const [sortvalue, setSortvalue] = useState('publication');
  const [sortorder, setSortorder] = useState(sortDirections.ASC);
  useEffect(() => {
    return () => {
      setSortvalue('publication');
      setSortorder(sortDirections.ASC);
      setAccountInfoDetails({
        publicationmodal: false,
        publicationName: '',
        editpublication: '',
      });
      setDeletedetails('');
    };
  }, [setAccountInfoDetails]);

  const DeleteModalFn = (item?: any) => {
    setDeletedetails(item && item.publicationId ? item : '');
    setAccountInfoDetails({ publicationdeletemodal: !publicationdeletemodal });
  };

  return (
    <>
      <h3 className='arrowWrapper' data-testid='publicationinfo.bids.publications.head'>
        Publications
      </h3>
      <div className='row'>
        <div className='col-md-12 col-lg-7'>
          <h2 className='lineHR' data-testid='publicationinfo.bids.publications.list.head'>
            Selected Bid Publications ({agencypublications.length})
          </h2>
          <Table className='tableHData' data-testid='publicationinfo.bids.publications.list'>
            <tr data-testid='publicationinfo.bids.publications.table.header'>
              <th>
                Publication Name
                {agencypublications.length > 0 && (
                  <SortingIcon
                    sortlist={agencypublications}
                    initialsortorder={sortorder}
                    currentsortname={sortvalue}
                    changesortname={(value: string) => setSortvalue(value)}
                    sortname='publication'
                    changeSortingFn={(list: any) =>
                      setAccountInfoDetails({ agencypublications: list })
                    }
                  />
                )}
              </th>
              <th style={tdWidth} />
              <th style={tdWidth} />
            </tr>
            {publicationmodal ? (
              <tr>
                <td>
                  <DeprecatedInput
                    label='Name'
                    type='text'
                    name='publicationName'
                    handleChange={(name: string, value: string) =>
                      setAccountInfoDetails({ publicationName: value })
                    }
                    value={publicationName}
                    placeholder='Name'
                  />
                </td>
                <td width='30px'>
                  <Buttons
                    classNames='bttn-primary small mb-1'
                    text='Save'
                    title='Save'
                    type='button'
                    onClick={() => {
                      if (publicationName.length > 0) {
                        submitaccountinfopublicationsadd();
                      }
                    }}
                    disable={!publicationName.trim()}
                  />
                  <Buttons
                    classNames='bttn-secondary small'
                    text='Cancel'
                    title='Cancel'
                    type='button'
                    onClick={AddPublicationFn}
                  />
                </td>
                <td width='30px' />
              </tr>
            ) : null}
            {agencypublications.length > 0 ? (
              <>
                {agencypublications
                  .map((item: any, index: number) => (
                    <tr key={index} data-testid='publicationinfo.bids.publications.table.body'>
                      <td>
                        {editpublication &&
                        editpublication.publicationId &&
                        editpublication.publicationId === item.publicationId ? (
                          <DeprecatedInput
                            label='Name'
                            type='text'
                            name='publicationName'
                            handleChange={(name: string, value: string) =>
                              setAccountInfoDetails({ publicationName: value })
                            }
                            value={publicationName}
                          />
                        ) : (
                          item.publication
                        )}
                      </td>
                      <td>
                        {publicationmodal ? null : (
                          <>
                            {editpublication && editpublication.publicationId ? (
                              <>
                                {editpublication.publicationId === item.publicationId ? (
                                  <Buttons
                                    classNames='bttn-primary small mb-1'
                                    text='Save'
                                    title='Save'
                                    type='button'
                                    onClick={() => {
                                      if (publicationName.length > 0) {
                                        submitaccountinfopublicationsadd();
                                      }
                                    }}
                                  />
                                ) : null}
                              </>
                            ) : (
                              <i
                                onClick={() =>
                                  DeleteModalFn({
                                    publicationId: item.publicationId,
                                    publicationName: item.publication,
                                  })
                                }
                                className='mdi mdi-delete-forever-outline staticLink mdi-24px'
                                title='Remove'
                              />
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {publicationmodal ? null : (
                          <>
                            {editpublication && editpublication.publicationId ? (
                              <>
                                {editpublication.publicationId === item.publicationId ? (
                                  <Buttons
                                    classNames='bttn-secondary small'
                                    text='Cancel'
                                    title='Cancel'
                                    type='button'
                                    onClick={() => EditPublicationFn('')}
                                  />
                                ) : null}
                              </>
                            ) : (
                              <i
                                onClick={() => EditPublicationFn(item)}
                                className='mdi mdi-square-edit-outline staticLink mdi-24px'
                                title='Edit'
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                  .slice(indexOfFirstList, indexOfLastList)}
              </>
            ) : (
              <tr>
                <td colSpan={6}>
                  <NoResult message={internalLoader ? loadingMsg : 'No Publications Available'} />
                </td>
              </tr>
            )}
          </Table>
          <Paging
            totalRecords={agencypublications.length}
            currentPage={currentPage}
            onPagingClick={(value: number) => {
              if (value !== currentPage) setCurrentPage(Number(value));
            }}
            pageLimit={listPerPage}
          />
          {(editpublication && editpublication.publicationId) || publicationmodal ? null : (
            <Buttons
              classNames='bttn-primary my-4 float-right'
              text='Add Publication'
              title='Add Publication'
              type='button'
              onClick={AddPublicationFn}
            />
          )}
        </div>
      </div>
      <ModalPopUp
        title='Delete Confirmation'
        size='md'
        isOpen={publicationdeletemodal}
        closeModal={DeleteModalFn}
      >
        <>
          <p>Are you sure you want to delete?</p>
          <Buttons
            classNames='bttn-secondary mt-2'
            text='Cancel'
            title='Cancel'
            type='button'
            onClick={DeleteModalFn}
          />
          <Buttons
            classNames='bttn-primary  mt-2 float-right'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => {
              if (deletedetails) submitaccountinfopublicationsremove(deletedetails);
            }}
          />
        </>
      </ModalPopUp>
    </>
  );
}

export default memo(PublicationsAccount);
