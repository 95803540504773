import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { DSTextAreaField } from '@demandstar/components/fields';

import { OpsActionsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';

import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from '../../common/modals';
import { SpacedButtonWrapper } from '../agency-selection/styles';
import { useCart } from 'src/components/products/cart/useCart';
import { useRouter } from 'src/shared/hooks';
import { useSubscription } from 'src/shared/hooks/useSubscription';

interface ConfirmComplimentaryOrderProps {
  /** called on `Cancel` to close the modal or go back */
  close: () => void;
}

interface SalesNote {
  salesNote: string;
}

/**
 * @description the content of the Complimentary Order Modal, allows OPS user to
 * enter a sales note to document the reason for the comp order
 * @returns JSX.Element
 *
 * @example <ConfirmComplimentaryOrder />
 */
const ConfirmComplimentaryOrder = ({ close }: ConfirmComplimentaryOrderProps) => {
  const { cartItems, cartTotal, unchangedSubscribedProducts } = useCart();
  const { createSubscription } = useSubscription();
  const { history } = useRouter();

  const [processing, setProcessing] = useState(false);

  const methods = useForm({
    reValidateMode: 'onChange',
  });
  const { handleSubmit } = methods;

  const placeComplimentaryOrder = useCallback(
    async (formData: SalesNote) => {
      setProcessing(true);

      const products = unchangedSubscribedProducts.concat(cartItems);

      const result = await createSubscription({
        isComplimentary: true,
        isUpgrade: true,
        products,
        expectedTotal: cartTotal,
        salesNotes: formData.salesNote,
      });

      if (result.status) {
        close();
        history.push(ProductsPaths.CurrentSubscription);
      }

      setProcessing(false);
    },
    [cartItems, cartTotal, close, createSubscription, history, unchangedSubscribedProducts],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit<SalesNote>(placeComplimentaryOrder)}>
        <DSTextAreaField
          dataTestId='test-complimentary-sales-note'
          label={'Sales Note'}
          message={OpsActionsCopy.ComplimentaryOrderHeader}
          name={'salesNote'}
          required={true}
        />
        <SpacedButtonWrapper justifyContent='space-between'>
          <DSButton type='secondary' onClick={close} inactive={processing}>
            {commonLabels.cancel}
          </DSButton>
          <DSButton type='submit' inactive={processing}>
            {processing ? 'Processing...' : commonLabels.confirm}
          </DSButton>
        </SpacedButtonWrapper>
      </form>
    </FormProvider>
  );
};

/**
 * @description a self-contained OPS-styled button that opens a modal that allows for a complimentary
 * order to be placed for a member
 * @returns JSX.Element
 *
 * @example <ComplimentaryOrder />
 */
export const ComplimentaryOrder = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DSButton type={'ops'} onClick={() => setModalOpen(!modalOpen)}>
        {OpsActionsCopy.PlaceComplimentaryOrder}
      </DSButton>
      <ModalPopUp
        title={OpsActionsCopy.PlaceComplimentaryOrder}
        size='lg'
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        <ConfirmComplimentaryOrder close={() => setModalOpen(false)} />
      </ModalPopUp>
    </>
  );
};
