import * as actionTypes from '../actionTypes';
import { action } from './utils';
import { actionPayloadTypes } from '../../types/actiontypedef';
import { AddNewSupplierPayload } from '../sagas/bidssummary';
import { BidSummaryState } from '../reducers/bidssummary';
import { DocumentDownloadPayload } from '../../types/document';

export interface BidsSummaryPayload {
  bidId: string | number; // is this right? we use both.
  otherapis?: boolean;
  checkfor?: 'ebid';
}

export function bidsSummary(payload: BidsSummaryPayload) {
  return action(actionTypes.LOAD_BID_SUMMARY, payload);
}

export function getBidsWatchStatus(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BID_SUMMARY_WATCH_STATUS, payload);
}

export function getPlanHoldersDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_PLANHOLDERS_DETAILS, payload);
}

export function getPlanholderOtherDetails(payload: actionPayloadTypes) {
  return action(actionTypes.GET_PLANHOLDERS_OTHER_DETAILS, payload);
}

export function bidsStats(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_STATS, payload);
}

export function bidsDocuments(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_DOCUMENTS, payload);
}

export function bidsWatchers(payload: { bidId: number }) {
  return action(actionTypes.LOAD_BID_WATCHERS, payload);
}
export function bidsPlanHolders(payload: { bidId: number }) {
  return action(actionTypes.LOAD_BID_PLANHOLDERS, payload);
}
export function getbidsPostbidViewers(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_POSTBIDVIEWERS, payload);
}
export function bidsBidders(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_BIDDERS, payload);
}

export function bidsSimilarBids(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_SIMILARBIDS, payload);
}
export function bidsBidWatch(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_BIDWATCH, payload);
}
export function bidsLegalAd(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_LEGALAD, payload);
}
export function bidsEbids(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_EBIDS, payload);
}
export function bidsPurchaseInfo(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_PURCHASEINFO, payload);
}
export function bidsDownloadDocument(payload: actionPayloadTypes) {
  return action(actionTypes.DOWNLOAD_DOCUMENT, payload);
}
export function bidsDownloadDocumentRest(payload: actionPayloadTypes) {
  return action(actionTypes.DOWNLOAD_DOCUMENT_RESET, payload);
}

export function bidsResponseDocumentDownload(payload: DocumentDownloadPayload) {
  return action(actionTypes.LOAD_BID_RESPONSE_DOCUMENTS, payload);
}

export function setPostBidSupplierDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_POST_BID_SUPPLIER_DETAILS, payload);
}

type SetBidSummaryDetailsPayload = Partial<BidSummaryState>;

export function setBidSummaryDetails(payload: SetBidSummaryDetailsPayload) {
  return action(actionTypes.SET_BID_SUMMARY_DETAILS, payload);
}

export function setSelectedBidsSummaryDetails(payload: actionPayloadTypes) {
  return action(actionTypes.SET_SELECTED_BID_SUMMARY, payload);
}

export function setSelectedPostbidSupplier(payload: actionPayloadTypes) {
  return action(actionTypes.SET_SELECTED_POST_BID_SUPPLIER_DETAILS, payload);
}

export function loadBidsSimilarSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS, payload);
}

export function loadBidPlanholderDocuments(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_PLANHOLDER_DOCUMENTS, payload);
}

export function addNewSupplier(payload: AddNewSupplierPayload) {
  return action(actionTypes.ADD_NEW_SUPPLIER, payload);
}

export function sendBidSelectedSupplier(payload: actionPayloadTypes) {
  return action(actionTypes.SEND_BID_SELECTED_SUPPLIER, payload);
}

export function removeBidPlanholderSupplier(payload: actionPayloadTypes) {
  return action(actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER, payload);
}

export function changeAddSupplierActive(payload: actionPayloadTypes) {
  return action(actionTypes.CHANGE_ADD_SUPPLIER_ACTIVE, payload);
}

export function getBidsSearchPlanholders(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BIDS_SEARCH_PLANDER, payload);
}

export function resetSearchPlanholder() {
  return action(actionTypes.RESET_BIDS_SEARCH_PLANDER, {});
}

export function loadBidsAuditTrial(payload: actionPayloadTypes) {
  return action(actionTypes.BIDS_AUDIT_TRIAL, payload);
}

export function getBidsBroadcastHistory(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BIDS_BROADCAST_HISTORY, payload);
}

export function loadbidaddplanholderdata(payload: actionPayloadTypes) {
  return action(actionTypes.LOAD_BID_PLANDER_DATA, payload);
}

export function setModelOpen(payload: actionPayloadTypes) {
  return action(actionTypes.SET_MODAL_OPEN, payload);
}

export function setSelfDeclarationDetail(payload: actionPayloadTypes) {
  return action(actionTypes.SET_SUPPLIER_SELF_DECLARATION, payload);
}

export function editBidPlanHolder(payload: actionPayloadTypes) {
  return action(actionTypes.EDIT_BID_PLANHOLDER, payload);
}

export function selectCountryState(payload: actionPayloadTypes) {
  return action(actionTypes.SELECT_COUNTRY_STATE, payload);
}

export function setFilteredAuditTrialList(payload: actionPayloadTypes) {
  return action(actionTypes.SET_FILTERED_AUDIT_TRIAL_LIST, payload);
}

export function submitBidsUpdateReminder(payload: actionPayloadTypes) {
  return action(actionTypes.SUBMIT_BIDS_UPDATE_REMINDER, payload);
}

export function getBidsReminders(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BIDS_BIDS_REMINDERS, payload);
}

export function getWriterInfo(payload: actionPayloadTypes) {
  return action(actionTypes.GET_WRITER_INFO, payload);
}

export function getCanUpgrade(payload: actionPayloadTypes = undefined) {
  return action(actionTypes.SUPPLIER_CAN_UPGRADE, payload);
}

export function getBidView(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BID_VIEW, payload);
}

export function getAwardees(payload: { bidId: number }) {
  return action(actionTypes.GET_BID_AWARDEES, payload);
}

export function getResponseEvents(payload: actionPayloadTypes) {
  return action(actionTypes.GET_RESPONSE_EVENTS, payload);
}

export function resetBidssummary(payload: actionPayloadTypes) {
  return action(actionTypes.RESET_BIDS_SUMMARY, payload);
}

export function addBidSupplierTabulation(payload: actionPayloadTypes) {
  return action(actionTypes.ADD_BID_SUPPLIER_TABULATION, payload);
}

export function exportPlanHoldersDetails(payload: actionPayloadTypes) {
  return action(actionTypes.EXPORT_PLANHOLDERS_DETAILS, payload);
}

export function getBidTabulation(payload: actionPayloadTypes) {
  return action(actionTypes.GET_BID_TABULATION, payload);
}

export function exportBroadcastList(payload: actionPayloadTypes) {
  return action(actionTypes.EXPORT_BROADCAST_LIST, payload);
}

export function exportSupplementalSuppliers(payload: actionPayloadTypes) {
  return action(actionTypes.EXPORT_SUPPLEMENTAL_SUPPLIERS, payload);
}

export function exportLegalAd(payload: actionPayloadTypes) {
  return action(actionTypes.EXPORT_LEGAL_AD, payload);
}

export function downloadAllDocument(payload: actionPayloadTypes) {
  return action(actionTypes.DOWNLOAD_ALL_DOCUMENT, payload);
}

export function updateBidsStatus(payload: actionPayloadTypes) {
  return action(actionTypes.UPDATE_BID_STATUS, payload);
}
