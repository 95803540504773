import { connect, ConnectedProps } from 'react-redux';
import { memo, useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';

import Commoditycodeedit from '../../../../common/breadcrumb/common/commoditycodeedit';
import Commoditylist from './commoditylist';
import CommoditySearch from '../../../../common/commoditycodeedit/commoditysearch';
import Commodityselected from './commodityselected';

import * as actionCreators from '../../../../../store/actions';
import { accountinfoType, anyParamType } from '../../../../../types/paymentdetails';
import { Buttons, DeprecatedInput } from '../../../../customcontrols';
import { commodityItemsParamType, commodityParamType } from '../../../../../types/commoditycodes';
import { IsIEFn, Searchingfn } from '../../../../../utils/helpers';
import { MemberType, relogintokenName } from '../../../../../utils/constants';

import { authTypes } from '../../../../../types/auth';
import { initialState as commodityinitial } from '../../../../../store/reducers/commoditycodes';
import { commodityLeavingAlertMessage } from '../../../../../utils/texts';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { sharedTypes } from '../../../../../types/broadcastlist';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface propsTypes extends PropsFromRedux {
  accountinfo: accountinfoType;
  history: anyParamType;
  auth: authTypes;
  shared: sharedTypes;
  commoditycodes: commodityParamType;
  getCommodityCodes: any;
  setCommodityCodes: any;
  setSelectedCommodityCodes: any;
  updateCommodityCodes: any;
  getaccountInfoCommodity: any;
  getRefreshToken: any;
}

function Commoditycodes(props: propsTypes) {
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const {
    getCommodityCodes,
    setCommodityCodes,
    setSelectedCommodityCodes,
    updateCommodityCodes,
    getRefreshToken,
    history,
    commoditycodes,
    accountinfo,
    auth,
  } = props;
  const { mt: memberType, tk = '', un = '' } = auth;
  const { commoditieslist = [], halfWayCancel, halfWayCancelAllow } = commoditycodes;
  //const [ waitCall, setWaitCall ] = useState(false)
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState('' as any);

  const CheckMemberType = useCallback(() => {
    if (memberType === MemberType.BasicSupplier) history.replace('/');
  }, [history, memberType]);

  useEffect(() => {
    if (sessionStorage.getItem(relogintokenName) && auth && tk && un) {
      sessionStorage.removeItem(relogintokenName);
      getRefreshToken({ type: 'commodityEdit' });
    } else {
      CheckMemberType();
    }
  }, [getRefreshToken, tk, un, auth, CheckMemberType]);

  const GoBackToPage = useCallback(() => {
    history.push('/suppliers/account/commoditycodes');
  }, [history]);

  useEffect(() => {
    let getCommodityCodesAPICALL = true;
    if (commoditieslist.length === 0 && getCommodityCodesAPICALL)
      getCommodityCodes({ pagefor: '' });
    return () => (getCommodityCodesAPICALL = false) as any;
  }, [commoditieslist.length, getCommodityCodes]);

  useEffect(() => {
    return () => {
      setCommodityCodes(commodityinitial);
    };
  }, [setCommodityCodes]);

  useSetBreadcrumb({
    component: (
      <Commoditycodeedit
        commoditycodes={commoditycodes}
        updateCommodityCodes={updateCommodityCodes}
        cancel={GoBackToPage}
      />
    ),
    page: 'Supplier Account Commodity Codes Edit',
    title: 'Commodity Codes edit',
    altname: 'Edit',
    name: 'Edit',
  });

  const searchCommodityCodes = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (searchText.length >= 3) {
      setShowSearch(true);
      const searchresult = Searchingfn(
        commoditieslist,
        ['commodityDescription', 'formattedCode'],
        searchText,
      );
      setCommodityCodes({ searchedCommodityCodes: searchresult });
    }
    if (!searchText || searchText.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const onChnageSearchCommodityCodes = (value: string) => {
    setSearchText(value);
    if (value.length >= 3) {
      setShowSearch(true);
      setCommodityCodes({
        searchedCommodityCodes: Searchingfn(
          commoditieslist,
          ['commodityDescription', 'formattedCode'],
          value,
        ),
      });
    }
    if (!value || value.length === 0) {
      setShowSearch(false);
      setCommodityCodes({ searchedCommodityCodes: [] });
    }
  };

  const SetSelectedCommodity = (value: boolean, items: commodityItemsParamType, type: string) => {
    setSelectedCommodityCodes({ commoditieslist, value, items, type });
    setCommodityCodes({ halfWayCancel: true });
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (halfWayCancelAllow || ConfirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };

  return (
    <>
      <Prompt when={halfWayCancel} message={handleBlockedNavigation} />
      <div className='container'>
        <div className='row commoditylist'>
          <div className='col-lg-8'>
            <div className='colWrapper'>
              <h3 className='arrowWrapper'>Commodity Codes</h3>
              <div className='innerColIndent'>
                {IsIEFn() ? (
                  <form>
                    <DeprecatedInput
                      type='text'
                      placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                      label='Search'
                      value={searchText}
                      handleChange={(name: string, value: string) => setSearchText(value)}
                      optional={'e.g "Administrative" or "001-000-00"'}
                      parentClass='mb-0'
                    />
                    <div className='d-flex justify-content-end mb-3'>
                      <Buttons
                        classNames='bttn-secondary mt-2 small'
                        text='Clear'
                        title='Clear'
                        type='button'
                        onClick={() => {
                          setShowSearch(false);
                          setSearchText('');
                          setCommodityCodes({ searchedCommodityCodes: [] });
                        }}
                      />
                      <Buttons
                        classNames='bttn-primary mt-2 ml-2 small'
                        text='Search'
                        title='Search'
                        type='submit'
                        onClick={searchCommodityCodes}
                      />
                    </div>
                  </form>
                ) : (
                  <DeprecatedInput
                    type='search'
                    placeholder='Search Commodity Codes ( Enter minimum 3 characters to search )'
                    label='Search'
                    value={searchText}
                    handleChange={(name: string, value: string) =>
                      onChnageSearchCommodityCodes(value)
                    }
                    optional={'e.g "Administrative" or "001-000-00"'}
                    parentClass='mb-0'
                  />
                )}
              </div>
              {showSearch ? (
                <CommoditySearch {...props} SetSelectedCommodity={SetSelectedCommodity} />
              ) : (
                <Commoditylist {...props} SetSelectedCommodity={SetSelectedCommodity} />
              )}
            </div>
          </div>

          <div className='col-lg-4'>
            <Commodityselected
              {...props}
              pagefor='supplierCommCode'
              GoBackToPage={GoBackToPage}
              SetSelectedCommodity={SetSelectedCommodity}
            />
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>{commodityLeavingAlertMessage}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Discard changes'
          title='Discard changes'
          type='button'
          onClick={discardConfirmationPopup}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Save and Continue'
          title='Save and Continue'
          type='button'
          onClick={() => {
            updateCommodityCodes({ redirectPath: nextLocation.pathname });
            setConfirmModalVisible(false);
          }}
        />
      </ModalPopUp>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    commoditycodes: state.commoditycodes,
    accountinfo: state.accountinfo,
    auth: state.auth,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Commoditycodes));
