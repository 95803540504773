// TOREFACTOR: Delete this and replace it with basicinfo.tsx (or vice-versa)

import React, { memo } from 'react';
import { Table } from 'reactstrap';
import { deprecatedGetDateTimeZoneConverted, useAssert } from '../../../../utils/helpers';
import { partOfExtNetworkText } from 'src/utils/constants';
import { biddetailsTypes } from '../../../../types/limited';
const tdWidth = {
  width: '240px',
};

interface LimitedBidsInfoProps {
  limited: {
    bidsdetails?: biddetailsTypes;
    //TODO: Should this be optional?
  };
}

const LimitedBidsInfo = (props: LimitedBidsInfoProps) => {
  const { limited } = props;
  const { bidsdetails } = limited;
  const {
    agencyName,
    bidWriter,
    bidIdentifier,
    bidTypeDescription,
    broadcastDate,
    tzfn,
    tzn,
    fiscalYear,
    dueDate,
    bidStatusText,
    isBonfireBid,
    bonfireAgencyURL,
    contactInfoVisibility,
    contactInfo,
    contactName,
  } = bidsdetails || {};
  useAssert(!!tzfn, 'Timezone should be defined.', 'bids/details/info.tsx');
  const bonfireAgencyUrl = `https://${bonfireAgencyURL}/portal?tab=login`;

  return (
    <>
      <Table borderless className='tableData'>
        <tbody>
          <tr>
            <th style={tdWidth}>Agency Name</th>
            <td>
              {agencyName}
              <br />
              <div className='text-purple200'>
                {isBonfireBid === true ? <>( {partOfExtNetworkText} )</> : null}
              </div>
            </td>
          </tr>
          {!isBonfireBid || contactInfoVisibility === 1 ? (
            <tr>
              <th style={tdWidth}>Bid Writer</th>
              <td>{!isBonfireBid ? bidWriter : contactName}</td>
            </tr>
          ) : null}

          {isBonfireBid ? (
            <tr>
              <th style={tdWidth}>Contact Email</th>
              <td>{contactInfo}</td>
            </tr>
          ) : null}

          <tr>
            <th>Bid ID</th>
            <td>{bidIdentifier}</td>
          </tr>
          <tr>
            <th>Bid Type</th>
            <td>{bidTypeDescription}</td>
          </tr>
          {broadcastDate ? (
            <tr>
              <th>Broadcast Date</th>
              <td className='text-red200'>
                {deprecatedGetDateTimeZoneConverted(broadcastDate, tzfn)} {tzn}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Fiscal Year</th>
            <td>{fiscalYear}</td>
          </tr>
          {dueDate ? (
            <tr>
              <th>Due Date</th>
              <td className='text-red200'>
                {deprecatedGetDateTimeZoneConverted(dueDate, tzfn)} {tzn}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Bid Status Text</th>
            <td>{bidStatusText}</td>
          </tr>
          <tr>
            <th>
              Additional Registration
              <br />
              <div className='text-danger'>(NEW)</div>
            </th>
            <td>
              {isBonfireBid === false ? (
                <>NONE</>
              ) : (
                <span
                  className='staticLink'
                  onClick={() => window.open(bonfireAgencyUrl, '_blank')}
                >
                  {bonfireAgencyUrl}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default memo(LimitedBidsInfo);
