import React, { memo, useEffect, useState } from 'react';
import { ModalPopUp } from '../../modals/ModalPopUp';
import { Buttons } from '../../../customcontrols';
import {
  editQuoteStatus,
  updateQuoteDuedatePermission,
  updateQuoteStatusPermission,
} from '../../../../utils/constants';
import history from '../../../../utils/history';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../store/actions';
import { useDSSelector } from '../../../../store/reducers';
import { QuoteSummaryType } from '../../../../types/quotedetailstypedef';
import { memberinfoParamType } from '../../../../types/bids';
import { addquoteParamType } from '../../../../types/addquote';
import { MembershipLevel, Permission } from '../../../../types/auth';

interface propsTypes {
  quoteSummary: QuoteSummaryType;
  memberinfo: memberinfoParamType;
  submitAddQuoteComplete: any;
  getAddQuoteCanComplete: any;
  addquotes: addquoteParamType;
  setQuoteSummaryDetails: any;
  QuoteSummaryDeleteQuote: any;
}

function Quotesdetailscontrol(props: propsTypes) {
  const [deletemodal, setDeletemodal] = useState(false);
  const {
    quoteSummary,
    memberinfo,
    submitAddQuoteComplete,
    getAddQuoteCanComplete,
    addquotes,
    setQuoteSummaryDetails,
    QuoteSummaryDeleteQuote,
  } = props;
  const prms = useDSSelector(state => state.auth.prms);
  const ml = useDSSelector(state => state.auth.ml);
  const { summary } = quoteSummary;
  const { statusType = '', memberId, quoteId = '' } = summary;
  const { canFinishQuote = false } = addquotes;
  const { mi = '' } = memberinfo;
  const DeleteModalFn = () => {
    setDeletemodal(!deletemodal);
  };

  const mlcheck = ml ? (ml.replace(/ /g, '').split(',') as MembershipLevel[]) : [];
  const prmscheck = prms ? (prms.replace(/ /g, '').split(',') as Permission[]) : [];

  useEffect(() => {
    if (quoteId && editQuoteStatus.includes(statusType)) getAddQuoteCanComplete({ quoteId });
  }, [quoteId, getAddQuoteCanComplete, statusType]);

  const ConfirmDeleteComponent = (props: any) => {
    return (
      <>
        <p>Are you sure you want to delete?</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Cancel'
          title='Cancel'
          type='button'
          onClick={DeleteModalFn}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Ok'
          title='Ok'
          type='button'
          onClick={deleteQuote}
        />
      </>
    );
  };

  const deleteQuote = () => {
    DeleteModalFn();
    QuoteSummaryDeleteQuote({ quoteId });
  };

  const ActionFn = (event: any, typefor: string) => {
    event.preventDefault();
    if (typefor === 'edit') history.push(`/buyers/quotes/${quoteId}/edit`);
    if (typefor === 'delete') DeleteModalFn();
    if (typefor === 'updatestatus')
      setQuoteSummaryDetails({ statusModal: true, modalType: 'status' });
    if (typefor === 'updateduedate')
      setQuoteSummaryDetails({ statusModal: true, modalType: 'duedate' });
    if (typefor === 'post') submitAddQuoteComplete({ quoteId, pagefor: 'summary' });
  };

  return (
    <>
      {mi === memberId &&
      mlcheck.includes(MembershipLevel.QuotePoster) &&
      prmscheck.includes(Permission.CreateQuotes) ? (
        <>
          {statusType === 'IN' ? (
            <li title='Delete'>
              <span onClick={event => ActionFn(event, 'delete')} className='staticLink'>
                <i className='mdi mdi-delete-forever-outline mr-1' /> Delete
              </span>
            </li>
          ) : null}
          {editQuoteStatus.includes(statusType) &&
          mlcheck.includes(MembershipLevel.QuotePoster) &&
          prmscheck.includes(Permission.CreateQuotes) ? (
            <>
              <li title='Edit'>
                <span onClick={event => ActionFn(event, 'edit')} className='staticLink'>
                  <i className='mdi mdi-square-edit-outline mr-1' /> Edit
                </span>
              </li>
            </>
          ) : null}
          {updateQuoteStatusPermission.includes(statusType) &&
          mlcheck.includes(MembershipLevel.QuotePoster) &&
          prmscheck.includes(Permission.CreateQuotes) ? (
            <li title='Update Status'>
              <span onClick={event => ActionFn(event, 'updatestatus')} className='staticLink'>
                <i className='mdi mdi-square-edit-outline mr-1' /> Update Status
              </span>
            </li>
          ) : null}
          {updateQuoteDuedatePermission.includes(statusType) &&
          mlcheck.includes(MembershipLevel.QuotePoster) &&
          prmscheck.includes(Permission.CreateQuotes || Permission.AwardQuotes) ? (
            <li title='Update Due Date'>
              <span onClick={event => ActionFn(event, 'updateduedate')} className='staticLink'>
                <i className='mdi mdi-square-edit-outline mr-1' /> Update Due Date
              </span>
            </li>
          ) : null}
          {editQuoteStatus.includes(statusType) ? (
            <>
              {canFinishQuote ? (
                <li title='Post Quote'>
                  <span onClick={event => ActionFn(event, 'post')} className='staticLink'>
                    <i className='mdi mdi-square-edit-outline mr-1' /> Post Quote
                  </span>
                </li>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <ModalPopUp
        title='Delete Confirmation'
        closeModal={DeleteModalFn}
        isOpen={deletemodal}
        size='md'
      >
        <ConfirmDeleteComponent {...props} />
      </ModalPopUp>
    </>
  );
}

export default connect(
  (state: any) => ({
    quoteSummary: state.quoteSummary,
    memberinfo: state.memberinfo,
    addquotes: state.addquotes,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(Quotesdetailscontrol));
