import * as actionTypes from '../actionTypes';

import { bidstatParamType } from '../../types/commoditycodes';
import { BidStatusPartial } from '../../utils/helpers';
import { GeoState } from '../../types/sharedtypedef';
import { payloadTypes } from '../../types/actiontypedef';
import { themetokenName } from '../../utils/constants';

export interface SharedState {
  timeZoneList?: Array<{ timeZone: number }>;
  // timezones are not numbers. If this is referring to offset, we should call it offset.
  agencies: any[];
  bidStatusList: BidStatusPartial[];
  quoteStatus: any[];
  commodities: any[];
  location: any[];
  showBidsList: any[];
  siblingProducts: any[];
  relatedBids: any[];
  stateslist: GeoState[];
  countieslist: any[];
  countrieslist: any;
  scrapers: any[];
  selfdeclaredattributes: any[];
  dashboardData: { bids: any[]; quotes: any[] };
  fiscalYear: any[];
  responseDisplays: any[];
  dashboardnetworkData: {
    suppliers: number;
    nearBySuppliers: number;
    governments: number;
    solicitations: number;
    nearByGovernments: number;
    totalBids: number;
    mr: number;
    marketRevenue: number;
  };
  statistics: {
    bids: [
      {
        title: string | 'Active';
        categoryType: 'AC';
        count: number;
      },
      {
        title: string | 'Upcoming';
        categoryType: string | 'UP';
        count: number;
      },
      {
        title: string | 'Under Evaluation';
        categoryType: string | 'OP';
        count: number;
      },
      {
        title: string | 'Awarded';
        categoryType: string | 'AW';
        count: number;
      },
      {
        title: string | 'Completed';
        categoryType: string | 'CP';
        count: number;
      },
      {
        title: string | 'Cancelled';
        categoryType: string | 'CN';
        count: number;
      },
      {
        title: string | 'My Bids';
        categoryType: string | 'MB';
        count: number;
      },
    ];
    quotes: [
      {
        title: string | 'Open';
        categoryType: string | 'OP';
        count: number;
      },
      {
        title: string | 'Incomplete';
        categoryType: string | 'IN';
        count: number;
      },
      {
        title: string | 'Awarded';
        categoryType: string | 'AW';
        count: number;
      },
      {
        title: string | 'Closed';
        categoryType: string | 'CL';
        count: number;
      },
      {
        title: string | 'Cancelled';
        categoryType: string | 'CA';
        count: number;
      },
      {
        title: string | 'My Quotes';
        categoryType: string | 'MQ';
        count: number;
      },
    ];
  };
  dashboardnotifications: any[];
  dashboardnotifications_internalLoader: boolean;
  dashboardCommodities_internalLoader: boolean;
  restartcommoditycodes: boolean;
  currentDSTheme: string | null;
  loadthemes: boolean;
  othermemberinfo: string | '';
  internalLoader: boolean;
  watchedBidIds: string | '';
  watchedBidIdsCall: boolean;
  limitedbidsdetails: {
    Commodities: [];
    Documents: [];
    LegalAd: Record<string, unknown>;
    Result: Record<string, unknown>;
  };
  userSelectedIndustries: string | '';
  showBids: any | any[] | undefined | never[] | never;
  dashboardCommodities: any[];
  userCommoditiesCount: string | '';
  selectedIndustries: any | any[] | undefined | never[] | never;
  trackevents: any;
  pathfromdashboard: boolean;
  ebidCampaignModal: boolean;
  postbid_internalLoader: boolean;
  tabulation_internalLoader: boolean;
  dashboardBids: bidstatParamType[];
  dashboardQuotes: bidstatParamType[];
  invaliddateerror: string;
  getTopCommoditiesAPICALL: boolean;
  tourStart: boolean;
  sharedFormInitialValues: any;
  formPage: string;
  isSuspenseLoading?: boolean;
  sibilingParentCounty: string;
  initialStateValue: string | number;
  PlanholderDetail_internalLoader: boolean;
  productPrices?: {
    countyPrice: number;
    statePrice: number;
    stateMaxPrice: number;
    nationalPrice: number;
  };
}

export const countrieslist = [
  {
    title: 'United States of America',
    label: 'United States of America',
    value: 1,
    key: 'usa',
  },
  {
    title: 'Canada',
    label: 'Canada',
    value: 5,
    key: 'canada',
  },
];

export const initialSharedState: SharedState = {
  agencies: [],
  bidStatusList: [],
  quoteStatus: [],
  commodities: [],
  location: [],
  showBidsList: [],
  stateslist: [],
  countieslist: [],
  countrieslist,
  siblingProducts: [],
  relatedBids: [],
  scrapers: [],
  selfdeclaredattributes: [],
  dashboardData: { bids: [], quotes: [] },
  fiscalYear: [],
  responseDisplays: [],
  dashboardnetworkData: {
    suppliers: 0,
    nearBySuppliers: 0,
    governments: 0,
    solicitations: 0,
    nearByGovernments: 0,
    totalBids: 0,
    mr: 0,
    marketRevenue: 0,
  },
  statistics: {
    bids: [
      {
        title: 'Active',
        categoryType: 'AC',
        count: 0,
      },
      {
        title: 'Upcoming',
        categoryType: 'UP',
        count: 0,
      },
      {
        title: 'Under Evaluation',
        categoryType: 'OP',
        count: 0,
      },
      {
        title: 'Awarded',
        categoryType: 'AW',
        count: 0,
      },
      {
        title: 'Completed',
        categoryType: 'CP',
        count: 0,
      },
      {
        title: 'Cancelled',
        categoryType: 'CN',
        count: 0,
      },
      {
        title: 'My Bids',
        categoryType: 'MB',
        count: 0,
      },
    ],
    quotes: [
      {
        title: 'Open',
        categoryType: 'OP',
        count: 0,
      },
      {
        title: 'Incomplete',
        categoryType: 'IN',
        count: 0,
      },
      {
        title: 'Awarded',
        categoryType: 'AW',
        count: 0,
      },
      {
        title: 'Closed',
        categoryType: 'CL',
        count: 0,
      },
      {
        title: 'Cancelled',
        categoryType: 'CA',
        count: 0,
      },
      {
        title: 'My Quotes',
        categoryType: 'MQ',
        count: 0,
      },
    ],
  },
  dashboardnotifications: [],
  dashboardnotifications_internalLoader: true,
  dashboardCommodities_internalLoader: true,
  restartcommoditycodes: true,
  currentDSTheme: localStorage.getItem(themetokenName)
    ? localStorage.getItem(themetokenName)
    : 'light_DS', // dark_DS, high_cont_DS,
  loadthemes: true,
  othermemberinfo: '',
  internalLoader: false,
  watchedBidIds: '',
  watchedBidIdsCall: false,
  limitedbidsdetails: { Commodities: [], Documents: [], LegalAd: {}, Result: {} },
  userSelectedIndustries: '',
  showBids: [],
  selectedIndustries: [],
  dashboardCommodities: [],
  userCommoditiesCount: '',
  trackevents: '',
  pathfromdashboard: false,
  ebidCampaignModal: false,
  invaliddateerror: '',
  postbid_internalLoader: false,
  tabulation_internalLoader: false,
  dashboardBids: [],
  dashboardQuotes: [],
  getTopCommoditiesAPICALL: true,
  tourStart: false,
  sharedFormInitialValues: {},
  formPage: '',
  sibilingParentCounty: '',
  initialStateValue: '',
  PlanholderDetail_internalLoader: false,
};

export const reducer = (state = initialSharedState, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.GET_LOCATION_LIST.SUCCESS:
      state = { ...state, location: action.payload };
      break;
    case actionTypes.BID_LIST_CLEAR_FILTER.ACTION:
      state = { ...state, showBids: [...action.payload.showBids] };
      break;
    case actionTypes.BID_LIST_FILTER_CHANGE.ACTION:
      state = { ...state, showBids: action.payload.showBids };
      break;
    case actionTypes.GET_STATES_LIST.SUCCESS:
      state = { ...state, stateslist: action.payload };
      break;
    case actionTypes.GET_COMMODITIES_LISTS.SUCCESS:
      state = {
        ...state,
        commodities: action.payload.commodities,
        userSelectedIndustries: action.payload.userSelectedIndustries,
        selectedIndustries: [...action.payload.userSelectedIndustries],
      };
      break;
    case actionTypes.GET_SHOW_BIDS.SUCCESS:
      state = { ...state /* , showBidsList: action.payload */ };
      break;
    case actionTypes.GET_AGENCIES_LIST.SUCCESS:
      state = { ...state, agencies: action.payload };
      break;
    case actionTypes.GET_SHARED_BID_STATUS.SUCCESS:
      state = { ...state, bidStatusList: action.payload };
      break;
    case actionTypes.GET_COUNTIES_LIST.SUCCESS:
      state = { ...state, countieslist: action.payload };
      break;
    case actionTypes.SET_SHARED_DETAILS.ACTION:
      state = { ...state, ...action.payload };
      break;
    case actionTypes.GET_SCRAPERS.SUCCESS:
      state = { ...state, scrapers: action.payload };
      break;
    case actionTypes.GET_SELF_DECLARATION.SUCCESS:
      state = { ...state, selfdeclaredattributes: action.payload };
      break;
    case actionTypes.GET_SIBLING_PRODUCTS.SUCCESS:
      state = {
        ...state,
        siblingProducts: action.payload.siblingProducts,
      };
      break;
    case actionTypes.GET_RELATED_BIDS.SUCCESS:
      state = {
        ...state,
        relatedBids: action.payload.relatedBids,
      };
      break;
    case actionTypes.GET_DASHBOARD_DATA.SUCCESS:
      state = {
        ...state,
        dashboardData: action.payload.dashboardData,
        statistics: action.payload.statistics,
      };
      break;
    case actionTypes.GET_FISCAL_YEAR.SUCCESS:
      state = { ...state, fiscalYear: action.payload };
      break;
    case actionTypes.GET_RESPONSE_DISPLAYS.SUCCESS:
      state = { ...state, responseDisplays: action.payload };
      break;
    case actionTypes.GET_DASHBOARD_NETWORK.SUCCESS:
      state = { ...state, dashboardnetworkData: action.payload };
      break;
    case actionTypes.GET_DASHBOARD_NOTIFICATIONS.SUCCESS:
      state = {
        ...state,
        dashboardnotifications: action.payload,
        dashboardnotifications_internalLoader: false,
      };
      break;
    case actionTypes.GET_DASHBOARD_NOTIFICATIONS.FAILURE:
      state = { ...state, dashboardnotifications_internalLoader: false };
      break;
    case actionTypes.GET_TOP_COMMODITIES.SUCCESS:
      state = {
        ...state,
        dashboardCommodities: action.payload.dashboardCommodities,
        userCommoditiesCount: action.payload.userCommoditiesCount,
        dashboardCommodities_internalLoader: false,
      };
      break;
    case actionTypes.GET_TOP_COMMODITIES.FAILURE:
      state = { ...state, dashboardCommodities_internalLoader: false };
      break;
    case actionTypes.GET_THEMES.SUCCESS:
      state = { ...state, currentDSTheme: action.payload };
      break;
    case actionTypes.GET_OTHER_MEMBER_INFO.SUCCESS:
      state = { ...state, othermemberinfo: action.payload };
      break;
    case actionTypes.SUBMIT_TRACK_EVENTS.SUCCESS:
      state = { ...state, trackevents: action.payload };
      break;
    case actionTypes.RESET_SHARED.TRIGGER:
      state = initialSharedState;
      break;
    default:
      break;
  }
  return state;
};
