import { atom, selector } from 'recoil';

import { getbidresponderinfo, getResponseEventsById } from '../services';
import { getEBidResponseDetails, getEBidResponseList } from '../services/ebids';
import {
  GetEBidResponseDetailsParameters,
  GetEBidResponseListParameters,
  GetResponseEventsParameters,
  ResponseApiResponse,
} from '../../types/ebids';
import { selectedMemberTypeState, supplierMemberIdState } from './memberState';
import { defaultNumericId } from '@demandstar/components/constants';
import { EBidResponse } from '../../types/suppliers';
import { EBidResponseFull } from '../../types/biddetail';
import { selectedBidIdState } from './bidState';
import { track } from '../../utils/telemetry';
import { DeprecatedFullTimeZone } from 'src/utils/constants';

export const SELECTED_RESPONSE_ID_DEFAULT = -1;

/**
 * Make a service API call to populate a Recoil Selector.
 */

export const bidResponseListSelector = selector<EBidResponse[]>({
  key: 'bidResponseListSelector',
  get: async ({ get }) => {
    const selectedMemberId = get(supplierMemberIdState);
    const selectedMemberType = get(selectedMemberTypeState);
    if (selectedMemberId !== -1) {
      let selectedBidId = 0;
      if (selectedMemberType === 'AB') {
        selectedBidId = get(selectedBidIdState);
      }

      const requestParams: GetEBidResponseListParameters = {
        memberId: selectedMemberId,
        bidId: selectedBidId,
      };
      try {
        // API call
        const response = await getEBidResponseList(requestParams);
        return response;
      } catch (error: any) {
        track(
          `eBidResponseListSelector -> getEBidResponseList(memberId: ${selectedMemberId}) ERROR:`,
          {
            error,
            method: 'GET',
            errorMessage: error.message,
          },
        );
        console.error(`eBidResponseListSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
        throw new Error(error.message);
      }
    } else {
      return [];
    }
  },
});

/** @deprecated try not to use this, but it's helpful to have for the sake of accuracy. */
export type ResponseDetails = ResponseApiResponse & {
  /** address 1 */
  a1: string;
  /** address 2 */
  a2: string;
  /** country // ? why is country 'ca'? */
  ca: string;
  /** state */
  st: string;
  /** city */
  ct: string;
  /** phone */
  p: string;
  /** postal code */
  pc: string;
  /** time zone full name */
  tzfn: DeprecatedFullTimeZone;
};

/**
 * Make a service API call to populate a Recoil Selector.
 * TODO: Implement dynamic updates via WebHooks or explicit component re-instantiation.
 */
export const bidResponseDetailsSelector = selector<ResponseDetails | null>({
  key: 'bidResponseDetailsSelector',
  get: async ({ get }) => {
    const selectedBidId = get(selectedBidIdState);

    const requestParams: GetEBidResponseDetailsParameters = { bidId: selectedBidId };
    try {
      // Skip the service call for the default bidId value (-1)
      const response = selectedBidId > 0 ? await getEBidResponseDetails(requestParams) : null;
      // ! FIXME: `responderInfo` is any.
      const responderInfo =
        response?.responderAddressId && response?.supplierId
          ? await getbidresponderinfo({
              bidResponderAddressId: response?.responderAddressId || 0,
              supplierId: response?.supplierId,
            })
          : null;
      return {
        ...response,
        ...responderInfo?.data.result,
        a1: responderInfo?.data.result.address1,
        a2: responderInfo?.data.result.address2,
        ca: responderInfo?.data.result.country,
        st: responderInfo?.data.result.state,
        ct: responderInfo?.data.result.city,
        p: responderInfo?.data.result.phone,
        pc: responderInfo?.data.result.postalCode,
      };
      // ! FIXME: any
    } catch (error: any) {
      track(`bidResponseDetailsSelector -> getEBidResponseDetails(${selectedBidId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      console.error(`eBidResponseDetailsSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      throw new Error(error.message);
    }
    // }
  },
});

/** Initialize the eBidResponseDetails state with the associated API call. */
export const bidResponseDetailsState = atom<ResponseDetails | null>({
  key: 'responseDetailsState',
  default: bidResponseDetailsSelector,
});

// Response Events

/**
 * Set up an ID to be set as a selector dependency
 * to kick off additional selector service calls
 * when changed by user interaction.
 */
export const selectedResponseIdState = atom<number>({
  key: 'selectedResponseIdState',
  default: SELECTED_RESPONSE_ID_DEFAULT,
});

/**
 * Make a service API call to populate a Recoil Selector.
 */
//TODO - should this type be responseEventsParamType?
export const responseEventListSelector = selector<EBidResponseFull>({
  key: 'responseEventListSelector',
  get: async ({ get }) => {
    const selectedResponseId = get(selectedResponseIdState);
    // Bail if the id is defaultNumericId
    if (selectedResponseId === defaultNumericId) {
      return {} as EBidResponseFull;
    }
    const requestParams: GetResponseEventsParameters = { responseId: selectedResponseId };
    try {
      const response =
        // selectedMemberId !== SELECTED_MEMBER_ID_DEFAULT ? await getEBidResponseList(requestParams) : null;
        await getResponseEventsById(requestParams);
      return response || [];
    } catch (error: any) {
      track(`eBidResponseListSelector -> getEBidResponseList(${selectedResponseId}) ERROR:`, {
        error,
        method: 'GET',
        errorMessage: error.message,
      });
      console.error(`eBidResponseListSelector -> getEBidResponseList() ERROR: \n${error}`); // TOREFACTOR - standardize error handling and reporting, failing silently, etc.
      // return [];
      throw new Error(error.message);
    }
  },
});

// ! FIXME: any
export const responseEventListState = atom<any>({
  key: 'responseEventListState',
  default: responseEventListSelector,
});
