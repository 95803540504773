import { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { RangeValue } from 'rc-picker/lib/interface';

import { DataTestId, Inactive } from '../../types';
import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import { AntRangePicker } from '../date-picker/AntDatePickerConfig';
import { BaseDateRangePickerContainer } from '../date-picker/DSDatePicker.styles';

export type DSDateRangeProps = FieldProps &
  DataTestId &
  Pick<
    RangePickerProps<Dayjs>,
    'allowClear' | 'autoFocus' | 'disabledDate' | 'value' | 'onChange'
  > &
  Inactive;

/** simple DatePicker - not for use with React-Hook-Form
 * @example
  <DSDateRange
    label={requiredDocLabels.updateAllDueDates}
    disabledDate={isTodayOrEarlier}
    onChange={changeAllDueDates}
    name={'update-all-dates'}
    value={defaultDate}
  />
 */
export const DSDateRange = (props: DSDateRangeProps) => {
  const {
    allowClear,
    autoFocus,
    dataTestId,
    disabledDate,
    inactive,
    label,
    name,
    onChange,
    value,
  } = props;
  const [format, setFormat] = useState('LL');

  const changeFormat = useCallback((open: boolean) => {
    setFormat(open ? 'l' : 'LL');
  }, []);

  function handleChange(date: RangeValue<Dayjs>, dateStrings: [string, string]) {
    if (onChange) {
      onChange(date, dateStrings);
    }
  }

  return (
    <FieldContainer {...getFieldContainerProps(props)}>
      <BaseDateRangePickerContainer inactive={inactive}>
        <AntRangePicker
          allowClear={allowClear}
          aria-disabled={inactive}
          aria-label={label || name}
          autoFocus={autoFocus}
          data-testid={dataTestId || name}
          disabled={inactive}
          disabledDate={disabledDate}
          format={format}
          id={name}
          inputReadOnly={inactive}
          name={name}
          onChange={handleChange}
          onOpenChange={changeFormat}
          value={value}
        />
      </BaseDateRangePickerContainer>
    </FieldContainer>
  );
};
