import { atom, selector } from 'recoil';

import { defaultTreeNode, DSTreeNode } from '@demandstar/components/tree';

import { getHistoricalBids, getSEOBaseCodes } from './BrowseBids.services';
import {
  HistoricalBidAPIRequest,
  HistoricalBidAPIResponse,
  SEOBaseCodeAPIResponse,
} from './BrowseBids.d';

import { tryCatchLog } from 'src/utils/errors';

export const selectedBrowseBidSEOGroupState = atom<DSTreeNode>({
  key: 'selectedBrowseBidSEOGroupState',
  default: defaultTreeNode,
});
export const selectedBrowseBidSEOCategoryState = atom<DSTreeNode>({
  key: 'selectedBrowseBidSEOCategoryState',
  default: defaultTreeNode,
});
export const selectedBrowseBidStateState = atom<DSTreeNode>({
  key: 'selectedBrowseBidStateState',
  default: defaultTreeNode,
});
export const selectedBrowseBidMetroState = atom<DSTreeNode>({
  key: 'selectedBrowseBidMetroState',
  default: defaultTreeNode,
});

export const urlSelectedSEOIDsState = atom<Record<string, string>>({
  key: 'urlSelectedSEOIDsState',
  default: {},
});

// Payload object for historical bids requests
export const browseBidsAPIRequestState = atom<HistoricalBidAPIRequest>({
  key: 'browseBidsAPIRequestState',
  default: {} as HistoricalBidAPIRequest,
});

// Makes an initial API call for base geo/cc codes
export const seoBaseCodesQueryState = selector<SEOBaseCodeAPIResponse>({
  key: 'seoBaseCodesQueryState',
  get: async () => {
    return tryCatchLog(async () => {
      const response = await getSEOBaseCodes();
      return response || {};
    }, 'seoBaseCodesQueryState');
  },
});

export const seoBaseCodesState = atom<SEOBaseCodeAPIResponse>({
  key: 'seoBaseCodesState',
  default: seoBaseCodesQueryState,
});

/** Map active geo codes and sub regions (having awarded bid data) to a hash with seoId as key.
 * This is used in the clickable map component `MapUSFull.tsx`.
 */
export const geoCodesMapState = atom<Record<string, DSTreeNode>>({
  key: 'geoCodesMapState',
  default: {},
});

export const historicalBidsQuery = selector<HistoricalBidAPIResponse[]>({
  key: 'historicBidsQuery',
  get: async ({ get }) => {
    const payload = get(browseBidsAPIRequestState); // Add HistoricalBidAPIRequest dependency.
    return tryCatchLog(async () => {
      // API call
      const response = await getHistoricalBids(payload);
      return response || [];
    }, 'historicalBidsQuery');
  },
});

export const historicBidsListState = atom<HistoricalBidAPIResponse[]>({
  key: 'historicBidsListState',
  default: historicalBidsQuery,
});

export const seoPageTitleFullState = atom<string>({
  key: 'seoPageTitleFullState',
  default: '',
});

export const seoSearchUrlState = atom<string>({
  key: 'seoSearchUrlState',
  default: '',
});
