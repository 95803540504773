/**
 * Assume 16px base font size.
 * @param {number} px - numeric px value to be converted
 * @param {number} basePx - denominator for rem calculation
 *                        - default to 16 (this should be the `font-size` set at the base style level of the document)
 * @returns {string} calculated value with 'rem' suffix
 * @example pxToRem(32); // '2rem';
 * @example pxToRem(28,14); // '2rem';
 */
export function pxToRem(px: number, basePx = 16): string {
  return px / basePx + 'rem';
}

/**
 * Assume 16px base font size.
 * @param rem
 */
export function remToPx(rem: number, basePx = 16): number {
  return basePx * rem;
}

/** Converts a string price into an object with dollar and cent properties.
 * @param {string} value - a string value for
 * @returns {dollars: string; cents: string}
 * @example convertPriceToDollarsAndCents("123.45") // {dollars: "123", cents: "45"}
 * @example convertPriceToDollarsAndCents("$123,45.678a") // {dollars: "12345", cents: "67"}
 * */
export function convertPriceToDollarsAndCents(value: string | number): {
  dollars: string;
  cents: string;
} {
  let price = String(value);
  price = price.replace(/[^\d.]/g, ''); // strips out all but numbers and decimal.
  let dollars = '';
  let cents = '';

  const decimalIndex = price.indexOf('.');
  if (decimalIndex === -1) {
    dollars = price;
    cents = '00';
  } else {
    dollars = price.substring(0, decimalIndex);
    cents = price.substring(decimalIndex + 1, price.length);
    cents = cents.replace(/[^\d]/g, ''); // strips out all but numbers.
    cents = cents.substring(0, 2);
    if (cents.length === 0) {
      cents = '00';
    }
    if (cents.length === 1) {
      cents += '0';
    }
  }
  if (dollars.length === 0) {
    dollars = '0';
  }

  return {
    dollars,
    cents,
  };
}

/**
 *
 * @param {string | dollar} value -  ere
 * @returns a string formatted as USD currency
 * @example formatDollar(5) // $5.00;
 */
export function formatDollar(value: string | number, isNaNMessage?: string, withCents = true) {
  if (value === 'NaN' || Number.isNaN(value)) {
    return isNaNMessage || '';
  }

  const conversion = convertPriceToDollarsAndCents(value);
  let dollars = parseInt(conversion.dollars, 10).toString(); //gets rid of preceding zeros.
  const cents = conversion.cents;

  // add commas
  for (let i = dollars.length - 3; i > 0; i = i - 3) {
    dollars = dollars.slice(0, i) + ',' + dollars.slice(i, dollars.length);
  }

  if (!withCents) return `$${dollars}`;

  return `$${dollars}.${cents}`;
}

/**
 *
 * @param {string} title convert a string to lower, kebab-case with special characters removed
 * @returns {string} lower, kebab-case version of title arg
 * @example formatTitleToSEOId("My Cool, $100 Title!"); // 'my-cool-100-title'
 */
export function formatTitleToSEOId(title: string) {
  return `${title}`
    .toLowerCase()
    .replaceAll(/[;:(),.'"$]/gi, '')
    .replaceAll(' ', '-');
}

export function convertRGBColorToHex(rgb = '') {
  const matchArray = rgb.match(/\d+/g) || [];
  const hex =
    '#' +
    matchArray
      .map(x => {
        x = parseInt(x).toString(16).toUpperCase();
        return x.length === 1 ? '0' + x : x;
      })
      .join('');
  return hex;
}
