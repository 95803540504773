import { useEffect } from 'react';

import county from '../../../../assets/images/icon-county.svg';
import government from '../../../../assets/images/icon-governmententity.svg';
import state from '../../../../assets/images/icon-state.svg';
import usa from '../../../../assets/images/icon-usa.svg';

import { CreateAccountForm } from './CreateAccountForm';
import { formatPrice } from '../../../../utils/helpers';
import { getDynamicProductPrices } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import { useDSSelector } from 'src/store/reducers';

export const CreateAccount = () => {
  const dispatch = useDispatch();
  const shared = useDSSelector(ds => ds.shared);
  const { productPrices } = shared;

  // TODO: Move to Recoil
  useEffect(() => {
    dispatch(getDynamicProductPrices());
  }, [dispatch]);

  return (
    <>
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center newRegWrapper'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row justify-content-center'>
                  <div className='col-12 regCaption'>
                    Join DemandStar for free, and start winning more government work today!
                  </div>
                  <div className='col-12 col-md-6 dividerClr'>
                    <div className='prodPrice'>
                      <div className='row align-items-center px-5'>
                        <div className='col-4'>
                          <img src={government} alt='Free Agency' />{' '}
                        </div>
                        <div className='col-8'>
                          <h3>Basic Plan</h3>
                          <h4>Free!</h4>
                        </div>
                      </div>
                      <p>
                        Get started! Choose a procurement agency, and get access to every bid and
                        quote they publish on DemandStar.
                      </p>
                    </div>
                    <div className='prodPrice'>
                      <div className='row align-items-center px-5'>
                        <div className='col-4'>
                          <img src={county} alt='County' />{' '}
                        </div>
                        <div className='col-8'>
                          <h3>County Plan</h3>
                          <h4>
                            ${productPrices ? formatPrice(productPrices.countyPrice, 0) : ''} per
                            year*
                          </h4>
                        </div>
                      </div>
                      <p>
                        Choose a county government, and get access to every bid and quote agencies
                        in that county publish on DemandStar.
                      </p>
                    </div>

                    <div className='prodPrice'>
                      <div className='row align-items-center px-5'>
                        <div className='col-4'>
                          <img src={state} alt='State' />{' '}
                        </div>
                        <div className='col-8'>
                          <h3>State Plan</h3>
                          <h4>
                            ${productPrices ? formatPrice(productPrices.statePrice, 0) : ''} per
                            year*
                          </h4>
                        </div>
                      </div>
                      <p>
                        Upgrade to a State plan, and get access to every bid and quote agencies in
                        that state publish on DemandStar.
                      </p>
                    </div>

                    <div className='prodPrice'>
                      <div className='row align-items-center px-5'>
                        <div className='col-4'>
                          <img src={usa} alt='National' />{' '}
                        </div>
                        <div className='col-8'>
                          <h3>National Plan</h3>
                          <h4>
                            ${productPrices ? formatPrice(productPrices.nationalPrice, 0) : ''} per
                            year
                          </h4>
                        </div>
                      </div>
                      <p>
                        Go nationwide! Get full access to every bid and quote publised by every
                        agency and government on DemandStar.
                      </p>

                      <small>
                        *Pricing varies per state and county, depending on coverage, from $
                        {productPrices ? formatPrice(productPrices.statePrice, 0) : ''} - $
                        {productPrices ? formatPrice(productPrices.stateMaxPrice, 0) : ''}.
                      </small>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 px-5 createWrapper step1'>
                    <div>
                      <h2 className='text-center'>Get started for free!</h2>
                      <CreateAccountForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
