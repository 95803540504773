import { LayoutType } from '@demandstar/components/constants';

import { navigationPaths } from '../../shared/constants/navigation';
import { Route } from '../../types/routes';

// Routes of View Components
import { AddBid } from '../../components/buyer/addbid/AddBid';
import { AddContract } from 'src/features/contract-management/buyer/add-contract/AddContract';
import { AwardBid } from '../../components/buyer/awardbid/AwardBid';
import { CMDashboard } from '../../features/contract-management/buyer/dashboard';
import { ContractDetails } from 'src/features/contract-management/buyer/contract-details';
import { ContractDocuments } from 'src/features/contract-management/buyer/documents/ContractDocuments';
import { contractManagementLabels } from 'src/features/contract-management/ContractManagement.texts';
import { ContractReminders } from 'src/features/contract-management/buyer/reminders/ContractReminders';
import { ContractSearch } from '../../features/contract-management/buyer/contracts';
import { ContractWizard } from '../../features/contract-management/buyer/wizard/ContractWizard';

import AccountInfoTabContent from '../../components/shared/accountinfo/tabcontent';
import Activities from '../../components/shared/activities';
import AddEditBidType from '../../components/buyer/account/publications/addeditbidtype';
import AddEditUser from '../../components/shared/accountinfo/tabcontent/useraccount/userform';
import AddQuotes from '../../components/buyer/quotes/addquotes';
import Bids from '../../components/shared/bids';
import BuyerBidDetail from '../../components/buyer/biddetail';
import BuyerDashboard from '../../components/buyer/dashboard';
import EBids from '../../components/supplier/ebids';
import LegalTemplates from '../../components/buyer/account/templates';
import Message from '../../components/shared/message';
import ProductsView from '../../components/buyer/account/products/index';
import ProgramsIndex from '../../components/buyer/account/programs';
import PublicationsIndex from '../../components/buyer/account/publications';
import Quotedetails from '../../components/buyer/quotes/quotedetails';
import Quotes from '../../components/shared/quotes';
import Reports from '../../components/shared/activities/reports/bidactivityreport';
import Research from '../../components/buyer/research';
import UserManual from '../../components/shared/usermanual';
import UserManualPages from '../../components/shared/usermanual/pages';

export const buyerRoutes: Route[] = [
  { path: '/buyers/help', component: UserManual, page: 'usermanual', breadcrumb: { Home: '/' } },
  {
    path: '/buyers/help/:pagesid',
    component: UserManualPages,
    page: 'usermanualpages',
    breadcrumb: { Home: '/', Help: '/buyers/help' },
  },
  {
    path: '/buyers/dashboard',
    component: BuyerDashboard,
    page: 'Dashboard',
    breadcrumb: { Home: '/' },
  },
  {
    path: `${navigationPaths.buyers.bids}`,
    component: Bids,
    page: 'Bids Search',
    breadcrumb: { Home: '/' },
  },
  { path: '/buyers/quotes', component: Quotes, page: 'Quotes Search', breadcrumb: { Home: '/' } },
  {
    path: `${navigationPaths.buyers.bids}/:bidId`,
    component: BuyerBidDetail,
    page: 'Buyer Bids Details',
    breadcrumb: { Home: '/', Bids: `${navigationPaths.buyers.bids}` },
  },
  {
    path: `${navigationPaths.buyers.bids}/:bidId/response/:responseId`,
    component: EBids,
    page: 'Ebids Response',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.buyers.bids}`,
      'Bids Details': `${navigationPaths.buyers.bids}/:bidId/details`,
    },
  },
  {
    path: `${navigationPaths.buyers.bids}/:bidId/addresponse/:memberId`,
    component: EBids,
    page: 'Ebids Response',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.buyers.bids}`,
      'Bids Details': `${navigationPaths.buyers.bids}/:bidId/details`,
    },
  },
  {
    path: ['/buyers/bid/creation', `${navigationPaths.buyers.bids}/:bidId/creation`],
    component: AddBid,
    page: 'Agency Bid Creation',
    breadcrumb: { Home: '/' },
  },
  {
    path: [
      `${navigationPaths.buyers.bids}/:bidId/edit`,
      `${navigationPaths.buyers.bids}/:bidId/edit/:Tab`,
    ],
    component: AddBid,
    page: 'Agency Bid Update',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.buyers.bids}`,
      'Bids Details': `${navigationPaths.buyers.bids}/:bidId`,
    },
  },
  {
    path: ['/buyers/bids/:bidId/award', '/buyers/bids/:bidId/award/:Tab'],
    component: AwardBid,
    page: 'Agency Bid Award',
    breadcrumb: {
      Home: '/',
      Bids: `${navigationPaths.buyers.bids}`,
      'Bids Details': `${navigationPaths.buyers.bids}/:bidId`,
    },
  },
  {
    path: `${navigationPaths.buyers.bids}/:bidId/:Tab`,
    component: BuyerBidDetail,
    page: 'Buyer Bids Details',
    breadcrumb: { Home: '/', Bids: `${navigationPaths.buyers.bids}` },
  },
  {
    path: '/buyers/accountinfo/:Tab',
    component: AccountInfoTabContent,
    page: 'Account Info',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/accountinfo/useraccounts/userform',
    component: AddEditUser,
    page: 'Account Add edit user',
    breadcrumb: { Home: '/', 'Account Info': '/buyers/accountinfo/useraccount' },
  },
  {
    path: ['/buyers/quotes/creation', '/buyers/quotes/:bidId/creation'],
    component: AddQuotes,
    page: 'Agency Quote Creation',
    breadcrumb: { Home: '/' },
  },
  {
    path: ['/buyers/quotes/:bidId/edit', '/buyers/quotes/:bidId/edit/:Tab'],
    component: AddQuotes,
    page: 'Agency Quote Update',
    breadcrumb: { Home: '/', Quotes: '/buyers/quotes', 'Quote Details': '/buyers/quotes/:bidId' },
  },
  {
    path: '/buyers/quotes/:quoteId',
    component: Quotedetails,
    page: 'Buyer Quote Details',
    breadcrumb: { Home: '/', Quotes: '/buyers/quotes' },
  },
  {
    path: '/buyers/quotes/:quoteId/:tab',
    component: Quotedetails,
    page: 'Buyer Quote Details',
    breadcrumb: { Home: '/', Quotes: '/buyers/quotes' },
  },
  {
    path: '/buyers/account/configuration/:Tab',
    component: PublicationsIndex,
    page: 'Agency Bid Configuration',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/account/templates',
    component: LegalTemplates,
    page: 'Agency Templates',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/account/certifications/:Tab',
    component: ProgramsIndex,
    page: 'Agency Programs',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/account/bidtype/addpbidtype',
    component: AddEditBidType,
    page: 'Add Bid Type',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/account/products',
    component: ProductsView,
    page: 'Products',
    breadcrumb: { Home: '/' },
  },
  {
    path: '/buyers/activities/:Tab',
    component: Activities,
    page: 'Activities',
    breadcrumb: { Home: '/', Activities: '/buyers/activities/notifications' },
  },
  { path: '/buyers/messages', component: Message, page: 'Message', breadcrumb: { Home: '/' } },
  { path: '/buyers/research', component: Research, page: 'Research', breadcrumb: { Home: '/' } },
  {
    path: '/buyers/contract',
    component: CMDashboard,
    page: contractManagementLabels.title,
    breadcrumb: { Home: '/' },
    layout: LayoutType.Basic,
  },
  { path: '/buyers/reports', component: Reports, page: 'Reports', breadcrumb: { Home: '/' } },

  {
    path: [
      '/buyers/contract/create',
      '/buyers/contract/edit/:contractId?',
      '/buyers/contract/import/bid/:bidId',
    ],
    component: ContractWizard,
    page: 'Contract Creation',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
    },
  },
  {
    path: '/buyers/contract/search',
    component: ContractSearch,
    layout: LayoutType.Basic,
    page: 'Contracts',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
    },
  },
  {
    path: '/buyers/contract/documents/:Tab?',
    component: ContractDocuments,
    layout: LayoutType.Basic,
    page: 'Documents',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
    },
  },
  {
    path: '/buyers/contract/:contractId/documents/:Tab?',
    component: ContractDocuments,
    layout: LayoutType.Basic,
    page: 'Contract Documents',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
      Documents: '/buyers/contract/documents',
    },
  },
  {
    path: '/buyers/contract/:contractId/reminders',
    component: ContractReminders,
    layout: LayoutType.Basic,
    page: 'Reminders',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
      Reminders: '/buyers/contract/reminders',
    },
  },
  {
    path: '/buyers/contract/reminders',
    component: ContractReminders,
    layout: LayoutType.Basic,
    page: 'Reminders',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
    },
  },
  {
    path: '/buyers/contract/add',
    component: AddContract,
    layout: LayoutType.Basic,
    page: 'Add a Contract',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
    },
  },
  {
    path: '/buyers/contract/:contractId?',
    component: ContractDetails,
    layout: LayoutType.Basic,
    page: 'Contract Details',
    breadcrumb: {
      Home: '/',
      'Contract Management': '/buyers/contract',
      Contracts: '/buyers/contract/search',
    },
  },
];
