import { atom, atomFamily, selector, selectorFamily } from 'recoil';

import { Authorization, Permission } from 'src/types';
import { AuthState, AuthUserType } from '../reducers/auth';

import { defaultNumericId } from 'src/shared/constants';
import { getTokenData } from 'src/utils/token';

export const defaultAuthState: Authorization = {
  accountId: defaultNumericId,
  accountLocked: false,
  accountLockedMinutes: defaultNumericId,
  accountPermissions: '',
  email: '',
  firstName: '',
  fullName: '',
  hasError: false,
  lastName: '',
  mainContact: false,
  memberBlackListed: false,
  memberCreatedDate: '',
  memberId: defaultNumericId,
  membershipLevels: '',
  memberStatus: '',
  memberType: '',
  migrated: false,
  opsId: defaultNumericId,
  primaryMemberId: defaultNumericId,
  themetokenName: '',
  token: '',
  userName: '',
  userType: AuthUserType.Initial,
  validatedResult: { status: true, firstName: '', lastName: '' },
};

export const authTokenState = atom<string>({
  key: 'authTokenState',
  default: '',
});

export const authSelectorState = selector<Authorization>({
  key: 'authSelectorState',
  get: ({ get }) => {
    const token = get(authTokenState); // Sets a dependency on authTokenState. TODO: restore setting token state to trigger auth re-parsing/refresh (6915?).
    const auth = token ? getTokenData(token) : defaultAuthState;
    return auth;
  },
});

// TODO: update default to null/undefined
export const authState = atom<Authorization>({
  key: 'authState',
  default: authSelectorState,
});

export const authErrorState = atom<string>({ key: 'authErrorState', default: '' });
