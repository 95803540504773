import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilStateLoadable } from 'recoil';

import { ContentRowWrapping, FlexContainer } from '@demandstar/components/styles';
import { DSCheckbox } from '@demandstar/components/index';

import { allProductsState, initialCountyProduct } from '../../../store/recoil/productState';
import { BundleCategory, productBundles } from './bundles';
import { getProduct, isSubscribed } from '../helpers';
import { NationalProductId, ProductApiResponse, ProductType } from '../../../types/products';

import { compareObjectsByKey } from '../../../utils/sort';
import { findArrayItemByKey } from '../../../utils';
import { ProductList } from './styles';
import { removeDuplicates } from '../../../utils/helpers';
import { useAccountInfo } from 'src/shared/hooks/useAccountInfo';
import { useSelectedProducts } from 'src/shared/hooks/useSelectedProducts';

interface SelectBundleProps {
  /** the current parent state product selected */
  parentState: ProductApiResponse;
}

interface BundleDisplay {
  items: ProductApiResponse[];
  name: string;
  price: number;
  mapComponent?: React.ComponentType<React.HTMLProps<HTMLSpanElement>>;
}

const initialBundleDisplay = {
  items: [initialCountyProduct],
  name: 'Test',
  price: 0,
};

const BundleList = styled(ProductList)`
  max-width: 27rem;
  width: 66.67%;

  li > div > div:last-of-type {
    margin-right: 0;
  }
`;

/**
 * @description section that displays checkboxes w/ map for regional product bundle options
 * @returns JSX.Element
 *
 * @example <SelectBundle parentState={{
 *  productId: 10,
 *  productName: 'Florida',
 *  productType: ProductType.State,
 *  price: 1050,
 * }} />
 */
export const SelectBundle = (props: SelectBundleProps) => {
  const [allProducts] = useRecoilStateLoadable(allProductsState);
  const { parentState } = props;
  const { addProduct, removeProduct, selectedProducts, setSelectedProducts } =
    useSelectedProducts();

  const [selectedBundleCategory, setSelectedBundleCategory] = useState<BundleCategory>();
  const [availableBundles, setAvailableBundles] = useState<BundleDisplay[]>([initialBundleDisplay]);
  const [selectedBundles, setSelectedBundles] = useState<BundleDisplay[]>([initialBundleDisplay]);
  const [totalSavings, setTotalSavings] = useState(0);

  const bundleCategoryKey = parentState.productName;

  const {
    accountInfo: { products: subscribedProducts },
  } = useAccountInfo();

  const isBundleSubscribed = useCallback(
    (bundle: BundleDisplay) => {
      return bundle?.items?.length &&
        bundle.items.filter(c => !isSubscribed(c, subscribedProducts)).length
        ? false
        : true;
    },
    [subscribedProducts],
  );

  function handleBundleSelection(name: string, value: boolean) {
    const selectedBundle =
      findArrayItemByKey<BundleDisplay>(availableBundles, 'name', name) || initialBundleDisplay;
    const checkedCounties = selectedProducts.county || [];

    // add
    if (value) {
      const bundleCounties = selectedBundle.items.filter(c => !isSubscribed(c, subscribedProducts));
      const allCounties = bundleCounties.concat(checkedCounties);
      const uniqueCounties: ProductApiResponse[] = removeDuplicates(allCounties, 'productId').sort(
        compareObjectsByKey('productName'),
      );

      const parentStateSelected =
        selectedProducts?.national ||
        selectedProducts?.state?.filter(st => st.productId === parentState.productId)?.length;

      if (parentStateSelected) {
        const bundleAndOutOfState = bundleCounties.concat(
          checkedCounties.filter(x => x.parentId !== parentState.productId),
        );

        const selectedStates =
          selectedProducts.national && allProducts.state === 'hasValue'
            ? allProducts.contents.filter(
                st =>
                  st.productType === ProductType.State && st.productId !== parentState.productId,
              )
            : selectedProducts.state?.filter(st => st.productId !== parentState.productId);

        setSelectedProducts({
          ...selectedProducts,
          county: bundleAndOutOfState,
          state: selectedStates,
          national: NationalProductId.None,
        });
        setSelectedBundles([selectedBundle]);
      } else {
        if (availableBundles.length <= selectedBundles.length + 1) {
          const selectedBundleNames = selectedBundles
            .concat(selectedBundle)
            .map(bundle => bundle.name);
          const unselectedBundle = availableBundles.concat(selectedBundle)?.find(bundle => {
            return !selectedBundleNames.includes(bundle.name);
          });
          if (!unselectedBundle) {
            addProduct(parentState);
            return;
          }
        }

        setSelectedProducts({
          ...selectedProducts,
          county: uniqueCounties,
        });
        setSelectedBundles([...selectedBundles, selectedBundle]);
      }
    }
    /**
     * NOTE: if Cart and county check boxes have not been touched, this will remove all counties associated
     * with the bundle otherwise, it does not affect counties in cart
     */
    // remove selectedBundle
    else {
      const stillSelectedBundles = selectedBundles.filter(bundle => bundle !== selectedBundle);

      setSelectedBundles(stillSelectedBundles);

      const remainingCounties = checkedCounties.filter(
        county => !selectedBundle.items?.find(item => item.productId === county.productId),
      );
      setSelectedProducts({
        ...selectedProducts,
        county: remainingCounties,
      });
    }
  }

  const handleMapComponentClick = (name: string) => () => {
    const isBundleSelected = findArrayItemByKey<BundleDisplay>(selectedBundles, 'name', name);
    handleBundleSelection(name, !isBundleSelected);
  };

  const handleCheckedState = (name: string, value: boolean) => {
    if (value) {
      addProduct(parentState);
    } else {
      removeProduct(parentState);
    }
  };

  useEffect(() => {
    let allBundlesPrice = 0;

    /* istanbul ignore else */
    if (allProducts.state === 'hasValue') {
      const bundleCategory = productBundles.find(
        bundleCategory => bundleCategoryKey === bundleCategory.title,
      );

      if (!bundleCategory) {
        return;
      }

      setSelectedBundleCategory(bundleCategory);

      const bundleDisplayList: BundleDisplay[] = [];

      (bundleCategory.items || []).forEach(bundle => {
        const products: ProductApiResponse[] = (bundle.idList || []).map(
          productId => getProduct(productId, allProducts.contents) || initialCountyProduct,
        );

        const totalPrice = products.reduce((a = 0, b) => {
          return a + (b?.price || 0);
        }, 0);

        allBundlesPrice += totalPrice;

        const newBundle: BundleDisplay = {
          items: products || initialBundleDisplay.items,
          name: bundle.title,
          price: totalPrice,
          mapComponent: bundle.mapComponent || 'span',
        };

        bundleDisplayList.push(newBundle);
      });

      setAvailableBundles(bundleDisplayList);

      setTotalSavings(allBundlesPrice - parentState.price);
    }
  }, [allProducts, bundleCategoryKey, parentState.price]);

  const mapComponents: React.ReactNode[] = [];
  const MapContainer = selectedBundleCategory?.mapContainer || 'span';

  return (
    <div>
      <h5>Choose a {bundleCategoryKey} Regional Package</h5>
      <p className='no-top-padding'>
        There are {availableBundles.length} packages available for {bundleCategoryKey}. Each of
        these packages contains access to all the governments on DemandStar in that region.
      </p>
      <p className='no-top-padding'>
        Select the region that matches where you do business, or purchase a {bundleCategoryKey}{' '}
        State subscription to buy access to all of them.
      </p>
      <ContentRowWrapping>
        <BundleList>
          {/** The first bundle is a state subscription, not a combination of counties */}
          <li key={bundleCategoryKey}>
            <FlexContainer justifyContent='space-between'>
              <div>
                <DSCheckbox
                  checked={
                    (selectedProducts?.national ?? 0) > 0 ||
                    selectedProducts.state?.includes(parentState) ||
                    isSubscribed(parentState, subscribedProducts)
                  }
                  id={'All-' + bundleCategoryKey}
                  onClick={() => {
                    handleCheckedState(
                      'All of ' + bundleCategoryKey,
                      !(
                        (selectedProducts?.national ?? 0) > 0 ||
                        selectedProducts.state?.includes(parentState)
                      ),
                    );
                  }}
                  name={'All of ' + bundleCategoryKey}
                  label={'All of ' + bundleCategoryKey}
                  inactive={isSubscribed(parentState, subscribedProducts)}
                />
              </div>
              <div className='reg-span'>
                {`$${parentState.price}/year (save $${totalSavings}!)`}
              </div>
            </FlexContainer>
          </li>
          {availableBundles.map(bundleDisplay => {
            // Set an optional map component
            const MapComponent = bundleDisplay.mapComponent || 'span';

            mapComponents.push(
              <MapComponent
                key={`map-${bundleDisplay.name}`}
                onClick={handleMapComponentClick(bundleDisplay.name)}
              />,
            );
            const isChecked =
              !selectedProducts.state?.includes(parentState) &&
              !((selectedProducts.national ?? 0) > 0) &&
              (selectedBundles.includes(bundleDisplay) || isBundleSubscribed(bundleDisplay));

            return (
              <li key={bundleDisplay.name}>
                <FlexContainer justifyContent='space-between'>
                  <div>
                    <DSCheckbox
                      checked={isChecked}
                      id={bundleDisplay.name}
                      onClick={() => {
                        handleBundleSelection(bundleDisplay.name, !isChecked);
                      }}
                      name={bundleDisplay.name}
                      label={bundleDisplay.name}
                      inactive={isBundleSubscribed(bundleDisplay)}
                    />
                  </div>
                  <div className='reg-span'>{`$${bundleDisplay.price}/year`}</div>
                </FlexContainer>
              </li>
            );
          })}
        </BundleList>
        <MapContainer>{mapComponents}</MapContainer>
      </ContentRowWrapping>
    </div>
  );
};
