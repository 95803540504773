/* eslint-disable no-console */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createClient, Provider as URQLProvider } from 'urql';

import { RecoilRoot } from 'recoil';
import { Router } from 'react-router-dom';
import { useMemo } from 'react';

import ErrorBoundary from './components/common/splash/errorboundary';
import history from './utils/history';
import Root from './containers/Root';

import { loadState } from './utils/storage';

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log =
      console.error =
      console.info =
      console.debug =
      console.warn =
      console.trace =
      console.dir =
      console.dirxml =
      console.group =
      console.groupEnd =
      console.time =
      console.timeEnd =
      console.assert =
      console.profile =
        function () {
          // do not log in production
        };
  }

  const state = loadState();

  // Set up urql - gql
  const storedToken = useMemo(() => {
    const authState = state?.auth;
    return authState?.tk || '';
  }, [state]);

  const client = createClient({
    url: 'https://dsstg-gateway.azurewebsites.net/graphql', // TODO - configure gql endpoint by .env values.
    fetchOptions: () => {
      const token = storedToken;
      return {
        headers: { authorization: token ? `Bearer ${token}` : '' },
      };
    },
  });

  return (
    <ErrorBoundary>
      <URQLProvider value={client}>
        <RecoilRoot>
          <Router history={history}>
            <Root />
          </Router>
        </RecoilRoot>
      </URQLProvider>
    </ErrorBoundary>
  );
}

export default App;
