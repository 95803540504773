import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { memo, useCallback, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { bidssummaryParamType, ebidItemParamType } from '../../../types/biddetail';

import * as actionCreators from '../../../store/actions';
import { initialStateBidsSummary } from '../../../store/reducers/bidssummary';

import { MemberTypeName } from '../../../types/dashboard';

import {
  awardeeStatus,
  BidExternalStatusType,
  MemberType,
  orderBidPackageSession,
  selectedBidDocId,
} from '../../../utils/constants';
import { navigationPaths, requiredActionLabels } from '../../../shared/constants';
import { useRouter, useSetBreadcrumb } from 'src/shared/hooks';

import Planholders from '../../shared/bidsdetail/righttab/planholders/index';
import PlanholdersListings from '../../buyer/biddetail/tabs/planholders';
import SimilarBids from '../similarbids';

import { authTypes } from '../../../types/auth';
import { BidInfo } from '../../shared/bidsdetail/lefttab/bidinfo';
import { bidsSummary } from '../../../store/actions';
import { loadingMsg } from '../../../utils/texts';
import { MyEBidResponse } from '../../shared/bidsdetail/lefttab/myebidresponse';
import { nargsfnTypes } from '../../../types/functions';
import { NoResult } from '../../customcontrols';
import { sharedTypes } from '../../../types/shared';
import { SupplierBidManagement } from '../../../features/bids/management';

interface propTypes extends PropsFromRedux {
  match: any;
  bidssummary: bidssummaryParamType;
  auth: authTypes;
  shared: sharedTypes;
  forceUpdateAfterTimerCompletion: () => void;
  reloadPageDetails: () => void;
  bidWriterInfo: Record<string, unknown>;
  bidsSummary: any;
  getWriterInfo: any;
  setBidSummaryDetails: any;
  getCanUpgrade: any;
  bidsPlanHolders: any;
  bidsEbids: any;
  getBidView: any;
  getAwardees: any;
  bidsSimilarBids: any;
  setSharedDetails: nargsfnTypes;
  getBidsWatchStatus: any;
  downloadAllDocument: any;
  bidsResponseDocumentDownload: any;
  getSelfDeclarations: any;
  exportBroadcastList: any;
  exportSupplementalSuppliers: any;
  bidsBidWatch: any;
  eBids: ebidItemParamType;
  memberTypeName: MemberTypeName;
  memberId: number;
  memberInfo: any;
  getPlanHoldersDetails: any;
  setSelectedBidsSummaryDetails: any;
}

function SupplierBidDetails(props: propTypes) {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const bidID = parseInt(props.match.params.bidId);
  const { routerParams } = useRouter();
  const { Tab } = routerParams;
  const [activeTab, setActiveTab] = useState(Tab ? Tab : 'details');
  const {
    bidssummary,
    getWriterInfo,
    auth,
    setBidSummaryDetails,
    getCanUpgrade,
    bidsPlanHolders,
    bidsEbids,
    getBidView,
    getAwardees,
    shared,
    bidsSimilarBids,
    setSharedDetails,
    getBidsWatchStatus,
    downloadAllDocument,
    bidsResponseDocumentDownload,
    memberTypeName,
    setSelectedBidsSummaryDetails,
  } = props;

  const {
    eBids,
    purchaseInfo,
    results,
    planHolders,
    planholdersdetail,
    similarBids,
    canSupplierUpgrade = false,
    canSupplierUpgradeAPICALL = true,
  } = bidssummary;
  const {
    bidWriterAccountID = '',
    memberID,
    bidName,
    bidID: bidId,
    eBidding,
    bidExternalStatusType = BidExternalStatusType.None,
  } = results;
  const { result: planholderslist } = planHolders;
  const { internalLoader } = shared;
  const { /* showButton = false, */ isPurchased = false } = purchaseInfo;
  const { /* prms: accountPermissions = '', mc: isPrimaryContact = false, */ mi, mt: memberType } =
    auth;

  const orderBidPackage = useCallback(() => {
    history.push({
      pathname: `/suppliers/bids/${bidID}/order`,
      state: { fromBitSummary: { ...results, ...purchaseInfo } },
    });
  }, [bidID, history, purchaseInfo, results]);

  const checkDocDownload = useCallback(async () => {
    const orderBidPackageOption = await sessionStorage.getItem(orderBidPackageSession);
    if (orderBidPackageOption === 'checkDownload') {
      if (isPurchased) {
        const bidDocId = await sessionStorage.getItem(selectedBidDocId);
        if (bidDocId) {
          bidsResponseDocumentDownload({ docId: bidDocId, id: bidID, type: 'Bid' });
        } else {
          downloadAllDocument({ id: bidID, type: 'Bid' });
        }
      } else {
        orderBidPackage();
      }
      sessionStorage.removeItem(orderBidPackageSession);
    }
  }, [bidID, isPurchased, downloadAllDocument, bidsResponseDocumentDownload, orderBidPackage]);

  useEffect(() => {
    if (purchaseInfo.loaded === true) checkDocDownload();
  }, [checkDocDownload, purchaseInfo.loaded]);

  useEffect(() => {
    setActiveTab(Tab ? Tab : 'details');
  }, [Tab]);

  useEffect(() => {
    if (bidId === bidID && bidId && bidExternalStatusType === BidExternalStatusType.Upcoming)
      history.replace('/');
  }, [bidExternalStatusType, bidId, history, bidID]);

  useEffect(() => {
    if (bidID) {
      dispatch(bidsSummary({ bidId: bidID, otherapis: true }));
    }
  }, [bidID, dispatch]);

  useEffect(() => {
    if (bidID && bidExternalStatusType && awardeeStatus.includes(bidExternalStatusType))
      getAwardees({ bidId: bidID });
  }, [bidExternalStatusType, bidID, getAwardees]);

  useEffect(() => {
    let getBidViewAPICALL = true;
    if (bidID && getBidViewAPICALL) getBidView({ bidId: bidID });
    return () => (getBidViewAPICALL = false) as any;
  }, [bidID, getBidView]);

  useEffect(() => {
    let getBidsWatchStatusAPICALL = true;
    if (bidID && getBidsWatchStatusAPICALL) getBidsWatchStatus({ bidId: bidID });
    return () => (getBidsWatchStatusAPICALL = false) as any;
  }, [bidID, getBidsWatchStatus]);

  useEffect(() => {
    let getSimilarBidsAPICALL = true;
    if (
      bidID &&
      memberType !== MemberType.AgencyBuyer &&
      similarBids.length === 0 &&
      getSimilarBidsAPICALL
    ) {
      setSharedDetails({ internalLoader: true });
      bidsSimilarBids({ bidId: bidID });
    }
    return () => (getSimilarBidsAPICALL = false) as any;
  }, [
    setSharedDetails,
    bidExternalStatusType,
    bidID,
    bidsSimilarBids,
    memberType,
    similarBids.length,
  ]);

  useEffect(() => {
    if (bidID && mi && eBidding === 'ViewEdit') {
      bidsEbids({ bidId: bidID, memberId: mi });
    }
  }, [bidsEbids, mi, bidID, eBidding]);

  useEffect(() => {
    let bidsPlanHoldersAPICALL = true;
    if (bidID && bidsPlanHoldersAPICALL) bidsPlanHolders({ bidId: bidID });
    return () => (bidsPlanHoldersAPICALL = false) as any;
  }, [bidID, bidsPlanHolders]);

  useEffect(() => {
    if (bidWriterAccountID && memberID && Number(bidId) === bidID) {
      setSharedDetails({ internalLoader: true });
      getWriterInfo({ writerAccountID: bidWriterAccountID, memberID: memberID });
    }
  }, [getWriterInfo, bidWriterAccountID, memberID, bidId, bidID, setSharedDetails]);

  useEffect(() => {
    if (canSupplierUpgradeAPICALL && bidID && bidName) {
      getCanUpgrade();
      setBidSummaryDetails({ canSupplierUpgradeAPICALL: false });
    }
  }, [bidID, bidName, canSupplierUpgradeAPICALL, getCanUpgrade, setBidSummaryDetails]);

  useEffect(() => {
    return () => {
      if (bidId !== bidID) {
        // TODO: Label these better.
        setBidSummaryDetails({ similarBids: [] });
      }
    };
  }, [bidID, bidId, setBidSummaryDetails]);

  useEffect(() => {
    return () => {
      setBidSummaryDetails({
        results: {},
        planHolders: { total: '', result: [], ids: [] },
        similarBids: [],
      });
    };
  }, [setBidSummaryDetails]);

  useSetBreadcrumb({
    component: '',
    page: 'Supplier Bids Details',
    altname: '',
    title: bidName,
    name: bidName,
    id: bidID,
    menuactive: 'bids',
  });

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      history.push(`/suppliers/bids/${bidID}/${tab}`);
    }
  };

  const SimilarBidsList = (list: any) => {
    const { agency = '', bidId, bidName, city, state, memberType } = list;
    const agencyInfo = `${agency}, ${city}, ${state}`;
    return (
      <div className='listGroupWrapper clearfix similarBids'>
        <h5 className='mw-100 text-truncate' title={bidName}>
          <Link
            className='w-95 text-truncate'
            to={
              `/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/bids/` +
              bidId +
              '/details'
            }
          >
            {bidName}
          </Link>
        </h5>
        <p>{agencyInfo}</p>
      </div>
    );
  };

  useEffect(() => {
    return () => {
      setBidSummaryDetails(initialStateBidsSummary);
    };
  }, [setBidSummaryDetails]);

  return (
    <>
      <div className='container'>
        <div className='row detailViewWrapper'>
          <div
            className={
              activeTab === 'details' &&
              (planholderslist.length > 0 || similarBids.length > 0 || canSupplierUpgrade)
                ? 'col-lg-8'
                : 'col-lg-12'
            }
          >
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'details' })}
                  onClick={() => {
                    toggle('details');
                  }}
                >
                  Bid Details
                </NavLink>
              </NavItem>
              {planholderslist.length > 0 && results.isBonfireBid !== true && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'planholders' })}
                    onClick={() => {
                      toggle('planholders');
                    }}
                  >
                    Planholders
                  </NavLink>
                </NavItem>
              )}
              {similarBids.length > 0 && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'similarbids' })}
                    onClick={() => {
                      toggle('similarbids');
                    }}
                  >
                    Similar Bids
                  </NavLink>
                </NavItem>
              )}
              {eBids && eBidding === 'ViewEdit' ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'ebidproposal' })}
                    onClick={() => {
                      toggle('ebidproposal');
                    }}
                  >
                    My eBid Proposal
                  </NavLink>
                </NavItem>
              ) : null}
              {/* Supplier Required Actions for Awarded Bids */}
              {results.isBonfireBid === true ? null : (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === navigationPaths.subPaths.Management,
                    })}
                    onClick={() => {
                      toggle(navigationPaths.subPaths.Management);
                    }}
                  >
                    {requiredActionLabels.titlePlural}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='details'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    <>
                      <BidInfo
                        {...props}
                        data={results}
                        eBids={eBids}
                        memberTypeName={memberTypeName}
                        purchaseInfo={purchaseInfo}
                        orderBidPackage={orderBidPackage}
                      />
                    </>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId='planholders'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    {planholderslist.length > 0 && activeTab === 'planholders' && (
                      <PlanholdersListings {...props} pagefor='supplier' />
                    )}
                  </div>
                </div>
              </TabPane>
              <TabPane tabId='similarbids'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    {similarBids.length > 0 && activeTab === 'similarbids' && (
                      <SimilarBids {...props} />
                    )}
                  </div>
                </div>
              </TabPane>
              <TabPane tabId='ebidproposal'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    {eBids && eBidding === 'ViewEdit' && activeTab === 'ebidproposal' ? (
                      <MyEBidResponse {...props} data={eBids} mId={memberID} />
                    ) : null}
                  </div>
                </div>
              </TabPane>
              <TabPane tabId='management'>
                <div className='row justify-content-center'>
                  <div className='col-lg-12'>
                    {activeTab === 'management' ? <SupplierBidManagement /> : null}
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
          {activeTab === 'details' &&
          (planholderslist.length > 0 || similarBids.length > 0 || canSupplierUpgrade) ? (
            <div className='col-lg-4'>
              <div className='sideBarSpacing'>
                {canSupplierUpgrade ? (
                  <>
                    <Link
                      className='bttn-accent w-100 mb-2 btn-block text-center'
                      title='Upgrade Subscription'
                      rel='noopener noreferrer'
                      // target='_blank'
                      to={
                        memberType === MemberType.BasicSupplier
                          ? '/suppliers/plans'
                          : '/subscription/renewal'
                      }
                    >
                      Upgrade Subscription
                    </Link>
                  </>
                ) : null}
                <h4>Similar Bids ({similarBids.length})</h4>
                <div className='innerColIndent clearfix'>
                  {similarBids.length > 0 ? (
                    <>
                      {similarBids.slice(0, 3).map((bids, id) => (
                        <SimilarBidsList key={id} {...bids} />
                      ))}
                      {similarBids.length > 3 && (
                        <span
                          className='float-right staticLink clearfix mt-3'
                          title='View more'
                          onClick={() => toggle('similarbids')}
                        >
                          View more <i className='mdi mdi-launch' />
                        </span>
                      )}
                    </>
                  ) : (
                    <NoResult message={internalLoader ? loadingMsg : 'No Similar Bids Available'} />
                  )}
                </div>
                {planholderslist.length > 0 && results.isBonfireBid !== true && (
                  <Planholders
                    toggle={() => toggle('planholders')}
                    datas={planholderslist}
                    planholdersdetail={planholdersdetail}
                    bidID={bidId}
                    bidName={bidName}
                    shared={shared}
                    setSelectedBidsSummaryDetails={setSelectedBidsSummaryDetails}
                    setBidSummaryDetails={setBidSummaryDetails}
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

const connector = connect(
  (state: any) => ({
    bidssummary: state.bidssummary,
    auth: state.auth,
    shared: state.shared,
    eBids: state.eBids,
    addbid: state.addbid,
    memberTypeName: state?.memberinfo?.mtn,
    memberId: state?.memberinfo?.mi,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(SupplierBidDetails));
