/** NOTE: This file is currently intended to exist as a child of DocumentUploadTable.
* There are some parts of this code that rely on some Redux actions taking place.
//TODO: re-implement with a component which wraps `react-table` and existing upload logic,
// e.g. `src/components/buyer/awardbid/add-vendor-docs/AddVendorDocs.tsx`
// User Story #6775
 * It's possible that we may want to use this on its own at some point.
 * For example, in a DocumentTable that we don't want to allow uploading to.
 * which exist in DocumentUploadTable. If you are using this for another purpose,
 * take care to make sure that this is taken care of. */

import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch } from 'react-redux';

import { fileUploadMaxSize } from '@demandstar/components/constants/document';

import DocumentFileTypeError, { DocError } from './documentfiletypeerror';

import { ActionColor, Colors } from '../../shared/styles';
import {
  AddBidDocTypeOption,
  BidDocument,
  DocStatus,
  DocTypeLabel,
  DocTypeValue,
  UploadedFile,
} from '../../types/addbid';
import {
  bidsResponseDocumentDownload,
  setAddBidDetails,
  updateBidDocumentTitle,
  setDocumentName,
} from '../../store/actions';
import { Buttons, CheckBox, DeprecatedInput, SelectBox } from '../customcontrols';
import { getFileExtension, toastFn } from '../../utils/helpers';

import { BidExternalStatusType } from '../../utils/constants';
import { BidSummaryResult } from 'src/types/bidssummary';
import { displayDate } from '../../utils/helpers';
import { fileUploadSizeError } from 'src/utils/constants/document';
import { TextInput } from '../forms/TextInput';
import { ToolTip } from '../../components/common/ToolTip';
import { useDSSelector } from '../../store/reducers';
import { DSTextInput } from '@demandstar/components/inputs';


interface DocumentUploadTableRowProps {
  doc: BidDocument;
  index: number;
  bidId: number;
  setNonDeleteAlert: (bool: boolean) => void;
  setNonDeleteDocumentType: (label: DocTypeLabel) => void;
  setFileTypeModalVisible: (bool: boolean) => void;
  documentUploadToastId: string;
  showErrors?: boolean;
  showDateModified?: boolean;
  showStatus?: boolean;
}

export const DocumentUploadTableRow = (props: DocumentUploadTableRowProps) => {
  const dispatch = useDispatch();

  const {
    bidId,
    doc,
    documentUploadToastId,
    index,
    setFileTypeModalVisible,
    setNonDeleteAlert,
    setNonDeleteDocumentType,
    showErrors,
    showDateModified,
    showStatus = true,
  } = props;

  //ops check (getting opi value)
  const auth = useDSSelector(state => state.auth);
  const { opi = '' } = auth || {};

  // DOCUMENTS // we establish these in the hooks on DocumentUploadTable.
  const documents = useDSSelector(state => state.addbid.documentslist || []);
  const approvedDocuments = useDSSelector(state => state.addbid.approvedDocuments || []);
  const doctypes = useDSSelector(state => state.addbid.doctypes);
  const selectedDoc = useDSSelector(state => state.addbid.selectedDoc);

  // PERMISSIONS // we establish these in the hooks on DocumentUploadTable.
  const permissions = useDSSelector(state => state.addbid.permissions);
  const { canView, canApprove, canDelete } = permissions;

  // UPLOAD PROGRESS
  const uploadProgress = useDSSelector(state => state.addbid.upload_progress);

  const bidExternalStatusType = useDSSelector(
    state =>
      (state.bidssummary.results as BidSummaryResult).bidExternalStatusType ||
      BidExternalStatusType.None,
  );

  const progress = doc.progress;

  function loadProgressBar(fillColor: string = Colors.blueGreenMedium) {
    return progress ? (
      <div style={{ width: '40px', margin: 'auto' }}>
        <CircularProgressbar
          styles={{
            text: {
              fill: ActionColor.primary,
              fontSize: '28px',
            },
            path: {
              stroke: `${fillColor}`,
            },
          }}
          value={progress}
          text={`${progress}%`}
        />
      </div>
    ) : (
      <></>
    );
  }

  function ShowCircular(doc: BidDocument) {
    if (doc.uploadprocess === 'completed' || doc.uploadprocess === 'notstarted') {
      return '';
    }
    return loadProgressBar();
  }
  function downloadDoc(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, docId: number) {
    e.preventDefault();
    dispatch(
      bidsResponseDocumentDownload({
        docId,
        id: bidId,
        type: 'Bid',
      }),
    );
  }
  const docAllowedExt = useDSSelector(state => state.accountinfo.agencydocs.DocAllowedExt);
  const convertableTypes = useDSSelector(state => state.accountinfo.agencydocs.convertableTypes);
  // we establish these in the hooks on DocumentUploadTable.
  const [documentTypeErrors, setDocumentTypeErrors] = useState<DocError[]>([]);
  const [docTitle, setDocTitle] = useState(doc.doctitle);

  function addPendingDocUpload(id: string, value?: UploadedFile) {
    if (uploadProgress) return;
    const currentDocIndex = documents.findIndex(doc => {
      return doc.id === id;
    });
    const currentDoc = documents[currentDocIndex];
    const doctypeErrorArray: DocError[] = [];
    if (value) {
      if (value.size > fileUploadMaxSize) {
        return toastFn('error', fileUploadSizeError, documentUploadToastId);
      } else {
        const docextension = value ? (getFileExtension([value]) as string) : '';
        if (!(docAllowedExt as string[])?.includes(docextension.toLowerCase())) {
          const errorExist = documentTypeErrors.filter(docEr => docEr.row === currentDocIndex);
          if (errorExist.length) {
            errorExist[0].error = `${docextension} is not a supported file type.`;
            setDocumentTypeErrors(doctypeErrorArray.concat(documentTypeErrors));
          } else {
            doctypeErrorArray.push({
              row: currentDocIndex,
              error: `${docextension} is not a supported file type.`,
              errortype: 'format',
            });
            setDocumentTypeErrors(doctypeErrorArray.concat(documentTypeErrors));
          }
        } else {
          currentDoc.docext = docextension; // TODO: type this shizz
          if (['zip', 'dwg'].includes(docextension.toLocaleLowerCase())) {
            currentDoc.docconversion = false;
          }
          currentDoc.docfile = value;
          const newDocList = [...documents];
          newDocList[currentDocIndex] = currentDoc;
          dispatch(setAddBidDetails({ documentslist: newDocList, halfWayCancel: true }));
          const filterError = doctypeErrorArray.filter(docEr => docEr.row !== currentDocIndex);
          setDocumentTypeErrors(filterError);
        }
      }
    } else {
      currentDoc.docext = '';
      currentDoc.docfile = undefined;
      const newDocList = [...documents];
      newDocList[currentDocIndex] = currentDoc;
      dispatch(setAddBidDetails({ documentslist: newDocList, halfWayCancel: true }));
    }
  }
  function updatePendingDocTitle(id: string, value: string) {
    if (uploadProgress) return;
    const currentDocIndex = documents.findIndex(doc => {
      return doc.id === id;
    });
    const currentDoc = documents[currentDocIndex];
    currentDoc.doctitle = value;
    const newDocList = [...documents];
    newDocList[currentDocIndex] = currentDoc;
    dispatch(
      setAddBidDetails({
        documentslist: newDocList,
        halfWayCancel: true,
      }),
    );
  }
  function updatePendingDocType(id: string, doctype: AddBidDocTypeOption) {
    if (uploadProgress) return;
    const currentDocIndex = documents.findIndex(doc => {
      return doc.id === id;
    });
    const currentDoc = documents[currentDocIndex];
    currentDoc.docType = doctype.value;
    currentDoc.doctype = doctype;
    currentDoc.type = doctype.label; // I don't love this, but for now it's how it's gotta be
    const newDocList = [...documents];
    newDocList[currentDocIndex] = currentDoc;
    dispatch(
      setAddBidDetails({
        documentslist: newDocList,
        halfWayCancel: true,
      }),
    );
  }
  function updatePendingDocConversion(id: string, value: boolean) {
    const currentDocIndex = documents.findIndex(doc => {
      return doc.id === id;
    });
    const currentDoc = documents[currentDocIndex];
    currentDoc.docconversion = value;
    const newDocList = [...documents];
    newDocList[currentDocIndex] = currentDoc;
    dispatch(
      setAddBidDetails({
        documentslist: newDocList,
        halfWayCancel: true,
      }),
    );
  }

  function updateDocTitle(value: string) {
    if (uploadProgress) return;
    setDocTitle(value);
  }

  function deletePendingDocument(id: string, index: number) {
    const filteredDocs = documents.filter(doc => doc.id !== id);
    dispatch(setAddBidDetails({ documentslist: filteredDocs }));
    const filterError = documentTypeErrors.filter(docEr => docEr.row !== index);
    setDocumentTypeErrors(filterError);
  }
  const approvedocumentmodal = useDSSelector(state => state.addbid.approvedocumentmodal || false);
  const deletedocumentmodal = useDSSelector(state => state.addbid.deletedocumentmodal || false);
  function toggleApproveDocModal(doc?: BidDocument) {
    dispatch(
      setAddBidDetails({
        approvedocumentmodal: !approvedocumentmodal,
        approvedocumentdetails: doc,
      }),
    );
  }
  function toggleDeleteDocModal(doc?: BidDocument) {
    if (!doc) {
      return dispatch(
        setAddBidDetails({
          deletedocumentmodal: false,
          deleteDocumentDetails: undefined,
        }),
      );
    }
    const docType: DocTypeValue = doc
      ? doc.docType || (doc.doctype as AddBidDocTypeOption).value
      : DocTypeValue.None;
    const existType = doctypes?.filter(type => type.value === docType) || [];
    if (existType.length === 0 && !(opi > 0 && (bidExternalStatusType === BidExternalStatusType.Active || bidExternalStatusType === BidExternalStatusType.Cancelled))) {
      setNonDeleteAlert(true);
      setNonDeleteDocumentType(doc ? doc.type || (doc.doctype as AddBidDocTypeOption).label : '');
    } else {
      dispatch(
        setAddBidDetails({
          deletedocumentmodal: !deletedocumentmodal,
          deleteDocumentDetails: doc,          
        }),
      );
    }
  }
  function onToggleEdit(docId: number, isEdit: boolean) {
    if (doc) {
      if (!isEdit || doc.doctitle !== docTitle) setDocTitle(doc.doctitle);
      dispatch(setDocumentName({ docId: docId, isEdit: isEdit }));
    }
  }
  function onSaveDocTitle(doc: BidDocument) {
    if (doc) {
      if (!docTitle.trim()) {
        toastFn('error', 'Document title is required', documentUploadToastId);
        return;
      }
      dispatch(
        updateBidDocumentTitle({
          bidID: bidId,
          bidDocID: doc.bidDocId,
          documentTitle: docTitle,
          oldDocumentTitle: doc.fileName ? doc.fileName : doc.doctitle,
          docFormat: doc.docFormat,
          statusType: doc.statusType,
        }),
      );
    }
  }
  /** This is here to replace the description for a document that is pending a bid award.
   * @param {BidDocument} doc */
  function statusHack(doc: BidDocument) {
    if (
      doc.statusType === DocStatus.ApprovalPending &&
      doc.docType === DocTypeValue.AwardDocument &&
      bidExternalStatusType !== BidExternalStatusType.Awarded
    ) {
      return 'Pending Award';
    }
    return doc.status;
  }
  return (
    <>
      {doc.bidDocId ? (
        <tr key={doc.bidDocId} className='vTop'>
          <td>
            {/* Column 1 - Title */}
            {doc.docFormat !== 'PLT ' &&
            ((doc.statusType === DocStatus.Complete && canView === true) ||
              (doc.statusType === DocStatus.ApprovalPending &&
                canView === true &&
                canDelete === true)) ? (
              selectedDoc && selectedDoc.docId === doc.bidDocId && selectedDoc.isEdit ? (
                <DSTextInput
                  name='doctitle'
                  optional={false}
                  marginBottom
                  value={docTitle}
                  onChange={(value: string) => updateDocTitle(value)}
                  maxLength={100}
                ></DSTextInput>
              ) : (
                <span
                  className='staticLink underLine'
                  onClick={event => downloadDoc(event, doc.bidDocId as number)}
                >
                  {doc.doctitle}
                  {doc.docFormat && '.'}
                  {doc.docFormat && doc.docFormat.toLowerCase()}
                </span>
              )
            ) : (
              <>
                {doc.doctitle}
                {doc.docFormat ? '.' : ''}
                {doc.docFormat && doc.docFormat.toLowerCase()}
              </>
            )}
          </td>
          <td>{doc.type}</td> {/* Column 2 - Type */}
          {showStatus && (
            <td width='15%'>
              {' '}
              {/* Column 3 - Status */}
              {doc.isDocumentProgress ? (
                <>
                  Processing
                  {loadProgressBar(ActionColor.primary)}
                </>
              ) : approvedDocuments.includes(doc.bidDocId) ? (
                'Complete'
              ) : (
                statusHack(doc)
              )}
            </td>
          )}
          {showDateModified && <td>{displayDate(doc.modifiedDate || '')}</td>}{' '}
          {/* Column 3.maybe - Date Modified - TODO: refactor to a configured column set, e.g. `react-table` */}
          <td>{doc.requireConversion ? 'Yes' : 'No'}</td> {/* Column 4 - PDF Conversion */}
          <td className='text-right'>
            {/* Column 5 -  */}
            {doc.uploadprocess === 'started' ? null : (
              <>
                <div className='docControls d-flex justify-content-end'>
                  {canApprove &&
                  doc.statusType === DocStatus.ApprovalPending &&
                  !approvedDocuments.includes(doc.bidDocId) ? (
                    <ToolTip
                      text='Awarding the bid will approve this document.'
                      hideTip={
                        doc.docType !== DocTypeValue.AwardDocument ||
                        bidExternalStatusType === BidExternalStatusType.Awarded
                      }
                    >
                      {selectedDoc && selectedDoc.docId !== doc.bidDocId && (
                        <Buttons
                          text='Approve'
                          classNames='bttn-primary approve mr-2'
                          type='button'
                          onClick={() => toggleApproveDocModal(doc)}
                          disable={
                            uploadProgress ||
                            (doc.docType === DocTypeValue.AwardDocument &&
                              bidExternalStatusType !== BidExternalStatusType.Awarded)
                          }
                          data-testid='addbid.documentupload.documents.list.body.approve'
                        />
                      )}
                    </ToolTip>
                  ) : null}
                  {canDelete && doc.docType !== DocTypeValue.NB ? (
                    <>
                      {uploadProgress ? (
                        <span className='mdi mdi-delete-forever-outline mdi-24px cursorNotAllowed' />
                      ) : (
                        <>
                          {selectedDoc &&
                          selectedDoc.docId &&
                          selectedDoc.docId === doc.bidDocId &&
                          selectedDoc.isEdit ? (
                            <>
                              <Buttons
                                text='Save'
                                classNames='bttn-primary approve mr-2'
                                type='button'
                                onClick={() => onSaveDocTitle(doc)}
                                disable={uploadProgress || docTitle === doc.doctitle}
                                data-testid='addbid.documentupload.documents.list.body.save'
                              />
                              <Buttons
                                text='Cancel'
                                classNames='bttn-secondary approve mr-2'
                                type='button'
                                onClick={() => onToggleEdit(0, false)}
                                disable={uploadProgress}
                                data-testid='addbid.documentupload.documents.list.body.cancel'
                              />
                            </>
                          ) : (
                            (doc.statusType === DocStatus.Complete ||
                              doc.statusType === DocStatus.ApprovalPending) && (
                              <i
                                onClick={() => onToggleEdit(doc.bidDocId as number, true)}
                                className='mdi mdi-square-edit-outline staticLink ml-3 mdi-24px'
                                title='Edit'
                              />
                            )
                          )}
                          <span
                            className='mdi mdi-delete-forever-outline mdi-24px staticLink'
                            title='Delete'
                            onClick={() => toggleDeleteDocModal(doc)}
                            data-testid='addbid.documentupload.documents.list.body.delete'
                          />
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </>
            )}
            {uploadProgress ? ShowCircular(doc) : null}
          </td>
        </tr>
      ) : (
        <tr className='vTop'>
          <td>
            {' '}
            {/* Column 1 - Title / File */}
            <TextInput
              name='doctitle'
              required
              marginBottom
              value={doc.doctitle}
              onChange={(value: string) => updatePendingDocTitle(doc.id, value)}
              errorMsg={doc.doctitle ? '' : 'Required'}
              showError={showErrors}
              maxLength={100}
            ></TextInput>
            {doc.docfile && (doc.docfile as UploadedFile).name ? (
              <span
                className='staticLink ml-2'
                data-testid='addbid.documentupload.documents.list.body.title'
              >
                {(doc.docfile as UploadedFile).name}
                <i
                  className='mdi mdi-close-box-outline mdi-24px ml-2'
                  title='Cancel/Replace File'
                  onClick={() => addPendingDocUpload(doc.id)}
                />
              </span>
            ) : (
              <DeprecatedInput
                type='file'
                name='docfile'
                handleChange={(name: string, value: UploadedFile[]) =>
                  addPendingDocUpload(doc.id, value && value[0])
                }
                meta={{
                  touched: showErrors && !doc.docfile,
                  error: 'Required',
                }}
                label='Choose File'
              />
            )}
          </td>
          <td>
            {' '}
            {/* Column 2 - Type */}
            <SelectBox
              reactselect={true}
              label='Document Type'
              name='doctype'
              options={doctypes}
              value={doc.doctype}
              handleSelect={(name: string, value: AddBidDocTypeOption) =>
                updatePendingDocType(doc.id, value)
              }
              meta={{
                touched: showErrors && !doc.doctype,
                error: 'Required',
              }}
              parentClass='tdSelect'
              placeholder={'Select Type'}
            />
          </td>
          <td width='15%'>
            {/* Column 3 - Status */}
            {doc.isDocumentProgress ? (
              <>
                Processing
                {loadProgressBar(ActionColor.primary)}
              </>
            ) : (
              doc.status
            )}
          </td>
          <td>
            {' '}
            {/* Column 4 - PDF Conversion */}
            <CheckBox
              label=''
              name='docconversion'
              value={doc.docconversion}
              checked={doc.docconversion}
              handleChecked={(name: string, value: boolean) =>
                updatePendingDocConversion(doc.id, value)
              }
              disabled={
                doc.docext && convertableTypes.includes(doc.docext.toLocaleLowerCase())
                  ? 'false'
                  : 'true'
              }
            />
          </td>
          <td className='text-right'>
            {/* Column 5 -  */}
            {doc.uploadprocess === 'started' ? null : (
              <>
                {uploadProgress ? (
                  <span className='mdi mdi-delete-forever-outline mdi-24px cursorNotAllowed' />
                ) : (
                  <span
                    className='mdi mdi-delete-forever-outline mdi-24px staticLink'
                    title='Delete'
                    onClick={() => deletePendingDocument(doc.id, index)}
                  />
                )}
              </>
            )}
            {uploadProgress ? ShowCircular(doc) : null}
          </td>
        </tr>
      )}
      {documentTypeErrors.length ? (
        <DocumentFileTypeError
          documentTypeError={documentTypeErrors.find(docEr => docEr.row === index)}
          setFileTypeModalVisible={(status: boolean) => setFileTypeModalVisible(status)}
        />
      ) : null}
    </>
  );
};
