import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as actionCreators from '../../../store/actions';
import { Buttons } from '../../customcontrols';
import { DemandStarSupportEmail } from 'src/utils/texts/common/emails';
import { DeprecatedInput } from '../../customcontrols/input';
import { limitedbiddetailsTypes } from '../../../types/limited';
import { MemberType } from '../../../utils/constants';

import { LimitedFooter } from '../footer';
import { LimitedHeader } from '../header';

interface propsTypes extends PropsFromRedux {
  limited: limitedbiddetailsTypes;
  setLimitedDetails: any;
  submitLimitedForgotPassword: any;
  forgotpasswordmodal: boolean;
  forgotpassword: any;
  location: Location;
  history: any;
}

function PasswordRecovery(props: propsTypes) {
  const [email, setEmail] = useState('');
  const [emailerror, setEmailError] = useState('');
  const { submitLimitedForgotPassword, setLimitedDetails, limited, auth, history } = props;
  const { accountrecovery } = limited;
  const { opi = '', mt } = auth || {};
  const { reason, data } = accountrecovery;
  const { status, statusCode } = data || {};
  const recoveryEmail: string = props.location.search.replace('?email=', '');

  useEffect(() => {
    if (recoveryEmail) submitLimitedForgotPassword({ username: recoveryEmail });
  }, [recoveryEmail, submitLimitedForgotPassword]);

  const PasswordRecoveryFn = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (email) {
      if (email.length > 3) {
        submitLimitedForgotPassword({ username: email });
      } else {
        setEmailError('Enter Valid login information');
      }
    } else {
      setEmailError('Required');
    }
  };

  /*	const ResetFn = () => {
    setEmail('')
    setEmailError('')
    setLimitedDetails({
      accountrecovery: { reason: '', data: { status: false, statusCode: 0 } }
    })
  }*/

  useEffect(() => {
    return () => {
      setLimitedDetails({
        accountrecovery: { reason: '', data: { status: false, statusCode: 0 } },
      });
    };
  }, [setLimitedDetails]);

  return (
    <>
      <LimitedHeader pagefor='accountrecovery' />
      <div className='unAuthWrapper'>
        <div className='container pt-5'>
          <div className='row d-flex justify-content-center'>
            <div className='col-12 col-md-11'>
              <div className='colWrapper'>
                <div className='row'>
                  <div className='col-12'>
                    <h2 className='arrowWrapper'>Password Reset</h2>
                  </div>
                </div>
                {reason ? (
                  <div className='row d-block'>
                    <div className='col-12 '>
                      {reason === 'success' ? (
                        <>
                          {status && statusCode === 1 ? (
                            <>
                              <p>
                                If we are able to locate your login information in our database, we
                                will send an email with a password reset link.{' '}
                              </p>
                              <p>
                                Please click on that link to reset your password. The link will
                                expire in three days. Once it expires, you'll need to request a new
                                password reset link.
                              </p>
                              <p>
                                If you do not receive an email within the next ten minutes, please
                                contact {DemandStarSupportEmail}.
                              </p>
                              {opi ? (
                                <div className='d-block mt-3'>
                                  <Buttons
                                    text='Back'
                                    classNames='bttn bttn-secondary float-left'
                                    onClick={() =>
                                      history.push(
                                        `/${
                                          mt === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                                        }/accountinfo/useraccount`,
                                      )
                                    }
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : null}
                          {!status && statusCode === 2 ? (
                            <>
                              <p>Your login information is not available in the database.</p>
                              <p>Please contact {DemandStarSupportEmail}.</p>
                              {/*	<span onClick={ResetFn} className='staticLink underLine'>
																Please try another
															</span>*/}
                              {opi ? (
                                <div className='d-block mt-3'>
                                  <Buttons
                                    text='Back'
                                    classNames='bttn bttn-secondary float-left'
                                    onClick={() =>
                                      history.push(
                                        `/${
                                          mt === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                                        }/accountinfo/useraccount`,
                                      )
                                    }
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : null}
                          {!status && statusCode === 3 ? (
                            <>
                              <p>Your account is not active.</p>
                              <p>{DemandStarSupportEmail}</p>
                              {opi ? (
                                <div className='d-block mt-3'>
                                  <Buttons
                                    text='Back'
                                    classNames='bttn bttn-secondary float-left'
                                    onClick={() =>
                                      history.push(
                                        `/${
                                          mt === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                                        }/accountinfo/useraccount`,
                                      )
                                    }
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : null}
                          {!status && !statusCode ? (
                            <>
                              <p>Network error. Please try again later.</p>
                              {opi ? (
                                <div className='d-block mt-3'>
                                  <Buttons
                                    text='Back'
                                    classNames='bttn bttn-secondary float-left'
                                    onClick={() =>
                                      history.push(
                                        `/${
                                          mt === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                                        }/accountinfo/useraccount`,
                                      )
                                    }
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <p>Network error. Please try again later.</p>
                          {opi ? (
                            <div className='d-block mt-3'>
                              <Buttons
                                text='Back'
                                classNames='bttn bttn-secondary float-left'
                                onClick={() =>
                                  history.push(
                                    `/${
                                      mt === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'
                                    }/accountinfo/useraccount`,
                                  )
                                }
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className='row d-block'>
                      <div className='col-12 col-md-7'>
                        {/* <h2 className='arrowWrapper'>Password Reset</h2> */}
                        <p className='pb-2'>
                          {"Don't worry! We can help you reset your password."}
                        </p>
                        <DeprecatedInput
                          name='email'
                          type='text'
                          label='Enter your login information'
                          placeholder='Your login information here'
                          value={email}
                          handleChange={(name: string, value: string) => {
                            setEmailError('');
                            setEmail(value ? value.trim() : value);
                          }}
                          meta={{ touched: true, error: emailerror }}
                          maxLength={100}
                        />
                        <Link to='/accountrecovery' className='underLine'>
                          <b>{"I don't remember my login information"}</b>
                        </Link>
                      </div>
                    </div>
                    <div className='row mt-5 d-block'>
                      <div className='col-12 col-md-7'>
                        <Buttons
                          type='submit'
                          text='Next'
                          title='Next'
                          classNames='bttn-primary float-right'
                          onClick={PasswordRecoveryFn}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LimitedFooter />
    </>
  );
}
const connector = connect(
  (state: any) => ({
    limited: state.limited,
    auth: state.auth,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(PasswordRecovery));
