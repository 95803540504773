import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Suspenseloader from '../../common/splash/suspenseloader';

import { loginUsingCookie } from 'src/store/actions';
import { useAuth } from 'src/shared/hooks/useAuth';

export function LoginFromCookie() {
  const dispatch = useDispatch();
  const { handleLoginUsingCookie } = useAuth();

  useEffect(() => {
    dispatch(loginUsingCookie({}));
  }, [dispatch]);

  useEffect(() => {
    handleLoginUsingCookie();
  }, [handleLoginUsingCookie]);

  return <Suspenseloader />;
}
