import Configurations from 'src/settings';
import { MemberType } from './member';

/**
 * Do NOT alphabetize:
 * The order of this list should be determined by product team as it coincides with placement in the dropdown menu
 **/
export const DropDownMenuItems = [
  {
    Url: '/accountinfo/memberinfo',
    Title: 'Account',
    classNames: 'mdi mdi-account',
    showTo: ['All'],
  },
  {
    Url: '/buyers/account/certifications/list',
    Title: 'Certification Center',
    classNames: 'mdi mdi-certificate',
    showTo: [MemberType.AgencyBuyer],
  },
  {
    Url: '/buyers/account/configuration/bidtypes',
    Title: 'Bid Configuration',
    classNames: 'mdi mdi-newspaper',
    showTo: [MemberType.AgencyBuyer],
  },
  /* {
    Url: '/buyers/account/templates',
    Title: 'Legal Ads',
    classNames: 'mdi mdi-gavel',
    showTo: [MemberType.AgencyBuyer],
  }, */
  {
    Url: '/suppliers/account/subscriptions',
    Title: 'Subscriptions',
    classNames: 'mdi mdi-autorenew',
    showTo: [
      MemberType.AgencyCreatedPlanholder,
      MemberType.BasicSupplier,
      MemberType.MigratedNonSubscriber,
      MemberType.SubscribingSupplier,
      MemberType.SupplementalSupplier,
      MemberType.SuspendedSupplier,
    ],
  },
  {
    Url: '/suppliers/account/payments',
    Title: 'Payments',
    classNames: 'mdi mdi-currency-usd',
    showTo: [MemberType.SubscribingSupplier, MemberType.BasicSupplier],
  },
  {
    Url: '/suppliers/account/commoditycodes',
    Title: 'Commodity Codes',
    classNames: 'mdi mdi-chart-timeline',
    showTo: [MemberType.SubscribingSupplier, MemberType.BasicSupplier],
  },
];

export const OpsDropDownMenuItems = [
  {
    Url: `${Configurations.REACT_APP_OPS_URL}/ops/manageusers`,
    Title: 'Manage Ops User',
    classNames: 'mdi',
  },
  {
    Url: `${Configurations.REACT_APP_OPS_URL}/ops/passwordreset`,
    Title: 'Change My Password',
    classNames: 'mdi',
  },
  {
    Url: `${Configurations.REACT_APP_OPS_URL}/Account/SignOut`,
    Title: 'Logout',
    classNames: 'mdi',
  },
];

export const planMenus = ['Commodity Codes'];

export interface footerMenuType {
  headerTitle: string;
  items: Array<{
    title: string;
    link: string;
  }>;
}

export const footerMenus: Array<footerMenuType> = [
  {
    headerTitle: 'About',
    items: [
      { title: 'About', link: 'https://network.demandstar.com/about/' },
      { title: 'News', link: 'https://network.demandstar.com/news/' },
      { title: 'Blogs', link: 'https://network.demandstar.com/blog-2/' },
      { title: 'Careers', link: 'https://network.demandstar.com/careers/' },
      { title: 'Pricing', link: 'https://network.demandstar.com/pricing/' },
      { title: 'FAQ', link: 'https://network.demandstar.com/faq/' },
    ],
  },
  {
    headerTitle: 'Support',
    items: [
      { title: 'Agency Support', link: 'https://network.demandstar.com/agency-support/' },
      { title: 'Supplier Support', link: 'https://network.demandstar.com/supplier-support/' },
      { title: 'Contact Form', link: 'https://network.demandstar.com/contact-us/' },
    ],
  },
];
