import { useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import Publicationcontrol from './publicationcontrol';
import SortingIcon from '../../../common/sortingIcon';

import { Buttons, DeprecatedInput, NoResult, Paging } from '../../../customcontrols';
import { MemberType, pageSize, sortDirections } from '../../../../utils/constants';

import { AccountInfo } from '../../../../types/publications';
import { authTypes } from '../../../../types/auth';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { nargsfnTypes } from '../../../../types/functions';
import { toastFn } from '../../../../utils/helpers';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  bType: {
    width: '200px',
  },
  action: {
    width: '50px',
  },
};

interface propsTypes {
  accountinfo: AccountInfo;
  getaccountinfobidtypes: any;
  submitaccountinfobidtypesremove: any;
  submitaccountinfobidtypesadd: any;
  setSharedDetails: nargsfnTypes;
  setAccountInfoDetails?: any;
  shared: {
    internalLoader: boolean;
  };
  pristine: boolean;
  submitting: boolean;
  valid: boolean;
  submitaccountinforequiredDocsUpdate: any;
  auth: authTypes;
}
function BidTypes(props: propsTypes) {
  const {
    getaccountinfobidtypes,
    accountinfo,
    submitaccountinfobidtypesremove,
    submitaccountinfobidtypesadd,
    setSharedDetails,
    shared,
    setAccountInfoDetails,
    auth,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deletedetails, setDeletedetails] = useState<string>('');
  const { agencybidtypes, bidtypesnmodal, bidtypesType, bidtypesDesc, bidtypesdeletemodal } =
    accountinfo;
  const { internalLoader } = shared;
  const { mt } = auth;

  useEffect(() => {
    if (agencybidtypes.length === 0 && mt && mt === MemberType.AgencyBuyer) {
      setSharedDetails({ internalLoader: true });
      getaccountinfobidtypes();
    }
  }, [setSharedDetails, getaccountinfobidtypes, agencybidtypes.length, mt]);

  const listPerPage: number = pageSize.default;
  // Logic for displaying pagination
  const indexOfLastList: number = currentPage * listPerPage;
  const indexOfFirstList: number = indexOfLastList - listPerPage;

  //Logic for sort
  const [sortvalue, setSortvalue] = useState<string>('bidType');
  const [sortorder, setSortorder] = useState<string>(sortDirections.ASC);
  useEffect(() => {
    return () => {
      setSortvalue('bidType');
      setSortorder(sortDirections.ASC);
      setAccountInfoDetails({
        bidtypesnmodal: false,
        bidtypesType: '',
        bidtypesDesc: '',
      });
      setDeletedetails('');
    };
  }, [setAccountInfoDetails]);

  const AddbidtypesFn = useCallback(() => {
    setAccountInfoDetails({
      bidtypesnmodal: !bidtypesnmodal,
      bidtypesType: '',
      bidtypesDesc: '',
    });
  }, [bidtypesnmodal, setAccountInfoDetails]);

  const DeleteModalFn = (item?: any) => {
    setDeletedetails(item && item.bidType ? item : '');
    setAccountInfoDetails({ bidtypesdeletemodal: !bidtypesdeletemodal });
  };

  useSetBreadcrumb({
    component: (
      <Publicationcontrol pagefor='bidtypes' accountinfo={accountinfo} AddFn={AddbidtypesFn} />
    ),
    page: 'Agency Bid Configuration',
    altname: 'Bid Types',
    title: 'Bid Types',
    name: 'Bid Types',
    menuactive: '',
  });

  return (
    <>
      <h3 className='arrowWrapper'>Manage Bid Types</h3>
      <div className='row'>
        <div className='col-8'>
          <h2 className='lineHR'>
            Current Bid Types ({agencybidtypes.length})
            {/*{bidtypesnmodal ? null : (
							<span title='Add' onClick={AddbidtypesFn} className='float-right staticLink'>
								<i className='mdi mdi-plus' />
								Add
							</span>
						)}*/}
          </h2>
          <Table className='tableHData'>
            <tr>
              <th style={tdWidth.bType}>
                Bid Type{' '}
                {agencybidtypes.length > 0 && (
                  <SortingIcon
                    sortlist={agencybidtypes}
                    initialsortorder={sortorder}
                    currentsortname={sortvalue}
                    changesortname={(value: string) => setSortvalue(value)}
                    sortname='bidType'
                    changeSortingFn={(list: any) => setAccountInfoDetails({ agencybidtypes: list })}
                  />
                )}
              </th>
              <th>
                Bid Type Description{' '}
                {agencybidtypes.length > 0 && (
                  <SortingIcon
                    sortlist={agencybidtypes}
                    initialsortorder={sortorder}
                    currentsortname={sortvalue}
                    changesortname={(value: string) => setSortvalue(value)}
                    sortname='bidTypeDesc'
                    changeSortingFn={(list: any) => setAccountInfoDetails({ agencybidtypes: list })}
                  />
                )}
              </th>
              <th style={tdWidth.action} />
              <th style={tdWidth.action} />
            </tr>
            {bidtypesnmodal ? (
              <tr>
                <td>
                  <DeprecatedInput
                    label='Name'
                    type='text'
                    name='bidtypesType'
                    handleChange={(name: string, value: any) =>
                      setAccountInfoDetails({ bidtypesType: value })
                    }
                    value={bidtypesType}
                    placeholder='Type'
                    maxLength={4}
                  />
                </td>
                <td>
                  <DeprecatedInput
                    label='Name'
                    type='text'
                    name='bidtypesDesc'
                    handleChange={(name: string, value: any) =>
                      setAccountInfoDetails({ bidtypesDesc: value })
                    }
                    value={bidtypesDesc}
                    placeholder='Description'
                  />
                </td>
                <td width='30px'>
                  <Buttons
                    classNames='bttn-primary small mb-1'
                    text='Save'
                    title='Save'
                    type='button'
                    onClick={() => {
                      if (bidtypesType.length > 0 && bidtypesDesc.length > 0) {
                        const typeExist = agencybidtypes.filter(
                          item =>
                            item.bidType === bidtypesType || item.bidTypeDesc === bidtypesDesc,
                        );
                        if (typeExist.length > 0) {
                          toastFn('error', 'Bid Type or bid Type Description already exist');
                        } else {
                          submitaccountinfobidtypesadd();
                        }
                      }
                    }}
                    disable={!bidtypesType.trim() || !bidtypesDesc.trim()}
                  />
                  <Buttons
                    classNames='bttn-secondary small'
                    text='Cancel'
                    title='Cancel'
                    type='button'
                    onClick={AddbidtypesFn}
                  />
                </td>
                <td width='30px' />
              </tr>
            ) : null}
            {agencybidtypes.length > 0 ? (
              <>
                {agencybidtypes
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.bidType}</td>
                      <td>{item.bidTypeDesc}</td>
                      <td>
                        {bidtypesnmodal ? null : (
                          <i
                            onClick={() => DeleteModalFn({ ...item, index })}
                            className='mdi mdi-delete-forever-outline staticLink mdi-24px'
                            title='Remove'
                          />
                        )}
                      </td>
                    </tr>
                  ))
                  .slice(indexOfFirstList, indexOfLastList)}
              </>
            ) : (
              <tr>
                <td colSpan={6}>
                  <NoResult message={internalLoader ? loadingMsg : 'No Bid Types Available'} />
                </td>
              </tr>
            )}
          </Table>
          <Paging
            totalRecords={agencybidtypes.length}
            currentPage={currentPage}
            onPagingClick={(value: number | string) => {
              if (value !== currentPage) setCurrentPage(Number(value));
            }}
            pageLimit={listPerPage}
          />
          {bidtypesnmodal ? null : (
            <Buttons
              classNames='bttn-primary my-4 float-right'
              text='Add Bid Type'
              title='Add Bid Type'
              type='button'
              onClick={AddbidtypesFn}
            />
          )}
        </div>
      </div>
      <ModalPopUp
        title='Delete Confirmation'
        size='md'
        isOpen={bidtypesdeletemodal}
        closeModal={DeleteModalFn}
      >
        <>
          <p>Are you sure you want to delete?</p>
          <div className='noteImportant clearfix'>
            <p>
              <i className='mdi mdi-information staticTxt mr-1' />
              Added any bid for this type? If yes, then please kindly do not delete this type.
            </p>
          </div>
          <Buttons
            classNames='bttn-secondary mt-2'
            text='Cancel'
            title='Cancel'
            type='button'
            onClick={DeleteModalFn}
          />
          <Buttons
            classNames='bttn-primary  mt-2 float-right'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => {
              if (deletedetails) submitaccountinfobidtypesremove(deletedetails);
            }}
          />
        </>
      </ModalPopUp>
    </>
  );
}

export default BidTypes;
