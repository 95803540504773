import throttle from 'lodash/throttle';

import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';

import { Dispatch } from 'react';

import * as actionCreators from '../store/actions';
import { loadState, saveState } from '../utils/storage';
import rootReducer, { DSState } from './reducers';

import { checkActionMiddleware } from '../utils/checkActionMiddleware';
import { telemetryMiddleware } from '../utils/telemetryMiddleware';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    store: any;
  }
}

interface DSStore {
  dispatch: Dispatch<any>;
  getState: () => DSState;
  replaceReducer: (nextRootReducer: any) => void;
  subscribe: (throttle: () => void) => void;
  runSaga?: any;
  close?: () => void;
}

const configureStoreDev = (preloadedState: DSState) => {
  const sagaMiddleware = createSagaMiddleware();

  let composeEnhancers = compose;
  if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionCreators,
      shouldCatchErrors: true,
      trace: true,
    });
  }

  const store: DSStore = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, checkActionMiddleware, telemetryMiddleware)),
  );

  if (process.env.NODE_ENV === 'development') {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot?.accept('./reducers', () => {
      // TODO: TS4 - Require not part of import, I'm not changing this as I can't verify quickly
      // importing differently does not affect the code.
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { ...store };
};

const configureStoreProd = (preloadedState: DSState) => {
  const sagaMiddleware = createSagaMiddleware();

  const store: DSStore = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(sagaMiddleware, telemetryMiddleware),
  );
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return { ...store };
};

const configureStore =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

const persistedState: DSState = loadState();
export const store = configureStore(persistedState);

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
    });
  }, 1000),
);

window.store = store;
