import * as actionTypes from '../actionTypes';
import { AuthKey, MemberType } from 'src/utils/constants';
import { payloadTypes } from '../../types/actiontypedef';
import { ProductType } from 'src/types/products';

/** @warning this is different from our standard user type. */
export enum AuthUserType {
  Anonymous = 'A',
  DSUser = 'DS',
  Initial = '',
  Ops = 'O',
}

export type AuthState = {
  [AuthKey.AccountCreatedDate]: string;
  [AuthKey.AccountId]: number;
  [AuthKey.AccountLocked]: boolean;
  [AuthKey.AccountLockedMinutes]: number;
  [AuthKey.AccountPermissions]: string;
  [AuthKey.AuthUserType]: AuthUserType;
  [AuthKey.Email]: string;
  [AuthKey.FirstName]: string;
  [AuthKey.FullName]: string;
  [AuthKey.HasError]: boolean;
  [AuthKey.LastName]: string;
  [AuthKey.MainContact]: boolean;
  [AuthKey.MemberBlackListed]: boolean;
  [AuthKey.MemberCreatedDate]: string;
  [AuthKey.MemberId]: number;
  [AuthKey.MembershipLevels]?: string;
  [AuthKey.MemberStatus]: string; // TODO: convert to enum
  [AuthKey.MemberType]: MemberType | '';
  [AuthKey.Migrated]: boolean;
  [AuthKey.OpsId]: number;
  [AuthKey.PrimaryMemberId]: number;
  [AuthKey.ProductType]: ProductType | '';
  [AuthKey.Theme]: string;
  [AuthKey.Token]: string;
  [AuthKey.Username]: string;
  validatedResult?: { status: boolean; firstName: string; lastName: string };
};

export const initialStateAuth: AuthState = {
  [AuthKey.AccountCreatedDate]: '',
  [AuthKey.Migrated]: false,
  eml: '',
  fln: '',
  fn: '',
  her: false,
  lkd: false,
  lm: 0,
  ln: '',
  mbl: false,
  mc: false,
  [AuthKey.MemberCreatedDate]: '',
  mi: 0,
  ms: '',
  mt: '',
  opi: 0,
  pmid: 0,
  prms: '',
  pt: '',
  tk: '',
  tm: '',
  un: '',
  us: 0,
  ut: AuthUserType.Initial,
};

export const reducer = (state = initialStateAuth, action: payloadTypes) => {
  switch (action.type) {
    case actionTypes.LOGIN.SUCCESS:
      return { ...state, ...action.payload, her: false };
    case actionTypes.GET_REFRESH_TOKEN.SUCCESS:
      return { ...state, ...action.payload, her: false };
    case actionTypes.LOGIN_USING_COOKIE.SUCCESS:
      return { ...state, her: false };
    case actionTypes.SET_LOGIN.ACTION:
      return { ...state, ...action.payload };
    case actionTypes.LOGIN.FAILURE:
      return { ...state, tk: '', her: true };
    case actionTypes.ADD_UPDATE_USER_ACCOUNT.SUCCESS:
      return {
        ...state,
        prms:
          Number(state.us) === Number(action.payload.values.accountId)
            ? action.payload.values.selectedPermissions
            : state.prms,
        mc:
          Number(state.us) === Number(action.payload.values.accountId)
            ? action.payload.values.mainContact
            : state.mc,
      };
    case actionTypes.VALIDATE_EMAIL.SUCCESS:
      return {
        ...state,
        validatedResult: {
          status: action.payload.validatedResult.firstName ? true : false,
          ...action.payload.validatedResult,
        },
      };
    case actionTypes.SET_LOGIN_DETAILS.TRIGGER:
      return { ...state, ...action.payload };
    case actionTypes.RESET_AUTH.TRIGGER:
      return initialStateAuth;
    default:
      return state;
  }
};
