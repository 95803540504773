import {
  GetVendorSpecificDocumentsAPIResponse,
  VendorDocumentDeleteAPIRequest,
  VendorDocumentDeleteAPIResponse,
  VendorDocumentGetAPIRequest,
  VendorDocumentUploadAPIRequest,
  VendorDocumentUploadAPIResponse,
} from '../../types';

import { axiosPostAuthenticated } from './api';
import { AxiosRequestConfig } from 'axios';
import { constants } from '../../utils/settings';
import { DocumentUploadParams } from '../../types/document';
import { getFileExtension } from '@demandstar/components/utils';
import { Paths } from '../../utils/constants';
import { uploadDocument } from './upload';

/**
 * Service call to get vendor-specific award docs
 * @param {VendorDocumentGetAPIRequest}
 * @returns {GetVendorSpecificDocumentsAPIResponse}
 */
export function getVendorSpecificDocuments({
  bidId,
  supplierMemberId,
}: VendorDocumentGetAPIRequest) {
  // Switch the url and `bidId` values from a by-id call, to a general request depending on the `bidId` parameter.
  const url = bidId <= 0 ? Paths.Award.VendorSpecificDocs.all : Paths.Award.VendorSpecificDocs.get;
  const requestBidId = Math.max(bidId, 0);
  return axiosPostAuthenticated<GetVendorSpecificDocumentsAPIResponse[]>({
    baseURL: constants.api.url,
    data: {
      bidId: requestBidId,
      supplierMemberId,
    },
    url,
  });
}

/**
 *
 * @param {FileUploadRequest<VendorDocumentUploadAPIRequest>} data
 * @param {VendorDocumentUploadAPIRequest} apiParameters
 * @param {(event: ProgressEvent<EventTarget>) => void} handleUploadProgress
 * @returns {Promise<VendorDocumentUploadAPIResponse>}
 */
export function uploadVendorSpecificDocument({
  data,
  handleUploadProgress,
}: DocumentUploadParams<VendorDocumentUploadAPIRequest>) {
  const formData = new FormData();
  // File object
  formData.append('file', data.file);

  // Related request values
  formData.append('agencyDocDescription', data.agencyDocDescription || ''); // this is a number. formData.append wants a string or Blob.
  formData.append('agencyDocType', data.agencyDocType || ''); // this is a number. formData.append wants a string or Blob.
  formData.append('bidId', data.bidId.toString()); // this is a number. formData.append wants a string or Blob.
  formData.append('docFormatType', data.docFormatType ? getFileExtension(data.docFormatType) : '');
  formData.append('documentTitle', data.documentTitle || '');
  formData.append('documentType', data.documentType?.toString() || '');
  formData.append('filePath', data.originalFileName || '');
  formData.append('fileSize', data.fileSize?.toString() || '');
  formData.append('isPdfConversion', data.isPDFConversion?.toString() || 'false'); // TODO: add pdf conversion checkbox to form.
  formData.append('memberId', data.memberId?.toString() || '');
  formData.append('originalFileName', data.originalFileName || '');
  formData.append('supplierMemberId', data.supplierMemberId?.toString() || '');

  const config: AxiosRequestConfig = { onUploadProgress: handleUploadProgress };

  config.url = Paths.Award.VendorSpecificDocs.upload;

  return uploadDocument<VendorDocumentUploadAPIResponse>(formData, config);
}

/**
 * Delete vendor-specific documents
 * @param {VendorDocumentDeleteAPIRequest}
 * @returns {VendorDocumentDeleteAPIResponse}
 */
export function deleteVendorSpecificDocument({
  bidAwardDocId,
  memberId,
  opsAccountId,
}: VendorDocumentDeleteAPIRequest) {
  return axiosPostAuthenticated<VendorDocumentDeleteAPIResponse>({
    baseURL: constants.api.url,
    data: {
      bidAwardDocId,
      memberId,
      opsAccountId,
    },
    url: Paths.Award.VendorSpecificDocs.delete,
  });
}
