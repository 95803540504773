import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { DSLink } from '@demandstar/components/link';
import { DSSelect } from '@demandstar/components/inputs';
import { DSTreeNode } from '@demandstar/components/tree';
import { FlexContainer } from '@demandstar/components/styles';

import * as browseBidsConstants from './BrowseBids.constants';
import * as texts from './BrowseBids.texts';

import {
  BidListSubTitle,
  ClearFiltersLinkContainer,
  ContentWrapper,
  HeaderContainer,
  HeaderTitle,
  HeaderTitleColumn,
  IntroTextList,
  IntroTextWrapper,
  ScrollLinkContainer,
} from './BrowseBids.styles';
import { commonLabels, limitedPaths } from 'src/shared/constants';
import { scrollToCommodityCodes, scrollToGeo } from './BrowseBids.utils';

import { addUpdateMetaTag } from '../../../utils/helpers';
import { BrowseBidsList } from './BrowseBidsList';
import { CommodityCodeTree } from './CommodityCodeTree';
import { MapUSFull } from './map/MapUSFull';
import { seoPageTitleFullState } from './BrowseBids.state';
import { SignUpButton } from 'src/components/common/layout/StaticHeader.styles';
import { StateAndMSATree } from './StateAndMSATree';
import { StaticFooter } from 'src/components/common/layout/StaticFooter';
import { StaticHeader } from 'src/components/common/layout/StaticHeader';
import { useBrowseBids } from './useBrowseBids';
import { useRouter } from 'src/shared/hooks';

export function BrowseBids() {
  const {
    buildCommodityCodeLinkURI,
    buildGeoLinkURI,
    commodityCodes,
    geoCodes,
    hasSEOSearchValues,
    headerTitleIntro,
    headerTitleSubject,
    metaDescription,
    setSEOSelectedIDsByRoute,
    urlSelectedSEOIDs,
  } = useBrowseBids();

  const { commodityGroup, state, msa } = urlSelectedSEOIDs;

  const { history } = useRouter();

  const browseBidsInitialLoad = useRef(true);

  const seoPageTitleFull = useRecoilValue(seoPageTitleFullState);

  const handleRegistrationClick = () => {
    history.push('/registration');
  };

  const handleGeoSelection = useCallback(
    (option?: DSTreeNode) => {
      const path = option?.link
        ? buildGeoLinkURI(limitedPaths.browseBids, option.link, option.parentId)
        : limitedPaths.browseBids;
      history.push(path);
    },
    [buildGeoLinkURI, history],
  );

  /** TODO: Replace with useEffect hook which writes the URL based off all factors */
  const handleCCSelection = useCallback(
    (link: string) => {
      const path = link
        ? buildCommodityCodeLinkURI(limitedPaths.browseBids, link, '')
        : limitedPaths.browseBids;
      history.push(path);
    },
    [buildCommodityCodeLinkURI, history],
  );

  const geoOptions: DSTreeNode[] = useMemo(() => {
    const rawGeoOptions = [];
    if (!geoCodes?.length) return [];
    const geoCodeCount = geoCodes?.length || 0;
    for (let i = 0; i < geoCodeCount; i++) {
      const stateNode = geoCodes[i];
      // Add an option per state.
      const { id, label, link } = stateNode;
      rawGeoOptions.push({ id, label, link });
      if (stateNode.items?.length) {
        // Add a sub-option per metro area.
        for (let j = 0; j < stateNode.items.length; j++) {
          const msa = stateNode.items[j];
          rawGeoOptions.push({
            id: msa.id,
            // Indent the metro label with an emdash.
            label: ` \u2014 ${msa.label}`,
            // Add a parentId to the node value for proper state and metro routing.
            parentId: stateNode.link,
            link: msa.link,
          });
        }
      }
    }
    return rawGeoOptions;
  }, [geoCodes]);

  const selectedGeoOption = useMemo(() => {
    const link = msa || state;
    return geoOptions.find(option => {
      return option.link === link;
    });
  }, [geoOptions, msa, state]);

  /** Set selected URL ids based upon route */
  useEffect(() => {
    setSEOSelectedIDsByRoute();
  }, [setSEOSelectedIDsByRoute]);

  useEffect(() => {
    document.title = seoPageTitleFull;
    addUpdateMetaTag('og:url', document.location.href);
    addUpdateMetaTag('description', metaDescription);
    addUpdateMetaTag('keywords', 'Procurement, bid, RFP, quote, government, RFQ, RFI, bids');
    const fileRef = document.createElement('link');
    fileRef.setAttribute('rel', 'canonical');
    fileRef.setAttribute('href', document.location.href);
  }, [metaDescription, seoPageTitleFull]);

  return (
    <>
      <StaticHeader initialLoadRef={browseBidsInitialLoad} />
      {
        <>
          <HeaderContainer>
            <HeaderTitleColumn>
              <HeaderTitle id={browseBidsConstants.headerTitleElementId}>
                {headerTitleIntro}
                {headerTitleSubject}
              </HeaderTitle>
              <IntroTextWrapper>
                <IntroTextList>
                  <li>New to government work?</li>
                  <li>
                    Search awarded bids below, or see all active bids on {commonLabels.demandStar}.
                  </li>
                  <li>
                    <SignUpButton
                      onClick={handleRegistrationClick}
                      title='See All Active Bids'
                      type='primary'
                    >
                      See all active bids on {commonLabels.demandStar}
                    </SignUpButton>
                  </li>
                </IntroTextList>
              </IntroTextWrapper>
            </HeaderTitleColumn>
            <FlexContainer direction='column' rowGap>
              <MapUSFull />
              <FlexContainer>
                <FlexContainer direction='column' wrap='wrap' columnGap={false}>
                  {/* TODO: differentiate between geo / cc args when clearing */}
                  <DSSelect
                    closeMenuOnSelect={true}
                    dataTestId={'browse-bids-select-state-metro'}
                    isClearable
                    label='State or metropolitan area'
                    marginBottom={false}
                    name={'browse-bids-geo'}
                    onSelect={handleGeoSelection}
                    options={geoOptions}
                    labelField='label'
                    valueField={false}
                    keyField={'link'}
                    placeholder={texts.chooseStateMetro}
                    value={selectedGeoOption}
                  />
                  <ScrollLinkContainer
                    alignItems='flex-start'
                    columnGap
                    direction='column'
                    justifyContent='flex-start'
                  >
                    or
                    <DSLink large onClick={scrollToGeo}>
                      browse State/Metro areas
                    </DSLink>
                  </ScrollLinkContainer>
                </FlexContainer>
                <FlexContainer direction='column' wrap='wrap' columnGap={false}>
                  {/* TODO: differentiate between geo / cc args when clearing */}
                  <DSSelect
                    closeMenuOnSelect={true}
                    dataTestId={'browse-bids-select-commodity-codes'}
                    isClearable
                    label={texts.nigpCommodityGroup}
                    marginBottom={false}
                    name={'browse-bids-cc'}
                    onSelect={handleCCSelection}
                    options={commodityCodes}
                    labelField='label'
                    valueField='link'
                    placeholder={texts.chooseCommodityCode}
                    value={commodityGroup}
                  />
                  <ScrollLinkContainer
                    alignItems='flex-start'
                    columnGap
                    direction='column'
                    justifyContent='flex-start'
                  >
                    or
                    <DSLink large onClick={scrollToCommodityCodes}>
                      {`browse ${texts.nigpCommodityGroups}`}
                    </DSLink>
                  </ScrollLinkContainer>
                  {
                    // Clear any selections - TODO: differentiate between geo / cc clearing
                    hasSEOSearchValues && (
                      <ClearFiltersLinkContainer justifyContent='flex-end'>
                        <DSLink to={limitedPaths.browseBids} large>
                          {'Clear your current selections.'}
                        </DSLink>
                      </ClearFiltersLinkContainer>
                    )
                  }
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </HeaderContainer>
          <ContentWrapper>
            <BrowseBidsList />
            <StateAndMSATree />
            <CommodityCodeTree />
            <FlexContainer justifyContent='center' direction='column' rowGap alignItems='center'>
              <BidListSubTitle>
                {"Don't see any awarded bids that fit your business? "}
                <DSLink to={'/login/'}>Login</DSLink> {'or '}
                <DSLink to={'/registration/'}>Create an Account</DSLink> to see all active bids in
                your industry.
              </BidListSubTitle>
              <SignUpButton
                onClick={handleRegistrationClick}
                title='See All Active Bids'
                type='primary'
              >
                See All Active Bids
              </SignUpButton>
            </FlexContainer>
          </ContentWrapper>
        </>
      }
      <StaticFooter />
    </>
  );
}
