// TOREFACTOR
import * as H from 'history';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { memo, useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Prompt } from 'react-router-dom';

import * as actionCreators from '../../../store/actions';
import {
  allowForwardNavStatuses,
  BidExternalStatusType,
  bidNonEditableStatus,
  userPermissionKeys,
} from '../../../utils/constants';
import { Assert, hasPermission, setWizardStatus } from '../../../utils/helpers';
import { authTypes, MembershipLevel, Permission } from '../../../types/auth';
import { setAddBidDetails, setAddBidInitialData } from '../../../store/actions';
import { WizardPage, WizardStatus } from '../../../types/wizard';
import { AddBidInformation } from '../../common/createbid/add-bid-information/AddBidInformation';
import { AddBidState } from '../../../store/reducers/addbid';
import { BidSummaryResult } from '../../../types/bidssummary';
import { BidSummaryState } from '../../../store/reducers/bidssummary';
import { ConfirmModal } from 'src/components/common/modals';
import { DSState } from 'src/store/reducers';
import { getBidsTypes } from '../../../types/bids';
import { leavingAlertMessage } from '../../../utils/texts';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { sharedTypes } from '../../../types/shared';
import { WizardContainer } from '../../customcontrols/wizard/WizardContainer';

import AddSupplementalSuppliers from '../../common/createbid/modals/addsupplementalsuppliers';
import BidBroadcastList from '../../common/createbid/bidbroadcastlist';
import DocumentUpload from '../../common/createbid/DocumentUpload';
import Ebidinformation from '../../common/createbid/ebidinformation';
import LegalAds from '../../common/createbid/legalads';
import PlanBluePrint from '../../common/createbid/planblueprint';
import Prebidconference from '../../common/createbid/prebidconference';
import { Publications } from '../../common/createbid/publications';
import SupplementalSuppliers from '../../common/createbid/supplementalsuppliers';
import SupplierBulkUpload from '../../common/createbid/supplierbulkupload';
import { MemberInfoState } from 'src/store/reducers/memberinfo';

interface AddBidProps extends PropsFromRedux {
  addbid: AddBidState;
  auth: authTypes;
  bidssummary: BidSummaryState;
  form: any;
  getAddBidBidStatus: any;
  getAddBidBidTypes: any;
  getAddBidBidWriters: any;
  getAddBidIsConstruction: any;
  getBids: getBidsTypes;
  history: H.History;
  match: any;
  resetCommodityCodes: any;
  shared: sharedTypes;
  memberinfo: MemberInfoState;
}

function AddBidComponent(props: AddBidProps) {
  const dispatch = useDispatch();
  const {
    addbid,
    auth,
    bidssummary,
    getAddBidBidStatus,
    getAddBidBidTypes,
    getAddBidBidWriters,
    getAddBidIsConstruction,
    getBids,
    history,
    match,
    resetCommodityCodes,
    shared,
    memberinfo,
  } = props;
  const { form, ...otherprops } = props;
  const {
    addbidinfo,
    addbidwizard = [],
    addbidwizardpartial,
    addsupplementalmodal,
    addsupplementalvalues,
    addSupplierBulkUploadModal,
    bidId = '',
    bidtypes = [],
    bidwriters = [],
    currentScreen = 1,
    ebidinfo,
    halfWayCancel,
    halfWayCancelAllow,
    isExistsSupplier,
    legalads,
    prebidconference,
  } = addbid || {};
  const { path, params } = match || {};
  const currentWizard = addbidwizard.find(
    page => page.status === WizardStatus.Current,
  ) as WizardPage;
  Assert(currentWizard, 'We must always have a current wizard', 'AddBid.tsx');
  const { mi = '', ml = '', prms = '', opi = '' } = auth || {};
  const { results = {} } = bidssummary || {};
  const {
    bidExternalStatusType: bEST = BidExternalStatusType.None,
    bidID,
    bidName = '',
    memberID = '',
  } = results as BidSummaryResult;
  const editBidId = parseInt(params?.bidId);
  const { fiscalYear = [] } = shared || {};
  const {
    addbidbidinforeduxForm,
    addbidlegalreduxForm,
    addbidprebidreduxForm,
    addbidebidreduxForm,
  } = form || {};
  const mlcheck = ml.split(',') as MembershipLevel[];
  const prmscheck = prms.split(',') as Permission[];
  const { isBonfireAgency } = memberinfo;
  let checkedit = path?.split('/') || [];
  checkedit = checkedit.find((item: string) => item === 'edit') || '';
  /**
   * TODO: This edit string from the URL should probably be type-safe from a route type config,
   * for re-use in other add/edit scenarios.
   * Though, it should also probably be declared as a URL param from React.Router
   * rather than as a prop, so this nit is better as a refactor note. - Dave */

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const toggleConfirmModalVisible = useCallback(() => {
    setConfirmModalVisible(x => !x);
  }, []);
  const [nextLocation, setNextLocation] = useState<H.Location<unknown> | ''>('');
  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;
  const hasManageBidsPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  useEffect(() => {
    if (checkedit === 'edit' && hasManageBidsPermission === false) {
      history.push(`buyers/bids/${bidId}/details`);
    }
  });

  useEffect(() => {
    if (editBidId && results && mi && memberID && bEST) {
      if (mi !== memberID || bidNonEditableStatus.includes(bEST)) {
        if (!opi) history.replace('/');
      }
    }
  }, [editBidId, bEST, history, memberID, mi, opi, results]);

  useEffect(() => {
    if (
      (!mlcheck.includes(MembershipLevel.BidPoster) &&
        !prmscheck.includes(Permission.ManageBids)) ||
      isBonfireAgency
    ) {
      if (!opi || isBonfireAgency) history.replace('/');
    }
  }, [history, mlcheck, opi, prmscheck, isBonfireAgency]);

  useEffect(() => {
    if (addbidbidinforeduxForm && addbidbidinforeduxForm.initial && addbidbidinforeduxForm.values) {
      if (addbidbidinforeduxForm.initial.dueDate !== addbidbidinforeduxForm.values.dueDate) {
        if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
      }
    }
  }, [addbidbidinforeduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (addbidbidinforeduxForm && addbidbidinforeduxForm.initial && addbidbidinforeduxForm.values) {
      if (
        addbidbidinforeduxForm.initial.broadcastDate !== addbidbidinforeduxForm.values.broadcastDate
      ) {
        if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
      }
    }
  }, [addbidbidinforeduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (addbidbidinforeduxForm && addbidbidinforeduxForm.anyTouched) {
      if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
    }
  }, [addbidbidinforeduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (addbidlegalreduxForm && addbidlegalreduxForm.anyTouched) {
      if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
    }
  }, [addbidlegalreduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (addbidprebidreduxForm && addbidprebidreduxForm.anyTouched) {
      if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
    }
  }, [addbidprebidreduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (addbidebidreduxForm && addbidebidreduxForm.anyTouched) {
      if (!halfWayCancel) dispatch(setAddBidDetails({ halfWayCancel: true }));
    }
  }, [addbidebidreduxForm, dispatch, halfWayCancel]);

  useEffect(() => {
    if (bidtypes.length === 0) getAddBidBidTypes();
    if (bidwriters.length === 0) getAddBidBidWriters();
  }, [
    getAddBidBidTypes,
    getAddBidBidWriters,
    fiscalYear.length,
    bidtypes.length,
    bidwriters.length,
  ]);

  useEffect(() => {
    if (editBidId) {
      dispatch(setAddBidInitialData({ bidId: editBidId }));
    }
  }, [editBidId, dispatch]);

  useEffect(() => {
    if (editBidId) getAddBidBidStatus({ bidId: editBidId });
  }, [editBidId, getAddBidBidStatus]);

  useEffect(() => {
    if (editBidId) getAddBidIsConstruction({ bidId: editBidId, broadcastlistnextbtn: false });
  }, [editBidId, getAddBidIsConstruction]);

  useEffect(() => {
    if (!editBidId) {
      dispatch(setAddBidDetails({ ResetState: true }));
    }
  }, [dispatch, editBidId]);

  useEffect(() => {
    return () => {
      if (path === ('/buyers/bid/creation' || '/buyers/bids/:bidId/creation')) {
        getBids();
      }
    };
  }, [getBids, path]);

  useEffect(() => {
    return () => {
      dispatch(
        setAddBidDetails({
          addbidwizard: [
            { id: 1, name: 'Bid Information', status: WizardStatus.Current },
            { id: 2, name: 'Legal Ads', status: WizardStatus.Unavailable },
            { id: 3, name: 'Pre-Bid Conference', status: WizardStatus.Unavailable },
            { id: 4, name: 'Publications', status: WizardStatus.Unavailable },
            { id: 5, name: 'Build Broadcast List', status: WizardStatus.Unavailable },
            { id: 6, name: 'Supplemental Suppliers', status: WizardStatus.Unavailable },
            { id: 7, name: 'Manage Documents', status: WizardStatus.Unavailable },
          ],
          addbidwizardpartial: false,
          halfWayCancel: false,
          halfWayCancelAllow: false,
          upcomingBidPageFor: '',
          editable: null,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetCommodityCodes();
    };
  }, [resetCommodityCodes]);

  useEffect(() => {
    return () => {
      dispatch(setAddBidDetails({ ResetState: true }));
    };
  }, [dispatch]);

  const SetAddSupplemental = () => {
    dispatch(
      setAddBidDetails({
        addsupplementalmodal: !addsupplementalmodal,
        isExistsSupplier: '',
        existssupplierlist: [],
      }),
    );
  };

  const setSupplierBulkUpload = () => {
    dispatch(
      setAddBidDetails({
        addSupplierBulkUploadModal: !addSupplierBulkUploadModal,
      }),
    );
  };

  const cancel = useCallback(() => {
    if (editBidId) {
      history.push(`/buyers/bids/${editBidId}`);
    } else {
      history.goBack();
    }
  }, [editBidId, history]);

  const SkipNext = useCallback(
    (typefor = '') => {
      if (typefor === 'partial' || addbidwizardpartial) {
        history.goBack();
      } else {
        dispatch(
          setAddBidDetails({ addbidwizard: setWizardStatus(addbidwizard, currentWizard.id + 1) }),
        );
      }
    },
    [addbidwizard, addbidwizardpartial, currentWizard.id, dispatch, history],
  );

  const bidIds = Number(editBidId) || Number(bidId) || Number(bidID);

  const currentBidName = checkedit === 'edit' ? bidName : '';

  // components used in the page
  // FIXME: Avoid spread operators below.
  const component = {
    'Bid Information': (
      <AddBidInformation
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        initialValues={addbidinfo}
        bidId={bidIds}
        addbidbidinforeduxForm={form && form.addbidbidinforeduxForm}
        currentBidName={currentBidName}
      />
    ),
    'eBidding Info': (
      <Ebidinformation
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        initialValues={ebidinfo}
        bidId={bidIds}
        currentBidName={currentBidName}
        addbidebidreduxForm={addbidebidreduxForm}
      />
    ),
    'Legal Ads': (
      <LegalAds
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        bidId={bidIds}
        initialValues={legalads}
        currentBidName={currentBidName}
      />
    ),
    'Pre-Bid Conference': (
      <Prebidconference
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        bidId={bidIds}
        initialValues={prebidconference}
        currentBidName={currentBidName}
      />
    ),
    Publications: (
      <Publications
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        currentBidName={currentBidName}
        bidId={bidIds}
      />
    ),
    'Build Broadcast List': (
      <BidBroadcastList
        {...otherprops}
        currentwizard={currentWizard}
        cancel={cancel}
        SkipNext={SkipNext}
        currentBidName={currentBidName}
        bidId={bidIds}
      />
    ),
    'Supplemental Suppliers': (
      <SupplementalSuppliers
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        bidId={bidIds}
        SetAddSupplemental={SetAddSupplemental}
        setSupplierBulkUpload={setSupplierBulkUpload}
        currentBidName={currentBidName}
      />
    ),
    'Plan/Blueprint Information': (
      <PlanBluePrint
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        bidId={bidIds}
        currentBidName={currentBidName}
      />
    ),
    'Manage Documents': (
      <DocumentUpload
        {...otherprops}
        currentwizard={currentWizard}
        SkipNext={SkipNext}
        bidId={bidIds}
        currentBidName={currentBidName}
      />
    ),
  };

  // fn to find which page to display currently based on redux value (status in wizard array)
  const [currentPageName] = addbidwizard
    .filter(page => page.status === 'current')
    .map(page => page.name);

  const currentComponent = component[currentPageName as keyof typeof component];

  const wizardclickFn = (id: number) => {
    dispatch(setAddBidDetails({ addbidwizard: setWizardStatus(addbidwizard, id) }));
  };
  const handleBlockedNavigation = (nextLocation: H.Location<unknown>) => {
    if (halfWayCancelAllow || confirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };

  return (
    <>
      <Prompt when={halfWayCancel} message={handleBlockedNavigation} />
      <WizardContainer
        navTitle='Bid Creation Progress'
        wizard={addbidwizard}
        currentWizard={currentWizard}
        navigateTo={wizardclickFn}
        allowForwardNav={allowForwardNavStatuses.includes(bEST)}
        testId='addbid.main.page.header'
        header={checkedit === 'edit' ? 'Update Bid' : 'Add Bid'}
      >
        {currentComponent}
      </WizardContainer>
      <ModalPopUp
        size={isExistsSupplier ? 'xl' : 'md'}
        title='Add Supplemental Suppliers'
        closeModal={SetAddSupplemental}
        isOpen={addsupplementalmodal}
        backdrop='static'
      >
        <AddSupplementalSuppliers
          {...otherprops}
          initialValues={addsupplementalvalues}
          bidId={bidIds}
        />
      </ModalPopUp>
      <ModalPopUp
        size={'lg'}
        title={currentScreen === 3 ? 'Review List' : 'Upload List of Suppliers'}
        closeModal={setSupplierBulkUpload}
        isOpen={addSupplierBulkUploadModal}
        backdrop='static'
      >
        <SupplierBulkUpload {...props} />
      </ModalPopUp>
      <ConfirmModal
        title='Save Confirmation'
        size='md'
        isOpen={confirmModalVisible}
        closeModal={toggleConfirmModalVisible}
        onConfirm={discardConfirmationPopup}
        cancelText='Stay on this page'
        confirmText='Leave without saving'
        dataTestId='save.confirmation'
      >
        {leavingAlertMessage}
      </ConfirmModal>
    </>
  );
}

const connector = connect(
  (state: DSState) => ({
    addbid: state.addbid,
    commoditycodes: state.commoditycodes,
    bidssummary: state.bidssummary,
    auth: state.auth,
    shared: state.shared,
    memberinfo: state.memberinfo,
    accountinfo: state.accountinfo,
    tours: state.tours,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const AddBid = connector(memo(AddBidComponent));
