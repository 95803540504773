import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';

import * as actionTypes from '../actionTypes';
import { reducer as accountinfo, AccountInfoState } from './accountinfo';
import { reducer as addbid, AddBidState } from './addbid';
import { reducer as agencyregistration, AgencyRegistrationState } from './agencyregistration';
import { reducer as auth, AuthState } from './auth';
import { reducer as bidssummary, BidSummaryState } from './bidssummary';
import { reducer as ebids, EBidsState } from './ebids';
import { reducer as loader, LoaderState } from './loader';
import { reducer as memberinfo, MemberInfoState } from './memberinfo';
import { reducer as shared, SharedState } from './shared';
import { reducer as tours, ToursState } from './tours';
import { reducer as activities } from './activities';
import { reducer as addquotes } from './addquotes';
import { reducer as bids } from './bids';
import { reducer as breadcrumb } from './breadcrumb';
import { reducer as broadcastList } from './broadcastlists';
import { reducer as commoditycodes } from './commoditycodes';
import { reducer as ebidCampaign } from './ebidcampaign';
import { reducer as internalLoader } from './internalLoader';
import { reducer as limited } from './limited';
import { reducer as payment } from './payment';
import { reducer as programs } from './programs';
import { reducer as quotes } from './quotes';
import { reducer as quoteSummary } from './quoteSummary';
import { reducer as registration } from './registration';
import { reducer as subscriptions } from './subscriptions';

import { SubscriptionDetails } from '../../types/subscriptions';

export interface DSState {
  accountinfo: AccountInfoState;
  activities: any;
  addbid: AddBidState;
  addquotes: any; // TOREFACTOR: Type this. (Or move everything to Recoil)
  agencyregistration: AgencyRegistrationState;
  auth: AuthState;
  bids: any;
  bidssummary: BidSummaryState;
  breadcrumb: any; // TOREFACTOR: Type this. (Or move everything to Recoil)
  broadcastList: any;
  commoditycodes: any;
  ebidCampaign: any;
  ebids: EBidsState;
  internalLoader: any;
  limited: any; // TOREFACTOR: Type this. (Or move everything to Recoil)
  loader: LoaderState;
  memberinfo: MemberInfoState;
  payment: any;
  programs: any;
  quoteSummary: any;
  quotes: any;
  shared: SharedState;
  tours: ToursState;
  subscriptions: SubscriptionDetails;
  registration: any; // TOREFACTOR: Type this. (Or move everything to Recoil)
}

/**
 * useSelector for Redux, specifically typed for DemandStar
 * @example const documentList = useDSSelector(state => state.addbid.documentslist);
 * */
export const useDSSelector: TypedUseSelectorHook<DSState> = useSelector;

const appReducer = combineReducers({
  accountinfo,
  activities,
  addbid,
  addquotes,
  agencyregistration,
  auth,
  bids,
  bidssummary,
  breadcrumb,
  broadcastList,
  commoditycodes,
  ebidCampaign,
  ebids,
  internalLoader,
  limited,
  loader,
  memberinfo,
  payment,
  programs,
  quoteSummary,
  quotes,
  shared,
  tours,
  subscriptions,
  registration,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === actionTypes.CLEAR_APP_STATE.ACTION) {
    localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
