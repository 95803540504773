import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  activitiesInitialValue,
  activitiesNotificationsInitialValue,
} from '../../../store/reducers/activities';
import { activitiesTypes, authTypes } from '../../../types';
import { Buttons, DeprecatedInput, SelectBox } from '../../customcontrols';
import { getActivitiesUsers } from '../../../store/actions';
import { MemberType } from '../../../utils/constants';

const activitiesOptions = [
  { label: 'Title', value: 'title' },
  { label: 'ID', value: 'id' },
];
const eBidResponseOptions = [
  { label: 'Title', value: 'title' },
  { label: 'ID', value: 'id' },
  { label: 'Supplier', value: 'supplier' },
];
const initialstate = {
  search: '',
  activitiesSearch: false,
  searchSelect: { label: 'Title', value: 'title' },
  search_internalLoader: true,
  activities_notifications_search: {
    page: 1,
    result: [],
    accounts: [],
    size: 0,
    total: 0,
    bidsCount: 0,
    accountCount: 0,
  },
  activities_watchs_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_planholders_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_ebidders_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_quotes_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  activities_users_search: {
    page: 1,
    result: [],
    size: 0,
    total: 0,
  },
  //notificationStatus: { label: 'All', value: '' }
};

interface propsTypes {
  pagefor: string;
  includeSupplierOption?: boolean;
  activities: activitiesTypes;
  auth: authTypes;
  setActivitiesDetails: any;
  getActivitiesSearch: any;
  getActivitiesNotifications: any;
  getActivitiesNotificationsMember: any;
  getActivitiesWatchs: any;
  getActivitiesPlanholders: any;
  getActivitiesEbidders: any;
  getActivitiesQuotes: any;
  setCurrentPages: any;
  canSelectCategory?: boolean;
}

function ActivitiesSearch(props: propsTypes) {
  const dispatch = useDispatch();

  const {
    pagefor,
    activities,
    setActivitiesDetails,
    getActivitiesSearch,
    auth,
    getActivitiesNotifications,
    getActivitiesNotificationsMember,
    getActivitiesWatchs,
    getActivitiesPlanholders,
    getActivitiesEbidders,
    getActivitiesQuotes,
    setCurrentPages,
    includeSupplierOption = false,
    canSelectCategory = true,
  } = props;
  const {
    searchSelect,
    search,
    notificationStatus,
    bitWatchStatus,
    planholderStatus,
    ebidproposalStatus,
    quotesStatus,
    userStatus,
  } = activities;
  const { mt } = auth;

  const onInputChange = (name: any, value: any) => {
    setActivitiesDetails({ search: value });
  };

  const onSelectChange = (name: any, value: any) => {
    setActivitiesDetails({ searchSelect: value });
  };

  const onSearchFn = () => {
    setActivitiesDetails({ activitiesSearch: true, search_internalLoader: true });
    setCurrentPages(1);
    const final = {
      page: 1,
      size: 100,
      pagination: false,
      pagefor,
      search,
      searchSelect,
      notificationStatus,
      bitWatchStatus,
      planholderStatus,
      ebidproposalStatus,
      quotesStatus,
      userStatus,
      type: '',
    };
    switch (pagefor) {
      case 'notificationsbid':
        final.type = 'notification';
        break;
      case 'notificationsaccount':
        final.type = 'notification';
        break;
      case 'bidwatch':
        final.type = 'watch';
        break;
      case 'planholders':
        final.type = 'planholder';
        break;
      case 'ebidresponse':
        final.type = 'bidder';
        break;
      case 'quotes':
        final.type = 'quote';
        break;
      case 'users':
        final.type = 'user';
        break;
      default:
    }
    getActivitiesSearch(final);
  };

  const onClearFn = () => {
    setActivitiesDetails(initialstate);
    setCurrentPages(1);
    switch (pagefor) {
      case 'notificationsbid':
        setActivitiesDetails({
          notifications_internalLoader: true,
          search_internalLoader: true,
          activities_notifications: activitiesNotificationsInitialValue,
          activities_notifications_search: activitiesNotificationsInitialValue,
        });
        getActivitiesNotifications({ page: 1, size: 100, pagination: false, notificationStatus });
        break;
      case 'notificationsaccount':
        setActivitiesDetails({
          notifications_internalLoader: true,
          activities_notifications: activitiesNotificationsInitialValue,
        });
        getActivitiesNotificationsMember({ page: 1, size: 500, pagination: false });
        break;
      case 'bidwatch':
        setActivitiesDetails({
          watchs_internalLoader: true,
          activities_watchs: activitiesInitialValue,
          search_internalLoader: true,
          activities_watchs_search: activitiesInitialValue,
        });
        getActivitiesWatchs({ page: 1, size: 100, pagination: false, bitWatchStatus });
        break;
      case 'planholders':
        setActivitiesDetails({
          planholders_internalLoader: true,
          search_internalLoader: true,
          activities_planholders: activitiesInitialValue,
          activities_planholders_search: activitiesInitialValue,
        });
        getActivitiesPlanholders({ page: 1, size: 100, pagination: false, planholderStatus });
        break;
      case 'ebidresponse':
        setActivitiesDetails({
          ebidders_internalLoader: true,
          search_internalLoader: true,
          activities_ebidders: activitiesInitialValue,
          activities_ebidders_search: activitiesInitialValue,
        });
        getActivitiesEbidders({ page: 1, size: 100, pagination: false, ebidproposalStatus });
        break;
      case 'quotes':
        setActivitiesDetails({
          quotes_internalLoader: true,
          search_internalLoader: true,
          activities_quotes: activitiesInitialValue,
          activities_quotes_search: activitiesInitialValue,
        });
        getActivitiesQuotes({ page: 1, size: 100, pagination: false, quotesStatus });
        break;
      case 'users':
        setActivitiesDetails({
          users_internalLoader: true,
          search_internalLoader: true,
          activities_users: activitiesInitialValue,
          activities_users_search: activitiesInitialValue,
        });
        dispatch(getActivitiesUsers({ page: 1, size: 100, pagination: false, userStatus }));
        break;
      default:
    }
  };

  useEffect(() => {
    return () => {
      setActivitiesDetails(initialstate);
    };
  }, [setActivitiesDetails]);

  return (
    <>
      {mt === MemberType.AgencyBuyer && (
        <div className='col-lg-12'>
          <div className='clearfix d-flex align-items-center py-3 activitiesFilter'>
            {canSelectCategory && (
              <SelectBox
                reactselect={true}
                label='Category'
                name='searchSelect'
                options={includeSupplierOption ? eBidResponseOptions : activitiesOptions}
                handleSelect={onSelectChange}
                value={searchSelect}
              />
            )}
            <DeprecatedInput
              type='search'
              placeholder='Search Text'
              name='searchWatch'
              label='Search Text'
              value={search}
              handleChange={onInputChange}
            />
            <Buttons
              classNames='bttn-primary'
              text='Search'
              title='Search'
              type='button'
              disable={!search}
              onClick={onSearchFn}
            />
            <Buttons
              classNames='bttn-secondary'
              text='Clear'
              title='Clear'
              type='button'
              onClick={onClearFn}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ActivitiesSearch;
