import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';

import * as actionTypes from '../actionTypes';
import * as Api from '../services';
import {
  AgencyMemberId,
  AgencyMemberName,
  CookieredirectUrl,
  limitedloginPreviousUrlName,
  MemberType,
  themetokenName,
  tokenCookieName,
} from '../../utils/constants';
import {
  Assert,
  clearStorageFn,
  clearStorageLoginFn,
  deleteAspCookies,
  deprecatedGetDate,
  getLoginRedirect,
  logOutFn,
  toastFn,
} from '../../utils/helpers';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookie';
import { AuthToken } from 'src/utils/token';
import { defaultFilter } from '../reducers/bids';
import { LegacyPayload } from '../../types/auth';
import { ProductType } from 'src/types/products';
import { trackAmplitudeUserAttributes } from '../../utils/telemetry';

import Configurations from '../../settings';
import history from '../../utils/history';

export function* watchLoginAsync() {
  yield takeLatest(actionTypes.LOGIN.TRIGGER, loginAsync);
}

function getTokenData(token: string) {
  const tokenData = jwt.decode(token) as AuthToken;
  Assert(tokenData, 'We should not get a null value when decoding tokenData', 'sagas/auth.ts');
  const fullName = tokenData.fn + ' ' + tokenData.ln;
  const {
    acd = '',
    eml,
    fn,
    her,
    lkd,
    lm,
    ln,
    mbl,
    mc,
    mcd = '',
    mgrtd,
    mi,
    ml,
    ms,
    mt,
    opi = 0,
    pmid,
    prms,
    tm = '',
    un,
    us,
    ut,
  } = tokenData;

  return {
    acd,
    eml,
    fln: fullName,
    fn,
    her,
    lkd: Boolean(lkd === 'True'), //FIXME: temporary solution
    lm: Number(lm),
    ln,
    mbl: Boolean(mbl === 'True'), //FIXME: temporary solution
    mc: Boolean(mc === 'True'), //FIXME: temporary solution
    mcd,
    mgrtd: Boolean(mgrtd === 'True'), //FIXME: temporary solution
    mi: Number(mi),
    ml,
    ms,
    mt,
    opi: isNaN(Number(opi)) ? 0 : Number(opi),
    pmid: Number(pmid),
    prms,
    tk: token,
    tm,
    un,
    us: Number(us),
    ut,
  };
}

function* loginAsync(action: { payload: LegacyPayload }): unknown {
  yield put({
    type: actionTypes.LOGIN.REQUEST,
    meta: { UserName: action.payload.userName },
  });
  try {
    const {
      userName = '',
      password = '',
      pagefor = '',
      orderBidPackage = false,
      pageBid = '',
    } = action.payload;
    const data = {
      Expiration: 24,
      IsAnonymous: false,
      UserName: userName,
      Password: password,
    };
    const response = yield call(Api.getAuthToken, data);
    const token = response.data.token;
    if (token && token.length > 0) {
      const payload = getTokenData(token);
      yield ResetAllStates();

      if (payload.mt === MemberType.ContentExtractor) {
        toastFn('error', 'Unauthorized', 'loginunauthorized');
        yield put({
          type: actionTypes.LOGIN.FAILURE,
          meta: { UserName: action.payload.userName },
        });
      } else {
        if (!payload.her && pagefor === 'popup' && orderBidPackage === true) {
          sessionStorage.setItem('orderBidPackage', 'checkDownload');
        } else if (pagefor === 'popup' && pageBid) {
          sessionStorage.setItem(limitedloginPreviousUrlName, `/bids/${pageBid}/details`);
        }

        if (payload && payload.tm) {
          yield put({
            type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
            payload: { currentDSTheme: payload.tm, loadthemes: false },
          });
          localStorage.setItem(themetokenName, payload.tm);
        }
        setCookie('MEMBERID', payload.mi, 1);
        setCookie('DemandStarToken', token, 1);
        sessionStorage.removeItem('isloggingout');
        yield put({ type: actionTypes.LOGIN.SUCCESS, payload, meta: payload });

        const redirectURL = getCookie(CookieredirectUrl);

        if (redirectURL) {
          const agencyId = getCookie(AgencyMemberId);
          if (agencyId && payload.mt !== MemberType.AgencyBuyer) {
            deleteCookie(AgencyMemberId);
            deleteCookie(AgencyMemberName);
            const bidpayload = {
              filters: {
                ...defaultFilter,
                agencyMemberId: parseInt(agencyId),
                agencyText: '',
                showBids: 'All',
              },
              initialRequest: false,
              selectedBidIds: '',
              loadedBids: [],
              results: [],
            };
            yield put({
              type: actionTypes.SET_BID_DETAILS.TRIGGER,
              payload: bidpayload,
            });
            yield delay(1500);
            yield put({ type: actionTypes.LOAD_BID_RESULT.TRIGGER });
            yield delay(1000);
            deleteCookie(AgencyMemberId);
            deleteCookie(AgencyMemberName);
            history.replace(redirectURL);
          } else {
            history.replace(redirectURL);
          }
          deleteCookie(CookieredirectUrl);
        } else history.replace(getLoginRedirect(payload));
      }
    } else if (response.data.isBidVisitExceeded) {
      history.replace(response.data.url);
    } else {
      yield put({
        type: actionTypes.LOGIN.FAILURE,
        meta: { UserName: action.payload.userName },
      });
    }
  } catch {
    yield put({
      type: actionTypes.LOGIN.FAILURE,
      meta: { UserName: action.payload.userName },
    });
  }
}

export function* watchloginUsingCookie() {
  yield takeLatest(actionTypes.LOGIN_USING_COOKIE.TRIGGER, loginUsingCookie);
}

function* loginUsingCookie(action: { payload: { userName: string } }) {
  yield put({ type: actionTypes.LOGIN_USING_COOKIE.REQUEST, meta: {} });
  try {
    deleteAspCookies();
    clearStorageLoginFn();
    const token = getCookie(tokenCookieName);
    if (token && token.length > 0) {
      yield ResetAllStates();
      const payload = getTokenData(token);
      yield put({ type: actionTypes.SET_LOGIN.ACTION, payload });
      if (payload && (payload.opi || !payload.mt)) {
        yield clearStorageFn();
        yield delay(3000);
      }
      if (payload && payload.tm) {
        yield put({
          type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
          payload: { currentDSTheme: payload.tm, loadthemes: false },
        });
        localStorage.setItem(themetokenName, payload.tm);
      }
      setCookie('MEMBERID', payload.mi, 1);
      yield put({
        type: actionTypes.LOGIN_USING_COOKIE.SUCCESS,
        payload,
        meta: payload,
      });
      const redirectURL = getCookie(CookieredirectUrl);

      if (redirectURL) {
        //redirectURL = redirectURL.replace("https://qa.demandstar.com/app", "");

        const agencyId = getCookie(AgencyMemberId);
        // let agencyName = getCookie(AgencyMemberName).split('+').join(' ')
        if (agencyId && payload.mt !== MemberType.AgencyBuyer) {
          deleteCookie(AgencyMemberId);
          deleteCookie(AgencyMemberName);
          const bidpayload = {
            filters: {
              ...defaultFilter,
              agencyMemberId: parseInt(agencyId),
              agencyText: '',
              showBids: 'All',
            },
            initialRequest: false,
            selectedBidIds: '',
            loadedBids: [],
            results: [],
          };
          yield put({
            type: actionTypes.SET_BID_DETAILS.TRIGGER,
            payload: bidpayload,
          });
          yield delay(1500);
          yield put({ type: actionTypes.LOAD_BID_RESULT.TRIGGER });
          yield delay(1000);
          deleteCookie(AgencyMemberId);
          deleteCookie(AgencyMemberName);
          history.replace(redirectURL);
        } else {
          history.replace(redirectURL);
        }
        deleteCookie(CookieredirectUrl);
      } else history.replace(getLoginRedirect(payload));
    } else {
      yield put({
        type: actionTypes.LOGIN_USING_COOKIE.FAILURE,
        meta: { UserName: action.payload ? action.payload.userName : '' },
      });
      window.location.href = Configurations.REACT_APP_REDIRECT_HOME_URL;
    }
  } catch (error) {
    yield put({
      type: actionTypes.LOGIN_USING_COOKIE.FAILURE,
      meta: {
        UserName: action.payload ? action.payload.userName : '',
        Error,
      },
    });
  }
}
export function* watchgetRefreshToken() {
  yield takeLatest(actionTypes.GET_REFRESH_TOKEN.TRIGGER, getRefreshToken);
}

function* getRefreshToken(action: { payload: { userName?: string; type: string } }): unknown {
  yield put({
    type: actionTypes.GET_REFRESH_TOKEN.REQUEST,
    meta: action.payload,
  });
  try {
    const { type = 'refresh' } = action.payload || {};
    const response = yield call(Api.getRefreshToken);
    const token = response.data.token;
    const payload = getTokenData(token);
    yield put({
      type: actionTypes.GET_REFRESH_TOKEN.SUCCESS,
      payload,
      meta: payload,
    });
    if (type === 'commodityEdit') {
      if (payload.mt === MemberType.BasicSupplier) {
        history.replace('/');
      }
    }
  } catch {
    yield put({
      type: actionTypes.GET_REFRESH_TOKEN.FAILURE,
      meta: { UserName: action.payload.userName },
    });
  }
}

export function* watchLogoutAsync() {
  yield takeLatest(actionTypes.LOGOUT.TRIGGER, logOutAsync);
}

function* logOutAsync(action: { payload: any }) {
  yield put({ type: actionTypes.LOGOUT.REQUEST, meta: action.payload });
  try {
    yield logOutFn();
    yield ResetAllStates();
    yield put({ type: actionTypes.LOGOUT.SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.LOGOUT.FAILURE, payload: { payload: action.payload, error } });
  }
}

function* watchValidateEmailAsync() {
  yield takeLatest(actionTypes.VALIDATE_EMAIL.TRIGGER, validateEmailAsync);
}

function* validateEmailAsync(action: { payload: any }): unknown {
  yield put({ type: actionTypes.VALIDATE_EMAIL.REQUEST, meta: action.payload });
  try {
    const response = yield call(Api.validateEmail, action.payload);
    const payload = { validatedResult: response.data.result };
    yield put({ type: actionTypes.VALIDATE_EMAIL.SUCCESS, payload });
  } catch (error) {
    yield put({
      type: actionTypes.VALIDATE_EMAIL.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* watchTrackAmplitudeUserActions() {
  yield takeLatest(actionTypes.TRACK_AMPLITUDE_USER_ACTION.TRIGGER, trackAmplitudeUserActions);
}

function* trackAmplitudeUserActions(action: { payload: any }): unknown {
  yield put({ type: actionTypes.TRACK_AMPLITUDE_USER_ACTION.REQUEST, meta: action.payload });
  try {
    const auth = yield select(state => state.auth);
    const shared = yield select(state => state.shared);
    const accountinfo = yield select(state => state.accountinfo);
    const memberinfo = yield select(state => state.memberinfo);
    const {
      accountinfo: accountInfo = {},
      membersinfo = [],
      programs = [],
      commodityCodes = [],
    } = accountinfo;
    const { accounts = [] } = accountInfo;
    let memberAddress = accountInfo.memberInfo.find((item: any) => item.addressType === 'MA');
    if (!memberAddress) {
      memberAddress = accountInfo.memberInfo.find((item: any) => item.addressType === 'BA');
    }
    const { ampUserProp = {} } = shared;

    const usercommodityCodes =
      commodityCodes.map((item: any) => `${item.fullCode} ${item.commodityDescription}`) || [];

    let userAttributes = {
      Name:
        accounts.length > 0 && accounts[0].firstName
          ? `${accounts[0].firstName}${accounts[0].lastName ? ' ' + accounts[0].lastName : ''}`
          : '',
      Email: accounts.length > 0 && accounts[0].email ? accounts[0].email : '',
      MemberID: memberinfo.mi,
      AccountID: auth.us,
      'Organization Name': membersinfo.companyname ? membersinfo.companyname : '',
      'User Type': auth.mt === MemberType.AgencyBuyer ? 'Agency' : 'Supplier',
      State: memberAddress.stateName,
      County: memberAddress.countyName,
      City: memberAddress.city,
      'Notifications Enabled': memberinfo.nfy,
      'Created Date (Member)': auth.mcd,
      'Created Date (Account)': auth.acd,
    } as any;

    if (auth.mt !== MemberType.AgencyBuyer) {
      let programsData = [...programs];
      if (programsData.length === 0) {
        const response = yield call(Api.searchPrograms, {
          memberId: auth.mi,
          buyerName: '',
          certificationStatus: '',
          statusChangedSince: '',
          expiresAfter: '',
          attribMatch: '',
        });
        programsData = response.data.result;
      }
      const cerfiedPrograms = programsData
        .filter((item: any) => item.certificationStatus === 'CT')
        .map((itemData: any) => itemData.programName)
        .join(', ');
      let subscriptionType = '';
      if (auth.mt !== MemberType.AgencyBuyer) {
        if (auth.mt === MemberType.BasicSupplier) subscriptionType = 'Basic';
        if (accountInfo.products && accountInfo.products.length > 0) {
          if (
            accountInfo.products.length === 1 &&
            accountInfo.products[0].productType === ProductType.FreeAgency
          )
            subscriptionType = 'Free';
          else subscriptionType = 'Paid';
        }
      }

      userAttributes = {
        ...userAttributes,
        'Commodity Codes': usercommodityCodes,
        Products: accountInfo.products.map((item: any) => item.productName),
        'Member Type': subscriptionType,
        Certifications: cerfiedPrograms,
        'Bid Documents Downloaded':
          ampUserProp && ampUserProp.totalDocumentDownloaded
            ? ampUserProp.totalDocumentDownloaded
            : '',
        'Last Document Download Date':
          ampUserProp &&
          ampUserProp.lastDocumentDownloaded &&
          ampUserProp.totalDocumentDownloaded > 0
            ? deprecatedGetDate(ampUserProp.lastDocumentDownloaded)
            : '',
        'eBid Responses Submitted':
          ampUserProp && ampUserProp.totalResponseSubmited ? ampUserProp.totalResponseSubmited : '',
        'Last eBid Response Date':
          ampUserProp && ampUserProp.lastResponseSubmited && ampUserProp.totalResponseSubmited > 0
            ? deprecatedGetDate(ampUserProp.lastResponseSubmited)
            : '',
      };
    }

    trackAmplitudeUserAttributes(
      action.payload.title,
      action.payload.desc,
      action.payload.data ? action.payload.data : {},
      userAttributes,
    );

    if (action.payload.isStore) {
      yield put({
        type: actionTypes.SET_SHARED_DETAILS.TRIGGER,
        payload: { amplitudeData: action.payload },
      });
    }

    yield put({ type: actionTypes.TRACK_AMPLITUDE_USER_ACTION.SUCCESS });
  } catch (error) {
    yield put({
      type: actionTypes.TRACK_AMPLITUDE_USER_ACTION.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}

function* ResetAllStates() {
  yield put({ type: actionTypes.RESET_AUTH.TRIGGER });
  yield put({ type: actionTypes.RESET_BIDS.TRIGGER });
  yield put({ type: actionTypes.RESET_EBIDS.TRIGGER });
  yield put({ type: actionTypes.RESET_ADD_BID.TRIGGER });
  yield put({ type: actionTypes.RESET_QUOTES.TRIGGER });
  yield put({ type: actionTypes.RESET_SHARED.TRIGGER });
  yield put({ type: actionTypes.RESET_PROGRAMS.TRIGGER });
  yield put({ type: actionTypes.RESET_ADD_QUOTES.TRIGGER });
  yield put({ type: actionTypes.RESET_BREADCRUMB.TRIGGER });
  yield put({ type: actionTypes.RESET_MEMBER_INFO.TRIGGER });
  yield put({ type: actionTypes.RESET_ACCOUNT_INFO.TRIGGER });
  yield put({ type: actionTypes.RESET_BIDS_PAYMENT.TRIGGER });
  yield put({ type: actionTypes.RESET_BIDS_SUMMARY.TRIGGER });
  yield put({ type: actionTypes.RESET_QUOTE_SUMMARY.TRIGGER });
  yield put({ type: actionTypes.RESET_COMMODITY_CODES.TRIGGER });
}

export function* authSaga() {
  yield fork(watchLoginAsync);
  yield fork(watchLogoutAsync);
  yield fork(watchloginUsingCookie);
  yield fork(watchgetRefreshToken);
  yield fork(watchValidateEmailAsync);
  yield fork(watchTrackAmplitudeUserActions);
}
