/*
	PARAM: Tab
	 PATH: accountinfo/certifications/<Tab>
	 DESC: Initial Component for User Accounts certifications page.
	       Created Tabs [ Manage certifications, Manage Suppliers ] and rendered Tabbed Components
*/
import { memo, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';

import * as actionCreators from '../../../../store/actions';
import { AuthState } from 'src/store/reducers/auth';
import { hasPermission } from '../../../../utils/helpers';
import { memberinfoParamType } from '../../../../types/bids';
import { ProgramsList } from './programslist';
import { programsType } from '../../../../types/programtypedef';
import { sharedTypes } from '../../../../types/shared';
import { userPermissionKeys } from '../../../../utils/constants';

import navHistory from '../../../../utils/history';
import ProgramSuppliers from './programsupplier';

const NavList = [
  { name: 'Manage Certifications', active: 'list' },
  { name: 'Manage Suppliers', active: 'suppliers' },
];

interface propsTypes {
  match?: any;
  memberinfo?: memberinfoParamType;
  auth?: AuthState;
  programs?: programsType;
  shared: sharedTypes;
}

function ProgramsIndex(props: propsTypes) {
  const { memberinfo, auth } = props;

  const tab = props.match.params.Tab;
  const [activeTab, setActiveTab] = useState(tab);

  // Change Tabs
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
    navHistory.push(`/buyers/account/certifications/${tab}`);
  };

  const { prms, mc: isPrimaryContact = false } = memberinfo || {};

  const { prms: authPerms } = auth || {};
  const accountPermissions = prms ? prms : authPerms;

  const hasEditProgramPermission =
    hasPermission(
      accountPermissions ? accountPermissions : '',
      userPermissionKeys.managePrograms,
    ) || isPrimaryContact;

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <Nav tabs>
              {NavList.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === item.active })}
                    onClick={() => {
                      toggle(item.active);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='list'>
                <ProgramsList
                  {...props}
                  activeTab={activeTab}
                  hasEditProgramPermission={hasEditProgramPermission}
                />
              </TabPane>
              <TabPane tabId='suppliers'>
                <ProgramSuppliers
                  {...props}
                  activeTab={activeTab}
                  hasEditProgramPermission={hasEditProgramPermission}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
    auth: state.auth,
    programs: state.programs,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(ProgramsIndex));
