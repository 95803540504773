import { AxiosRequestConfig } from 'axios';

import Api, {
  axiosPostAuthenticated,
  axiosRequestAuthenticated,
  downloadRequestAuthenticated,
} from './api';
import { BidApiResponse, BidWatcher } from '../../types/bidssummary';

import { AddBidPlanholderPayload } from './';
import { BidCommodityCode } from '../../types/commodity';
import { BidId } from '../../types/shared';
import { constants } from '../../utils/settings';
import { defaultNumericId } from '../../shared/constants';
import { DocumentDownloadPayload } from '../../types/document';
import { getCommodityCodeList } from './commodityCode';
import { Paths } from '../../utils/constants';
import { Planholder } from '../../types/biddetail';
import { uploadDocument } from './upload';

export function getBids(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bids, data);
}

export function watchBid(data = {}) {
  return Api.postRequestWithAuthentication(Paths.watchBids, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function deleteBid(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsremove, data, {
    baseURL: constants.api.url,
  });
}
export function getConditionspending(data = {}) {
  return Api.postRequestWithAuthentication(Paths.conditionspending, data, {
    baseURL: constants.api.url,
  });
}

//-----MemberINFO API CALL------

export function getMemberInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.memberInfo, data, {
    baseURL: constants.api.userUrl,
  });
}

//--Get User Preference API CALL-----

export function getUserPreference() {
  return Api.getRequestWithAuthentication(Paths.getUserPreference, {
    baseURL: constants.api.url,
  });
}

//--Update User Preference API CALL-----
export function updateUserPreferences(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateUserPreference, data, {
    baseURL: constants.api.url,
  });
}

//-----Bids Summary API calls-------

/**
 * Typed Bid Summary request
 * @param bidId
 * @returns a Promise with a BidApiResponse type
 */
export function getBidSummary(bidId: number) {
  // Pass an empty config if the bidId is the default value (defaultNumericId).
  const requestConfig: AxiosRequestConfig =
    bidId === defaultNumericId
      ? {}
      : {
          baseURL: constants.api.url,
          data: {
            bidId,
            checkfor: '',
            otherapis: true,
          },
          url: Paths.bidsSummary,
        };
  return axiosPostAuthenticated<BidApiResponse | null>(requestConfig);
}

export function getBidsSummary(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsSummary, data);
}

export function getBidsWatchStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidswatchstatus, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getbBidView(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidView, data, {
    baseURL: constants.api.trackUrl,
  });
}
export function getBidsStats(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsStats, data);
}
export function getBidsDocuments(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsDocuments, data);
}
export function getBidsWatchers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getWatchers, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getBidsPlanHolders(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getPlanholders, data);
}
export function getPlanholdersDetail(data = {}) {
  return Api.postRequestWithAuthentication(Paths.planholdersDetail, data, {
    baseURL: constants.api.userUrl,
  });
}
export function getBidsPostbidViewers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsPostbidViewers, data);
}
export function getBidsBidders(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsBidders, data);
}

export function getBidsSimilarBids(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsSimilarBids, data);
}

export function getBidsLegalAd(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsLegalAd, data);
}

export function getBidsEbids(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getresponse, data);
}
export function getBidsPurchaseInfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsPurchaseInfo, data);
}

export function getBidsDocument(data = {}) {
  return Api.downloadFileWithAuthentication(
    Paths.bidsDocumentDownload,
    data,
    constants.api.documentUrl,
  );
}

/**
 * Typed download API call for a specific document.
 * TODO:  currently just Bid - expand to allow other doc types?
 * @param docIdParams
 */
export function downloadResponseDocument(docIdParams: DocumentDownloadPayload) {
  return downloadRequestAuthenticated({
    baseUrl: constants.api.documentUrl,
    docIdParams,
    requestUrl: Paths.bidsDocumentDownload,
  });
}

/**
 * Typed download API call for all response documents.
 * TODO: expand to allow other doc types?
 * @param docIdParams
 */
export function downloadAllResponseDocuments(docIdParams: DocumentDownloadPayload) {
  return downloadRequestAuthenticated({
    baseUrl: constants.api.documentUrl,
    docIdParams,
    requestUrl: Paths.bidsDocumentsAll,
  });
}

export function getBidsResponseDocument(data?: DocumentDownloadPayload) {
  if (data) {
    return Api.downloadFileWithAuthentication(
      Paths.bidsDocumentDownload,
      data,
      constants.api.documentUrl,
    );
  }
}

export function getBidsSimilarSellers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsSimilarSuppliers, data);
}

export function getBidsPlanholdersDocuments(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsplanholderDocuments, data);
}

export function sendBidSelectedSupplier(data = {}) {
  return Api.postRequestWithAuthentication(Paths.similarbroadcast, data, {
    baseURL: constants.api.url,
  });
}

export function removeBidPlanholderSupplier(data = {}) {
  return Api.postRequestWithAuthentication(Paths.removePlanholder, data);
}

export function getBidsSearchPlanholders(data = {}) {
  return Api.postRequestWithAuthentication(Paths.searchPlanholder, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getbidAuditTrial(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getBidAuditTrialView, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getbidBroadcastHistory(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsbroadcasthistory, data);
}

// export function addBidPlanholder(data = {}) {
//   return Api.postRequestWithAuthentication(Paths.addBidPlanholder, data);
// }

/**
 * Add Bid Planholder request
 * @param {AddBidPlanholderPayload} payload
 * @returns {unknown}
 */
export function addBidPlanholder(payload: AddBidPlanholderPayload) {
  return axiosPostAuthenticated<unknown>({
    baseURL: constants.api.url,
    data: payload,
    url: Paths.addBidPlanholder,
  });
}

export function getSupplierSelfDeclarations(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getSelfDeclarations, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getbidresponderinfo(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidResponderInfo, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitBidsUpdateReminder(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updatereminders, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getbidsReminders(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsreminders, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getbidWriter(accountId: string | number) {
  return Api.getRequestWithAuthentication(`${Paths.bidWriter}?accountId=${accountId}`, {
    baseURL: constants.api.userUrl,
  });
}

export function getCanUpgrade(data = {}) {
  return Api.postRequestWithAuthentication(Paths.canUpgrade, data, {
    baseURL: constants.api.subscriptionUrl,
  });
}

// export function bidsCounters(data = {}) {
//   return Api.postRequestWithAuthentication(Paths.bidsCounters, data, {
//     baseURL: constants.api.url,
//   });
// }

export function getBidCommodityByType(data = {}) {
  return Api.postRequestWithAuthentication(Paths.commodityByType, data, {
    baseURL: constants.api.url,
  });
}

export function getResponseEvents(data = {}) {
  return Api.postRequestWithAuthentication(Paths.responseEvents, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function addBidSupplierTabulation(data: any = {}) {
  if (data.isExport) {
    return Api.downloadFileWithAuthentication(
      Paths.supplierTabulation,
      data,
      constants.api.documentUrl,
    );
  } else {
    return Api.postRequestWithAuthentication(Paths.supplierTabulation, data, {
      baseURL: constants.api.documentUrl,
    });
  }
}

export function exportPlanholders(data = {}) {
  return Api.downloadFileWithAuthentication(Paths.exportPlanholders, data, constants.api.url);
}

export function getBidTabulation(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsTabulation, data);
}

export function exportBroadcastList(data = {}) {
  return Api.downloadFileWithAuthentication(Paths.exportBroadcastList, data, constants.api.url);
}

export function exportSupplementalSuppliers(data = {}) {
  return Api.downloadFileWithAuthentication(
    Paths.exportSupplementalSuppliers,
    data,
    constants.api.url,
  );
}

export function exportLegalAd(data = {}) {
  return Api.downloadFileWithAuthentication(Paths.exportLegalAd, data, constants.api.documentUrl);
}

export function bidsWatchersCount(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidsCounters, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getBidfinishStates(data = {}) {
  return Api.postRequestWithAuthentication(Paths.finishStates, data, {
    baseURL: constants.api.url,
  });
}

export function getBidsAllDocuments(data = {}) {
  return Api.downloadFileWithAuthentication(
    Paths.bidsDocumentsAll,
    data,
    constants.api.documentUrl,
  );
}

export function updateBidStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.updateBidStatus, data, {
    baseURL: constants.api.url,
  });
}
//<-----Bids Summary API calls------->

/**
 *
 * Bid Management - Required Actions
 *
 */

/**
 * General contract document upload service call.
 * TOOD: enforce typing on `data`, clean up and/or replace `src/types/addbid -> BidDocument`
 * @param data
 * @param progressCallback
 */
export function uploadContractDocument(
  data: any,
  handleUploadProgress: (event: ProgressEvent) => void,
  uploadUrl?: string,
) {
  const formData = new FormData();
  if (data) {
    const { bidId, title, type, requireConversion, extension, file } = data;
    formData.append('bidId', bidId); // this is a number. formData.append wants a string or Blob.
    formData.append('documentTitle', title);
    formData.append('documentType', type);
    formData.append('isPdfConversion', requireConversion); // this is a boolean. formData.append wants a string or Blob.
    formData.append('docFormatType', extension);
    formData.append('uploadFile', file);
  }

  const config: AxiosRequestConfig = { onUploadProgress: handleUploadProgress };

  // Overwrite defaults for any supoplied url (e.g. testing).
  if (uploadUrl) {
    config.baseURL = uploadUrl;
    config.url = '';
  }

  return uploadDocument<any>(formData, config);
}

/**
 * Bid Planholder list request
 * @param {bidId}:BidId
 * @returns api response: Planholder[]
 */
export function getBidPlanholderList(bidId: number): Promise<Planholder[]> {
  return axiosRequestAuthenticated<Planholder[]>({
    baseURL: constants.api.url,
    data: {
      bidId,
    },
    method: 'POST',
    url: Paths.getPlanholders,
  });
}

/**
 * Bid Watcher list request
 * @param {bidId}:BidId
 * @returns api response: BidWatcher[]
 */
export function getBidWatcherList({ bidId }: BidId) {
  return axiosRequestAuthenticated<BidWatcher[]>({
    baseURL: constants.api.trackUrl,
    data: {
      bidId,
    },
    method: 'POST',
    url: Paths.getWatchers,
  });
}

/**
 * Bid Commodity Code list request
 * @param {bidId}:BidId
 * @returns api response: BidCommodityCode[]
 */
export function getBidCommodityCodeList({ bidId }: BidId) {
  return getCommodityCodeList<BidCommodityCode>({ idField: 'bidId', idValue: bidId, type: 'bid' });
}
