import { useEffect, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { H2 } from '@demandstar/components/styles';

import { ButtonsFlexContainer, UnderlinedSection } from '../../ContractManagement.styles';
import { cmButtonTexts, cmExplanatoryTexts } from '../../ContractManagement.texts';
import { Feature, FeatureFlagKey } from 'src/components/common/feature-flag';
import { useContractDetails, useSetSelectedContractIdFromRoute } from '../../useContractDetails';

import { AddDocument } from './add-document/AddDocument';
import { CMTab } from '../../navigation/useCMNavigation';
import { CMTabNavigation } from '../../navigation/CMTabNavigation';
import { DocumentFilterSearchForm } from './DocumentFilterSearchForm';
import { ManageDocumentTypes } from './document-types';
import { useBreadcrumbs } from 'src/components/common/breadcrumb';
import { useRouter } from 'src/shared/hooks';
import { useDSSelector } from 'src/store/reducers';
import { hasPermission } from 'src/utils/helpers';
import { userPermissionKeys } from 'src/utils/constants';
import history from 'src/utils/history';

export const ContractDocuments = () => {
  useSetSelectedContractIdFromRoute();
  const { Tab } = useRouter().routerParams;
  const [showForm, setShowForm] = useState(Tab);
  const { setBreadcrumbInfo } = useBreadcrumbs();

  const { contractDetails } = useContractDetails();

  // const requestDocuments = () => {
  //   showForm === 'request' ? setShowForm('') : setShowForm('request');
  // };

  const uploadDocuments = () => {
    showForm === 'upload' ? setShowForm('') : setShowForm('upload');
  };

  const manageDocumentTypes = () => {
    showForm === 'manageTypes' ? setShowForm('') : setShowForm('manageTypes');
  };
  const auth = useDSSelector(state => state.auth);
  const memberinfo = useDSSelector(state => state.memberinfo);
  const { prms } = memberinfo;
  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;

  const hasManageBidsPermission =
    hasPermission(accountPermissions, userPermissionKeys.manageBids) || isPrimaryContact;
  useEffect(() => {
    if (hasManageBidsPermission === false) {
      history.push(`/buyers/dashboard`);
    }
  }, [hasManageBidsPermission]);

  useEffect(() => {
    setBreadcrumbInfo({
      activePageLabel: contractDetails?.id
        ? contractDetails?.solicitationId || contractDetails?.name
        : 'Documents',
    });
  }, [contractDetails, setBreadcrumbInfo]);

  return (
    <Feature flag={FeatureFlagKey.ContractManagement} redirect={'/'}>
      <CMTabNavigation selectedTab={CMTab.Documents} />
      <H2>{contractDetails?.id && 'Contract'} Documents</H2>
      <UnderlinedSection>
        <p>{cmExplanatoryTexts.Documents}</p>
      </UnderlinedSection>
      <UnderlinedSection>
        <ButtonsFlexContainer justifyContent='center'>
          {/* <DSButton onClick={requestDocuments}>{cmButtonTexts.requestDocuments}</DSButton> */}
          <DSButton onClick={uploadDocuments}>{cmButtonTexts.uploadDocuments}</DSButton>
          <DSButton onClick={manageDocumentTypes}>{cmButtonTexts.manageDocumentTypes}</DSButton>
        </ButtonsFlexContainer>
        {/* {showForm === 'request' && (
          <div data-testid='form-request-document'>
            <RequestDocumentForm />
          </div>
        )} */}
        {showForm === 'upload' && (
          <div data-testid='form-upload-document'>
            <AddDocument />
          </div>
        )}
        {showForm === 'manageTypes' && (
          <div data-testid='form-manage-document-types'>
            <ManageDocumentTypes />
          </div>
        )}
      </UnderlinedSection>
      <DocumentFilterSearchForm />
    </Feature>
  );
};
