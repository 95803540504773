import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes } from 'react';

import { AllowedTextInputType, BaseTextInput } from '../../inputs';
import { FieldContainer, FieldProps, getFieldContainerProps } from './../../field-container';
import { Inactive } from 'src/types/shared';

export type DSTextFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus' | 'id'> &
  Inactive & {
    /** optional defaultValue - this addresses https://github.com/react-hook-form/react-hook-form/discussions/3409*/
    defaultValue?: string;
    /** optional isNumeric */
    isNumeric?: boolean;
    /** optional RHF rules  */
    rules?: RegisterOptions;
    /** paired down HTML type */
    type?: AllowedTextInputType;
  };

/** DSTextField - text field exclusively used with react-hook-form
 * @example
 * <FormProvider>
 *  <DSTextField
 *    name={fieldName}
 *  />
 * </FormProvider>
 * */
export const DSTextField = (props: DSTextFieldProps) => {
  const {
    autoFocus,
    dataTestId,
    defaultValue,
    id,
    inactive,
    isNumeric,
    name,
    optional,
    rules,
    type = 'text',
  } = props;

  const methods = useFormContext();
  const { control, errors, watch } = methods;

  const registerOptions: RegisterOptions = {
    ...rules,
    required: !optional,
  };

  const maxLength = registerOptions.maxLength ? Number(registerOptions.maxLength) : undefined;

  const error = get(errors, name);

  // Accept a property or default to the form hook watch() result.
  const defaultValueFinal = defaultValue || watch(name);

  return (
    <Controller
      control={control}
      defaultValue={defaultValueFinal || ''}
      name={name}
      rules={registerOptions}
      render={({ name, onChange, ref, value, onBlur }) => {
        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <BaseTextInput
              autoFocus={autoFocus}
              data-testid={dataTestId || id || name}
              error={error}
              id={id || name}
              isNumeric={isNumeric ?? type === 'number'}
              maxLength={maxLength}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              readOnly={inactive}
              ref={ref}
              type={type}
              value={value}
            />
          </FieldContainer>
        );
      }}
    />
  );
};
