import {
  Colors,
  ContentRowWrapper,
  DashboardRightColumn,
  HighlightedDetailsWrapper,
  HighlightedListWrapper,
  LeftColumnAbove,
} from '../../../../shared/styles';
import { navigationPaths, RESPONSE_LABEL_PLURAL } from '../../../../shared/constants';
import { ArrowTitle } from '../../../../components/layout/titles';
import { BidResponseDetailsPanel } from './BidResponseDetailsPanel';
import { BidResponseListPanel } from './BidResponseListPanel';
import { EditBidResponseButton } from './EditBidResponseButton';
import { MY_RESPONSE_LABEL_PLURAL } from '../../../../shared/constants/required-action';
import { useSetBreadcrumb } from '../../../../shared/hooks';

/**
 * Presentational component - view layout for responses
 * @returns JSX
 */
export function BidResponses() {
  useSetBreadcrumb({
    altname: MY_RESPONSE_LABEL_PLURAL,
    component: <EditBidResponseButton />,
    menuactive: navigationPaths.subPaths.Responses,
    name: MY_RESPONSE_LABEL_PLURAL,
    page: RESPONSE_LABEL_PLURAL,
    title: MY_RESPONSE_LABEL_PLURAL,
  });

  return (
    <>
      <ArrowTitle bgColor={Colors.blueGreenArrowHighlight} label={RESPONSE_LABEL_PLURAL} />
      <ContentRowWrapper>
        <LeftColumnAbove>
          <HighlightedListWrapper>
            <BidResponseListPanel />
          </HighlightedListWrapper>
        </LeftColumnAbove>
        <DashboardRightColumn>
          <HighlightedDetailsWrapper>
            <BidResponseDetailsPanel />
          </HighlightedDetailsWrapper>
        </DashboardRightColumn>
      </ContentRowWrapper>
    </>
  );
}
