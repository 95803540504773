import { memo, useEffect } from 'react';
import { connect } from 'react-redux';

import AddCommoditycode from './addcommoditycode';
import AddingUser from './addinguser';
import AgencyAddUser from './agency/agencyadduser';
import AgencyGettingHelp from './agency/agencygettinghelp';
import BroadcastQuote from './agency/broadcastquote';
import ChooseFreeAgency from './choosefreeagency';
import CreateBroadcastBid from './agency/createbroadcastbid';
import CreateBroadcastQuote from './agency/createbroadcastquote';
import DetailsPage from './agency/detailspage';
import GettingStart from './gettingstart';
import LogOut from './agency/logout';
import MakingAward from './agency/makingaward';
import MapingCertificate from './mapingcertificate';
import Reminders from './agency/reminders';
import RespondingEBid from './respondingebid';
import SearchBid from './searchbid';
import SelfDeclarations from './selfdeclarations';
import SetUpAccount from './agency/setupaccount';
import SetUpCertification from './agency/setupcertification';
import SetUpPublication from './agency/setuppublication';
import TabulationForm from './agency/tabulationform';
import TipsAndTricks from './tipstricks';
import UpgradeSubscription from './upgradesubscription';

import * as actionCreators from '../../../../store/actions';
import { authTypes } from '../../../../types/auth';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { MemberType } from '../../../../utils/constants';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface PropsTypes {
  auth: authTypes;
  match: any;
}

function UserManualPages(props: PropsTypes) {
  const { /* history,*/ auth } = props;
  const { mt: memberType } = auth;
  const component = [
    { name: 'Adding Users', id: 'adduser', component: <AddingUser /> },
    {
      name: 'Upgrading your Subscription',
      id: 'upgradesubscription',
      component: <UpgradeSubscription />,
    },
    { name: 'Managing Certifications', id: 'mapingcertificate', component: <MapingCertificate /> },
    { name: 'Self Declarations', id: 'selfdeclarations', component: <SelfDeclarations /> },
    { name: 'Gettin Start with DemandStar', id: 'gettingstart', component: <GettingStart /> },
    { name: 'Choose your Free Agency', id: 'choosefreeagency', component: <ChooseFreeAgency /> },
    {
      name: 'Adding Commodity Codes to your DemandStar Account',
      id: 'addcommoditycode',
      component: <AddCommoditycode />,
    },
    { name: 'Searching for Bids', id: 'searchbid', component: <SearchBid /> },
    { name: 'Tips and Tricks', id: 'tipstricks', component: <TipsAndTricks /> },
    {
      name: 'Responding to an Electronic Bid',
      id: 'respondingebid',
      component: <RespondingEBid />,
    },
    { name: 'Getting Help ', id: 'gettinghelp', component: <GettingStart /> },
    { name: 'Setting Up Your Account', id: 'setupaccount', component: <SetUpAccount /> },
    { name: 'Add User', id: 'agencyadduser', component: <AgencyAddUser /> },
    {
      name: 'Setting-up your Certifications',
      id: 'setupcertification',
      component: <SetUpCertification />,
    },
    {
      name: 'Setting up your Publications',
      id: 'setuppublication',
      component: <SetUpPublication />,
    },
    {
      name: 'Creating and Broadcasting a Solicitation',
      id: 'createbroadcastbid',
      component: <CreateBroadcastBid />,
    },
    { name: 'Details Page', id: 'detailspage', component: <DetailsPage /> },
    { name: 'Reminders', id: 'reminders', component: <Reminders /> },
    { name: 'Tabulation Form', id: 'tabulationform', component: <TabulationForm /> },
    { name: 'Making an Award', id: 'makingaward', component: <MakingAward /> },
    { name: 'Log Out', id: 'logout', component: <LogOut /> },
    {
      name: 'Creating and Broadcasting a Quote',
      id: 'createbroadcastquote',
      component: <CreateBroadcastQuote />,
    },
    { name: 'Broadcasting a Quote', id: 'broadcastingquote', component: <BroadcastQuote /> },
    { name: 'Getting Help', id: 'agencygettinghelp', component: <AgencyGettingHelp /> },
  ];

  const helpPages = props.match.params.pagesid || '';
  const currentcomponent: any = component.find(item => item.id === helpPages) || '';

  useSetBreadcrumb({
    component: (
      <li title='Back'>
        <Link
          to={`/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/help`}
          className='staticLink'
        >
          <i className='mdi mdi-keyboard-backspace mr-1' />
          Back
        </Link>
      </li>
    ),
    page: 'usermanualpages',
    title: currentcomponent.name || '',
    name: currentcomponent.name || '',
  });

  return (
    <div className='container'>
      <div className='row'>
        <div className='col helpWrapper'>
          <div className='colWrapper'>
            <h3 className='arrowWrapper'>{currentcomponent ? currentcomponent.name : ''}</h3>
            {currentcomponent && currentcomponent.component}
            <Link
              to={`/${memberType === MemberType.AgencyBuyer ? 'buyers' : 'suppliers'}/help`}
              className='bttn-primary text-center float-right'
            >
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state: { auth: authTypes }) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(UserManualPages));
