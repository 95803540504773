import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import Filters from './filters';
import history from '../../../utils/history';
import QuoteList from '../../common/quotelist';
import Quotescontrol from './quotescontrol';

import * as actionCreators from '../../../store/actions';
import {
  appKeys,
  MemberType,
  pageSize,
  quoteSortFields,
  sortDirections,
} from '../../../utils/constants';
import { Buttons, NoResult, Paging, Sort } from '../../customcontrols';

import { authTypes } from '../../../types/auth';
import { noResultMsg } from '../../../utils/texts';
import { quotesParamType } from '../../../types/quotesdetails';
import { sharedParamType } from '../../../types/commoditycodes';
import { SortingFn } from '../../../utils/helpers';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface propsTypes extends PropsFromRedux {
  auth: authTypes;
  quotes: quotesParamType;
  getQuotesList: any;
  setQuotesDetails: any;
  resetQuoteSummaryDetails: any;
  resetCommodityCodes: any;
  setAddBidDetails: any;
  resetAddQuotesDetails: any;
  shared: sharedParamType;
  getFiscalYear: any;
  getContactList: any;
  setQuotesFilter: any;
}

function Home(props: propsTypes) {
  const {
    quotes,
    auth,
    getQuotesList,
    setQuotesDetails,
    resetQuoteSummaryDetails,
    resetCommodityCodes,
    setAddBidDetails,
    resetAddQuotesDetails,
  } = props;
  const { quoteslist, quotesFilters, quotesinitial, quotesreload, quotecurrentPage } = quotes;
  const { mt: memberType } = auth;
  const { quotesortBy, quotesortOrder } = quotesFilters || {};

  useEffect(() => {
    if (memberType === MemberType.BasicSupplier) history.replace('/');
  }, [memberType]);

  useEffect(() => {
    if ((quoteslist.length === 0 && quotesinitial) || quotesreload) {
      getQuotesList({ types: 'initial' });
      setQuotesDetails({ quotesreload: false });
    }
  }, [getQuotesList, quotesinitial, quoteslist.length, quotesreload, setQuotesDetails]);

  useSetBreadcrumb({
    component:
      memberType === MemberType.AgencyBuyer ? (
        <Quotescontrol
          auth={auth}
          resetQuoteSummaryDetails={resetQuoteSummaryDetails}
          resetCommodityCodes={resetCommodityCodes}
          setAddBidDetails={setAddBidDetails}
          resetAddQuotesDetails={resetAddQuotesDetails}
        />
      ) : undefined,
    page: 'Quotes Search',
    title: `${appKeys.headers.quotes}`,
    name: 'Quotes Search',
    menuactive: 'quotes',
  });

  const listPerPage = pageSize.default;
  const indexOfLastList = quotecurrentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  // Sort
  const changeSortSelectBox = (key: string, value: string) => {
    if (value !== quotesortBy) {
      setQuotesDetails({
        quotesFilters: { ...quotesFilters, quotesortBy: value },
        quoteslist: SortingFn(quoteslist, quotesortOrder as sortDirections, value),
      });
    }
  };
  const changeSortOrder = () => {
    const sort =
      quotesortOrder === sortDirections.DESC
        ? sortDirections.ASC
        : quotesortOrder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    setQuotesDetails({
      quotesFilters: { ...quotesFilters, quotesortOrder: sort },
      quoteslist: SortingFn(quoteslist, sort, quotesortBy),
    });
  };

  const gotoprofilepage = () => {
    history.replace('/suppliers/account/commoditycodes');
  };

  return (
    <div className='container'>
      <div className='row'>
        <Filters {...props} />
        <div className='col-12 col-lg-9 col-xl-9'>
          <div className='colWrapper'>
            <div className='headerFilter clearfix d-flex align-items-center'>
              <h2 className='arrowWrapper mr-auto'>Quotes</h2>
              <b className='mr-3'>Sort By</b>
              <Sort
                sortFields={quoteSortFields}
                selectedFieldName={quotesortBy}
                onChange={changeSortSelectBox}
                onSortClick={changeSortOrder}
                sortIcon={quotesortOrder}
              />
            </div>
            <div className={'scrollContent ' + (quoteslist.length < 10 ? '' : '')}>
              {quoteslist.length > 0 ? (
                quoteslist
                  .map((item, index) => (
                    <QuoteList key={index} item={item} memberType={memberType} />
                  ))
                  .slice(indexOfFirstList, indexOfLastList)
              ) : (
                <>
                  {memberType !== MemberType.AgencyBuyer ? (
                    <>
                      <p className='mt-3'>{noResultMsg.quoteSupplier}</p>
                      <p>{noResultMsg.quoteSupplierUpdateProfile}</p>
                      <Buttons
                        classNames='bttn-primary'
                        onClick={gotoprofilepage}
                        text='Edit Your Profile'
                        title='Edit Your Profile'
                        type='button'
                      />
                      <p className='mt-3'>
                        <i>{noResultMsg.quoteSupplierTips}</i>
                      </p>
                    </>
                  ) : (
                    <NoResult message={noResultMsg.commonMsg} />
                  )}
                </>
              )}
            </div>
            <footer className='row'>
              <div className='col-12 '>
                <Paging
                  totalRecords={quoteslist.length}
                  currentPage={quotecurrentPage}
                  onPagingClick={(value: number) => {
                    if (value !== quotecurrentPage)
                      setQuotesDetails({ quotecurrentPage: Number(value) });
                  }}
                  pageLimit={listPerPage}
                />
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

const connector = connect(
  (state: any) => ({ auth: state.auth, quotes: state.quotes, shared: state.shared }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(Home));
