import { Suspense, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { DSButton } from '@demandstar/components/button';
import { DSLink } from '@demandstar/components/link';
import { FlexContainer } from '@demandstar/components/styles';

import { initialAgencyProduct, selectedProductsState } from '../../../store/recoil/productState';

import { AgencyByProductModal } from '../agency-by-product';
import { Cart } from '../cart/Cart';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { PrepopulatedProducts } from '../../../types/subscriptions';
import { prepopulatedProductsState } from '../../../store/recoil/subscriptionState';
import { registrationComponent } from '../../../utils/constants';
import { RegistrationComponent } from '../../../store/recoil/registrationState';
import { scrollToTop } from 'src/utils/helpers';
import { SelectState } from './SelectState';
import { SubscriptionProducts } from '../../../types/products';
import { UpsellList } from '../upsell/UpsellList';
import { useCart } from '../cart/useCart';
import { useRegistration } from 'src/shared/hooks/useRegistration';
import { useUpsellProducts } from '../upsell/useUpsellProducts';

/**
 * @description the page for selecting products in registration
 * @returns JSX.Element
 *
 * @example <SelectProducts />
 */
export const SelectProducts = () => {
  const selectedProducts = useRecoilValue<SubscriptionProducts>(selectedProductsState);
  const prepopulatedProducts = useRecoilValue<PrepopulatedProducts>(prepopulatedProductsState);

  const [confirmationPopup, toggleConfirmationPopup] = useState(false);

  const { setCurrentComponent } = useRegistration();
  const { clearExtraUpsellProducts } = useUpsellProducts();
  const { showCart } = useCart();

  let prepopulatedState = prepopulatedProducts.stateName;
  /* istanbul ignore else */
  if (selectedProducts.state) {
    prepopulatedState = selectedProducts.state[selectedProducts.state.length - 1]
      ? selectedProducts.state[selectedProducts.state.length - 1].productName
      : prepopulatedProducts.stateName;
  }

  const checkout = () => {
    if (showCart) {
      setCurrentComponent(registrationComponent.CompleteProfile);
    } else toggleConfirmationPopup(true);
  };

  const goBack = (
    previousComponent: RegistrationComponent = registrationComponent.CommodityCode,
  ) => {
    clearExtraUpsellProducts();
    setCurrentComponent(previousComponent);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row regWrapper'>
          <div className='col-12'>
            <div className='colWrapper subscriptionWrapper'>
              <div className='row'>
                <div className='col col-8'>
                  <h2 className='arrowWrapper'>3 of 4: Choose Subscriptions</h2>
                  {selectedProducts.agency && selectedProducts.agency !== initialAgencyProduct ? (
                    <>
                      <p className='reg-intro no-bottom-margin'>
                        {"Now that you've chosen "}
                        <DSLink onClick={() => goBack(registrationComponent.ChooseAgency)}>
                          {selectedProducts.agency?.productName}
                        </DSLink>{' '}
                        as your free agency, add more counties to grow your network of potential
                        clients.
                      </p>
                      <p className='reg-intro no-top-padding'>
                        County subscriptions start at $25 per year.
                      </p>
                    </>
                  ) : (
                    <p className='reg-intro no-top-padding'>
                      Build your network by adding county subscriptions, starting at $25 a year. Or,{' '}
                      <DSLink onClick={() => goBack(registrationComponent.ChooseAgency)}>
                        go back and add a single agency for free
                      </DSLink>
                      !
                    </p>
                  )}
                  <SelectState prePopulatedState={prepopulatedState} />
                  {showCart ? (
                    <>
                      <Suspense fallback={<></>}>
                        <Cart />
                      </Suspense>
                      <FlexContainer justifyContent='space-between'>
                        <DSButton type='secondary' onClick={() => goBack()}>
                          Go Back
                        </DSButton>
                        <DSButton type='primary' onClick={checkout}>
                          Next
                        </DSButton>
                      </FlexContainer>
                    </>
                  ) : (
                    <FlexContainer justifyContent='space-between'>
                      <DSButton type='secondary' onClick={() => goBack()}>
                        Go Back
                      </DSButton>
                      <DSButton type='primary' onClick={checkout}>
                        Continue without adding subscriptions
                      </DSButton>
                    </FlexContainer>
                  )}
                </div>
                <div className='col col-4'>
                  <UpsellList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgencyByProductModal />
      <ModalPopUp
        size='lg'
        title={`${selectedProducts.agency?.productName ? 'Notice' : 'Warning'}`}
        closeModal={() => toggleConfirmationPopup(false)}
        isOpen={confirmationPopup === true}
        backdrop='static'
      >
        {selectedProducts.agency?.productName ? (
          <>
            <p>
              By not subscribing to any counties or states, you risk missing out on opportunities
              from other agencies near you.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency, not just <b>{selectedProducts.agency?.productName}</b>, in those
              counties posts a new bid.
            </p>
          </>
        ) : (
          <>
            <p>
              To receive proactive notifications about opportunities near you, you must subscribe to
              a geographic region.
            </p>
            <p>
              Find and subscribe to counties near you and DemandStar will send you an email the
              moment any agency in those counties posts a new bid.
            </p>
          </>
        )}
        <p>Make sure you never miss out on a government contract again!</p>

        <FlexContainer justifyContent='space-between'>
          <DSButton
            type='secondary'
            title='Notice'
            onClick={() => {
              toggleConfirmationPopup(false);
              setCurrentComponent(registrationComponent.CompleteProfile);
            }}
          >
            Skip getting notifications
          </DSButton>
          <DSButton
            type='primary'
            title='I want to subscribe to counties!'
            onClick={() => {
              toggleConfirmationPopup(false);
            }}
          >
            I want to subscribe to counties!
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
    </>
  );
};
