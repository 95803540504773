import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';

import { DSLoadingPanel } from '@demandstar/components/loading-panel';
import { DSTable } from '@demandstar/components/table';

import { commonLabels, navigationPaths } from '../../../../shared/constants';
import { DateCell, LinkTextCell } from '../../../../components/common/table/renderers';
import { RequiredAction, RequiredActionType } from '../../../../types/required-action';
import { requiredActionLabels, tableHeaders } from '../../../../shared/constants/required-action';
import { getDefaultLoadingMessage } from '../../../../utils/message';
import { NotFoundPanel } from '../../../../components/common/loading/NotFoundPanel';
import { requiredDocLabels } from '../../../../components/buyer/awardbid/add-vendor-docs/constants';
import { useRequiredActions } from '../../../../shared/hooks/award-bid/useRequiredActions';

interface RequiredActionsTableProps {
  requiredActions: RequiredAction[];
}

/**
 * Container for the Panel displaying Required Actions.
 * TODO: Abstract this logic to shared and synchronize with the Required Actions List view.
 */
export function RequiredActionsTable({ requiredActions }: RequiredActionsTableProps) {
  const { requiredActionsLoading } = useRequiredActions();

  const getActionId = useCallback((requiredAction: RequiredAction) => {
    return requiredAction.actionId;
  }, []);

  /**
   * Render cell text based upon row data.
   * @param original: table row data for `actionId` and `bidId`
   * @returns
   */
  const renderActionLabelPrefix = useCallback((original: RequiredAction) => {
    return original?.bidTitle && original?.type === RequiredActionType.RequiredDocument
      ? `${requiredDocLabels.requiredActionMessageUpload}: `
      : '';
  }, []);

  /**
   * Required Actions - List Table
   */

  const requiredActionsTableColumns = useMemo((): Column<RequiredAction>[] => {
    return [
      {
        Header: tableHeaders.solicitationName,
        accessor: 'bidTitle',
        Cell: LinkTextCell<RequiredAction>({
          baseUrl: navigationPaths.suppliers.bids,
          idField: 'bidId',
          labelField: 'bidTitle',
          subUrl: navigationPaths.subPaths.Details,
        }),
        minWidth: 300,
      },
      {
        Header: tableHeaders.requiredAction,
        accessor: 'label',
        Cell: LinkTextCell<RequiredAction>({
          baseUrl: navigationPaths.suppliers.bids,
          idField: 'bidId',
          labelField: 'label',
          renderLabelPrefix: renderActionLabelPrefix,
          subUrl: navigationPaths.subPaths.Management,
          getItemId: getActionId,
        }),
        minWidth: 300,
      },
      {
        Header: tableHeaders.dueDate,
        accessor: 'dueDate',
        Cell: DateCell({ dateField: 'dueDate' }),
        minWidth: 200,
      },
    ];
  }, [getActionId, renderActionLabelPrefix]);

  return requiredActionsLoading ? (
    <DSLoadingPanel>{getDefaultLoadingMessage(requiredActionLabels.titlePlural)}</DSLoadingPanel>
  ) : requiredActions?.length ? (
    <DSTable
      columns={requiredActionsTableColumns}
      data={requiredActions}
      emptyMessage={commonLabels.notFound}
      sort='dueDate'
    />
  ) : (
    <NotFoundPanel message={requiredActionLabels.notFound} />
  );
}
