import { AxiosRequestConfig } from 'axios';

import { AddressCounty, AddressState, MetroDetails } from '../recoil/address/address';
import Api, { axiosPostAuthenticated, axiosRequestAuthenticated } from './api';
import { constants } from '../../utils/settings';
import { Paths } from '../../utils/constants';
import { SiblingProductPayload } from '../../types/actiontypedef';

export function getAgencies(data = {}) {
  return Api.postRequestWithAuthentication(Paths.agencies, data);
}

export function getsharedBidStatus(data = {}) {
  return Api.postRequestWithAuthentication(Paths.bidStatuses, data);
}

export function getCommodities(data = {}) {
  return Api.postRequestWithAuthentication(Paths.commodities, data);
}

export function getLocations(data = {}) {
  return Api.postRequestWithAuthentication(Paths.locations, data);
}

export function getShowBids(data = {}) {
  return Api.postRequestWithAuthentication(Paths.showbids, data);
}

/**
 * @deprecated use `getAllStates` instead
 * `data = {}` is required in order to keep TypeScript happy.
 */
export function getStatesList(data = {}) {
  return Api.getRequestWithAuthentication(Paths.geoStates, {
    baseURL: constants.api.userUrl,
  });
}

/** gets a list of all states in US and Canada  */
export function getAllStates() {
  return axiosRequestAuthenticated<AddressState[]>({
    baseURL: constants.api.userUrl,
    url: Paths.geoStates,
    method: 'GET',
  });
}

/** @deprecated use `getAllCountiesByState` instead */
export function getCountiesList(data = '') {
  return Api.getRequestWithAuthentication(`${Paths.geoCounties}?stateId=${data}`, {
    baseURL: constants.api.userUrl,
  });
}

/** gets a list of all counties by stateId  */
export function getAllCountiesByState(stateId: number) {
  return axiosRequestAuthenticated<AddressCounty[]>({
    baseURL: constants.api.userUrl,
    url: `${Paths.geoCounties}?stateId=${stateId}`,
    method: 'GET',
  });
}

export function getScrapers(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getScrapers);
}

export function getselfdeclarations(data = { isCurrentMember: true }) {
  return Api.postRequestWithAuthentication(Paths.getSelfDeclarations, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getdashboarddata(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getDashboardData, data);
}

export function getSiblingProductsData(data: SiblingProductPayload = {}) {
  const { productId, memberId } = data;
  let url = `${Paths.getSiblingProductsData}`;
  if (productId) {
    url += `?productId=${productId}`;
  } else if (memberId) {
    url += `?memberId=${memberId}`;
  }
  return Api.getRequestWithAuthentication(url, {
    baseURL: constants.api.userUrl,
  });
}

export function getRelatedBidsData(data = '') {
  return Api.getRequestWithAuthentication(Paths.getRelatedBidsData, {
    baseURL: constants.api.url,
  });
}

export function getdashboarddataTracking(data = {}) {
  return Api.postRequestWithAuthentication(Paths.dashboardBids, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getAddBidFiscalYear(data = {}) {
  return Api.postRequestWithAuthentication(Paths.FiscalYear, data, {
    baseURL: constants.api.url,
  });
}

export function getResponseDisplays(data = {}) {
  return Api.postRequestWithAuthentication(Paths.ResponseDisplays, data, {
    baseURL: constants.api.url,
  });
}

export function dashboardBidsList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.dashboardBids, data, {
    baseURL: constants.api.url,
  });
}

export function dashboardQuotesList(data = {}) {
  return Api.postRequestWithAuthentication(Paths.dashboardQuotes, data, {
    baseURL: constants.api.url,
  });
}

export function getDashboardNetwork(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getdashboardnetwork, data);
}

export function getdashboardNotifications(data = {}) {
  return Api.postRequestWithAuthentication(Paths.getdashboardnotifications, data);
}

export function getdashboardNotificationsaccount(data = {}) {
  return Api.postRequestWithAuthentication(Paths.dashboardnotifications, data, {
    baseURL: constants.api.userUrl,
  });
}

export function submitDisablenotification(data = {}) {
  return Api.postRequestWithAuthentication(Paths.disablenotification, data, {
    baseURL: constants.api.userUrl,
  });
}

export function getTopCommodities(data = {}) {
  return Api.postRequestWithAuthentication(Paths.topCommodities, data, {
    baseURL: constants.api.url,
  });
}
export function getThemes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.themeget, data, {
    baseURL: constants.api.userUrl,
  });
}
export function setThemes(data = {}) {
  return Api.postRequestWithAuthentication(Paths.themeset, data, {
    baseURL: constants.api.userUrl,
  });
}

/** @deprecated use `getMetroDetails` instead */
export function populateMetroDetails(data = {}) {
  return Api.postRequestWithAuthentication(Paths.metro, data, {
    baseURL: constants.api.url,
  });
}

/** gets an object comprising country, state, and county from postal code */
export function getMetroDetails(postalCode: string) {
  return axiosRequestAuthenticated<MetroDetails | Record<string, never>>({
    baseURL: constants.api.url,
    url: Paths.metro,
    data: { zip: postalCode },
    method: 'POST',
  });
}

export function getTimezoneList(data = {}) {
  return Api.getRequestWithAuthentication(Paths.timezones, {
    baseURL: constants.api.userUrl,
  });
}

export function getWatchedBidIds(data = {}) {
  return Api.postRequestWithAuthentication(Paths.watchedBids, data, {
    baseURL: constants.api.trackUrl,
  });
}

/**
 * Typed Bid Summary request
 * @returns a Promise with a string of IDs
 * @todo - return and object containing `bidId` and `bidTitle`.
 */
export function getWatchedBids() {
  const requestConfig: AxiosRequestConfig = {
    baseURL: constants.api.trackUrl,
    data: {},
    url: Paths.watchedBids,
  };
  return axiosPostAuthenticated<string>(requestConfig);
}

export function submitTrackEvents(data = {}) {
  return Api.postRequestWithAuthentication(Paths.track, data, {
    baseURL: constants.api.trackUrl,
  });
}

export function getContractInterest(data: any = {}) {
  if (data.method && data.method === 'POST') {
    return Api.postRequestWithAuthentication(Paths.contractInterest, data, {
      baseURL: constants.api.userUrl,
    });
  } else {
    return Api.getRequestWithAuthentication(Paths.contractInterest, {
      baseURL: constants.api.userUrl,
    });
  }
}

export function getAmplitudeUserProperties(data = {}) {
  return Api.postRequestWithAuthentication(Paths.GetAmplitudeUserProperties, data, {
    baseURL: constants.api.url,
  });
}

export function getProductDynamicPrices() {
  return Api.getRequest(Paths.getProductDynamicPrices, { baseURL: constants.api.userUrl });
}
