import { appstatetokenName } from './constants';
import { AuthState } from '../store/reducers/auth';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(appstatetokenName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: { auth: AuthState }) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(appstatetokenName, serializedState);
  } catch (error) {
    console.log(error);
  }
};

export const loadBidName = () => {
  try {
    const serializedState = localStorage.getItem('bidName');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveBidName = (bidname: { bidName: any; bidID: any }) => {
  try {
    const serializedname = JSON.stringify(bidname);
    localStorage.setItem('bidName', serializedname);
  } catch (e) {
    console.log(e);
  }
};
