import { memo, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table } from 'reactstrap';
import update from 'immutability-helper';

import DragDropCard from '../../../customcontrols/dragdropcard';
import EbidDocumentPopup from './ebiddocumentpopup';
import NoResult from '../../../customcontrols/noresult';

import {
  AccountInfo,
  Auth,
  RequiredPublicationDocument,
  Shared,
} from '../../../../types/publications';
import { Buttons, ToggleBtn } from '../../../customcontrols';
import { AcceptedFileFormatsModal } from '../../../common/AcceptedFileFormatsModal';
import { loadingMsg } from '../../../../utils/texts';
import { ModalPopUp } from '../../../common/modals/ModalPopUp';
import { nargsfnTypes } from '../../../../types/functions';
import { useSetBreadcrumb } from 'src/shared/hooks';

const tdWidth = {
  width50Per: {
    width: '50%',
  },
  width25Per: {
    width: '25%',
  },
};

interface propsTypes {
  getaccountinforequiredDocs: any;
  accountinfo: AccountInfo;
  submitaccountinforequiredDocsUpdate: any;
  setSharedDetails: nargsfnTypes;
  shared: Shared;
  setAccountInfoDetails: any;
  handleSubmit: any;
  submitting: boolean;
  auth: Auth;
  toggleDocAutoApproval: any;
  getDocAutoApprovalStatus: any;
  toggleSuppDocDownload: any;
  getSuppDocDownloadStatus: any;
}

function EBidDocuments(props: propsTypes) {
  const {
    getaccountinforequiredDocs,
    accountinfo,
    submitaccountinforequiredDocsUpdate,
    setSharedDetails,
    setAccountInfoDetails,
    auth,
    toggleDocAutoApproval,
    getDocAutoApprovalStatus,
    toggleSuppDocDownload,
    getSuppDocDownloadStatus,
  } = props;
  const {
    agencydocs,
    ebiddocumentPopupdata,
    ebiddocumentPopup,
    requiredDocuments_internalLoader,
    ebiddocumentPopupDelete,
    ebiddocumentreorder,
    halfWayCancel,
    autoApprovalStatus,
    suppDocDownloadStatus = false,
  } = accountinfo;
  const { requiredDocuments } = agencydocs;
  const { mc: isMainContact = false, opi = 0 } = auth || {};
  const [fileTypeModalVisible, setFileTypeModalVisible] = useState(false);

  useEffect(() => {
    setSharedDetails({ internalLoader: true });
    getaccountinforequiredDocs();
  }, [setSharedDetails, getaccountinforequiredDocs]);

  useEffect(() => {
    getDocAutoApprovalStatus();
    if (opi > 0) {
      getSuppDocDownloadStatus();
    }
  }, [auth, getDocAutoApprovalStatus, getSuppDocDownloadStatus, opi]);

  const setEditEbitModal = useCallback(
    (item?: any, actionfor = '') => {
      const finalvalue = item ? item : '';
      if (actionfor === 'delete') {
        setAccountInfoDetails({
          ebiddocumentPopup: false,
          ebiddocumentPopupDelete: true,
          ebiddocumentPopupdata: finalvalue,
        });
      } else {
        setAccountInfoDetails({
          ebiddocumentPopup: !ebiddocumentPopup,
          ebiddocumentPopupdata: finalvalue,
        });
      }
    },
    [ebiddocumentPopup, setAccountInfoDetails],
  );

  useSetBreadcrumb({
    component: (
      <>
        <li title='Add Bid Document'>
          <span className='staticLink' onClick={setEditEbitModal}>
            <i className='mdi mdi-plus mr-1' /> Add Bid Document
          </span>
        </li>
      </>
    ),
    page: 'Agency Bid Configuration',
    altname: 'eBidding Documents',
    title: 'eBidding Documents',
    name: 'eBidding Documents',
    menuactive: '',
  });

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const dragCard: RequiredPublicationDocument = requiredDocuments[dragIndex];
    setAccountInfoDetails({
      agencydocs: {
        ...agencydocs,
        requiredDocuments: update(requiredDocuments, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      },
      ebiddocumentreorder: true,
    });
  };

  const CancelDelete = () => {
    setAccountInfoDetails({
      ebiddocumentPopupDelete: false,
      ebiddocumentPopup: false,
      ebiddocumentPopupdata: '',
    });
  };

  const toggleDocApproval = (e: any) => {
    e.preventDefault();
    toggleDocAutoApproval();
  };
  const toggleSuppDocDownloadClick = (e: any) => {
    e.preventDefault();
    toggleSuppDocDownload();
  };

  return (
    <>
      <h3 className='arrowWrapper'>Required Documents</h3>
      <div className='row'>
        <div className='col-md-12 col-lg-8'>
          {isMainContact === true ? (
            <>
              <h2 className='lineHR' data-testid='publicationinfo.bids.publications.document.head'>
                Document Approval
              </h2>
              <div className='row'>
                <div className='col-12'>
                  <ToggleBtn
                    value={autoApprovalStatus}
                    classNames='class'
                    onChange={(e: any) => toggleDocApproval(e)}
                    reduxform={false}
                    checked={autoApprovalStatus}
                    label='Approve Documents Automatically'
                  />
                </div>
              </div>
              <div className='col-12 border rounded pt-4 bg-sidebarGray border-secondary mt-3 mb-5'>
                <p>
                  <b>On</b> - Each uploaded document will be approved automatically without user
                  intervention
                </p>
                <p>
                  <b>Off</b> - Each uploaded document have to be approved manually by the bid
                  approver once they receive an email
                </p>
              </div>
            </>
          ) : null}
          {opi > 0 ? (
            <>
              <h2 className='lineHR'>Download Supplier Documents Before eBid Closing</h2>
              <div className='row'>
                <div className='col-12'>
                  <ToggleBtn
                    value={suppDocDownloadStatus}
                    classNames='class'
                    onChange={(e: any) => toggleSuppDocDownloadClick(e)}
                    reduxform={false}
                    checked={suppDocDownloadStatus}
                    label='Download Supplier Documents Before eBid Closing'
                  />
                  <p>
                    Allow agency users to download supplier eBid response documents before the
                    response deadline.
                  </p>
                </div>
              </div>
              <div className='col-12 border rounded pt-4 bg-sidebarGray border-secondary mt-3 mb-5'>
                <p>
                  <b>On</b> - Agencies will be able to download the supplier response documents
                  before due date
                </p>
                <p>
                  <b>Off</b> - Agencies will not be able to download the supplier response documents
                  before due date
                </p>
                <p>
                  <b>Note: </b> -{' '}
                  {`This option is applicable only for bids that Agency has opted for "View respondent
									names and documents"`}
                </p>
              </div>
            </>
          ) : null}
          <h2 className='lineHR'>Current Required Documents ({requiredDocuments.length})</h2>
          {requiredDocuments.length > 1 && (
            <p className='border border-secondary rounded px-2 py-1 bg-purple50 text-grayD200 d-flex align-items-center'>
              <i className='mdi mdi-information text-purple300 mr-1' style={{ lineHeight: 0 }} />
              {
                'Please use "Drag & Drop" to reorder required documents  & click "Save Order" button.'
              }
            </p>
          )}
          <Table className='tableHData'>
            <tr>
              <th style={tdWidth.width25Per}>
                Display Order{' '}
                <span className='mdi ml-2 staticText mdi-sort-descending' title='Ascending' />
              </th>
              <th style={tdWidth.width50Per}>Document Description</th>
              <th style={tdWidth.width25Per} />
            </tr>
            {requiredDocuments.length > 0 ? (
              <DndProvider backend={HTML5Backend}>
                {requiredDocuments.map((item, i) => (
                  <DragDropCard
                    key={item.id}
                    index={i}
                    id={item.id}
                    text={item.description}
                    moveCard={moveCard}
                    item={item}
                    setEditEbitModal={setEditEbitModal}
                    setAccountInfoDetails={setAccountInfoDetails}
                    halfWayCancel={halfWayCancel}
                    // halfWayCancelAllow={halfWayCancelAllow}
                  />
                ))}
              </DndProvider>
            ) : (
              <tr>
                <th colSpan={6}>
                  <NoResult
                    message={
                      requiredDocuments_internalLoader
                        ? loadingMsg
                        : 'No Required Documents Available'
                    }
                  />
                </th>
              </tr>
            )}
          </Table>
          <div className='clearfix'>
            <Buttons
              classNames='bttn-primary my-4 float-right ml-5'
              text='Save Order'
              type='submit'
              disable={!ebiddocumentreorder}
              onClick={() => submitaccountinforequiredDocsUpdate({ actionfor: 'reorder' })}
            />
            <Buttons
              classNames='bttn-secondary my-4 float-right'
              text='Add'
              title='Add'
              type='button'
              onClick={setEditEbitModal}
            />
          </div>
        </div>
        <div className='col-8'>
          <h2 className='lineHR'>Supported File Formats</h2>
          <p>
            DemandStar supports many different file formats.{' '}
            <span className='staticLink' onClick={() => setFileTypeModalVisible(true)}>
              Click here to see the complete list.
            </span>
          </p>
        </div>
      </div>
      <ModalPopUp
        title={
          ebiddocumentPopupdata?.description ? 'Edit Required Documents' : 'Add Required Documents'
        }
        size='md'
        isOpen={ebiddocumentPopup}
        closeModal={setEditEbitModal}
      >
        <EbidDocumentPopup
          {...props}
          initialValues={{
            description:
              ebiddocumentPopupdata && ebiddocumentPopupdata.description
                ? ebiddocumentPopupdata.description
                : '',
          }}
          ebiddocumentPopupdata={ebiddocumentPopupdata}
          setAccountInfoDetails={setAccountInfoDetails}
          submitaccountinforequiredDocsUpdate={submitaccountinforequiredDocsUpdate}
          cancelEditdocumentPopup={() =>
            setAccountInfoDetails({ ebiddocumentPopupdata: '', ebiddocumentPopup: false })
          }
        />
      </ModalPopUp>
      <ModalPopUp
        title='Delete Confirmation'
        size='md'
        isOpen={ebiddocumentPopupDelete}
        closeModal={CancelDelete}
      >
        <>
          <p>Are you sure you want to delete?</p>
          <Buttons
            classNames='bttn-secondary mt-2'
            text='Cancel'
            title='Cancel'
            type='button'
            onClick={CancelDelete}
          />
          <Buttons
            classNames='bttn-primary  mt-2 float-right'
            text='Ok'
            title='Ok'
            type='button'
            onClick={() => submitaccountinforequiredDocsUpdate({ actionfor: 'delete' })}
          />
        </>
      </ModalPopUp>
      <AcceptedFileFormatsModal
        isOpen={fileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      />
    </>
  );
}

export default memo(EBidDocuments);
