export function compareLists<Type>(
  list1: Type[],
  list2: Type[],
  comparator?: (a: Type, b: Type) => boolean | undefined,
) {
  const defaultComparator: (a: Type, b: Type) => boolean = (a, b) => a === b;
  const compare = comparator ?? defaultComparator;

  const firstOnly = list1.filter(item1 => !list2.find(item2 => compare(item1, item2)));

  const secondOnly = list2.filter(item2 => !list1.find(item1 => compare(item2, item1)));

  const intersect = list1.filter(item1 => list2.find(item2 => compare(item1, item2)));

  return {
    firstOnly,
    secondOnly,
    intersect,
  };
}

export function removeDuplicates<T>(duplicateArray: T[], key: keyof T) {
  const lookup = {} as Record<string | number | symbol, T>;

  duplicateArray.forEach((arrObj: T) => {
    const keyVal = arrObj[key];
    if (typeof keyVal === 'string' || typeof keyVal === 'number' || typeof keyVal === 'symbol') {
      lookup[keyVal] = arrObj;
    }
  });

  return Object.keys(lookup).map(key => lookup[key]);
}
