import { connect, ConnectedProps } from 'react-redux';
import { memo, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import DashboardControl from '../../common/breadcrumb/common/dashboardcontrol';
import DashboardLeftPanel from '../../shared/dashboard/dashboardleftpanel';
import Rightside from './rightside';

import * as actionCreators from '../../../store/actions';

import { appstatetokenName } from '../../../utils/constants';
import { authTypes } from '../../../types/auth';
import { dismisstoastAllFn } from '../../../utils/helpers';
import { getBidsTypes } from '../../../types/bids';
import { memberinfoTypes } from '../../../types/dashboard';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface BuyerDashboardProps extends PropsFromRedux {
  getDashboardData: any;
  memberInfo: any;
  memberinfo: memberinfoTypes;
  getDashboardNetwork: any;
  auth: authTypes;
  history: any;
  setBidDetails: any;
  setQuotesDetails: any;
  getBids: getBidsTypes;
  getQuotesList: any;
}

function BuyerDashboard(props: BuyerDashboardProps) {
  const { getDashboardData, memberInfo, memberinfo, getDashboardNetwork } = props;
  const { mn = '', mi = '' } = memberinfo;

  useSetBreadcrumb({
    component: <DashboardControl />,
    page: 'Dashboard',
    altname: 'Dashboard',
    title: 'Dashboard',
    name: 'Dashboard',
    menuactive: 'dashboard',
  });

  useEffect(() => {
    const l_storage = localStorage.getItem(appstatetokenName) || '';
    if (l_storage) {
      const { auth: localauth } = (l_storage && JSON.parse(l_storage)) || {};
      if (localauth && localauth.opi) {
        if (mi && mi !== localauth.mi) {
          memberInfo({ isCurrentMember: true });
        }
      }
    }
  }, [memberInfo, mi, mn]);

  useEffect(() => {
    getDashboardData({});
  }, [getDashboardData]);

  useEffect(() => {
    dismisstoastAllFn();
  }, []);

  useEffect(() => {
    getDashboardNetwork({});
  }, [getDashboardNetwork]);

  return (
    <div className='container dashboardWrapper'>
      <div className='row justify-content-center'>
        <div className='col-12 col-lg-3 mb-3 mb-lg-0'>
          <div className='sideBarSpacing dashboardSidebar'>
            <DashboardLeftPanel {...props} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='colWrapper'>
            <h2 className='arrowWrapper'>{mn}</h2>
            <Rightside {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

const connector = connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
    shared: state.shared,
    auth: state.auth,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(BuyerDashboard));
