import { connect, ConnectedProps } from 'react-redux';
import { memo, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';

import Filters from './filters';
import Homecontrol from '../../common/breadcrumb/buyer/homecontrol';
import Searchlabel from './label';

import * as actionCreators from '../../../store/actions';
import {
  appKeys,
  bidSortFields,
  MemberType,
  pageSize,
  sortDirections,
  TourId,
} from '../../../utils/constants';
import { BidsList, NoResult, Paging, Sort } from '../../customcontrols';
import { bidsStateTypes, memberinfoParamType } from '../../../types/bids';
import { noCommodityCodesTexts, noResultMsg } from '../../../utils/texts';

import { authTypes } from '../../../types/auth';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { nargsfnTypes } from '../../../types/functions';
import { sharedTypes } from '../../../types/shared';
import { checkPaginationCallFn, SortingFn } from '../../../utils/helpers';
import { ToursState } from '../../../store/reducers/tours';
import { useSetBreadcrumb } from 'src/shared/hooks';

interface propsTypes extends PropsFromRedux {
  memberInfo: any;
  getFiscalYear: any;
  setAddBidDetails: any;
  setBidSummaryDetails: any;
  bidWatchClick: any;
  setBidDetails: any;
  bids: bidsStateTypes;
  shared: sharedTypes;
  memberinfo: memberinfoParamType;
  auth: authTypes;
  getCommoditiesList: any;
  getBidsDetails: any;
  getConditionspending: any;
  getSharedBidStatus: any;
  getAgenciesList: any;
  getLocationList: any;
  bidListFilterChange: any;
  getPaginatedBids: any;
  setSharedDetails: nargsfnTypes;
  tours: ToursState;
  location: Location;
  history: History;
}

const Bids = (props: propsTypes) => {
  const {
    getBids,
    bidListFilterChange,
    setAddBidDetails,
    setBidSummaryDetails,
    bidWatchClick,
    setBidDetails,
    bids,
    shared,
    memberinfo,
    auth,
    getCommoditiesList,
    getBidsDetails,
    getConditionspending,
    getSharedBidStatus,
    getAgenciesList,
    getLocationList,
    setSharedDetails,
    getPaginatedBids,
    tours,
  } = props;
  const {
    initialRequest,
    filters,
    sortingDefaultFilter,
    results = [],
    bidscurrentPage,
    selectedBidIds = '',
    upcomingBidIds = '',
    incompleteconditionslist = [],
    incompletebidid = '',
    noCommodityCodesBidsResults = false,
    total = 0,
  } = bids || {};
  const { completedOffsets = [1] } = bids || {};
  const { showBids } = filters || {};
  const { sortBy, sortOrder } = sortingDefaultFilter || {};
  const {
    fiscalYear = [],
    commodities = [],
    showBidsList = [],
    agencies = [],
    location = [],
    dashboardCommodities = [],
    bidStatusList = [],
  } = shared || {};
  const { mt: memberType } = auth || {};
  const { mi } = memberinfo || {};
  const listPerPage = pageSize.default;
  const indexOfLastList = bidscurrentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;
  const { tourStart, tourId } = tours || {};

  const getShownBidIds = useCallback(
    data => {
      const bidIds = results
        .map(item => {
          return item.bidId;
        })
        .slice(indexOfFirstList, indexOfLastList)
        .join(',');

      const upcomingBidIds = results
        .slice(indexOfFirstList, indexOfLastList)
        .filter(item1 => item1.statusType === 'UP') // should this be BidExternalStatusType.Upcoming?
        .map(item => {
          return item.bidId;
        })
        .join(',');

      setBidDetails({ selectedBidIds: bidIds, upcomingBidIds });
    },
    [indexOfFirstList, indexOfLastList, results, setBidDetails],
  );

  useSetBreadcrumb({
    component:
      memberType === MemberType.AgencyBuyer ? (
        <Homecontrol
          setAddBidDetails={setAddBidDetails}
          setBidSummaryDetails={setBidSummaryDetails}
          auth={auth}
        />
      ) : undefined,
    page: 'Bids Search',
    title: `${appKeys.headers.bids}`,
    name: 'Bids Search',
    menuactive: 'bids',
  });

  useEffect(() => {
    let getSharedBidStatusAPICALL = true;
    if (showBidsList.length === 0 && bidStatusList.length === 0 && getSharedBidStatusAPICALL) {
      getSharedBidStatus();
    }
    return () => {
      getSharedBidStatusAPICALL = false;
      setBidDetails({ isBidsLoaded: false });
    };
  }, [bidStatusList.length, getSharedBidStatus, setBidDetails, showBidsList.length]);

  useEffect(() => {
    let getLocationListAPICALL = true;
    if (location.length === 0 && getLocationListAPICALL) {
      getLocationList();
    }
    return () => {
      getLocationListAPICALL = false;
    };
  }, [location.length, getLocationList]);

  useEffect(() => {
    let getAgenciesListAPICALL = true;
    if (agencies.length === 0 && getAgenciesListAPICALL) {
      getAgenciesList();
    }
    return () => {
      getAgenciesListAPICALL = false;
    };
  }, [agencies.length, getAgenciesList]);

  useEffect(() => {
    let commoditiesAPICALL = true;
    if (commodities.length === 0 && commoditiesAPICALL) {
      getCommoditiesList();
    }
    return () => {
      commoditiesAPICALL = false;
    };
  }, [commodities.length, getCommoditiesList]);

  useEffect(() => {
    getShownBidIds(results);
  }, [results, results.length, filters, getShownBidIds]);

  useEffect(() => {
    if (selectedBidIds) {
      getBidsDetails({ bidIds: selectedBidIds, upcomingBidIds });
    }
  }, [getBidsDetails, selectedBidIds, upcomingBidIds]);

  const changeSortOrder = () => {
    const sort =
      sortOrder === sortDirections.DESC
        ? sortDirections.ASC
        : sortOrder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    const sortData = SortingFn(results, sort, sortBy);
    getShownBidIds(sortData);
    bidListFilterChange({ pageNum: 0 });
    setBidDetails({
      sortingDefaultFilter: { ...sortingDefaultFilter, sortOrder: sort, bidscurrentPage: 1 },
    });
    getBids({ preserveFilters: true });
  };

  const changeSortSelectBox = (key: string, value: string) => {
    if (value !== sortBy) {
      const sortData = SortingFn(results, sortOrder, value);
      getShownBidIds(sortData);
      bidListFilterChange({ pageNum: 0 });
      setBidDetails({
        sortingDefaultFilter: { ...sortingDefaultFilter, sortBy: value, bidscurrentPage: 1 },
      });
      getBids({ preserveFilters: true });
    }
  };

  const moveToNextPage = (pageNo: number) => {
    const last = pageNo * listPerPage;
    const first = last - listPerPage;
    const bidIds = results
      .map(item => {
        return item.bidId;
      })
      .slice(first, last)
      .join(',');

    const upcomingBidIds = results
      .slice(first, last)
      .filter(item1 => item1.statusType === 'UP') // should this be BidExternalStatusType.Upcoming?
      .map(item => {
        return item.bidId;
      })
      .join(',');

    setBidDetails({ selectedBidIds: bidIds, upcomingBidIds, bidscurrentPage: Number(pageNo) });
  };

  const onPagingClick = (value: number) => {
    if (value !== bidscurrentPage) {
      const currentOffset = Math.trunc(value / 10) + 1;
      if (
        checkPaginationCallFn(value, listPerPage, results, total) &&
        !completedOffsets.includes(currentOffset)
      ) {
        completedOffsets.push(currentOffset);
        bidListFilterChange({ pageNum: currentOffset });
        getPaginatedBids({ preserveFilters: true });
      }
      moveToNextPage(value);
    }
  };

  let bidNoResultMsg: string | (string | JSX.Element)[] =
    memberType === MemberType.SubscribingSupplier && showBids === 'Notified'
      ? noResultMsg.bidSupplier
      : noResultMsg.bidSearch;

  if (noCommodityCodesBidsResults) {
    bidNoResultMsg = [
      noCommodityCodesTexts,
      <Link key={1} to='/suppliers/account/commoditycodes/edit'>
        Subscribe to some here.
      </Link>,
    ];
  }

  const tourPosition: any = tourStart && tourId === TourId.bidSearch ? { paddingTop: '0' } : {};

  const memoizedGetBids = useCallback(args => getBids(args), [getBids]);
  const memoizedBidListFilterChange = useCallback(
    args => bidListFilterChange(args),
    [bidListFilterChange],
  );
  const filterProps = {
    ...props,
    getBids: memoizedGetBids,
    bidListFilterChange: memoizedBidListFilterChange,
  };
  return (
    <div className='container' style={tourPosition}>
      <div className='row'>
        <Filters {...filterProps} />
        <div className='col-12 col-lg-9 col-xl-9'>
          <div className='colWrapper' data-tut='reactour__agency_2'>
            <h2 className='arrowWrapper mr-auto'>Bids</h2>
            <Searchlabel {...props} />
            <div className='headerFilter clearfix d-flex align-items-center justify-content-end'>
              <b className='mr-3'>Sort By</b>
              <Sort
                sortFields={bidSortFields}
                selectedFieldName={sortBy}
                onChange={changeSortSelectBox}
                onSortClick={changeSortOrder}
                sortIcon={sortOrder}
              />
            </div>
            <div
              className={'scrollContent ' + (results.length < 10 ? '' : '')}
              data-testid='bids.search.result.list'
            >
              {results.length > 0 ? (
                results
                  .map((item, index) => (
                    <BidsList
                      key={index}
                      {...item}
                      memberType={memberType}
                      getConditionspending={getConditionspending}
                      incompleteconditionslist={incompleteconditionslist}
                      incompletebidid={incompletebidid}
                      setBidDetails={setBidDetails}
                      onWatchClick={bidWatchClick}
                    />
                  ))
                  .slice(indexOfFirstList, indexOfLastList)
              ) : (
                <NoResult message={bidNoResultMsg} />
              )}
            </div>
            {results.length > listPerPage ? (
              <footer className='row'>
                <div className='col-12 '>
                  <Paging
                    totalRecords={total}
                    currentPage={bidscurrentPage}
                    onPagingClick={onPagingClick}
                    pageLimit={listPerPage}
                    directpage={false}
                  />
                </div>
              </footer>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const connector = connect(
  (state: any) => ({
    memberinfo: state.memberinfo,
    shared: state.shared,
    auth: state.auth,
    bids: state.bids,
    tours: state.tours,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(memo(Bids));
