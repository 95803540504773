import { memo, useEffect, useState } from 'react';

import { authTypes, MembershipLevel, Permission } from '../../../../../types/auth';
import {
  BidExternalStatusType,
  EbidresponseSortFields,
  pageSize,
  sortDirections,
} from '../../../../../utils/constants';
import {
  bidItemParamType,
  bidssummaryParamType,
  EBidResponseFull,
  ebidsParamType,
} from '../../../../../types/biddetail';
import { Buttons, Paging, Sort } from '../../../../customcontrols';
import {
  deprecatedCountDownTimerTimeZone,
  deprecatedGetDateTimeZoneConvertedDate,
  SortingFn,
} from '../../../../../utils/helpers';
import { WizardPage, WizardStatus } from '../../../../../types/wizard';
import { loadingMsg } from '../../../../../utils/texts';
import { memberinfoParamType } from '../../../../../types/bids';
import { ModalPopUp } from '../../../../common/modals/ModalPopUp';
import { NoResult } from '../../../../customcontrols/index';

import AddNewResponse from './addnewresponse';
import CountDownTimer from '../../../../common/countdowntimer';
import Documents from '../../../../shared/bidsdetail/lefttab/myebidresponse/documents';
import EbidSupplierDetails from './ebidsupplierdetails';
import history from '../../../../../utils/history';
import imgsCdn from '../../../../../assets/images/imagescdn';

const envelopeBG = imgsCdn.otherImg.envelope;

interface PropsTypes {
  getbidsEbidResponse?: any;
  setBidsResponseData?: any;
  bidsResponseDocumentDownload?: any;
  downloadAllDocument?: any;
  setSelectedBidsResponseData?: any;
  ebids?: ebidsParamType;
  auth: authTypes;
  bidssummary?: bidssummaryParamType;
  internalLoader?: { internalLoaderPages: Array<string> };
  setSharedDetails?: any;
  dueDate: string;
  memberinfo?: memberinfoParamType;
  match?: any;
  setEbidDetails: any;
  setBidSummaryDetails: any;
  updateBidsStatus?: any;
}

const currentPageName = 'ebidresponse' as string;

function EbidResponse(props: PropsTypes) {
  const {
    getbidsEbidResponse,
    setBidsResponseData,
    bidsResponseDocumentDownload,
    downloadAllDocument,
    setSelectedBidsResponseData,
    ebids,
    auth,
    bidssummary,
    internalLoader,
    setSharedDetails,
    dueDate,
    memberinfo,
    setEbidDetails,
    setBidSummaryDetails,
    updateBidsStatus,
  } = props;
  const bidId = parseInt(props.match.params.bidId);
  const { mi: memberId = 0 } = auth || {};
  const { sdbd = false } = memberinfo || {};
  const { ebidResponse = [], currentEbidResponse, selectedEbidResponses = [] } = ebids || {};
  const { results } = bidssummary || {};
  const {
    bidExternalStatusType = BidExternalStatusType.None,
    tse: tabSheetOption,
    dueDate: bidOpenDate,
    tzfn,
    bidIdentifier,
  } = (results as bidItemParamType) || {};
  let suppliers_list = ebidResponse as EBidResponseFull[];
  const { internalLoaderPages = [] } = internalLoader || {};
  const [countdownDueDate, setcountDownDueDate] = useState('');
  const { ml = '', prms = '' } = auth;
  const mlcheck = ml.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = prms.replace(/ /g, '').split(',') as Permission[];

  useEffect(() => {
    if (bidId && ebidResponse.length === 0) {
      setSharedDetails({ internalLoader: true });
      getbidsEbidResponse({ bidId, memberId });
    }
  }, [setSharedDetails, bidId, getbidsEbidResponse, memberId, ebidResponse.length]);

  useEffect(() => {
    if (!currentEbidResponse && suppliers_list.length > 0) {
      const value = suppliers_list[0];
      value.index = 0;
      setSharedDetails({ internalLoader: true });
      setBidsResponseData({
        bidId: bidId,
        responseId: value.responseId,
        index: 0,
        supplierName: value.name,
        memberId: value.supplierMemberId,
        addressId: value.supplierAddressId,
      });
    }
  }, [currentEbidResponse, suppliers_list, setBidsResponseData, bidId, setSharedDetails]);

  useEffect(() => {
    let CheckOpsLocalStorage: any;
    if (
      bidId &&
      sdbd === false &&
      bidExternalStatusType === BidExternalStatusType.Active &&
      countdownDueDate !== 'Closed'
    ) {
      CheckOpsLocalStorage = setInterval(() => {
        const checkBidDueDate = bidOpenDate && deprecatedCountDownTimerTimeZone(bidOpenDate, tzfn);
        setcountDownDueDate(checkBidDueDate);
        if (checkBidDueDate === 'Closed') {
          setBidSummaryDetails({
            results: {
              ...results,
              bidExternalStatusType: BidExternalStatusType.UnderEvaluation,
              bidExternalStatus: 'Under Evaluation',
            },
          });
          clearInterval(CheckOpsLocalStorage);
        }
      }, 1000);
    }
    return () => clearInterval(CheckOpsLocalStorage);
  }, [
    bidId,
    bidExternalStatusType,
    countdownDueDate,
    setcountDownDueDate,
    sdbd,
    bidOpenDate,
    tzfn,
    setBidSummaryDetails,
    results,
  ]);

  // Logic for displaying pagination
  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.default;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;
  // const currentLists = suppliers_list.slice(indexOfFirstList, indexOfLastList) || []

  const [newResponseModal, setNewResponseModal] = useState(false);

  const handleNewResponseModal = (e: any) => {
    e.preventDefault();
    setNewResponseModal(!newResponseModal);
  };

  const handleEbidResponse = (selectedPlanholder: number) => {
    const wizard: WizardPage[] = [
      { name: 'Contact Information', status: WizardStatus.Current, id: 1 },
      { name: 'Documents Upload', status: WizardStatus.Unavailable, id: 2 },
      { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
    ];
    setEbidDetails({ wizard });
    setNewResponseModal(!newResponseModal);
    history.push(`/buyers/bids/${bidId}/addresponse/${selectedPlanholder}`);
  };

  const [sortvalue, setSortvalue] = useState('responseDate');
  const [sortorder, setSortorder] = useState(sortDirections.DESC);

  const changeSortSelectBox = (key: string, value: string) => {
    if (value !== sortvalue) {
      setSortvalue(value);
      suppliers_list = SortingFn(suppliers_list, sortorder, value);
    }
  };

  const changeSortOrder = () => {
    const sort =
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort;
    setSortorder(
      sortorder === sortDirections.DESC
        ? sortDirections.ASC
        : sortorder === sortDirections.ASC
        ? sortDirections.DESC
        : sortDirections.noSort,
    );
    suppliers_list = SortingFn(suppliers_list, sort, sortvalue);
  };

  let checkduedate = bidOpenDate && deprecatedCountDownTimerTimeZone(bidOpenDate, tzfn);

  const dueDateEnvelope = dueDate && (
    <div className='d-flex justify-content-center align-items-center h-100 w-100 envelopeWrapper'>
      <img src={envelopeBG} className='img-fluid' alt='envelope' />
      <div className='envelopeTxt'>
        {checkduedate === 'closed' ? (
          <h4>No Response Found</h4>
        ) : (
          <>
            <CountDownTimer
              dueDate={bidOpenDate}
              tzfn={tzfn}
              pageFor='ebidresponse'
              component={(Timer: any) => {
                if (Timer === 'closed' && checkduedate !== 'Closed') {
                  setSharedDetails({ internalLoader: true });
                  getbidsEbidResponse({ bidId, memberId });
                  setBidSummaryDetails({
                    results: {
                      ...results,
                      bidExternalStatusType: BidExternalStatusType.UnderEvaluation,
                      bidExternalStatus: 'Under Evaluation',
                    },
                  });
                  updateBidsStatus({ id: bidId, type: 'Bid' });
                  checkduedate = bidOpenDate && deprecatedCountDownTimerTimeZone(bidOpenDate, tzfn);
                }
                return (
                  <>
                    <h4>This bid will open in</h4>
                    <h3> {Timer} </h3>
                  </>
                );
              }}
              custom={true}
            />
          </>
        )}
      </div>
    </div>
  );

  function toggleNewResponseModal() {
    setNewResponseModal(!newResponseModal);
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 d-flex'>
          <h3 className='mb-3 mr-3 flex1'>
            <span className='arrowWrapper'>eBid Response</span>
          </h3>
          {Number(tabSheetOption) === 0 &&
          bidExternalStatusType === BidExternalStatusType.Active ? null : (
            <>
              {[
                BidExternalStatusType.Active,
                BidExternalStatusType.UnderEvaluation,
                BidExternalStatusType.Awarded,
              ].includes(bidExternalStatusType) &&
              mlcheck.includes(MembershipLevel.BidPoster) &&
              prmscheck.includes(Permission.ManageBids) ? (
                <Buttons
                  text='Add Response'
                  title='Add Response'
                  onClick={toggleNewResponseModal}
                  classNames='bttn bttn-secondary small float-right mr-2'
                />
              ) : null}
            </>
          )}
        </div>
        <div className='col-12'>
          <div className='float-left  mr-2'>
            <b>Bid Identifier: </b>
            {bidIdentifier}
          </div>
        </div>
        {checkduedate !== 'Closed' &&
        Number(tabSheetOption) === 0 &&
        bidExternalStatusType === BidExternalStatusType.Active ? (
          dueDateEnvelope
        ) : suppliers_list && suppliers_list.length > 0 ? (
          <>
            <div className='col-lg-4'>
              <div className='ulListWrapper'>
                <h4>Supplier</h4>
                <header className='headerFilter d-flex align-items-center clearfix pl-0'>
                  <Sort
                    sortFields={EbidresponseSortFields}
                    selectedFieldName={sortvalue}
                    onChange={changeSortSelectBox}
                    onSortClick={changeSortOrder}
                    sortIcon={sortorder}
                    parentClass='flex1'
                  />
                </header>
                <ul className='list-unstyled clearfix'>
                  {suppliers_list
                    .map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          if (
                            currentEbidResponse &&
                            item.responseId !== currentEbidResponse.responseId
                          ) {
                            item.index = index;
                            const selectEbidResponse = selectedEbidResponses.filter(
                              fitem => fitem.responseId === item.responseId,
                            );
                            if (
                              selectEbidResponse.length === 0 &&
                              (bidExternalStatusType !== BidExternalStatusType.Active ||
                                Number(tabSheetOption) === 1 ||
                                Number(tabSheetOption) === 3)
                            )
                              setBidsResponseData({
                                bidId: bidId,
                                responseId: item.responseId,
                                index: item.index,
                                supplierName: item.name,
                                memberId: item.supplierMemberId,
                                addressId: item.supplierAddressId,
                              });
                            else {
                              let selectedEbidResponse = item;
                              if (selectEbidResponse.length > 0)
                                selectedEbidResponse = selectEbidResponse[0];
                              setSelectedBidsResponseData(selectedEbidResponse);
                            }
                          }
                        }}
                        className={
                          currentEbidResponse && item.responseId === currentEbidResponse.responseId
                            ? 'active'
                            : ''
                        }
                      >
                        <h6 className='mw-100 text-truncate'>{item.name}</h6>
                        {item.responseDate ? (
                          <span>
                            <i>Responded Date :</i>{' '}
                            {deprecatedGetDateTimeZoneConvertedDate(item.responseDate, tzfn)}
                          </span>
                        ) : null}
                      </li>
                    ))
                    .slice(indexOfFirstList, indexOfLastList)}
                </ul>
                <Paging
                  totalRecords={suppliers_list.length}
                  currentPage={currentPage}
                  pagefor='planholders-supplier'
                  onPagingClick={(value: number) => {
                    if (value !== currentPage) setCurrentPage(Number(value));
                  }}
                  pageLimit={listPerPage}
                />
              </div>
            </div>
            {bidExternalStatusType !== BidExternalStatusType.Active ||
            Number(tabSheetOption) === 1 ||
            Number(tabSheetOption) === 3 ? (
              <div className='col-lg-8 responsecontent'>
                <EbidSupplierDetails {...props} />
                <Documents
                  data={currentEbidResponse ? currentEbidResponse.requiredDocuments : []}
                  bidID={currentEbidResponse ? currentEbidResponse.bidId : null}
                  responseId={currentEbidResponse ? currentEbidResponse.responseId : null}
                  documentTitle='Required Documents'
                  documentType='1'
                  checkduedate={
                    checkduedate === 'Closed' || Number(tabSheetOption) === 3 || sdbd ? true : false
                  }
                  bidsResponseDocumentDownload={bidsResponseDocumentDownload}
                  downloadAllDocument={downloadAllDocument}
                />
                <Documents
                  data={currentEbidResponse ? currentEbidResponse.supplementalDocuments : []}
                  bidID={currentEbidResponse ? currentEbidResponse.bidId : null}
                  responseId={currentEbidResponse ? currentEbidResponse.responseId : null}
                  documentTitle='Supplemental Documents'
                  documentType='1'
                  pagefor='ebidresponse'
                  checkduedate={
                    checkduedate === 'Closed' || Number(tabSheetOption) === 3 || sdbd ? true : false
                  }
                  bidsResponseDocumentDownload={bidsResponseDocumentDownload}
                  downloadAllDocument={downloadAllDocument}
                />
              </div>
            ) : null}
          </>
        ) : (
          <NoResult
            message={
              internalLoaderPages.includes(currentPageName)
                ? loadingMsg
                : 'No eBid Response Available'
            }
          />
        )}
      </div>
      <ModalPopUp
        title='Add eBid Response'
        size='lg'
        isOpen={newResponseModal}
        closeModal={handleNewResponseModal}
      >
        <AddNewResponse {...props} handleOnClick={handleEbidResponse} />
      </ModalPopUp>
    </>
  );
}

export default memo(EbidResponse);
