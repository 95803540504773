import { memo, useCallback, useEffect, useState } from 'react';

import { Buttons, CheckBox, DeprecatedInput, Paging } from '../../../customcontrols';
import { deprecatedGetDate, Searchingfn, toastFn } from '../../../../utils/helpers';
import { pageSize, sortDirections } from '../../../../utils/constants';
import { addbidParamType } from '../../../../types/addbid';
import { authTypes } from '../../../../types/auth';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { loadingMsg } from '../../../../utils/texts';
import { sharedTypes } from '../../../../types/shared';
import { Table } from 'reactstrap';
import { useForceUpdate } from '../../../../utils/helperHooks';

import NoResult from '../../../customcontrols/noresult';
import SortingIcon from '../../sortingIcon';

interface PropsTypes {
  submitAddBidGetPreBuiltList?: any;
  setAddBidDetails?: any;
  addbid?: addbidParamType;
  shared?: sharedTypes;
  submitAddBidDeleteList?: any;
  setFilteredPreBuiltList?: any;
  SubmitAddBidusePreBuiltList?: any;
  getListCountDetails?: any;
  pagefor?: string;
  quotesId?: number | string;
  bidId?: number;
  auth?: authTypes;
  Setprebuiltmodal?: any;
}

const Prebuiltlist = (props: PropsTypes) => {
  const forceUpdate = useForceUpdate();
  const {
    submitAddBidGetPreBuiltList,
    //getCommodityCodes,
    setAddBidDetails,
    addbid,
    shared,
    submitAddBidDeleteList,
    setFilteredPreBuiltList,
    SubmitAddBidusePreBuiltList,
    getListCountDetails,
    pagefor,
    quotesId,
    bidId,
    auth,
  } = props;
  const { internalLoader } = shared || {};
  const {
    preBuiltBroadCastList = [],
    filteredPreBuiltList = [],
    confirmChanges = false,
    commodityAlert = false,
    selectedListIds = '',
  } = addbid || {};

  const { /* mi: memberId, */ us: accountId, mc: isMainContact } = auth || {};

  /* const { commoditieslist = [] } = commoditycodes

	useEffect(() => {
		if (commoditieslist && commoditieslist.length === 0) getCommodityCodes({ pagefor: 'addbid' })
	}, [commoditieslist, getCommodityCodes])

	const selectedcomodity =
		(commoditieslist &&
			commoditieslist.length > 0 &&
			commoditieslist.filter(item => item.isExisting && item.isSelected)) ||
		[]

	const newSelectedcomodity =
		(commoditieslist &&
			commoditieslist.length > 0 &&
			commoditieslist.filter(item => !item.isExisting && item.isSelected)) ||
		[] */

  const [currentPage, setCurrentPage] = useState(1);
  const listPerPage = pageSize.prebuiltList;
  const indexOfLastList = currentPage * listPerPage;
  const indexOfFirstList = indexOfLastList - listPerPage;

  const id = pagefor === 'addquotes' ? quotesId : bidId;
  const type = pagefor === 'addquotes' ? 'Quote' : 'Bid';

  useEffect(() => {
    submitAddBidGetPreBuiltList({ id, type });
  }, [bidId, id, submitAddBidGetPreBuiltList, type]);

  const filterPredefinedList = (name: string, value: any) => {
    const filterResult = Searchingfn(preBuiltBroadCastList, ['name'], value);
    setFilteredPreBuiltList(filterResult);
  };

  const getShownListIds = useCallback(() => {
    const listIds = filteredPreBuiltList
      .map(item => {
        return item.listId;
      })
      .slice(indexOfFirstList, indexOfLastList)
      .join(',');
    setAddBidDetails({ selectedListIds: listIds });
  }, [indexOfFirstList, indexOfLastList, filteredPreBuiltList, setAddBidDetails]);

  useEffect(() => {
    getShownListIds();
  }, [getShownListIds, filteredPreBuiltList]);
  /* TODO: Previously, we were passing in filteredPreBuiltList into getShownListIds, which took
    (data: any) as a parameter, but did not do anything with data. Having taken the parameter
    out, I've chosen to keep this dependency in since I'm not familiar with this code. However, I
    suspect we can remove it. If you're in this area of the code, please consider removing
    filteredPreBuiltList (and of course using useDSSelector or recoil instead of passing it through
    props.)
  */

  useEffect(() => {
    if (selectedListIds) getListCountDetails({ listIds: selectedListIds });
  }, [getListCountDetails, selectedListIds]);

  const selectPreBuiltList = (list: any, value: any) => {
    const newlist = filteredPreBuiltList.map(item => {
      if (item.listId === list.listId) {
        item.selected = value;
      }
      return item;
    });
    setAddBidDetails({ filteredPreBuiltList: newlist });
    forceUpdate();
  };

  const filterlist = filteredPreBuiltList.filter(item => item.selected);

  const addPreBuiltList = () => {
    if (filterlist.length > 0) {
      SubmitAddBidusePreBuiltList({ listIds: filterlist.map(item => item.listId), id, type });
    } else {
      toastFn('error', 'Please Select List', 'Selecting Prebuilt List');
    }
  };

  const cancelBroadcastListModal = () => {
    setAddBidDetails({ confirmChanges: false });
  };

  const onConfirm = () => {
    cancelBroadcastListModal();
    if (filterlist.length > 0) {
      SubmitAddBidusePreBuiltList({ listIds: filterlist.map(item => item.listId), id, type });
    } else {
      toastFn('error', 'Please Select List', 'Selecting Prebuilt List');
    }
  };

  const [sortvalue, setSortvalue] = useState('name');

  return (
    <>
      <DeprecatedInput
        label='Search'
        type='search'
        handleChange={filterPredefinedList}
        placeholder='Search by Broadcast Name'
        iconType='search'
      />
      <Table className='tableHdata'>
        <tr>
          <th></th>
          <th>
            Broadcast List
            <SortingIcon
              sortlist={filteredPreBuiltList}
              initialsortorder={sortDirections.ASC}
              currentsortname={sortvalue}
              changesortname={(value: string) => setSortvalue(value)}
              sortname='name'
              changeSortingFn={(list: any) => setAddBidDetails(list)}
            />
          </th>
          <th>Created By</th>
          <th># Suppliers</th>
          <th>Last Updated</th>
          <th>Action</th>
        </tr>
        {filteredPreBuiltList.length > 0 ? (
          <>
            {filteredPreBuiltList
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <CheckBox
                      label=''
                      checked={item.selected}
                      handleChecked={(name: string, value: any) => selectPreBuiltList(item, value)}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.createdBy}</td>
                  <td>{item.noOfMembers ? item.noOfMembers : '#'}</td>
                  <td>{deprecatedGetDate(item.lastUpdatedOn)}</td>
                  <td>
                    {accountId === item.accountId || isMainContact ? (
                      <i
                        onClick={() =>
                          submitAddBidDeleteList({ listId: item.listId, currentindex: index })
                        }
                        className='mdi mdi-delete-forever-outline mdi-24px staticLink'
                      />
                    ) : null}
                  </td>
                </tr>
              ))
              .slice(indexOfFirstList, indexOfLastList)}
          </>
        ) : (
          <tr>
            <td colSpan={5}>
              <NoResult message={internalLoader ? loadingMsg : 'No Broadcast Available'} />
            </td>
          </tr>
        )}
      </Table>
      <Paging
        totalRecords={filteredPreBuiltList.length}
        currentPage={currentPage}
        pagefor='planholders-supplier'
        onPagingClick={(value: number) => {
          if (value !== currentPage) setCurrentPage(Number(value));
        }}
        pageLimit={listPerPage}
      />
      <div className='d-flex justify-content-end'>
        <Buttons
          classNames='bttn-primary mt-5 float-right'
          text='Use Selected'
          title='Use Selected'
          type='button'
          onClick={() =>
            commodityAlert === false
              ? setAddBidDetails({ confirmChanges: true })
              : addPreBuiltList()
          }
          disable={filterlist.length === 0 || filteredPreBuiltList.length === 0}
        />
      </div>
      <ConfirmModal
        onConfirm={onConfirm}
        closeModal={cancelBroadcastListModal}
        title='Confirm Changes'
        isOpen={confirmChanges}
        size='md'
      >
        This will overwrite your previous commodities/suppliers selections. Do you want to proceed
        now?
      </ConfirmModal>
    </>
  );
};

export default memo(Prebuiltlist);
