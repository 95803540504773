// TOREFACTOR: Rename file to InitialData.tsx

import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';
import { bindActionCreators } from 'redux';
import { MemberType, tokenCookieName } from '../utils/constants';
import { ReduxAction } from '../store/actions/utils';
import { authTypes } from '../types/auth';
import { actionPayloadTypes } from '../types/actiontypedef';
import { logOutFn, toastFn } from '../utils/helpers';
import { getCookie } from 'src/utils/cookie';

interface Propstypes {
  getTopCommodities: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyrequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getAddAgencyBidTypes: (payload?: actionPayloadTypes) => ReduxAction;
  auth: authTypes;
  getAccountInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getStateList: (payload?: actionPayloadTypes) => ReduxAction;
  getFiscalYear: (payload?: actionPayloadTypes) => ReduxAction;
  memberInfo: (payload?: actionPayloadTypes) => ReduxAction;
  getSelfDeclarations: (payload?: actionPayloadTypes) => ReduxAction;
  getAmplitudeUserProperties: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountInfoCommodity: (payload?: actionPayloadTypes) => ReduxAction;
  getToursConfigurations: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinforequiredDocs: (payload?: actionPayloadTypes) => ReduxAction;
  getaccountinfobidtypes: (payload?: actionPayloadTypes) => ReduxAction;
  children?: Component;
}

function InitialData(props: Propstypes) {
  const {
    getTopCommodities,
    getAddAgencyrequiredDocs,
    auth,
    getAccountInfo,
    getStateList,
    getFiscalYear,
    memberInfo,
    getSelfDeclarations,
    getAmplitudeUserProperties,
    getaccountInfoCommodity,
    getToursConfigurations,
    getaccountinforequiredDocs,
    getaccountinfobidtypes,
    children,
  } = props;
  const { mi = '', mt = '', opi = '', tk = '' } = auth || {};

  const token = getCookie(tokenCookieName);

  useEffect(() => {
    if (mt && mt === MemberType.ContentExtractor) {
      logOutFn();
      toastFn('error', 'Unauthorizied', 'loginunauthorized');
    }
  }, [mt]);

  useEffect(() => {
    if (mt && mt !== MemberType.AgencyBuyer && mt !== MemberType.ContentExtractor && tk === token)
      getTopCommodities({ type: 'Member' });
  }, [getTopCommodities, mt, tk, token]);

  useEffect(() => {
    if (mt && mt === MemberType.AgencyBuyer && tk === token) getaccountinfobidtypes();
  }, [mi, getaccountinfobidtypes, mt, opi, tk, token]);

  /* useEffect(() => {
    if (mt && mt === MemberType.AgencyBuyer) getAddAgencyrequiredDocs({ buyerId: mi });
  }, [mt, mi, getAddAgencyrequiredDocs]); */

  useEffect(() => {
    if (mt && mt === MemberType.AgencyBuyer && tk === token) getaccountinforequiredDocs();
  }, [mt, mi, getaccountinforequiredDocs, tk, token]);

  useEffect(() => {
    if (mt && mt !== MemberType.AgencyBuyer && mt !== MemberType.ContentExtractor)
      getAmplitudeUserProperties();
  }, [mt, getAmplitudeUserProperties, tk, token]);

  useEffect(() => {
    if (
      mt &&
      mt !== MemberType.AgencyBuyer &&
      mt !== MemberType.BasicSupplier &&
      mt !== MemberType.ContentExtractor
    )
      getaccountInfoCommodity();
  }, [mt, getaccountInfoCommodity]);

  useEffect(() => {
    let getAccountinfoApiCall = true;
    if (getAccountinfoApiCall && mt && mt !== MemberType.ContentExtractor) getAccountInfo();
    return () => {
      getAccountinfoApiCall = false;
    };
  }, [getAccountInfo, mt]);

  useEffect(() => {
    if (mt && mt !== MemberType.ContentExtractor) memberInfo({ isCurrentMember: true });
  }, [memberInfo, mt]);

  useEffect(() => {
    if (mt !== MemberType.ContentExtractor) getStateList();
  }, [mt, getStateList]);

  useEffect(() => {
    if (mt && mt !== MemberType.ContentExtractor) getFiscalYear();
  }, [getFiscalYear, mt]);

  useEffect(() => {
    if (mt && mt !== MemberType.ContentExtractor) getSelfDeclarations();
  }, [getSelfDeclarations, mt]);

  useEffect(() => {
    if (mt && mt !== MemberType.ContentExtractor && !opi) getToursConfigurations();
  }, [getToursConfigurations, mt, opi]);

  return <>{children}</>;
}
const connector = connect(
  (state: any) => ({
    shared: state.shared,
    commoditycodes: state.commoditycodes,
    accountinfo: state.accountinfo,
    auth: state.auth,
    memberinfo: state.memberinfo,
    bidssummary: state.bidssummary,
    tours: state.tours,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

export default connector(InitialData);
