import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import { convertPriceToDollarsAndCents, formatDollar } from '../../utils';
import { FieldContainer, FieldProps, getFieldContainerProps } from '../../field-container';
import { BaseTextInput } from '../..';
import { Inactive } from 'src/types/shared';
import { NAWrapper } from './NAWrapper';

export const NADollarDisplay = '--';
const dollarMaxLength = 20;

export type DSDollarFieldProps = FieldProps &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'autoFocus'> &
  Inactive & {
    /** sets whether NaN is an appropriate value. */
    allowNA?: boolean;
    /** optional function that calls on value change. */
    onUpdate?: () => void;
  };

/** Dollar field which converts text into valid dollars.
 * @example <DSDollarField
          name={fieldName}
          allowNA
        />
 * */
export const DSDollarField = (props: DSDollarFieldProps) => {
  const { allowNA, autoFocus, dataTestId, inactive, name, optional, onUpdate } = props;

  const methods = useFormContext();
  const { control, errors, setValue, watch } = methods;
  const [preventUpdate, setPreventUpdate] = useState(autoFocus);

  const setDisplayValue = useCallback(
    (value: string) => {
      setValue(name, value);
    },
    [name, setValue],
  );

  /** the number value of the input */
  const value: number = watch(name);

  /** The correct string value we should be displaying when the input is not focused. */
  const trueDisplay = useMemo(() => {
    if (value === 0 || value === undefined) {
      return '$0.00';
    }

    if (Number.isNaN(value)) {
      return NADollarDisplay;
    }

    return formatDollar(value);
  }, [value]);

  // /** true if defaultValue is a nonzero falsy value.*/
  const notApplicable = useMemo(() => Number.isNaN(value), [value]);

  const registerOptions: RegisterOptions = {
    required: !optional,
    setValueAs: getNumericValue,
    maxLength: dollarMaxLength,
  };

  const error = get(errors || methods.formState.errors, name);

  useEffect(() => {
    /** keeps the display in sync after blurs.
     * Does not get triggered while the user is typing. */
    if (!preventUpdate) {
      setDisplayValue(trueDisplay);
    } else if (value === 0) {
      /** This makes it so that the user does not have to backspace $0.00 and can type right out the gate */
      setDisplayValue('$');
    }
  }, [preventUpdate, setDisplayValue, trueDisplay, value]);

  /** Converts the dollar and cent value into a proper number.
   * @param   {string} price
   * @returns {number}
   * @example getDefaultValue("500") // 500
   * @example getDefaultValue("$9,999.99") // 9999.99
   */
  function getNumericValue(price?: string): number {
    if (price === NADollarDisplay) {
      return NaN;
    }
    if (!price) {
      return 0;
    }
    const { dollars, cents } = convertPriceToDollarsAndCents(price);
    return parseInt(dollars, 10) + parseInt(cents, 10) / 100;
  }

  /** changes the defaultValue from '' to 0, or from any number to '' */
  function toggleNA() {
    if (Number.isNaN(value)) {
      setDisplayValue('$0.00');
    } else {
      setDisplayValue('--');
    }
    if (onUpdate) onUpdate();
  }

  /** sets preventUpdate to true, which triggers replacing the $0.00 value with $ for easy UX,
   * so the user can immediately start typing after focusing. */
  function onFocus() {
    setPreventUpdate(true);
  }

  return (
    <Controller
      control={control}
      defaultValue={trueDisplay}
      name={name}
      rules={registerOptions}
      render={({ name, onChange, ref, value, onBlur }) => {
        function formatPrice() {
          setPreventUpdate(false);
          setDisplayValue(trueDisplay === '$' ? '$0.00' : trueDisplay);
          if (onUpdate) onUpdate();
          onBlur();
        }

        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <NAWrapper
              name={name}
              notApplicable={notApplicable}
              onNAClick={allowNA ? toggleNA : undefined}
              inactive={!!inactive}
            >
              <BaseTextInput
                autoFocus={autoFocus}
                data-testid={dataTestId}
                error={error}
                hasNA={allowNA}
                id={name}
                isNumeric
                maxLength={dollarMaxLength}
                name={name}
                onBlur={formatPrice}
                onChange={onChange}
                onFocus={onFocus}
                readOnly={inactive}
                ref={ref}
                value={value}
              />
            </NAWrapper>
          </FieldContainer>
        );
      }}
    />
  );
};
