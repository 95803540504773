import { ChangeEvent, InputHTMLAttributes, useCallback, useMemo } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import {
  BaseFileInput,
  FileInputButton,
  FileInputContainer,
  FileNameWrapper,
} from './../../inputs/file/DSFileInput.styles';
import {
  FieldContainer,
  FieldProps,
  getFieldContainerProps,
} from '../../field-container/FieldContainer';
import { Inactive, Rules, Title } from './../../types';
import { DSLogoStar } from './../../progress-indicator/DSLogoStar';
import { FileUploadText } from './../../inputs/file/constants';
import { getError } from './../../utils/react-hook-form';
import { getFileNames } from './../../utils';
import { LogoStarSize } from './../../progress-indicator/DSProgressIndicator.d';

const ProgressIndicatorWrapper = styled.div`
  position: absolute;
  width: 4rem;
  height: 1.5rem;
  margin: 0.625rem;
`;

export type DSFileFieldProps = FieldProps &
  Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'autoFocus' | 'placeholder' | 'onFocus' | 'maxLength' | 'name'
  > &
  Inactive &
  Rules &
  Title & {
    /** {boolean} toggle allowing multiple files to be selected */
    allowMultiple?: boolean;
    /** {boolean} flag to clear text input when uploading */
    uploadProgress?: number;
  };

/**
 * @example
 * <FormProvider {...methods}>
 *   <DSFileField
 *     name={fieldName}
 *     uploadProgress={uploadProgress}
 *   />
 * </>
 * */
export const DSFileField = (props: DSFileFieldProps) => {
  const {
    allowMultiple,
    dataTestId,
    inactive,
    label,
    message,
    name,
    optional,
    rules,
    title,
    uploadProgress = 0,
  } = props;

  const methods = useFormContext();
  const { control, errors, watch, setValue, trigger } = methods;

  const value: FileList = watch(name);

  const uploading = useMemo(() => uploadProgress > 0 && uploadProgress < 100, [uploadProgress]);

  const registerOptions: RegisterOptions = {
    ...rules,
    validate: {
      ...rules?.validate,
      hasFile: (v: FileList) => {
        if (optional) {
          return true;
        }
        return v.length > 0 || `${label} is required`;
      },
    },
  };

  const error = getError(errors, name);

  /** simple callback for developer ease. */
  const setFileValue = useCallback(
    (value: FileList) => {
      setValue(name, value);
    },
    [name, setValue],
  );

  /**
   * Handle fileInput change
   * @param {ChangeEvent<HTMLInputElement>} event
   * @description Update the file name component via the useFileInput hook state,
   *              then pass the event to any change handler set in the props.
   */
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const fileList = event?.target?.files;
    if (fileList) {
      setFileValue(fileList);
      trigger(name);
    }
  }

  return (
    <Controller
      control={control}
      defaultValue={value || []}
      name={name}
      rules={registerOptions}
      render={({ name, ref, value }) => {
        const fileNameDisplay = uploading ? '' : getFileNames(value);

        const testId = dataTestId || name;

        return (
          <FieldContainer {...getFieldContainerProps(props, error)}>
            <FileInputContainer title={label} data-testid={testId}>
              <BaseFileInput
                data-testid={`${testId}-input`}
                id={name}
                inactive={inactive || uploading}
                multiple={allowMultiple}
                name={name}
                onChange={handleInputChange}
                readOnly={inactive || uploading}
                title={title || `${label} ${message}`}
                type={'file'}
                ref={ref}
              />
              <FileNameWrapper
                data-testid={`${testId}-file-name`}
                hasError={!!error}
                inactive={!!inactive}
                title={fileNameDisplay || label}
              >
                {fileNameDisplay}
              </FileNameWrapper>
              {uploading && (
                <ProgressIndicatorWrapper>
                  <DSLogoStar progress={uploadProgress} width={LogoStarSize.Inline} />
                </ProgressIndicatorWrapper>
              )}
              <FileInputButton inactive={inactive || uploading}>
                {allowMultiple ? FileUploadText.ChooseFiles : FileUploadText.ChooseFile}
              </FileInputButton>
            </FileInputContainer>
          </FieldContainer>
        );
      }}
    />
  );
};
