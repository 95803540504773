import isEqual from 'lodash/isEqual';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { initialEbidsTypes } from '../../../types/ebids';
import { bidResponseLeavingAlertMessage } from '../../../utils/texts';
import { toastFn } from '../../../utils/helpers';
import AddressReduxForm from '../../common/addressreduxform';
import { ModalPopUp } from '../../common/modals/ModalPopUp';
import { Buttons } from '../../customcontrols';
import { WizardPage, WizardStatus } from '../../../types/wizard';

interface PropsTypes {
  contactinfo?: Record<string, unknown>;
  setEbitPage?: any;
  addressreduxForm?: any;
  setEbidDetails?: any;
  submitEbidResponseDetails?: any;
  submit_response?: { for: string };
  submit_response_error?: any;
  getEbitsResponseDetails?: any;
  match?: any;
  history?: any;
  ebids?: initialEbidsTypes;
}

const Contactinfo = (props: PropsTypes) => {
  const {
    contactinfo = {},
    setEbitPage,
    addressreduxForm = {},
    setEbidDetails,
    submitEbidResponseDetails,
    submit_response,
    submit_response_error,
    getEbitsResponseDetails,
    history,
    ebids,
    match,
  } = props;

  const { getEbitsResponseDetailsAPICall } = ebids || {};
  const bidID = match.params.bidId || '';
  const responseId = match.params.responseId || '';
  const memberId = match.params.memberId || '';
  const [pagehasValues, setPagehasValues] = useState(false);
  const [ConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState('' as any);

  // to get response tabs
  useEffect(() => {
    if (bidID && getEbitsResponseDetailsAPICall) {
      getEbitsResponseDetails({ bidId: bidID, responseId, memberId });
    }
  }, [getEbitsResponseDetails, bidID, responseId, memberId, getEbitsResponseDetailsAPICall]);

  // component wizard to set to go to next page
  const setPage = useCallback(() => {
    const wizard: WizardPage[] = [
      { name: 'Contact Information', status: WizardStatus.Completed, id: 1 },
      { name: 'Documents Upload', status: WizardStatus.Current, id: 2 },
      { name: 'Review Bid', status: WizardStatus.Unavailable, id: 3 },
    ];
    setEbitPage({ wizard });
  }, [setEbitPage]);

  // contact info error msg
  useEffect(() => {
    if (submit_response && submit_response.for === 'contact_info') {
      setEbidDetails({ submit_response: '' });
    } else if (submit_response_error && submit_response_error.for === 'contact_info') {
      setEbidDetails({ submit_response_error: '' });
      toastFn(
        'error',
        submit_response_error.error || 'Update Failed',
        'response updates 001 failed',
      );
    }
  }, [setEbidDetails, submit_response, submit_response_error]);

  useEffect(() => {
    if (addressreduxForm) {
      const { values = {}, initial = {} } = addressreduxForm || {};
      const formDiff = Object.keys(initial).reduce((diff: any, key: string) => {
        if (values[key] === initial[key]) return diff;
        return {
          ...diff,
          [key]: values[key],
        };
      }, {});
      if (formDiff && Object.keys(formDiff).length > 0) {
        setPagehasValues(true);
      }
    }
  }, [addressreduxForm, pagehasValues, setPagehasValues]);

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!pagehasValues || ConfirmModalVisible) {
      return true;
    } else {
      setConfirmModalVisible(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const discardConfirmationPopup = () => {
    if (nextLocation) {
      history.push(nextLocation.pathname);
    }
  };

  const checksubmit = (formData = {}) => {
    const finalvalue = {
      bidId: bidID,
      partialsubmit: false,
      reasonfor: 'agency-submit',
      memberId,
      formData,
    };
    submitEbidResponseDetails(finalvalue);
  };

  return (
    <>
      <Prompt when={pagehasValues} message={handleBlockedNavigation} />
      <div className='row'>
        <div className='col-8'>
          <div className='innerColIndent'>
            <AddressReduxForm
              {...props}
              initialValues={contactinfo}
              onSubmit={checksubmit}
              breadcrumbs={false}
              pagevaluespagefor='ebids-response'
              pagevaluessavename='Next'
            />
          </div>
        </div>
      </div>
      <ModalPopUp
        title='Save Confirmation'
        size='md'
        isOpen={ConfirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
      >
        <p>{bidResponseLeavingAlertMessage}</p>
        <Buttons
          classNames='bttn-secondary mt-2'
          text='Stay on this page'
          title='Cancel'
          type='button'
          onClick={() => {
            setConfirmModalVisible(false);
          }}
        />
        <Buttons
          classNames='bttn-primary  mt-2 float-right'
          text='Leave without saving'
          title='OK'
          type='button'
          onClick={discardConfirmationPopup}
        />
      </ModalPopUp>
    </>
  );
};

export default memo(Contactinfo);
