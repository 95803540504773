import dayjs from 'dayjs';
import { useMemo } from 'react';

import { DSPill, DSPillType } from '@demandstar/components/pill';
import { FlexContainer, H3 } from '@demandstar/components/styles';
import { DSLinkButton } from '@demandstar/components/button';

import {
  ProductsPaths,
  SubscriptionStatusCopy,
  SubscriptionStatusLabels,
} from '../../../utils/texts/supplier/subscription';
import { AutoRenewal } from './autorenewal/AutoRenewal';
import { DateTimeUnits } from '../../../shared/constants';
import { displayDate } from '../../../utils/helpers';
import { Product } from 'src/types/subscriptions';
import { ProfileInfoState } from '../../../store/recoil/accountInfoState';
import { StatusRow } from './styles';
import { DemandStarSupportEmail } from '../../../utils/texts/common';
import { UserType } from 'src/types/accountinfo';

/**
 * @description shows the subscription status in the form of a pill, used by SubscriptionStatus component
 * @param expiryDate - dayjs.Dayjs
 * @returns JSX.Element, the status pill
 *
 * @example <ExpiryPill expiryDate={dayjs()} />
 */
const ExpiryPill = ({ expiryDate }: { expiryDate: dayjs.Dayjs }) => {
  const currentDateTimestamp = dayjs();
  const dueDateTimestamp = expiryDate;

  const timeRemaining = dueDateTimestamp.diff(currentDateTimestamp);

  let type = DSPillType.Success;
  let message: string = SubscriptionStatusLabels.Active;

  if (timeRemaining <= 0) {
    type = DSPillType.Error;
    message = SubscriptionStatusLabels.Expired;
  } else if (timeRemaining <= DateTimeUnits.month) {
    type = DSPillType.Warning;
    message = `${SubscriptionStatusLabels.Expiring} ${displayDate(dueDateTimestamp)}`;
  }

  return <DSPill type={type}>{message}</DSPill>;
};

interface SubscriptionStatusProps {
  /** contains card-on-file details */
  profileInfo: ProfileInfoState;
  /** date subscription is set to expire */
  expiryDate: dayjs.Dayjs;
  /** if the subscription has expired, the expired set of products */
  expiredProducts?: Product[];
  /** the user type */
  userType: UserType;
  /** whether logged in account is the member's main contact */
  isMainContact: boolean;
}

/**
 * @description displays the subscription status details
 * @returns JSX.Element
 *
 * @example <SubscriptionStatus
 *  profileInfo={{
 *    card:{
 *      cardType:'Visa',
 *      cardLastFour: 1111,
 *      isAutoRenewalOn:{true},
 *    },
 *    result: 'SUCCESS',
 *  }}
 *  expiryDate={new Date()}
 *  userType={UserType.PaidSupplier}
 *  isMainContact={true}
 * />
 */
export const SubscriptionStatus = ({
  profileInfo,
  expiryDate,
  expiredProducts = [],
  userType,
  isMainContact,
}: SubscriptionStatusProps) => {
  const { card, result = '' } = profileInfo || {};
  const { isAutoRenewalOn, isDefaultCard } = card || {};

  const hasProfile = result === 'SUCCESS';
  const expiredProductIds = useMemo(
    () => expiredProducts.map(({ productId }) => productId).join(','),
    [expiredProducts],
  );
  const newExpiryDate = dayjs(expiryDate).endOf('day');
  const daysUntilExpiry = newExpiryDate.diff(dayjs(), 'day');

  return (
    <>
      <H3>Subscription Status</H3>
      <div>
        <StatusRow>
          <div>{SubscriptionStatusCopy.Subscription}</div>
          <ExpiryPill expiryDate={expiryDate}></ExpiryPill>
        </StatusRow>
        {expiryDate.isAfter(dayjs()) && (
          <StatusRow>
            <div>{SubscriptionStatusCopy.ExpirationDate}</div>
            <div>{displayDate(expiryDate)}</div>
          </StatusRow>
        )}
        {userType === UserType.PaidSupplier && hasProfile && (isDefaultCard || isMainContact) && (
          <StatusRow>
            <div>{SubscriptionStatusCopy.Autorenew}</div>
            <AutoRenewal
              isAutoRenewalOn={isAutoRenewalOn}
              isDisabled={isMainContact && !isDefaultCard}
            />
          </StatusRow>
        )}
        <StatusRow>
          {SubscriptionStatusCopy.ModifyOrCancel}
          {DemandStarSupportEmail}
        </StatusRow>
        {((daysUntilExpiry < 89 && daysUntilExpiry > 0) || expiredProducts.length > 0) && (
          <StatusRow>
            <FlexContainer justifyContent={'center'} style={{ width: '100%' }}>
              <DSLinkButton to={`${ProductsPaths.ExploreProducts}?pid=${expiredProductIds}`}>
                Renew Subscription
              </DSLinkButton>
            </FlexContainer>
          </StatusRow>
        )}
      </div>
    </>
  );
};
