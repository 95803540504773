import * as actionTypes from '../actionTypes';
import {
  audittrialItemParamType,
  Awardee,
  bidItemParamType,
  BidWriter,
  broadcastHistoryItemParamType,
  currentRecepientsItemType,
  EBidResponseFull,
  legalAdParamType,
  Planholder,
  PlanholderDetailType,
  planholderDocumentParamType,
  purchaseInfoParamType,
  recepientsItemParamType,
  reminderItemType,
  responseEventsParamType,
  searchFieldParamType,
  supplierItemFields,
  tablulatationItemParamType,
  watchItemParamType,
} from '../../types/biddetail';
import { BidSummaryResult } from '../../types/bidssummary';
import { DSDocument } from 'src/types';
import { payloadTypes } from '../../types/actiontypedef';
import { resultsTypes } from '../../types/addbid';

export interface BidSummaryState {
  addSupplierActive: string; // more specific? "search"
  addSupplierModalOpen: boolean;
  auditTrail: '';
  auditTrialCategories: { selected: boolean }[];
  auditTrialQuoteCategories: Array<{ selected: boolean }>;
  auditTrials: { result: audittrialItemParamType[] };
  awardees: Awardee[];
  bidCommodity: [];
  bidders: [];
  bidTabulation: tablulatationItemParamType[];
  broadcast: [];
  broadcasthistory: broadcastHistoryItemParamType[];
  broadcastShow: boolean;
  canSupplierUpgrade: boolean;
  canSupplierUpgradeAPICALL: boolean;
  currentbroadcast: any;
  currentplanholderDocuments?: planholderDocumentParamType;
  currentpostviewer: supplierItemFields | '';
  currentrecipients?: currentRecepientsItemType[];
  currentreminders: reminderItemType;
  currentremindersrecipients?: string[];
  currentsupplier: supplierItemFields | '';
  documentData: '';
  documentDataError: any;
  documents: DSDocument[];
  ebidresponseEvents: responseEventsParamType[];
  eBids: EBidResponseFull;
  FilteredAuditTrialList: audittrialItemParamType[];
  isBonfireBid: boolean;
  bonfireAgencyURL: string;
  bonfireId: number;
  contactInfoVisibility: number;
  contactInfo: string;
  contactName: string;
  filteredPlanholderData?: Planholder[];
  focusbroadcasttimefield: boolean;
  legalAd: legalAdParamType;
  modalAddReminder: { open: boolean; modalfor: string };
  modalOpen: false;
  modalType: string; //more specific? 'add'
  planholders_internalLoader: boolean;
  planHolders: { total?: any; result: Planholder[]; ids: [] };
  planholders: Planholder[];
  postbidviewers_internalLoader: boolean;
  PostbidViewers: { total: ''; result: Planholder[]; ids: [] };
  purchaseInfo: purchaseInfoParamType;
  recipients: recepientsItemParamType[];
  reminders: reminderItemType[];
  ResponseDocumentData: '';
  ResponseDocumentDataError: '';
  /**@deprecated please try not to use this. it is inconsistent in its shape. */
  results: BidSummaryResult | bidItemParamType | resultsTypes; // we need to kill this.
  searchFields: searchFieldParamType;
  searchPlanholder: Planholder[];
  selectedCountry: '';
  selectedState: '';
  selectedStatelist: [];
  selectedSuppliers: Array<{ mi: number }>;
  similarBids: Array<Record<string, unknown>>;
  similarSuppliers: Array<{
    mi: number;
    supplierName: string;
    fn: string;
    ln: string;
    selected: boolean;
  }>;
  stats: {
    bidID: number;
    broadcasts: number;
    planholders: number;
    postBidViewers: number;
    supplimentalSuppliers: number;
  };
  supplierFields: { planholderId: any; country: any; state: any };
  watchers_internalLoader: boolean;
  watchers: { total: number; result: watchItemParamType[] };
  watchOn: boolean;
  writerInfo: BidWriter | '';
  legalAddLoading: boolean;
  trackTitle: string;
  planholdersdetail: Array<PlanholderDetailType>;
  spLoader: boolean;
}

const supplierFields = {
  companyname: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  state: '',
  postalCode: '',
  phoneNumber: '',
  phoneExtension: '',
  faxNumber: '',
  faxExtension: '',
  email: '',
  contact: '',
  memberId: '',
  selfdecAttr: {},
  memberType: '',
  planholderId: 0,
};

const auditTrialCategories = [
  { id: 0, value: 'Bid Event', title: 'By Agency', key: 0, selected: true },
  { id: 1, value: 'Bid View', title: 'By Supplier', key: 1, selected: false },
];

const auditTrialQuoteCategories = [
  { id: 0, value: 'Quote Event', title: 'By Agency', key: 0, selected: true },
  { id: 1, value: 'Quote View', title: 'By Supplier', key: 1, selected: false },
];

export const initialStateBidsSummary: BidSummaryState = {
  addSupplierActive: 'search',
  addSupplierModalOpen: false,
  auditTrail: '',
  auditTrialCategories,
  auditTrialQuoteCategories,
  auditTrials: { result: [] },
  awardees: [],
  bidCommodity: [],
  bidders: [],
  bidTabulation: [],
  broadcast: [],
  broadcasthistory: [],
  broadcastShow: true,
  canSupplierUpgrade: false,
  canSupplierUpgradeAPICALL: true,
  currentbroadcast: '',
  currentpostviewer: '',
  currentreminders: [],
  currentremindersrecipients: {},
  currentsupplier: '',
  documentData: '',
  documentDataError: '',
  documents: [],
  ebidresponseEvents: [],
  eBids: '',
  FilteredAuditTrialList: [],
  focusbroadcasttimefield: false,
  legalAd: '',
  modalAddReminder: { open: false, modalfor: '' },
  modalOpen: false,
  modalType: 'add',
  planholders_internalLoader: true,
  planholders: [],
  planHolders: { total: '', result: [] as Planholder[], ids: [] },
  postbidviewers_internalLoader: true,
  PostbidViewers: { total: '', result: [], ids: [] },
  purchaseInfo: '',
  recipients: [],
  reminders: [],
  ResponseDocumentData: '',
  ResponseDocumentDataError: '',
  results: {},
  searchFields: { name: '', city: '', phoneNumber: '', email: '' },
  searchPlanholder: [],
  selectedCountry: '',
  selectedState: '',
  selectedStatelist: [],
  selectedSuppliers: [],
  similarBids: [],
  similarSuppliers: [],
  stats: '',
  supplierFields,
  watchers_internalLoader: true,
  watchers: '',
  watchOn: false,
  writerInfo: '',
  legalAddLoading: false,
  supplierSelectedPlanholder: '',
  trackTitle: '',
  planholdersdetail: '',
  spLoader: false,
} as any; // TODO: Define this as BidSummaryState.

export const reducer = (state = initialStateBidsSummary, { type, payload }: payloadTypes) => {
  switch (type) {
    case actionTypes.LOAD_BID_SUMMARY.SUCCESS:
      state = {
        ...state,
        results: payload.summary,
        writerInfo: '', // Why are we doing this?
        broadcastShow: true,
      };
      break;
    case actionTypes.GET_BID_AWARDEES.SUCCESS:
      state = {
        ...state,
        awardees: payload.awardees,
      };
      break;
    case actionTypes.GET_BID_SUMMARY_WATCH_STATUS.SUCCESS:
      state = {
        ...state,
        watchOn: payload.watchOn,
      };
      break;
    case actionTypes.LOAD_BID_STATS.SUCCESS:
      state = {
        ...state,
        stats: payload,
      };
      break;
    case actionTypes.LOAD_BID_DOCUMENTS.SUCCESS:
      state = {
        ...state,
        documents: payload,
      };
      break;
    case actionTypes.LOAD_BID_WATCHERS.SUCCESS:
      state = {
        ...state,
        watchers: payload,
        watchers_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_WATCHERS.FAILURE:
      state = {
        ...state,
        watchers_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_PLANHOLDERS.SUCCESS:
      state = {
        ...state,
        planHolders: payload,
        planholdersdetail: payload.planholdersdetail,
        currentsupplier: payload.currentsupplier,
        planholders_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_PLANHOLDERS.FAILURE:
      state = {
        ...state,
        planholders_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_POSTBIDVIEWERS.SUCCESS:
      state = {
        ...state,
        PostbidViewers: payload,
        currentpostviewer: '',
        postbidviewers_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_POSTBIDVIEWERS.FAILURE:
      state = {
        ...state,
        postbidviewers_internalLoader: false,
      };
      break;
    case actionTypes.LOAD_BID_BIDDERS.SUCCESS:
      state = {
        ...state,
        bidders: payload,
      };
      break;
    case actionTypes.LOAD_BID_SIMILARBIDS.SUCCESS:
      state = {
        ...state,
        similarBids: payload,
      };
      break;
    case actionTypes.LOAD_BID_LEGALAD.SUCCESS:
      state = {
        ...state,
        legalAd: payload,
      };
      break;
    case actionTypes.LOAD_BID_EBIDS.SUCCESS:
      state = {
        ...state,
        eBids: payload,
      };
      break;
    case actionTypes.LOAD_BID_PURCHASEINFO.SUCCESS:
      state = {
        ...state,
        purchaseInfo: { ...payload, loaded: true },
      };
      break;
    case actionTypes.DOWNLOAD_DOCUMENT.SUCCESS:
      state = {
        ...state,
        documentData: payload,
        documentDataError: '',
      };
      break;
    case actionTypes.DOWNLOAD_DOCUMENT.FAILURE:
      state = {
        ...state,
        documentDataError: 'Error',
        documentData: '',
      };
      break;
    case actionTypes.DOWNLOAD_DOCUMENT_RESET.TRIGGER:
      state = {
        ...state,
        documentDataError: '',
        documentData: '',
      };
      break;
    case actionTypes.LOAD_BID_RESPONSE_DOCUMENTS.SUCCESS:
      state = {
        ...state,
        ResponseDocumentData: payload,
        ResponseDocumentDataError: '',
      };
      break;
    case actionTypes.GET_PLANHOLDERS_DETAILS.SUCCESS:
      state = {
        ...state,
        currentsupplier: payload.currentsupplier,
        selectedSuppliers: [...state.selectedSuppliers, payload.currentsupplier],
      };
      break;
    case actionTypes.SET_POST_BID_SUPPLIER_DETAILS.SUCCESS:
      state = {
        ...state,
        currentpostviewer: payload.currentpostviewer,
        selectedSuppliers: [...state.selectedSuppliers, payload.currentpostviewer],
      };
      break;
    case actionTypes.SET_SELECTED_BID_SUMMARY.TRIGGER:
      state = {
        ...state,
        currentsupplier: payload,
      };
      break;
    case actionTypes.SET_SELECTED_POST_BID_SUPPLIER_DETAILS.TRIGGER:
      state = {
        ...state,
        currentpostviewer: payload,
      };
      break;
    case actionTypes.LOAD_BID_BIDWATCH.SUCCESS:
      state = {
        ...state,
        watchOn: payload,
        // results: { ...state.results, watchOn: payload }
      };
      break;
    case actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER:
      state = { ...state, ...payload };
      break;
    case actionTypes.LOAD_BIDS_SIMILAR_SUPPLIERS.SUCCESS:
      state = {
        ...state,
        similarSuppliers: payload.similarSuppliers,
      };
      break;
    case actionTypes.SEND_BID_SELECTED_SUPPLIER.SUCCESS:
      state = { ...state, similarSuppliers: payload };
      break;
    case actionTypes.ADD_NEW_SUPPLIER.SUCCESS:
      if (payload.type === 1) {
        state = {
          ...state,
          modalOpen: false,
          addSupplierModalOpen: false,
          addSupplierActive: 'search',
          modalType: 'add',
          searchPlanholder: [],
          searchFields: { name: '', city: '', phoneNumber: '', email: '' },
        };
      } else {
        if (state.supplierFields.planholderId) {
          state = {
            ...state,
            addSupplierActive: 'search',
            supplierFields,
            searchPlanholder: payload.searchResult,
          };
        } else {
          state = {
            ...state,
            addSupplierActive: 'add',
            supplierFields,
          };
        }
      }
      break;
    case actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.SUCCESS:
      state = {
        ...state,
        planHolders: { ...state.planholders, result: payload.result, ids: payload.planholderids },
        currentsupplier: payload.currentsupplier,
      };
      break;
    case actionTypes.REMOVE_BID_PLANHOLDER_SUPPLIER.FAILURE:
      state = { ...state };
      break;
    case actionTypes.CHANGE_ADD_SUPPLIER_ACTIVE.TRIGGER:
      state = {
        ...state,
        supplierFields: {
          ...supplierFields,
          country: state.selectedCountry,
          state: state.selectedState,
        },
        addSupplierActive: payload.addSupplierActive,
      };
      break;
    case actionTypes.GET_BIDS_SEARCH_PLANDER.SUCCESS:
      state = {
        ...state,
        searchPlanholder: payload.searchPlanholder,
        searchFields: payload.searchFields,
      };
      break;
    case actionTypes.GET_BIDS_SEARCH_PLANDER.FAILURE:
      state = {
        ...state,
        searchPlanholder: [],
      };
      break;
    case actionTypes.RESET_BIDS_SEARCH_PLANDER.TRIGGER:
      state = {
        ...state,
        searchPlanholder: [],
      };
      break;
    case actionTypes.BIDS_AUDIT_TRIAL.SUCCESS:
      state = {
        ...state,
        auditTrials: payload,
        auditTrail: payload.result,
      };
      break;
    case actionTypes.GET_BIDS_BROADCAST_HISTORY.SUCCESS:
      state = {
        ...state,
        broadcasthistory: payload.broadcasthistory,
        recipients: payload.recipients,
        currentbroadcast: '',
      };
      break;
    case actionTypes.GET_BIDS_BIDS_REMINDERS.SUCCESS:
      state = {
        ...state,
        reminders: payload.reminders,
        currentremindersrecipients: payload.currentremindersrecipients,
        currentreminders: payload.currentreminders,
      };
      break;
    case actionTypes.LOAD_BID_PLANDER_DATA.SUCCESS:
      state = {
        ...state,
        supplierFields: payload.supplierData,
      };
      break;
    case actionTypes.SET_MODAL_OPEN.SUCCESS:
      state = {
        ...state,
        modalOpen: payload.openType,
        modalType: payload.modalType,
        addSupplierActive: payload.modalType === 'add' ? 'search' : 'add',
        searchPlanholder: [],
        supplierFields: {
          ...supplierFields,
          country: payload.selectedCountry,
          state: payload.selectedState,
        },
        selectedCountry: payload.selectedCountry,
        selectedState: payload.selectedState,
        selectedStatelist: payload.selectedStatelist,
        searchFields: { name: '', city: '', phoneNumber: '', email: '' },
      };
      break;
    case actionTypes.SELECT_COUNTRY_STATE.SUCCESS:
      state = {
        ...state,
        supplierFields: {
          ...state.supplierFields,
          country: payload.selectedCountry,
          state: payload.selectedState,
        },
      };
      break;
    case actionTypes.SET_FILTERED_AUDIT_TRIAL_LIST.TRIGGER:
      state = {
        ...state,
        FilteredAuditTrialList: payload,
      };
      break;
    case actionTypes.GET_WRITER_INFO.SUCCESS:
      state = {
        ...state,
        writerInfo: payload,
      };
      break;
    case actionTypes.GET_RESPONSE_EVENTS.SUCCESS:
      state = {
        ...state,
        ebidresponseEvents: payload,
      };
      break;
    case actionTypes.RESET_BIDS_SUMMARY.TRIGGER:
      state = initialStateBidsSummary;
      break;
    case actionTypes.GET_BID_TABULATION.SUCCESS:
      state = {
        ...state,
        bidTabulation: payload,
      };
      break;
    default:
      break;
  }
  return state;
};
