import React, { memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions';
import { authTypes, MembershipLevel, Permission } from '../../../../types/auth';
import history from '../../../../utils/history';
import { useDSSelector } from 'src/store/reducers';

interface propsTypes {
  setAddBidDetails: any;
  setBidSummaryDetails: any;
  resetQuoteSummaryDetails: any;
  resetCommodityCodes: any;
  resetAddQuotesDetails: any;
  /* trackAmplitudeUserActions: any; */
  auth: authTypes;
  accountinfo?: any; // TODO: TS4 - this does not seem to be in use
}

const DashboardControl = (props: propsTypes) => {
  const {
    setAddBidDetails,
    setBidSummaryDetails,
    resetQuoteSummaryDetails,
    resetCommodityCodes,
    resetAddQuotesDetails,
    /* trackAmplitudeUserActions, */
    auth,
  } = props;

  const memberinfo = useDSSelector(state => state.memberinfo);
  const { ml = '', prms = '', mc = false } = auth;
  const mlcheck = ml.replace(/ /g, '').split(',') as MembershipLevel[];
  const prmscheck = prms.replace(/ /g, '').split(',') as Permission[];

  const { isBonfireAgency = false } = memberinfo;

  const AddQuote = () => {
    resetQuoteSummaryDetails();
    setAddBidDetails({ ResetState: true, broadcastListId: '' });
    resetCommodityCodes();
    resetAddQuotesDetails();
    //trackAmplitudeUserActions({title:'Add quote - button', desc:'add quote button clicked from dashboard'});
    history.push('/buyers/quotes/creation');
  };

  return (
    <>
      <>
        {(mc ||
          (mlcheck.includes(MembershipLevel.BidPoster) &&
            prmscheck.includes(Permission.ManageBids))) &&
          !isBonfireAgency && (
            <li title='Add Bid'>
              <span
                className='staticLink addBid'
                onClick={event => {
                  event.preventDefault();
                  setAddBidDetails({ ResetState: true, broadcastListId: '' });
                  setBidSummaryDetails({ results: {} });
                  //trackAmplitudeUserActions({title:'Add bid - button', desc:'add bid button clicked from dashboard'});
                  history.push('/buyers/bid/creation');
                }}
              >
                <i className='mdi mdi-plus mr-1' /> Add Bid
              </span>
            </li>
          )}
        {(mc ||
          (mlcheck.includes(MembershipLevel.QuotePoster) &&
            prmscheck.includes(Permission.CreateQuotes))) &&
          !isBonfireAgency && (
            <li title='Add Quote'>
              <span className='staticLink addBid' onClick={AddQuote}>
                <i className='mdi mdi-plus mr-1' /> Add Quote
              </span>
            </li>
          )}
      </>
    </>
  );
};

export default connect(
  (state: any) => ({ auth: state.auth }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(memo(DashboardControl));
