import { connect, ConnectedProps } from 'react-redux';
import { memo, useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../../../store/actions';
import { accountinfoType, anyParamType } from '../../../../types/paymentdetails';
import { Buttons, NoResult } from '../../../customcontrols';
import {
  InternalLoaderNames,
  MemberType,
  relogintokenName,
  userPermissionKeys,
} from '../../../../utils/constants';

import { loadingMsg } from '../../../../utils/texts';
import { sharedParamType } from '../../../../types/commoditycodes';
import { useSetBreadcrumb } from 'src/shared/hooks';
import { hasPermission } from 'src/utils/helpers';
import { useDSSelector } from 'src/store/reducers';

interface propsTypes extends PropsFromRedux {
  accountinfo: accountinfoType;
  history: anyParamType;
  shared: sharedParamType;
  getaccountInfoCommodity: any;
  getRefreshToken: any;
  internalLoader: { internalLoaderPages: Array<string> };
}

const CommodityCodes = (props: propsTypes) => {
  const {
    getaccountInfoCommodity,
    history,
    getRefreshToken,
    shared,
    accountinfo,
    internalLoader: internalLoaderReducer,
  } = props;
  const memberinfo = useDSSelector(state => state.memberinfo);
  const auth = useDSSelector(state => state.auth);
  const { commodityCodes = [] } = accountinfo || {};
  const { internalLoader } = shared;
  //const { mt: memberType } = memberinfo
  const { prms } = memberinfo;
  const { tk = '', un = '', mt: memberType = '' } = auth || {};
  const { internalLoaderPages = [] } = internalLoaderReducer || {};
  const [loadingState, setLoadingState] = useState(false);

  const { prms: authPerms, mc: isPrimaryContact = false } = auth;
  const accountPermissions = prms ? prms : authPerms;
  const hasEditMemberPermission =
    hasPermission(accountPermissions, userPermissionKeys.editMember) || isPrimaryContact;

  useEffect(() => {
    if (sessionStorage.getItem(relogintokenName) && auth && tk && un) {
      sessionStorage.removeItem(relogintokenName);
      getRefreshToken();
    }
  }, [getRefreshToken, tk, un, auth]);

  useEffect(() => {
    if (memberType && memberType === MemberType.BasicSupplier) history.replace('/');
  }, [history, memberType]);

  const GoToPage = useCallback(() => {
    history.push('/suppliers/account/commoditycodes/edit');
  }, [history]);

  useEffect(() => {
    let commodityCodesAPICALL = true;
    if (
      loadingState === false &&
      commodityCodes.length === 0 &&
      commodityCodesAPICALL &&
      !internalLoaderPages.includes(InternalLoaderNames.OtherAccountInfo)
    ) {
      getaccountInfoCommodity();
      setLoadingState(true);
    }
    return () => (commodityCodesAPICALL = false) as any;
  }, [
    commodityCodes.length,
    getaccountInfoCommodity,
    internalLoaderPages,
    loadingState,
    setLoadingState,
  ]);

  useSetBreadcrumb({
    component: hasEditMemberPermission ? (
      <li>
        <span className='staticLink' onClick={() => GoToPage()}>
          <i className='mdi mdi-square-edit-outline mr-1' /> Edit
        </span>
      </li>
    ) : (
      ''
    ),
    page: 'Supplier Account Commodity Codes',
    title: 'Commodity Codes',
    altname: 'Commodity Codes',
    name: 'Commodity Codes',
  });

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='colWrapper'>
              <div className='row '>
                <div className='col-12'>
                  <h3 className='arrowWrapper'>Commodity Codes</h3>
                  <div className='innerColIndent'>
                    {commodityCodes.length > 0 ? (
                      <>
                        <h4>Selected Codes ({commodityCodes.length})</h4>
                        {commodityCodes.map((commodity, id) => (
                          <p key={id} className='mb-3'>
                            <i className='staticTxt'>
                              [{commodity.formattedCode || commodity.fullCode}]
                            </i>{' '}
                            {commodity.commodityDescription}
                          </p>
                        ))}
                      </>
                    ) : (
                      <NoResult
                        message={
                          internalLoader
                            ? loadingMsg
                            : 'Select Commodity Codes to receive bid notifications.'
                        }
                      />
                    )}
                    {hasEditMemberPermission && (
                      <Buttons
                        classNames='bttn-primary mt-2 float-right'
                        text='Edit'
                        type='button'
                        onClick={GoToPage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    shared: state.shared,
    accountinfo: state.accountinfo,
    auth: state.auth,
    internalLoader: state.internalLoader,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(CommodityCodes));
