import React, { memo } from 'react';
import { editQuoteStatus } from '../../../../../../utils/constants';
import { deprecatedCheckTimezoneCountDown } from '../../../../../../utils/helpers';
import CommodityExpand from '../../../../../common/commodityexpand';
import { ModalPopUp } from '../../../../../common/modals/ModalPopUp';
import { Buttons } from '../../../../../customcontrols';
import BroadcastFilter from '../../../../../shared/bidsdetail/lefttab/bidinfo/broadcastfilter';
import Documents from '../../../../../shared/bidsdetail/lefttab/bidinfo/documents';
import LineItems from '../../../../../supplier/quotedetail/lineitems';
import Quotereplyinfo from '../../../../../supplier/quotedetail/quotereplyinfo';
import HeaderStatistics from './headerstatistics';
import ChangeStatus from './modal/changestatus';
import QuoteInformation from './quoteinformation';
import { QuoteSummaryType } from '../../../../../../types/quotedetailstypedef';
import { authTypes } from '../../../../../../types/auth';
import { sharedTypes } from '../../../../../../types/shared';

interface PropsTypes {
  quoteSummary: QuoteSummaryType;
  setQuoteSummaryDetails: any;
  submitAddQuoteComplete?: any;
  statusTheme?: string;
  purchaseInfo?: any;
  addquotes?: any;
  userpagefor: string;
  getSelfDeclarations?: any;
  auth: authTypes;
  quoteId: any;
  shared: sharedTypes;
}

function Details(props: PropsTypes) {
  const {
    quoteSummary,
    setQuoteSummaryDetails,
    submitAddQuoteComplete,
    statusTheme = '',
    purchaseInfo = {},
    addquotes,
    shared,
    userpagefor,
    getSelfDeclarations,
    auth,
  } = props;
  const {
    summary,
    statusModal = false,
    modalType,
    quoteDocuments = [],
    lineItems,
    quotereplyinfo,
  } = quoteSummary || {};
  const { selfdeclaredattributes = [] } = shared || {};
  const {
    statusType,
    dueDateTime,
    quoteId,
    commodities = [],
    filtered,
    filteredStates = '',
    filteredCities = '',
    filteredDeclarations = '',
    filteredPrograms = '',
    responseStatus = '',
    memberId: mID = '',
    quoteName = '',
    status = '',
    tzfn = '',
  } = summary || {};
  const { canFinishQuote = false } = addquotes || {};
  const { mi: memberId } = auth || {};
  const { delivery = '', additionalSupplierInfo = '', preparedBy = '' } = quotereplyinfo || {};

  const checkLineItemAwarded = () => {
    const awardedItemsLength = lineItems.filter(
      item => item.awardedToId && item.awardedToId !== 0,
    ).length;
    if (awardedItemsLength === lineItems.length) return true;
    else return false;
  };

  const PostButtonComponent = () => {
    return (
      <>
        {userpagefor !== 'suppliers' && canFinishQuote && editQuoteStatus.includes(statusType) ? (
          <Buttons
            classNames='bttn-primary mt-4 mb-4 float-right'
            text='Post Quote'
            title='Post Quote'
            onClick={() => submitAddQuoteComplete({ quoteId, pagefor: 'summary' })}
            type='button'
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className='row '>
        <div className='col-12'>
          <h3 className='d-flex align-items-center'>
            <label className='flex1 mr-5 mb-0'>
              <span className='arrowWrapper'>{quoteName}</span>
            </label>
            <i className={'tag ' + (statusTheme && statusTheme === 'RED' ? 'draft' : 'status')}>
              {status}
            </i>
          </h3>
          {userpagefor === 'suppliers' ? (
            <>
              {statusType === 'CA' || statusType === 'CL' ? (
                <div className='alert alert-secondary text-danger text-center mt-4'>
                  No quotes will be accepted this time.
                </div>
              ) : statusType === 'AW' ? (
                <div className='alert alert-secondary text-danger text-center mt-4'>
                  This quote has been processed and awarded. No quotes will be accepted at this
                  time.
                </div>
              ) : null}
            </>
          ) : null}
          {Number(mID) === Number(memberId) && <HeaderStatistics {...props} />}
          <h4 data-testid='quotes.details.heading'>Quote Details</h4>
          <div className='innerColIndent'>
            <QuoteInformation {...props} />
          </div>
          {userpagefor === 'suppliers' && (delivery || additionalSupplierInfo || preparedBy) ? (
            <>
              <h4>Supplier Details</h4>
              <div className='innerColIndent'>
                <Quotereplyinfo {...props} />
              </div>
            </>
          ) : null}
          {userpagefor !== 'suppliers' && filtered === 'Yes' ? (
            <>
              <h4>Filters</h4>
              <div className='innerColIndent'>
                <BroadcastFilter
                  filteredStates={filteredStates}
                  filteredCities={filteredCities}
                  filteredDeclarations={filteredDeclarations}
                  filteredPrograms={filteredPrograms}
                  selfdeclaredattributes={selfdeclaredattributes}
                />
              </div>
            </>
          ) : null}
          {userpagefor === 'suppliers' ? (
            <LineItems
              lineItems={lineItems}
              shared={shared}
              statusType={
                (statusType === 'OP' &&
                  deprecatedCheckTimezoneCountDown(dueDateTime, tzfn) === 'closed' &&
                  userpagefor === 'suppliers') ||
                statusType === 'CL'
                  ? 'Expired'
                  : statusType === 'CA'
                  ? 'Cancelled'
                  : ''
              }
              memberId={memberId}
              userpagefor={userpagefor}
              responseStatus={responseStatus}
              awardShow={statusType === 'AW' && checkLineItemAwarded()}
            />
          ) : null}
          {userpagefor === 'suppliers' && statusType === 'AW' && checkLineItemAwarded() ? (
            <>
              <LineItems
                lineItems={lineItems}
                shared={shared}
                statusType='Awarded'
                memberId={memberId}
                userpagefor={userpagefor}
                pageTitle='Awarded Response'
                responseStatus={responseStatus}
                awardShow={true}
              />
            </>
          ) : null}
          <h4>Documents</h4>
          <div className='innerColIndent'>
            <Documents
              {...props}
              documents={quoteDocuments}
              page='quotes'
              purchaseinfo={purchaseInfo}
            />
          </div>
          {userpagefor === 'suppliers' ? null : (
            <>
              <h4>Commodity Code </h4>
              <div className='innerColIndent'>
                <CommodityExpand shared={shared} commodities={commodities} />
              </div>
            </>
          )}
          <PostButtonComponent />
        </div>
      </div>
      <ModalPopUp
        title={modalType === 'status' ? 'Update Quote Status' : 'Update Quote Due Date'}
        isOpen={statusModal}
        closeModal={() => setQuoteSummaryDetails({ statusModal: !statusModal })}
      >
        <ChangeStatus {...props} data={summary} />
      </ModalPopUp>
    </>
  );
}

export default memo(Details);
