import { call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';

import * as actionTypes from '../../actionTypes';
import * as Api from '../../services/addbid';
import * as bidApi from '../../services/bids';
import * as sharedApi from '../../services/shared';
import {
  AddBidState,
  addbidwizardfull,
  addbidwizardfullplan,
  addbidwizardplan,
  initialAddBidWizard,
} from '../../reducers/addbid';
import {
  allowForwardNavStatuses,
  bidActiveEditableStatus,
  BidExternalStatusType,
  bidFullEditableStatus,
  bidSemiEditableStatus,
} from '../../../utils/constants';
import { BidType, resultsTypes } from '../../../types/addbid';
import {
  deprecatedGetDateTimeZoneConverted,
  getTimeFromDate,
  getZoneFromDate,
  setWizardStatus,
  toastFn,
} from '../../../utils/helpers';
import { accountinfoType } from '../../../types/paymentdetails';
import { addBidToastId } from '../addbid';
import { AuthState } from '../../reducers/auth';
import { BidSummaryResult } from '../../../types/bidssummary';
import { BidSummaryState } from '../../reducers/bidssummary';
import { MemberInfoState } from '../../reducers/memberinfo';
import { SharedState } from '../../reducers/shared';
import { WizardPage } from '../../../types/wizard';

export function* watchsetAddBidInitialData() {
  yield takeLatest(actionTypes.SET_ADD_BID_INITIAL_DATA.TRIGGER, setAddBidInitialData);
}

function* setAddBidInitialData(action: { payload: { bidId: number | string } }): any {
  yield put({ type: actionTypes.SET_ADD_BID_INITIAL_DATA.REQUEST, meta: action.payload });
  try {
    const { bidId = '' } = action.payload;
    const bidssummary: BidSummaryState = yield select(state => state.bidssummary) || '';
    const { bidID = '' } = (bidssummary && (bidssummary.results as BidSummaryResult)) || '';
    let results: resultsTypes;
    if (Number(bidID) === Number(bidId)) {
      results = bidssummary.results as resultsTypes;
    } else {
      const response = yield call(bidApi.getBidsSummary, action.payload);
      results = response.data.result || {};
    }
    const auth: AuthState = yield select(state => state.auth) || '';
    yield put({ type: actionTypes.SET_BID_SUMMARY_DETAILS.TRIGGER, payload: { results } });
    const isConstructionResponse = yield call(Api.getAddBidIsConstruction, action.payload);
    const isConstruction: boolean =
      (isConstructionResponse.data.result && isConstructionResponse.data.result.icb) || false;

    const memberinfo: MemberInfoState = yield select(state => state.memberinfo);
    const { ci } = memberinfo;
    const stateresponse = yield call(sharedApi.getStatesList, action.payload);
    let stateslist = yield (stateresponse.data && stateresponse.data.result) || [];
    stateslist = stateslist
      .map((items: { name: string; title: string; id: number }) => ({
        ...items,
        label: items.name || items.title,
        value: items.id,
      }))
      .filter((item: { countryId: number }) => item.countryId === ci);
    let { bcs = 'Bid Information' } = results as resultsTypes;
    let { eBidding = '' } = results as resultsTypes;
    const { bidExternalStatusType = BidExternalStatusType.None } = results as resultsTypes;
    const addbid: AddBidState = yield select(state => state.addbid);
    const shared: SharedState = yield select(state => state.shared);
    const { bidtypes, bidwriters, ebidinfo, activeprograms = [], upcomingBidPageFor = '' } = addbid;
    let { fiscalYear } = shared;
    const { selfdeclaredattributes = [] } = shared;
    const accountinfo: accountinfoType = yield select(state => state.accountinfo);
    const { agencydocs } = accountinfo;

    if (agencydocs && agencydocs.requiredDocuments.length === 0) {
      eBidding = 'No';
    }

    let wizard = eBidding === 'No' ? initialAddBidWizard : addbidwizardfull;
    if (eBidding === 'No' && isConstruction) {
      wizard = addbidwizardplan;
    }
    if (eBidding === 'Yes' && isConstruction) {
      wizard = addbidwizardfullplan;
    }
    if (allowForwardNavStatuses.includes(bidExternalStatusType)) {
      bcs = 'Manage Documents';
    }

    if (upcomingBidPageFor) {
      bcs = upcomingBidPageFor;
      yield put({
        type: actionTypes.SET_ADD_BID_DETAILS.TRIGGER,
        payload: { upcomingBidPageFor: '' },
      });
    }

    const currentPage = wizard.find(item => item.name === bcs) as WizardPage;

    let currentPageId = allowForwardNavStatuses.includes(bidExternalStatusType)
      ? currentPage.id
      : currentPage.id + 1;

    if (bcs === 'Manage Documents' || upcomingBidPageFor) {
      currentPageId = currentPage.id;
    }

    if (!currentPageId) {
      currentPageId = 1;
    }
    /* TODO: Clean this up. What is happening here?
    Our goal here should be that we either:
    - get the page with an id and use that
    - fail to get a page with an id and fall back to 1 (or whatever the id of the first page is)
    I can't think of why we should ever need to add +1 or include more than one if statement.
    Leaving functionally untouched.

    We should not be keeping track of
    currentPage.id
    currentPageId
    wizardCurrentPage

    as three potentially different values. There needs to be ONE value that is correct.
    */
    let wizardCurrentPage: number;

    if (currentPage && currentPage.id) {
      wizardCurrentPage = currentPage.id;
    } else {
      wizardCurrentPage = currentPageId;
    }

    if (currentPageId > currentPage.id) {
      wizardCurrentPage = currentPageId;
    }

    const {
      commodities,
      filteredStates = '',
      filteredCities = '',
      filteredDeclarations = '',
      filteredPrograms = '',
    } = results;

    const commodityAlert = commodities.length > 0 ? false : true;

    const state = (filteredStates && filteredStates.split(',')) || [];
    const declaration = (filteredDeclarations && filteredDeclarations.split(',')) || [];
    const programs = (filteredPrograms && filteredPrograms.split(',')) || [];

    const stated =
      stateslist.length > 0 && state.length > 0
        ? stateslist.filter((item: { name: string }) => state.includes(item.name))
        : [];
    const selfdeclaration =
      selfdeclaredattributes.length > 0 && declaration.length > 0
        ? selfdeclaredattributes.filter((item: { value: string }) =>
            declaration.includes(item.value),
          )
        : [];
    const program =
      activeprograms.length > 0 && programs.length > 0
        ? activeprograms.filter((item: { value: { toString: () => any } }) =>
            programs.includes(item.value.toString()),
          )
        : [];

    const resFilter = {
      filterState: stated || [],
      filterCity: (filteredCities && filteredCities.split(',')) || [],
      filterDeclaration: selfdeclaration || [],
      filterPrograms: program || [],
    };

    const bidYear =
      fiscalYear.find((item: { value: string }) => item.value === results.fiscalYear) || '';

    if (!bidYear) {
      fiscalYear = [
        ...fiscalYear,
        { label: results.fiscalYear, value: Number(results.fiscalYear) },
      ].sort((a, b) => a.value - b.value);
      yield put({ type: actionTypes.SET_SHARED_DETAILS.TRIGGER, payload: { fiscalYear } });
    }

    const broadcastDatetzfn =
      deprecatedGetDateTimeZoneConverted(results.broadcastDate, results.tzfn) || '';
    const dueDatetzfn = deprecatedGetDateTimeZoneConverted(results.dueDate, results.tzfn) || '';
    const addbidinfo = {
      broadcastDate: moment(broadcastDatetzfn),
      bidName: results.bidName,
      bidBond: results.bidBond,
      displayBudgetToSuppliers: results.displayBudgetToSuppliers,
      projectEstimatedBudget:
        results.projectEstimatedBudget === 0 ? null : results.projectEstimatedBudget,
      scopeOfWork: results.scopeOfWork === 'None' ? '' : results.scopeOfWork,
      openedText: results.bidStatusText,
      dueDate: moment(dueDatetzfn),
      dueTime: getTimeFromDate(dueDatetzfn),
      dueTimeZone: getZoneFromDate(dueDatetzfn),
      broadcastDateTime: getTimeFromDate(broadcastDatetzfn),
      broadcastDateTimeZone: getZoneFromDate(broadcastDatetzfn),
      bidNumber: results.bidNumber,
      bidstatus: { value: results.bidExternalStatusType, label: results.bidExternalStatus },
      bidYear: fiscalYear.find((item: { value: string }) => item.value === results.fiscalYear),
      memberBidType: (bidtypes as BidType[]).find(item => item.value === results.bidType.trimEnd()),
      bidWriterAccountId: (bidwriters as { label: string; value: string; ac: boolean }[]).find(
        item => Number(item.value) === Number(results.bidWriterAccountID),
      ),
      eBidding: eBidding.toLowerCase(),
    };
    if (!addbidinfo.bidWriterAccountId) {
      addbidinfo.bidWriterAccountId = {
        label: `${results.bidWriter} (ineligible)`,
        value: results.bidWriterAccountID,
        ac: true,
      };
      const updatedBidWriters: { label: string; value: string; ac: boolean }[] = [
        ...bidwriters,
        addbidinfo.bidWriterAccountId,
      ];
      yield put({ type: actionTypes.GET_ADD_BID_BID_WRITERS.SUCCESS, payload: updatedBidWriters });
    } else if (
      addbidinfo &&
      addbidinfo.bidWriterAccountId &&
      addbidinfo.bidWriterAccountId.ac === false
    ) {
      addbidinfo.bidWriterAccountId.label = `${addbidinfo.bidWriterAccountId.label} (ineligible)`;
    }
    const prebidconference = {
      pbcMemo:
        results.pbcMemo === 'No Pre-Bid Conference Data Found'
          ? ''
          : results.pbcMemo.replace('NON-MANDATORY ', '').replace('MANDATORY ', ''),
      preBidMandatory: results.pbcMemo.startsWith('MANDATORY') ? true : false,
    };

    const blueprintStatusType = results.blueprintStatusType || 'EL';
    /* if (results.blueprintStatusType === 'NA') {
      blueprintStatusType = 'EL';
    } */
    const plandistributedby =
      results.distributedBy === 'DemandStar'
        ? 'DM'
        : results.distributedBy === 'Agency'
        ? 'AG'
        : 'TP';
    const plandetails = {
      planname: plandistributedby === 'TP' ? results.distributedBy : '',
      planemail: results.fulfillmentEmail || '',
      plancost: results.cost ? Number(results.cost).toFixed(3) : '',
      plannotes: results.distributionNotes,
    };

    let editable = bidFullEditableStatus.includes(bidExternalStatusType)
      ? 'all'
      : bidSemiEditableStatus.includes(bidExternalStatusType)
      ? 'semi'
      : bidActiveEditableStatus.includes(bidExternalStatusType)
      ? 'active'
      : null;

    if (auth?.opi) {
      editable = 'all';
    }

    const payload = {
      addbidwizard: setWizardStatus(wizard, currentPageId),
      wizardcurrentpage: wizardCurrentPage,
      // FIXME: let's not have wizardCurrentPage and currentPageId as two separate variables.
      // seems like we're asking for trouble.
      addbidinfo,
      editbiddata: true,
      prebidconference,
      ebidinfo: {
        ...ebidinfo,
        viewsupplierinfortabulation: results.tse,
        bidAmtRequired: !results.bidAmtOptional,
        supplementalDocRequired: results.supplementalDocOptional,
      },
      isConstruction,
      plandetails,
      blueprintStatusType,
      broadcastFilter: resFilter,
      stateslist,
      plandistributedby,
      commodities,
      editable,
      loadNewCommodities: true,
      commodityAlert: commodityAlert,
    };
    yield put({ type: actionTypes.SET_ADD_BID_INITIAL_DATA.SUCCESS, payload });
  } catch (error) {
    toastFn('error', 'Network Failure', addBidToastId);
    yield put({
      type: actionTypes.SET_ADD_BID_INITIAL_DATA.FAILURE,
      payload: { payload: action.payload, error },
    });
  }
}
