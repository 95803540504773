import { Cell, Column } from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { DSDatePicker } from '@demandstar/components/inputs';
import { DSTable } from '@demandstar/components/table';

import { Assert, convertResponseToRequiredDoc, isTodayOrEarlier } from '../../../../utils/helpers';
import {
  commonFieldNames,
  commonLabels,
  ControlLabels,
  documentLabels,
} from '../../../../shared/constants';
import { Id, ReadOnly } from '../../../../types/shared';
import { isDefined, noOpFunction } from '../../../../utils';
import {
  requiredDocumentResponsesState,
  selectedBidAwardeeState,
  selectedRequiredDocumentState,
} from '../../../../store/recoil/award-bid';
import { useRequiredDocumentResponses, useRequiredDocuments } from '../../../../shared/hooks';
import { useSelectedMemberType, useSupplierMemberId } from '../../../../shared/hooks/useMemberInfo';

import { DateCell, LinkTextCell } from '../../../common/table/renderers';
import { BoldText } from '../../../../shared/styles';
import { ConfirmModal } from '../../../common/modals/ConfirmModal';
import { DeleteIconCell } from '../../../common/table/renderers/DeleteIconCell';
import { deleteRequiredDocumentResponseFromService } from '../../../../shared/hooks/award-bid/required-document-response';
import { documentDownloadInProgressState } from '../../../../store/recoil/documentState';
import { requiredDocLabels } from '../add-vendor-docs/constants';
import { RequiredDocument } from '../../../../store/services';
import { RequiredDocumentResponseGetAPIResponse } from '../../../../types';
import { RequiredDocumentStatusCell } from '../../../common/table/renderers/RequiredDocumentStatusCell';
import { TableRowSelectionProps } from '../../../common/table';
import { track } from '../../../../utils/telemetry';
import { useDownload } from 'src/shared/hooks/useDownload';
import { useRequiredActions } from '../../../../shared/hooks/award-bid/useRequiredActions';
import { useResetOnUnmount } from '../../../../shared/hooks/useResetOnUnmount';
import { useSelectedBidId } from '../../../../shared/hooks/useSelectedBidId';

interface RequiredDocumentResponsesTableProps extends ReadOnly {
  deleteEnabled?: boolean;
  editEnabled?: boolean;
  showSingleRow?: boolean;
}

type RequiredDocumentTableRowSelectionProps = TableRowSelectionProps & { showSingleRow?: boolean };

/**
 * Table for the display of Required Documents for all vendors in the Award Bid process
 * @param props: RequiredDocumentResponsesTableProps
 * @returns
 */
export function RequiredDocumentResponsesTable({
  deleteEnabled,
  editEnabled,
  showSingleRow, // Shows a single entry by id
}: RequiredDocumentResponsesTableProps) {
  // Hooks //
  const { refreshRequiredDocumentResponses, requiredDocumentResponses } =
    useRequiredDocumentResponses();
  const { deleteRequiredDoc, updateRequiredDocs } = useRequiredDocuments();
  const selectedBidAwardee = useRecoilValue(selectedBidAwardeeState);
  const { handleDownloadLinkClick } = useDownload({
    fieldConfig: { idField: 'bidId', downloadIdField: 'bidDocId' },
  });
  useResetOnUnmount(requiredDocumentResponsesState);

  const { refreshRequiredActions, selectedRequiredActionId } = useRequiredActions();

  const { selectedBidId } = useSelectedBidId();
  const { supplierMemberId } = useSupplierMemberId();

  const { selectedMemberIsAgency } = useSelectedMemberType();

  const [selectedRequiredDocument, setSelectedRequiredDocument] = useRecoilState(
    selectedRequiredDocumentState,
  );
  const resetSelectedRequiredDocument = useResetRecoilState(selectedRequiredDocumentState);
  const [documentDownloading, setDocumentDownloading] = useRecoilState(
    documentDownloadInProgressState,
  );

  // Local state //
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteResponseModalIsOpen, setDeleteResponseModalIsOpen] = useState(false);

  const showDelete = deleteEnabled || showSingleRow;

  /** Determine delete handler by member type:
   * Agencies can delete Required Docs
   * Suppliers can delete Required Doc Responses */
  const deleteRowHandler = selectedMemberIsAgency() ? showDeleteDocModal : showDeleteResponseModal;

  const handleDueDateChange = useCallback(
    (id: number, date: Dayjs | null) => {
      if (!date) {
        return;
      }
      const selectedDocResponse = requiredDocumentResponses.find(doc => {
        return doc.bidAwardRequiredDocId === id;
      });
      if (selectedDocResponse) {
        const { bidAwardRequiredDocId, docTitle, isDelete } = selectedDocResponse;
        const updatedRequiredDoc: RequiredDocument = {
          dueDate: date.format(),
          bidAwardRequiredDocId,
          docName: '',
          docTitle,
          docDescription: '',
          isDelete,
        };
        updateRequiredDocs([updatedRequiredDoc]);
      }
    },
    [requiredDocumentResponses, updateRequiredDocs],
  );

  /** Column configurations */
  const getColumns = useCallback(
    ({
      handleDeleteRowClick,
      handleDownloadClick,
      showDelete,
    }: RequiredDocumentTableRowSelectionProps) => {
      const handleDeleteClick = handleDeleteRowClick || noOpFunction;
      const handleDownload =
        !documentDownloading && handleDownloadClick ? handleDownloadClick : noOpFunction;
      const deleteIdField = selectedMemberIsAgency()
        ? 'bidAwardRequiredDocId'
        : 'bidAwardRequiredDocResponseId';

      const columns: Column<RequiredDocumentResponseGetAPIResponse>[] = [
        {
          accessor: 'docName',
          Cell: LinkTextCell({
            idField: 'bidId',
            downloadIdField: 'bidDocId',
            onClick: handleDownload,
            labelField: 'docName',
          }),
          Header: requiredDocLabels.responseDocumentTitle,
          minWidth: 300,
        },
        {
          accessor: 'docTitle',
          Header: documentLabels.docType,
          minWidth: 300,
        },
        {
          accessor: 'dueDate',
          Cell: editEnabled
            ? (cellData: Cell<RequiredDocumentResponseGetAPIResponse>) => {
                const requiredDoc = { ...cellData.row.original };
                return (
                  <DSDatePicker
                    name={`dueDate-${cellData.row.id}`}
                    onChange={value => {
                      handleDueDateChange(requiredDoc?.bidAwardRequiredDocId, value);
                    }}
                    disabledDate={isTodayOrEarlier}
                    allowClear={false}
                    value={dayjs(requiredDoc.dueDate)}
                  />
                );
              }
            : DateCell<RequiredDocumentResponseGetAPIResponse>({
                dateField: 'dueDate',
              }),
          Header: commonFieldNames.dueDate,
          minWidth: 200,
        },
        {
          Header: commonFieldNames.status,
          accessor: 'bidAwardRequiredDocResponseId',
          Cell: RequiredDocumentStatusCell,
          minWidth: 200,
        },
      ];

      if (showDelete) {
        columns.push({
          Header: '',
          accessor: 'isDelete',
          Cell: DeleteIconCell<number, RequiredDocumentResponseGetAPIResponse>({
            idField: deleteIdField,
            labelField: 'docTitle',
            handleClick: handleDeleteClick,
          }),
          maxWidth: 30,
          disableSortBy: true,
        });
      }

      return columns;
    },
    [documentDownloading, editEnabled, handleDueDateChange, selectedMemberIsAgency],
  );

  const columnConfig = useMemo(
    () =>
      getColumns({
        showDelete,
        handleDeleteRowClick: deleteRowHandler,
        handleDownloadClick: handleDownloadLinkClick,
        updateRow: noOpFunction,
      }),
    [deleteRowHandler, getColumns, handleDownloadLinkClick, showDelete],
  );

  /** For `showSingleRow` display, filter `requiredDocs` to just the selected RequiredDoc. */
  const filteredRequiredDocs = useMemo(
    () =>
      !showSingleRow
        ? requiredDocumentResponses
        : requiredDocumentResponses.filter(
            doc => doc.bidAwardRequiredDocId === selectedRequiredDocument.bidAwardRequiredDocId,
          ),
    [requiredDocumentResponses, selectedRequiredDocument.bidAwardRequiredDocId, showSingleRow],
  );

  const docDisplayName = selectedRequiredDocument
    ? selectedRequiredDocument.docTitle
    : 'this document';

  const docResponseDisplayName = selectedRequiredDocument
    ? `the response for ${selectedRequiredDocument.docName}`
    : 'the response for this document';

  // Functions //

  /** Delete row */

  function hideDeleteModal() {
    setDeleteModalIsOpen(false);
    resetSelectedRequiredDocument();
  }

  function hideDeleteResponseModal() {
    setDeleteResponseModalIsOpen(false);
    resetSelectedRequiredDocument();
  }

  function showDeleteDocModal({ id }: Id<number>) {
    const selectedDoc = requiredDocumentResponses.find(doc => {
      return doc?.bidAwardRequiredDocId === id;
    });
    Assert(
      !!selectedDoc,
      'Expected: `selectedDoc` to exist in requiredDocuments (for reqDoc - delete)',
      'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentResponsesTable.tsx',
    );

    if (selectedDoc) {
      setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
      setDeleteModalIsOpen(true);
    } else {
      deleteRequiredDocument();
    }
  }

  function showDeleteResponseModal({ id }: Id<number>) {
    const selectedDoc = requiredDocumentResponses.find(doc => {
      return doc.bidAwardRequiredDocResponseId > 0 && doc.bidAwardRequiredDocResponseId === id;
    });
    Assert(
      isDefined(selectedDoc),
      'Expected: `selectedDoc` to exist in requiredDocuments (for reqDocResponse - delete)',
      'src/components/buyer/awardbid/add-vendor-docs/RequiredDocumentResponsesTable.tsx',
    );
    if (selectedDoc) {
      setSelectedRequiredDocument({ ...selectedDoc, isDelete: false });
      setDeleteResponseModalIsOpen(true);
    }
  }

  async function deleteRequiredDocument() {
    if (selectedRequiredDocument) {
      await deleteRequiredDoc(convertResponseToRequiredDoc(selectedRequiredDocument));
    }
    await refreshRequiredActions();
    hideDeleteModal();
  }

  async function deleteRequiredDocResponse() {
    //TODO: Implement tryCatchLog()
    try {
      const deleteResponse = await deleteRequiredDocumentResponseFromService({
        bidId: selectedBidId,
        bidAwardRequiredDocResponseId: selectedRequiredDocument.bidAwardRequiredDocResponseId,
        isDelete: true,
        memberId: supplierMemberId,
      });

      // refresh the requiredDoc state.
      if (deleteResponse?.bidAwardRequiredDocId === 0) {
        await refreshRequiredDocumentResponses();
      }

      // Refresh the view with the updated required Actions.
      // TODO: move deletion handler to a property and/or create separate table components.
      await refreshRequiredActions();
    } catch (error) {
      track('RequiredDocumentResponsesTable -> deleteRequiredDocResponse() ERROR:', {
        error,
        errorMessage: (error as any).message,
      });
      console.error(
        `RequiredDocumentResponsesTable -> deleteRequiredDocResponse() ERROR: \n${error}`,
      );
    }

    hideDeleteResponseModal();
  }

  // Effects //
  useEffect(() => {
    if (supplierMemberId > 0) {
      refreshRequiredDocumentResponses();
    }
  }, [
    refreshRequiredDocumentResponses,
    selectedBidAwardee,
    selectedBidId,
    supplierMemberId,
    selectedRequiredActionId,
  ]);

  return (
    <>
      <DSTable
        columns={columnConfig}
        data={filteredRequiredDocs}
        emptyMessage={commonLabels.notFound}
        sort={'dueDate'}
      />

      {/* Delete Required Document confirmation modal */}
      <ConfirmModal
        onConfirm={deleteRequiredDocument}
        title={commonLabels.confirmDelete}
        closeModal={hideDeleteModal}
        isOpen={deleteModalIsOpen}
        size='md'
        danger='delete'
      >
        {ControlLabels.confirmDeletePrefix} <BoldText>{docDisplayName}</BoldText>?
      </ConfirmModal>

      {/* Delete Required Document Response confirmation modal */}
      <ConfirmModal
        onConfirm={deleteRequiredDocResponse}
        title={commonLabels.confirmDelete}
        closeModal={hideDeleteResponseModal}
        isOpen={deleteResponseModalIsOpen}
        danger='delete'
      >
        {ControlLabels.confirmDeletePrefix} <BoldText>{docResponseDisplayName}</BoldText>?
      </ConfirmModal>
    </>
  );
}
