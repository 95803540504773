import { useCallback, useState } from 'react';

import { DSButton } from '@demandstar/components/button';
import { FlexContainer } from '@demandstar/components/styles';

import { OpsActionsCopy, ProductsPaths } from 'src/utils/texts/supplier/subscription';

import { commonLabels } from 'src/shared/constants';
import { ModalPopUp } from 'src/components/common/modals';
import { useRouter } from 'src/shared/hooks';
import { useSubscription } from 'src/shared/hooks/useSubscription';

/**
 * @description a self-contained OPS-styled button that opens a modal that allows for the cancellation of
 * a member's subscription
 * @returns JSX.Element
 *
 * @example <CancelSubscription />
 */
export const CancelSubscription = () => {
  const { history } = useRouter();
  const { cancelSubscription } = useSubscription();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setCancelModalOpen(!cancelModalOpen);
  }, [cancelModalOpen]);

  const confirmCancel = useCallback(async () => {
    await cancelSubscription();
    setCancelModalOpen(false);
    history.push(ProductsPaths.CurrentSubscription);
  }, [cancelSubscription, history]);

  return (
    <>
      <DSButton
        type={'ops'}
        title={OpsActionsCopy.CancelSubscription}
        onClick={() => {
          setCancelModalOpen(!cancelModalOpen);
        }}
      >
        {OpsActionsCopy.CancelSubscription}
      </DSButton>
      <ModalPopUp
        title={OpsActionsCopy.CancelSubscription}
        size='md'
        isOpen={cancelModalOpen === true}
        closeModal={() => setCancelModalOpen(false)}
        backdrop='static'
      >
        <p>{OpsActionsCopy.CancelAreYouSure}</p>
        <FlexContainer justifyContent='space-between'>
          <DSButton type='secondary' onClick={handleClick}>
            {commonLabels.no}
          </DSButton>
          <DSButton type='primary' onClick={confirmCancel}>
            {commonLabels.yes}
          </DSButton>
        </FlexContainer>
      </ModalPopUp>
    </>
  );
};
