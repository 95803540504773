import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  authMemberIdState,
  selectedMemberTypeState,
  supplierMemberIdState,
} from '../../store/recoil/memberState';

import { AuthState } from '../../store/reducers/auth';

// TODO: 6915
import { defaultNumericId } from '../constants';
import { MemberType } from '../../utils/constants';

/**
 * @deprecated use useAuth() instead.
 * @example const { memberId } = useAuth();
 */
export function useAuthMemberId() {
  const [authMemberId, setAuthMemberId] = useRecoilState(authMemberIdState);
  function resetSelectedMemberId() {
    setAuthMemberId(defaultNumericId);
  }
  return { resetSelectedMemberId, authMemberId, setAuthMemberId };
}

/**
 * Helper fn to save a step when reading/writing to `selectedMemberIdState`,
 * @returns
 */
export function useSupplierMemberId() {
  const [supplierMemberId, setSupplierMemberId] = useRecoilState(supplierMemberIdState);
  function resetSelectedMemberId() {
    setSupplierMemberId(defaultNumericId);
  }
  return { resetSelectedMemberId, supplierMemberId, setSupplierMemberId };
}

/**
 * Helper fn to save member type in `selectedMemberTypeState`,
 * @returns
 */
export function useSelectedMemberType() {
  const [selectedMemberType, setSelectedMemberType] = useRecoilState(selectedMemberTypeState);
  function resetSelectedMemberType() {
    setSelectedMemberType('');
  }
  function selectedMemberIsAgency() {
    return selectedMemberType === MemberType.AgencyBuyer;
  }
  return {
    selectedMemberIsAgency,
    resetSelectedMemberType,
    selectedMemberType,
    setSelectedMemberType,
  };
}

/**
 * Helper fn to set member info when AuthState changes.
 * Also sets `supplierMemberIdState` value for supplier members.
 * TODO: move auth out of redux and enforce types.
 */

// TODO: 6915
// export function useSetMemberInfo(auth: Authorization) {
export function useSetMemberInfo(auth: AuthState) {
  const { setAuthMemberId } = useAuthMemberId();
  const { setSupplierMemberId } = useSupplierMemberId();
  const { setSelectedMemberType, selectedMemberIsAgency } = useSelectedMemberType();
  useEffect(() => {
    const { mt, mi } = auth;
    if (mi) {
      setAuthMemberId(mi);
      // Set the supplierId as the authId for non-agency users.
      if (!selectedMemberIsAgency()) {
        setSupplierMemberId(mi);
      }
    }
    if (mt) {
      setSelectedMemberType(mt as MemberType);
    }
  }, [auth, selectedMemberIsAgency, setAuthMemberId, setSelectedMemberType, setSupplierMemberId]);
}

/** ensures that the user on the page has the proper memberId.
 * Redirects to new page if they do not have credentials.
 * Does nothing if passed a falsy value.
 * @example useEnforceMemberId(contractDetails?.memberId);
 */
export function useEnforceMemberId(matchMemberId?: number, path?: string) {
  const history = useHistory();
  const authMemberId = useRecoilValue(authMemberIdState);
  useEffect(() => {
    if (matchMemberId && authMemberId > 0 && matchMemberId !== authMemberId) {
      history.push(path || '/');
    }
  }, [authMemberId, history, matchMemberId, path]);
}
