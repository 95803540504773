import { connect, ConnectedProps } from 'react-redux';
import { memo, useCallback, useEffect } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { bindActionCreators } from 'redux';

import BillingInfo from './billinginfo';
import EditBillingInfo from './editbillinginfo';
import Navbars from '../../../customcontrols/navbar';
import OrdersList from './orderslist';
import PaymentHistory from './paymenthistory';
import Receipt from './receipt';

import * as actionCreators from '../../../../store/actions';
import { accountinfoType, paymentsType } from '../../../../types/paymentdetails';
import { scrollToTop } from '../../../../utils/helpers';
import { useSetBreadcrumb } from 'src/shared/hooks';

const navLinks = [
  {
    name: 'Billing Info',
    id: '1',
  },
  {
    name: 'Payment History',
    id: '2',
  },
  {
    name: 'Orders',
    id: '3',
  },
];

const initialval = {
  activeTab: '1',
  isEditBilling: false,
  isPrintReceipt: false,
  paymentInfo: {},
};

interface propsTypes extends PropsFromRedux {
  accountinfo: accountinfoType;
  paymentInfo: paymentsType;
  addUpdateBTProfile: any;
  setAccountInfoDetails: any;
  getPaymentHistory: any;
  getOrders: any;
  setOrderFilter: any;
  auth: {
    fln: string;
    eml: string;
    mt: string;
    opi: number;
  };
  memberinfo: {
    mn: string;
  };
}

const Payments = (props: propsTypes) => {
  const { addUpdateBTProfile, setAccountInfoDetails, accountinfo, getPaymentHistory, getOrders } =
    props;
  const { paymentvals } = accountinfo || {};
  const { activeTab, isEditBilling, isPrintReceipt, paymentInfo } = paymentvals || {};

  const breadcrumbLabel = navLinks.find(x => x.id === activeTab)?.name || 'Billing Info';

  useSetBreadcrumb({
    page: 'Payments',
    title: breadcrumbLabel,
    altname: breadcrumbLabel,
    name: breadcrumbLabel,
  });

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setAccountInfoDetails({
        paymentvals: {
          ...paymentvals,
          activeTab: tab,
          isEditBilling: false,
          isPrintReceipt: false,
        },
      });
    }
  };

  const handleEditClick = useCallback(() => {
    scrollToTop();
    setAccountInfoDetails({ paymentvals: { ...paymentvals, isEditBilling: true } });
  }, [paymentvals, setAccountInfoDetails]);

  const handleEditPayment = useCallback(() => {
    scrollToTop();
    setAccountInfoDetails({ paymentvals: { ...paymentvals, isEditBilling: true } });
  }, [paymentvals, setAccountInfoDetails]);

  const cancelEditPayment = useCallback(() => {
    scrollToTop();
    setAccountInfoDetails({ paymentvals: { ...paymentvals, isEditBilling: false } });
  }, [paymentvals, setAccountInfoDetails]);

  const handleCancelClick = useCallback(() => {
    scrollToTop();
    setAccountInfoDetails({ paymentvals: { ...initialval, activeTab } });
  }, [activeTab, setAccountInfoDetails]);

  const handlePrintClick = (paymentInfo: paymentsType) => {
    setAccountInfoDetails({ paymentvals: { ...paymentvals, isPrintReceipt: true, paymentInfo } });
  };

  const UpdateCardDetails = (values: { nonce: string }) => {
    scrollToTop();
    addUpdateBTProfile({ nonce: values.nonce });
  };

  useEffect(() => {
    getPaymentHistory();
  }, [getPaymentHistory]);

  useEffect(() => {
    return () => {
      setAccountInfoDetails({ reloadBillingInfo: true });
    };
  }, [setAccountInfoDetails]);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <Navbars toggle={toggle} activeTab={activeTab} navlinks={navLinks} />
            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                {activeTab === '1' && (
                  <>
                    {!isEditBilling ? (
                      <BillingInfo
                        {...props}
                        handleEditClick={handleEditClick}
                        handleEditPayment={handleEditPayment}
                      />
                    ) : (
                      <EditBillingInfo
                        {...props}
                        cancelEditPayment={cancelEditPayment}
                        UpdateCardDetails={UpdateCardDetails}
                        handleCancelClick={handleCancelClick}
                      />
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tabId='2'>
                {activeTab === '2' && (
                  <>
                    {!isPrintReceipt ? (
                      <PaymentHistory {...props} handlePrintClick={handlePrintClick} />
                    ) : (
                      <Receipt
                        {...props}
                        handleCancelClick={handleCancelClick}
                        paymentInfo={paymentInfo}
                        initialval={initialval}
                      />
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tabId='3'>
                {activeTab === '3' && (
                  <>
                    <OrdersList {...props} />
                  </>
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

const connector = connect(
  (state: any) => ({
    accountinfo: state.accountinfo,
    paymentInfo: state.paymentInfo,
    auth: state.auth,
    memberinfo: state.memberinfo,
    shared: state.shared,
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(memo(Payments));
