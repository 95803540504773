// TOREFACTOR
// - fix all wrongcased names, for example: getaccountinforequiredDocs

import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Flex, FlexContainer } from '@demandstar/components/styles';
import { DSButton } from '@demandstar/components/button';

import {
  AddBidDocTypeOption,
  BidDocument,
  DocStatus,
  DocTypeLabel,
  DocTypeValue,
} from '../../types/addbid';
import { AwardBidEventName, useAwardBidEvents } from '../../shared/hooks/amplitude/awardBid.events';
import {
  bidsSummary,
  getaccountinforequiredDocs,
  getAddBidBidsDocuments,
  getAddBidDocTypes,
  getAddBidIsPermissions,
  setAddBidDetails,
  submitAddBidDocumentUpload,
} from '../../store/actions';
import { generateKey, toastFn } from '../../utils/helpers';
import { deleteActiveBidDocConfirmMsgText, deleteDocConfirmMsgText } from '../../utils/texts';
import { AcceptedFileFormatsModal } from './AcceptedFileFormatsModal';
import { ApproveDocument } from '../common/createbid/modals/approvedocument';
import { BidApiResponse } from '../../types/bidssummary';
import { BidExternalStatusType } from '../../utils/constants';
import { ConfirmModal } from './modals/ConfirmModal';
import { DeleteDocumentModal } from './createbid/modals/DeleteDocumentModal';
import { documentLabels } from '../../shared/constants';
import DocumentsOptions from './createbid/modals/DocumentOptions';
import { DocumentUploadTableRow } from './DocumentUploadTableRow';
import { ModalPopUp } from './modals/ModalPopUp';
import { NoResult } from '../customcontrols';
import { TimeZone } from '../../utils/constants';
import { useDSSelector } from '../../store/reducers';

interface DocumentUploadTableProps {
  bidId: number;
  type: 'Award' | 'Bid';
  addLabel?: string;
}

// TODO: Worth looking at how we want to do tables site-wide.
const divStyle = {
  td1: { width: '25%' },
  td2: {
    width: '20%',
  },
  td3: {
    width: '15%',
  },
  td4: {
    width: '6%',
  },
  td5: {
    width: '40px',
  },
};

export const DocumentUploadTable = (props: DocumentUploadTableProps) => {
  const dispatch = useDispatch();
  const { logEvent } = useAwardBidEvents();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { bidId, addLabel, type } = props;

  const auth = useDSSelector(state => state.auth);
  const { opi = '' } = auth || {};

  const addMoreDocsButtonLabel = addLabel || documentLabels.addMoreDocuments;

  const [showErrors, setShowErrors] = useState(false);

  // * DOCUMENTS
  const documents = useDSSelector(state => state.addbid.documentslist || []);
  const uploadableDocs = documents.filter(doc => !doc.bidDocId) || [];
  useEffect(() => {
    // this adds bidDocuments to the state for us to manipulate.
    if (bidId > 0) {
      dispatch(getAddBidBidsDocuments({ bidId }));
    }
  }, [dispatch, bidId]);

  // * PERMISSIONS
  const canAdd = useDSSelector(state => state.addbid.permissions.canAdd);
  const isMainContact = useDSSelector(state => state.auth.mc);
  // this useEffect gets our permissions
  useEffect(() => {
    if (bidId > 0) {
      dispatch(getAddBidIsPermissions({ bidId }));
    }
  }, [dispatch, bidId]);

  // * DOC TYPES
  const docTypes = useDSSelector(state => state.addbid.doctypes);
  // this gets our doctypes if we don't have them already
  useEffect(() => {
    if (!docTypes || !docTypes.length) {
      dispatch(getAddBidDocTypes({ bidId, type }));
    }
  }, [bidId, type, dispatch, docTypes]);

  // * UPLOAD PROGRESS
  const uploadProgress = useDSSelector(state => state.addbid.upload_progress);

  const documentUploadToastId = '5c972335-a2d5-4380-880e-ab29135712db';

  const bidExternalStatusType = useDSSelector(
    state =>
      (state.bidssummary.results as BidApiResponse).bidExternalStatusType ||
      BidExternalStatusType.None,
  );

  useEffect(() => {
    /** This updates the state with account information.
     * Specifically, it gets us DocAllowedExt, convertableTypes [sic]
     * It might be better to do this once when the app first starts up,
     * but I'm following precedent for now. */
    // TODO: Replace this with Recoil state. (src/store/recoil/memberState.ts)
    dispatch(getaccountinforequiredDocs());
  }, [dispatch]);

  useEffect(() => {
    /** gets our bidSummary results,
     * which we need in order to accurately represent our progress.*/
    if (bidId > 0) {
      dispatch(bidsSummary({ bidId, otherapis: true }));
    }
  }, [bidId, dispatch]);

  function addNewDocumentRow() {
    const list = documents
      .filter(doc => !doc.bidDocId)
      .filter(doc => !doc.docfile || !doc.doctitle.trim() || !doc.doctype);
    if (list.length > 0) {
      setShowErrors(true);
      toastFn('error', 'Please Complete Previous', documentUploadToastId);
    } else {
      setShowErrors(false);
      const newDocList = [...documents];
      newDocList.push({
        bidDocId: '',
        bidId: '',
        docconversion: false,
        docext: '',
        docfile: '',
        doctitle: '',
        doctype: '',
        docType: DocTypeValue.None,
        id: generateKey('doc'),
        isExist: false,
        modifiedDate: dayjs().tz(TimeZone.Eastern).format(),
        progress: 0,
        statusType: DocStatus.None,
        uploadprocess: 'notstarted',
      });
      dispatch(setAddBidDetails({ documentslist: newDocList, halfWayCancel: true }));
    }
  }

  /** Save and Upload All Pending Documents */
  function uploadDocuments() {
    if (uploadableDocs.length > 0) {
      const checkValidation = uploadableDocs.filter(
        doc => !doc.doctitle.trim() || !doc.docfile || !doc.doctype,
      );
      if (checkValidation.length === 0) {
        const addendums = uploadableDocs.filter(doc => doc.docType === DocTypeValue.Addendum);
        const awardDocCount = uploadableDocs.filter(
          doc => doc.docType === DocTypeValue.AwardDocument,
        ).length;
        dispatch(setAddBidDetails({ newDocAdded: true }));

        if (addendums.length > 0 && bidExternalStatusType === BidExternalStatusType.Active) {
          toggleDocumentOptionModal();
        } else {
          if (awardDocCount) {
            logUploadAmplitude(awardDocCount);
          }
          dispatch(submitAddBidDocumentUpload({ bidId }));
        }
        setShowErrors(false);
      } else {
        setShowErrors(true);
        toastFn('error', 'Please fill all required fields', documentUploadToastId);
      }
    }
  }

  function logUploadAmplitude(awardDocCount: number) {
    logEvent({
      eventName: AwardBidEventName.UploadPublicDocs,
      eventProps: {
        'Award Documents Uploaded': awardDocCount,
      },
    });
  }

  // * Modals
  const approveDocumentModal = useDSSelector(state => state.addbid.approvedocumentmodal || false);
  const deleteDocumentModal = useDSSelector(state => state.addbid.deletedocumentmodal || false);
  const documentOptionsModal = useDSSelector(state => state.addbid.documentsoptionsmodal);
  const [fileTypeModalVisible, setFileTypeModalVisible] = useState(false);

  // * Approve
  function toggleApproveDocModal(doc?: BidDocument) {
    dispatch(
      setAddBidDetails({
        approvedocumentmodal: !approveDocumentModal,
        approvedocumentdetails: doc,
      }),
    );
  }

  // * Delete
  const [nonDeleteAlert, setNonDeleteAlert] = useState(false);
  const [nonDeleteDocumentType, setNonDeleteDocumentType] = useState<DocTypeLabel>('');

  // ! FIXME: We never pass in a doc here. Remove that logic.
  function toggleDeleteDocModal(doc?: BidDocument) {
    if (!doc) {
      return dispatch(
        setAddBidDetails({
          deletedocumentmodal: false,
          deleteDocumentDetails: undefined,
        }),
      );
    }
    const docType: DocTypeValue = doc
      ? doc.docType || (doc.doctype as AddBidDocTypeOption).value
      : DocTypeValue.None;
    const existType = docTypes?.filter(type => type.value === docType) || [];
    if (existType.length === 0) {
      setNonDeleteAlert(true);
      setNonDeleteDocumentType(doc ? doc.type || (doc.doctype as AddBidDocTypeOption).label : '');
    } else {
      dispatch(
        setAddBidDetails({
          deletedocumentmodal: !deleteDocumentModal,
          deleteDocumentDetails: doc,
        }),
      );
    }
  }
  // * Document Options
  function toggleDocumentOptionModal() {
    dispatch(
      setAddBidDetails({
        documentsoptionsmodal: !documentOptionsModal,
      }),
    );
  }

  function toggleNonDeleteAlert() {
    setNonDeleteAlert(!nonDeleteAlert);
  }

  return (
    <>
      <Table className='tableHData'>
        <thead>
          <tr data-testid='addbid.documentupload.documents.list.head'>
            <th style={divStyle.td1}>Title</th>
            <th style={divStyle.td2}>Type</th>
            <th style={divStyle.td3}>Status</th>
            <th style={divStyle.td4}>Convert to PDF</th>
            <th style={divStyle.td5}></th>
          </tr>
        </thead>
        <tbody data-testid='addbid.documentupload.documents.list.body'>
          {documents.length > 0 ? (
            <>
              {documents.map((doc, index) => (
                <DocumentUploadTableRow
                  key={index}
                  doc={doc}
                  index={index}
                  bidId={bidId}
                  setNonDeleteAlert={setNonDeleteAlert}
                  setNonDeleteDocumentType={setNonDeleteDocumentType}
                  setFileTypeModalVisible={setFileTypeModalVisible}
                  documentUploadToastId={documentUploadToastId}
                  showErrors={showErrors}
                />
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={5}>
                <NoResult message={'No Documents Available'} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <FlexContainer justifyContent='flex-end'>
        <Flex grow={0}>
          {canAdd || isMainContact ? (
            <DSButton
              title={addMoreDocsButtonLabel}
              type='secondary'
              onClick={addNewDocumentRow}
              inactive={uploadProgress}
            >
              {addMoreDocsButtonLabel}
            </DSButton>
          ) : null}
        </Flex>
        <Flex grow={0}>
          {canAdd || isMainContact ? (
            <DSButton
              type='tertiary'
              onClick={uploadDocuments}
              inactive={uploadProgress || uploadableDocs.length === 0}
            >
              {uploadableDocs.length > 1 ? 'Save & Upload All' : 'Save & Upload'}
            </DSButton>
          ) : null}
        </Flex>
      </FlexContainer>
      {/* TODO: Replace both of these with regular ConfirmModal dialog boxes. */}
      <ModalPopUp
        size='md'
        title='Confirmation'
        closeModal={() => toggleApproveDocModal()}
        isOpen={approveDocumentModal}
      >
        <ApproveDocument bidId={bidId} SetApproveDocument={toggleApproveDocModal} />
      </ModalPopUp>
      <DeleteDocumentModal
        /**
         * ! Do not replace with `closeModal={toggleDeleteDocModal}`.
         * The function wants to pass in nothing instead of a ClickEvent*/
        closeModal={() => toggleDeleteDocModal()}
        isOpen={deleteDocumentModal}
        bidId={bidId}
        type='bid'
        message={opi > 0 && (bidExternalStatusType === BidExternalStatusType.Active || bidExternalStatusType === BidExternalStatusType.Cancelled) ? deleteActiveBidDocConfirmMsgText : deleteDocConfirmMsgText}
        
      />
      <AcceptedFileFormatsModal
        isOpen={fileTypeModalVisible}
        closeModal={() => setFileTypeModalVisible(false)}
      />
      <ConfirmModal
        onConfirm={toggleNonDeleteAlert}
        onlyConfirm={true}
        title='Alert Message'
        closeModal={toggleNonDeleteAlert}
        isOpen={nonDeleteAlert}
      >
        {`To avoid confusing suppliers, ${nonDeleteDocumentType} documents cannot be deleted once your bid has been broadcast.
         If you need to make updates to this document, please upload an Addendum. This will trigger a notification to suppliers that
          a new document has been uploaded.`}
      </ConfirmModal>

      <ModalPopUp
        size='md'
        title={'Change Due Date'}
        closeModal={toggleDocumentOptionModal}
        isOpen={documentOptionsModal}
        backdrop='static'
      >
        <DocumentsOptions bidId={bidId} />
      </ModalPopUp>
    </>
  );
};
