import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useCallback } from 'react';

import { authErrorState, authState } from 'src/store/recoil/authState';
import { Authorization, GetAuthTokenRequest } from 'src/types';
import { getCookie, setCookie } from 'src/utils/cookie';
import { isLoggingOut, memberCookieName, tokenCookieName } from 'src/utils/constants';

import { authMessaging } from '../constants';
import { getAuthToken } from 'src/service/auth';
import { getTokenData } from 'src/utils/token';
import { track } from 'src/utils/telemetry';

export function useAuth() {
  const [auth, setAuth] = useRecoilState(authState);
  const [authError, setAuthError] = useRecoilState(authErrorState);

  /**
   * @param: {GetAuthTokenRequest} payload
   * @returns: {Authorization} tokenData
   * @todo: move all legacy login logic into this fn.
   */

  const handleLogin = useCallback(
    async ({ userName, password }: GetAuthTokenRequest) => {
      try {
        // API call
        const loginResponse = await getAuthToken({ userName, password });

        // Bypass normal token parsing and state for `isBidVisitExceeded` flag.
        // Set Auth object directly for redirect.
        if (loginResponse.isBidVisitExceeded) {
          setAuth({
            isBidVisitExceeded: true,
            bidVisitExceededUrl: loginResponse.url,
          } as Authorization);
          // return;
        } else if (loginResponse.token) {
          // Setting authTokenState will update authState via the authState selector.
          const tokenData = getTokenData(loginResponse.token);
          setAuth(tokenData);
          // Set cookie auth
          setCookie(memberCookieName, tokenData.memberId, 1);
          setCookie(tokenCookieName, loginResponse.token, 1);
          sessionStorage.removeItem(isLoggingOut);
          return tokenData;
        } else if (!loginResponse.token) {
          setAuthError(authMessaging.loginError);
        }
      } catch (error: any) {
        track('handleLogin -> getAuthToken() ERROR:', {
          error,
          method: 'GET',
          errorMessage: error.message,
        });
        if (error.toString().indexOf('401')) {
          setAuthError(authMessaging.loginError);
        }
        if (error.message) console.error('handleLogin -> getAuthToken() ERROR:', error);
      }
    },
    [setAuth, setAuthError],
  );

  function handleLoginUsingCookie() {
    const token = getCookie(tokenCookieName);
    if (token && token.length > 0) {
      const auth = getTokenData(token);
      setAuth(auth);
    }
  }

  const resetAuthError = useResetRecoilState(authErrorState);

  const { memberId, memberType } = auth;

  return {
    auth,
    authError,
    handleLogin,
    handleLoginUsingCookie,
    memberId,
    memberType,
    resetAuthError,
    setAuth,
    setAuthError,
  };
}
