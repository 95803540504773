import { axiosBarePostAuthenticated, axiosBareRequestAuthenticated } from 'src/store/services/api';
import { DocumentAPIResponse, DocumentAPIUploadRequest } from './documents.d';
import { constants } from 'src/utils/settings';
import { paths } from './Documents.paths';

export function downloadDocument(
  documentId: string,
  handleDownloadProgress?: (event: ProgressEvent) => void,
) {
  return axiosBareRequestAuthenticated<ArrayBuffer>({
    baseURL: constants.api.contractDocumentUrl,
    url: paths.api.documents.download(documentId),
    onDownloadProgress: handleDownloadProgress,
    responseType: 'arraybuffer',
  });
}

export function getDocument(documentId: string) {
  return axiosBareRequestAuthenticated<DocumentAPIResponse>({
    baseURL: constants.api.contractDocumentUrl,
    url: paths.api.documents.get(documentId),
  });
}

export function getDocumentList(documentIdList: string[]) {
  return axiosBareRequestAuthenticated<DocumentAPIResponse[]>({
    baseURL: constants.api.contractDocumentUrl,
    url: paths.api.documents.getList(documentIdList),
  });
}

export function uploadDocument(
  upload: DocumentAPIUploadRequest,
  handleUploadProgress?: (event: ProgressEvent) => void,
) {
  const formData = new FormData();
  formData.append('file', upload.file);
  formData.append('title', upload.title || '');
  formData.append('whitelistMemberId', upload.whitelistMemberId?.toString() || '0');

  // temporary, types should move to other services (e.g. contracts)
  formData.append('type', 'Other');

  return axiosBarePostAuthenticated<DocumentAPIResponse>({
    baseURL: constants.api.contractDocumentUrl,
    data: formData,
    url: paths.api.documents.upload,
    onUploadProgress: handleUploadProgress,
  });
}
